import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery } from 'hooks';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Breadcrumb.sass';

function Breadcrumb({
  isTemplateOwner = false,
  isEdit = false,
  isPreview = false,
  templateStatus = '',
  templateIsShared = false,
}) {
  const intl = useIntl();

  const { system } = useQuery();

  const templateStatusSearch = new URLSearchParams({
    status: templateIsShared
      ? `${isTemplateOwner ? 'draft' : 'shared'}`
      : templateStatus,
  });
  const templateLibrarySearch = new URLSearchParams({
    category: 'all',
  });

  return (
    <div aria-label="landing pages breadcrumb" className="dnd-breadcrumb">
      <Link to={{ pathname: '/' }}>
        {templateStatus ? (
          <FormattedMessage id="breadcrumb.dashboard-title-1" />
        ) : (
          <FormattedMessage id="breadcrumb.dashboard-title-2" />
        )}
      </Link>
      {templateStatus && (
        <>
          <span className="dnd-breadcrumb__separator">
            <span className="icon icon-chevron-right" />
          </span>
          {system && system === 'true' ? (
            <Link
              className="dnd-breadcrumb__template-status"
              to={{ pathname: '/', search: String(templateLibrarySearch) }}>
              <FormattedMessage id="editor-header.system-templates-title" />
            </Link>
          ) : (
            <Link
              className="dnd-breadcrumb__template-status"
              to={{ pathname: '/', search: String(templateStatusSearch) }}>
              {templateIsShared
                ? intl.formatMessage({
                    id: isTemplateOwner
                      ? 'breadcrumb.dashboard-owner-shared-status-title'
                      : 'breadcrumb.dashboard-shared-status-title',
                  })
                : templateStatus}
            </Link>
          )}
        </>
      )}
      {isEdit && (
        <>
          <span className="dnd-breadcrumb__separator">
            <span className="icon icon-chevron-right" />
          </span>
          <strong>
            <FormattedMessage id="breadcrumb.current-edit-template" />
          </strong>
        </>
      )}
      {isPreview && (
        <>
          <span className="dnd-breadcrumb__separator">
            <span className="icon icon-chevron-right" />
          </span>
          <strong>
            <FormattedMessage id="breadcrumb.preview-title" />
          </strong>
        </>
      )}
    </div>
  );
}

Breadcrumb.propTypes = {
  isTemplateOwner: PropTypes.bool,
  isEdit: PropTypes.bool,
  isPreview: PropTypes.bool,
  templateStatus: PropTypes.string,
  templateIsShared: PropTypes.bool,
};

export default Breadcrumb;
