import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ImageItem.sass';

const ImageItem = React.forwardRef(function ImageItem(
  {
    selected,
    thumbUrl,
    description,
    userUrl,
    userName,
    type,
    onItemClick,
    onDelete,
    ...rest
  },
  ref,
) {
  return (
    <div
      aria-label="image item"
      role="button"
      tabIndex="0"
      className={cx('dnd-image-item', { icon: type === 'icon', selected })}
      onClick={onItemClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onItemClick();
        }
      }}
      ref={ref}
      // rest of the props are for Tooltip
      {...rest}>
      <img
        className={cx('dnd-image-item__image', {
          icon: type === 'icon',
        })}
        src={thumbUrl}
        alt={description}
      />
      {type === 'default' && (
        <h2 className="dnd-image-item__image-name">{description}</h2>
      )}
      {typeof onDelete === 'function' && (
        <button
          aria-label="delete"
          type="button"
          className="dnd-image-item__delete-button"
          onClick={(e) => {
            e.stopPropagation();

            onDelete();
          }}>
          <span className="icon icon-close" />
        </button>
      )}
      {type === 'unsplash' && (
        <span className="dnd-image-item__item-author">
          <span>by</span>
          <a
            href={userUrl}
            target="_blank"
            rel="noopener noreferrer"
            // preventing click event if user just wants to go to the link url
            onClick={(e) => e.stopPropagation()}>
            {userName}
          </a>
        </span>
      )}
    </div>
  );
});

ImageItem.propTypes = {
  selected: PropTypes.bool,
  thumbUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  userUrl: PropTypes.string,
  userName: PropTypes.string,
  type: PropTypes.oneOf(['default', 'unsplash', 'icon']),
  onItemClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

ImageItem.defaultProps = {
  type: 'default',
};

export default ImageItem;
