import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { generateId } from 'helpers';
import Tag from 'components/Tag';
import FormHelperText from '../FormHelperText';
import Input from '../Input';
import FormControl from '../FormControl';
import Label from '../Label';
import './TextFieldTags.sass';

const TextFieldTags = React.forwardRef(function TextFieldTags(
  {
    type,
    className,
    label,
    id,
    error: errorProp,
    value,
    helperText,
    onChange,
    onValidate,
    fullWidth,
    after,
    ...rest
  },
  ref,
) {
  const inputId = useRef(generateId('dnd-text-field'));

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = ({ target: { value: newValue } }) => {
    if (!newValue) {
      setError('');
      return;
    }

    // validating entered to prevent invalid values in tags
    if (onValidate instanceof Function) {
      const error = onValidate(newValue);

      if (error) {
        setError(error);
        return;
      }

      setError('');
    }

    // preventing to add same tag twice
    if (value.includes(newValue)) {
      setInputValue('');
      return;
    }

    // adding a new tag
    onChange([...value, newValue]);
    setInputValue('');
  };

  const handleRemoveTag = (tag) => {
    onChange(value.filter((item) => item !== tag));
  };

  const handleKeyDown = (e) => {
    const newValue = e.target.value;

    // if user press backspace remove on of the tags
    if (e.key === 'Backspace' && !newValue) {
      handleRemoveTag(value[value.length - 1]);
      return;
    }

    if (e.key === 'Enter') {
      // preventing form submit for example
      e.preventDefault();

      handleAddTag(e);
    }
  };

  const formHelperText = error || helperText;
  const isError = errorProp || !!error;

  return (
    <FormControl fullWidth={fullWidth} className="dnd-text-field-tags">
      {label && <Label htmlFor={inputId.current}>{label}</Label>}
      <Input
        id={inputId.current}
        error={isError}
        before={value.map((item) => (
          <Tag key={item} name={item} onDelete={() => handleRemoveTag(item)}>
            {item}
          </Tag>
        ))}
        after={after}
        fullWidth={fullWidth}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleAddTag}
        aria-describedby={`${inputId.current}-helper-text`}
        {...rest}
        ref={ref}
      />
      {formHelperText && (
        <FormHelperText id={`${inputId.current}-helper-text`} error={isError}>
          {formHelperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

TextFieldTags.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  helperText: PropTypes.node,
  type: PropTypes.oneOf(['text', 'number', 'email']),
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  fullWidth: PropTypes.bool,
  after: PropTypes.node,
};

TextFieldTags.defaultProps = {
  type: 'text',
};

export default TextFieldTags;
