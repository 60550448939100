import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import './BlockSettingsBox.sass';

function BlockSettingsBox({ editor, formState }) {
  const intl = useIntl();

  const [pagesListToggle, setPagesListToggle] = useState(false);

  const handleFormBoxView = (state = 'normal') => {
    const setFormState = editor?.getModel()?.get('setFormState');

    editor.runCommand('change-block-settings-box-status', { state });

    setFormState(state);
  };

  return (
    <div
      style={{ display: 'none' }}
      className={cx('block-settings-box', {
        hide: pagesListToggle,
      })}>
      <button
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setPagesListToggle(!pagesListToggle);
          }
        }}
        onClick={() => setPagesListToggle(!pagesListToggle)}
        className="block-settings-box__toggle"
      />
      <div className="block-settings-box__header">
        <div>
          <span className="icon icon-settings" />
          <p>
            {intl.formatMessage({
              id: 'block-settings-box.title',
            })}
          </p>
        </div>
      </div>
      <span
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setPagesListToggle(!pagesListToggle);
          }
        }}
        onClick={() => setPagesListToggle(!pagesListToggle)}
        className="icon icon-close"
      />
      <div className="block-settings-box__content">
        <div className="block-settings-box__horiz-buttons">
          <button
            className={cx({ active: formState === 'normal' })}
            onClick={() => handleFormBoxView('normal')}>
            <FormattedMessage id="block-settings-box.buttons.main-form-title" />
          </button>
          <button
            className={cx({ active: formState === 'success' })}
            onClick={() => handleFormBoxView('success')}>
            <FormattedMessage id="block-settings-box.buttons.thank-title" />
          </button>
          <button
            className={cx({ active: formState === 'error' })}
            onClick={() => handleFormBoxView('error')}>
            <FormattedMessage id="block-settings-box.buttons.error-title" />
          </button>
        </div>
      </div>
    </div>
  );
}

BlockSettingsBox.propTypes = {
  editor: PropTypes.shape({
    runCommand: PropTypes.func,
    getModel: PropTypes.func,
  }),
  formState: PropTypes.oneOf(['normal', 'success', 'error']),
};

export default BlockSettingsBox;
