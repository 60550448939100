import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isStringWithSpecialSigns } from 'helpers';
import TextField from 'components/Forms/TextField';
import Button from 'components/Button';
import './TemplateSettingsModal.sass';
import CategoriesCombobox from 'components/CategoriesCombobox';

const messages = defineMessages({
  nameValidation: { id: 'general-error.name-validation' },
  descriptionValidation: { id: 'general-error.description-validation' },
  maxLenExceededValidation: {
    id: 'general-error.max-len-exceeded-validation',
  },
  nameEmptyError: { id: 'general-error.name-empty' },
  nameLabel: { id: 'page-settings-modal.page.name-label' },
  namePlaceholder: { id: 'page-settings-modal.page.name-placeholder' },
  nameRequiredError: { id: 'template-settings.name-required-error' },
  descriptionLabel: { id: 'page-settings-modal.page.description-label' },
  descriptionPlaceholder: {
    id: 'page-settings-modal.page.description-placeholder',
  },
  tagsPlaceholder: { id: 'new-template-modal.tags-placeholder' },
  tagsLabel: { id: 'new-template-modal.draft-tags-label' },
  cancelButton: { id: 'button.cancel' },
  saveButton: { id: 'button.save' },
});

function TemplateSettingsModal({ template, isSystem, onSave, onClose }) {
  const intl = useIntl();

  const {
    register,
    control,
    formState: { isDirty, isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: template?.name,
      description: template?.description,
      categories: template?.categories,
      tags: template?.tags,
    },
  });

  const handleSave = (payload) => {
    onSave({ id: template.id, isSystem, payload });
    onClose();
  };

  return (
    <form
      className="template-settings-modal__form"
      onSubmit={handleSubmit(handleSave)}>
      <div className="template-settings-modal__content">
        <TextField
          {...register('name', {
            required: intl.formatMessage(messages.nameEmptyError),
            maxLength: {
              value: 255,
              message: intl.formatMessage(messages.maxLenExceededValidation),
            },
            validate: (value) => {
              if (isStringWithSpecialSigns(value)) {
                return intl.formatMessage(messages.nameValidation);
              }
            },
          })}
          maxLength={255}
          label={intl.formatMessage(messages.nameLabel)}
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          helperText={errors.name?.message}
          error={Boolean(errors.name)}
          fullWidth
        />
        <TextField
          {...register('description', {
            maxLength: {
              value: 70,
              message: intl.formatMessage(messages.maxLenExceededValidation),
            },
            validate: (value) => {
              if (value.length > 0 && isStringWithSpecialSigns(value)) {
                return intl.formatMessage(messages.descriptionValidation);
              }
            },
          })}
          maxLength={70}
          label={intl.formatMessage(messages.descriptionLabel)}
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          error={Boolean(errors.description)}
          helperText={errors.description?.message}
          fullWidth
        />
        {isSystem ? (
          <Controller
            control={control}
            name="categories"
            render={({ field: { onChange, value } }) => (
              <CategoriesCombobox
                showNewCategoryItem
                value={value}
                onChange={onChange}
              />
            )}
          />
        ) : (
          <TextField
            {...register('tags')}
            fullWidth
            placeholder={intl.formatMessage(messages.tagsPlaceholder)}
            label={intl.formatMessage(messages.tagsLabel)}
          />
        )}
      </div>
      <div className="template-settings-modal__buttons">
        <Button
          type="button"
          color={isSystem ? 'secondary' : 'default'}
          onClick={onClose}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={!isDirty || !isValid}
          color={isSystem ? 'secondary' : 'default'}>
          {intl.formatMessage(messages.saveButton)}
        </Button>
      </div>
    </form>
  );
}

TemplateSettingsModal.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    tags: PropTypes.string,
  }),
  isSystem: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TemplateSettingsModal;
