import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { showErrorMessage, getErrorText } from 'helpers';
import { httpGetContactGroups } from 'api/contacts.api';
import SelectBox from 'components/SelectBox';

function GroupCombobox({ value, onChange, error }) {
  let newValue = {};

  const intl = useIntl();
  const [loading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  if (value) {
    const { id, name } = value;

    newValue = { value: id, label: name };
  } else {
    newValue = '';
  }

  useEffect(() => {
    setIsLoading(true);

    const getGroups = async () => {
      try {
        let newData = [];
        const { data } = await httpGetContactGroups();

        data.forEach(({ id, name, description, count }) =>
          newData.push({ value: id, label: name, description, count }),
        );

        setGroups(newData);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    getGroups();

    return () => {
      setIsLoading(false);
    };
  }, [setGroups]);

  return (
    <SelectBox
      isDisabled={loading}
      error={error}
      onChange={onChange}
      loading={loading}
      options={groups}
      defaultValue={newValue}
      tooltip={intl.formatMessage({
        id: 'page-settings-modal.forms.group-label-tooltip',
      })}
      label={intl.formatMessage({
        id: 'page-settings-modal.forms.group-label',
      })}
      placeholder={intl.formatMessage({
        id: 'page-settings-modal.forms.group-placeholder',
      })}
      description={intl.formatMessage({
        id: error
          ? 'page-settings-modal.forms.group-validation'
          : 'page-settings-modal.forms.group-description',
      })}
    />
  );
}

GroupCombobox.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default GroupCombobox;
