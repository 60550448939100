import { useReducer, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import produce from 'immer';
import { useParams, useHistory } from 'react-router-dom';
import merge from 'lodash.merge';
import {
  getLinks,
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
  customScript,
} from 'helpers';
import { isAdminSelector, principalIdSelector } from 'store/selectors';
import {
  getTemplate,
  getSystemTemplate,
  updateTemplate,
  updateSystemTemplate,
  changeStatusTemplate,
  cleanTemplate,
  uploadPageImage,
  duplicateTemplate,
  getSubscriptionPlans,
  getTemplates,
} from 'store/actions';
import { httpGetCaptchaSiteKey } from 'api';
import {
  ANIMATE_CSS_URL,
  BASE64_REGEX,
  FONTAWESOME_URL,
  FONTS,
  LOAD_STATUS,
  TEMPLATE_STATUS,
} from 'appConstants';
import { useDomain } from 'hooks/useDomain';
import { useQuery } from 'hooks/useQuery';
import { useToggle, useToggleTypes } from './useToggle';

const messages = defineMessages({
  saveInfoSuccessMessage: {
    id: 'template.info.save.success-message',
    defaultMessage: 'Template settings has been saved',
  },
  defaultSiteKeyErrorMessage: {
    id: 'template.default-sitekey.error-message',
  },
  saveSuccessMessage: {
    id: 'template.save.success-message',
  },
  templateNotFoundMessage: {
    id: 'template.not-found',
  },
  errorMessage: {
    id: 'toast.error-message',
  },
  uploadImageError: {
    id: 'toast.template.upload-image-error',
  },
  convertSuccessMessage: {
    id: 'template.convert.success-message',
  },
  domainWarning: {
    id: 'toast.domain-warning',
  },
});

const templateLoadingStatuses = {
  isChangeInfoLoading: 'changeInfoLoading',
  isSaveSettingsLoading: 'saveSettingsLoading',
  isSaveTemplateLoading: 'saveTemplateLoading',
  isChangeStatusLoading: 'changeStatusLoading',
  isConvertTemplateLoading: 'convertTemplateLoading',
};

const CHANGE_SETTINGS = 'CHANGE_SETTINGS';
const CHANGE_APPEARANCE_SETTINGS = 'CHANGE_APPEARANCE_SETTINGS';
const ADD_FONT = 'ADD_FONT';
const SET_TEMPLATE = 'SET_TEMPLATE';
const SET_HAVE_CHANGES = 'SET_HAVE_CHANGES';

const initialState = {
  haveChanges: false,
  template: null,
  appearanceSettings: {
    headerFont: FONTS[0],
    paragraphFont: FONTS[0],
    fonts: [],
    lineSpacing: '1.5',
    containerWidth: '960',
  },
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case SET_HAVE_CHANGES: {
      draft.haveChanges = payload;

      break;
    }
    case CHANGE_SETTINGS: {
      draft.template = merge({}, draft.template, payload);

      break;
    }
    case SET_TEMPLATE: {
      draft.template = payload;

      break;
    }
    case CHANGE_APPEARANCE_SETTINGS: {
      draft.appearanceSettings = merge({}, draft.appearanceSettings, payload);

      break;
    }
    case ADD_FONT: {
      draft.appearanceSettings.fonts.push(payload);

      break;
    }
    default:
      throw new Error('Invalid type in useTemplate reducer');
  }
});

export const useTemplate = () => {
  const { id } = useParams();
  const { system } = useQuery();
  const history = useHistory();

  const intl = useIntl();

  const isAdmin = useSelector(isAdminSelector);
  const templateState = useSelector((state) => state.template.template);
  const token = useSelector((state) => state.user.token);
  const currentUser = useSelector(principalIdSelector);

  const reduxDispatch = useDispatch();

  const { show, handleShow } = useToggle();

  const [{ template, appearanceSettings, haveChanges }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const domainMethods = useDomain(
    template?.settings.domain.id,
    template?.settings.domain.status,
  );

  const [loadingStatus, setLoadingStatus] = useState(LOAD_STATUS.IDLE);

  const updateRequest = system ? updateSystemTemplate : updateTemplate;

  useEffect(() => {
    if (token) {
      if (isAdmin) {
        reduxDispatch(getSubscriptionPlans());
      }

      reduxDispatch(getTemplates());
      reduxDispatch(
        system ? getSystemTemplate(id) : getTemplate({ id, currentUser }),
      )
        .unwrap()
        .catch(() => {
          showErrorMessage(
            intl.formatMessage(messages.templateNotFoundMessage),
          );

          history.replace('/');
        });
    }
  }, [reduxDispatch, token, intl, history, id, system, isAdmin, currentUser]);

  // syncing local state with global
  useEffect(() => {
    dispatch({ type: SET_TEMPLATE, payload: templateState.template });
  }, [templateState.template]);

  useEffect(() => {
    return () => {
      reduxDispatch(cleanTemplate());
    };
  }, [reduxDispatch]);

  const handleChangeTemplate = useCallback(() => {
    dispatch({ type: SET_HAVE_CHANGES, payload: true });
  }, []);

  const handleChangeSettings = (payload) => {
    dispatch({ type: CHANGE_SETTINGS, payload });
  };

  const handleChangeAppearanceSettings = useCallback((payload) => {
    dispatch({
      type: CHANGE_APPEARANCE_SETTINGS,
      payload,
    });
  }, []);

  const handleAddFont = useCallback((font) => {
    dispatch({
      type: ADD_FONT,
      payload: font,
    });
  }, []);

  const handleChangeInfo = useCallback(
    async (payload) => {
      setLoadingStatus(templateLoadingStatuses.isChangeInfoLoading);
      handleChangeSettings(payload);

      try {
        await reduxDispatch(
          updateRequest({
            id: template.id,
            payload,
          }),
        ).unwrap();

        setLoadingStatus(LOAD_STATUS.RESOLVED);
        handleShow(useToggleTypes.showInfoModal);
        showSuccessMessage(intl.formatMessage(messages.saveInfoSuccessMessage));
      } catch {
        setLoadingStatus(LOAD_STATUS.REJECTED);
      }
    },
    [reduxDispatch, updateRequest, template, intl, handleShow],
  );

  const handleChangeStatus = useCallback(
    async ({ showDomainWarning } = {}) => {
      setLoadingStatus(templateLoadingStatuses.isChangeStatusLoading);
      const isPublishing = template.status === TEMPLATE_STATUS.DRAFT;

      try {
        await reduxDispatch(
          changeStatusTemplate({
            id: template.id,
            status: isPublishing
              ? TEMPLATE_STATUS.PUBLISHED
              : TEMPLATE_STATUS.DRAFT,
          }),
        ).unwrap();

        await reduxDispatch(getTemplate({ id, currentUser })).unwrap();

        setLoadingStatus(LOAD_STATUS.RESOLVED);

        if (isPublishing) {
          handleShow(useToggleTypes.showPublishedModal);
        }

        if (showDomainWarning) {
          showWarningMessage(
            intl.formatMessage(messages.domainWarning, {
              name: template.settings.domain.name,
            }),
          );
        }
      } catch {
        showErrorMessage(intl.formatMessage(messages.errorMessage));
        setLoadingStatus(LOAD_STATUS.REJECTED);
      }
    },
    [handleShow, id, intl, reduxDispatch, template, currentUser],
  );

  const handleUploadImage = useCallback(
    async (type, file) => {
      try {
        const formData = new FormData();

        formData.append('file', file);

        const { url } = await reduxDispatch(
          uploadPageImage({ type, id, payload: formData }),
        ).unwrap();

        switch (type) {
          case 'favicon':
            await reduxDispatch(
              updateRequest({
                id,
                payload: { settings: { favicon: url } },
              }),
            );
            return;
          case 'appicon':
            await reduxDispatch(
              updateRequest({
                id,
                payload: { settings: { appicon: url } },
              }),
            );
            return;
          default:
            await reduxDispatch(
              updateRequest({
                id,
                payload: {
                  settings: { social: { image: url } },
                },
              }),
            );
            return;
        }
      } catch {
        // catching error and doing nothing
      }
    },
    [id, reduxDispatch, updateRequest],
  );

  const handleSaveSettings = useCallback(
    async (payload) => {
      try {
        setLoadingStatus(templateLoadingStatuses.isSaveSettingsLoading);

        await reduxDispatch(updateRequest({ id, payload })).unwrap();

        setLoadingStatus(LOAD_STATUS.RESOLVED);
        handleShow(useToggleTypes.showPageSettings);
        showSuccessMessage(intl.formatMessage(messages.saveInfoSuccessMessage));
      } catch {
        setLoadingStatus(LOAD_STATUS.REJECTED);
      }
    },
    [id, intl, handleShow, reduxDispatch, updateRequest],
  );

  const handleSaveTemplate = useCallback(
    async (editor) => {
      setLoadingStatus(templateLoadingStatuses.isSaveTemplateLoading);
      try {
        const wrapper = editor.getWrapper();
        const AnimateItemsCount = wrapper.find('.animate__animated').length;
        const socialIconWrapperTypeCount = wrapper.findType(
          'social-icon-wrapper',
        ).length;
        const socialBlockTypeCount = wrapper.findType('social-block').length;
        const wrapperFormTypeCount = wrapper.findType('form').length;
        const wrapperDiscountTypeCount =
          wrapper.findType('discount-code').length;
        const wrapperPhoneInputTypeCount =
          wrapper.findType('phone-input').length;
        const matchedBase64ForHtml = editor.getHtml().match(BASE64_REGEX);
        const matchedBase64ForCss = editor.getCss().match(BASE64_REGEX);

        if (matchedBase64ForHtml || matchedBase64ForCss) {
          throw showErrorMessage(
            'Base64 encoded content is not allowed in the template. Please check and remove them before saving the template',
          );
        }

        const payload = {
          name: template.name,
          tags: template.tags,
          description: template.description,
          template: {
            links: getLinks(template.template.links, [
              ...appearanceSettings.fonts.map((font) => font.url),
              appearanceSettings.headerFont.url,
              appearanceSettings.paragraphFont.url,
            ]),
            html: editor.getHtml(),
            css: editor.getCss(),
            style: editor.getStyle(),
            components: editor.getComponents(),
            scripts: [],
            customScript: customScript(
              template.id,
              wrapperFormTypeCount,
              wrapperDiscountTypeCount,
            ),
          },
        };

        if (wrapperFormTypeCount > 0) {
          const customDomainSiteKey =
            template?.formSettings?.customDomainSiteKey;
          let defaultSiteKey = template?.formSettings?.siteKey;

          if (!defaultSiteKey) {
            try {
              const { data } = await httpGetCaptchaSiteKey();
              defaultSiteKey = data?.siteKey;
            } catch {
              showErrorMessage(
                intl.formatMessage(messages.defaultSiteKeyErrorMessage),
              );
            }
          }

          payload.template.scripts.push({
            src: `https://www.google.com/recaptcha/api.js?render=${
              template?.formSettings?.recaptchaEnabled
                ? customDomainSiteKey
                : defaultSiteKey
            }`,
          });
        }

        if (wrapperFormTypeCount > 0 && wrapperPhoneInputTypeCount > 0) {
          payload.template.scripts.push({
            src: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/intlTelInput.min.js',
            attributes: 'async',
          });
        }

        if (AnimateItemsCount > 0) {
          if (
            payload?.template?.links.filter(
              ({ href }) => href === ANIMATE_CSS_URL,
            ).length === 0
          ) {
            payload.template.links.push({
              href: ANIMATE_CSS_URL,
              rel: 'stylesheet',
            });
          }
        }

        if (socialIconWrapperTypeCount > 0 || socialBlockTypeCount > 0) {
          if (
            payload?.template?.links.filter(
              ({ href }) => href === FONTAWESOME_URL,
            ).length === 0
          ) {
            payload.template.links.push({
              href: FONTAWESOME_URL,
              rel: 'stylesheet',
            });
          }
        }

        if (
          payload?.template?.links.filter(
            ({ href }) =>
              href ===
              'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/css/intlTelInput.css',
          ).length === 0
        ) {
          payload.template.links.push({
            href: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/css/intlTelInput.css',
            rel: 'stylesheet',
          });
        }

        await reduxDispatch(
          updateRequest({ id: template.id, payload }),
        ).unwrap();

        dispatch({ type: SET_HAVE_CHANGES, payload: false });
        showSuccessMessage(intl.formatMessage(messages.saveSuccessMessage));
        setLoadingStatus(LOAD_STATUS.RESOLVED);
      } catch {
        setLoadingStatus(LOAD_STATUS.REJECTED);
      }
    },
    [intl, reduxDispatch, template, appearanceSettings, updateRequest],
  );

  const handleConvertToSystem = useCallback(async () => {
    setLoadingStatus(templateLoadingStatuses.isConvertTemplateLoading);
    try {
      const params = { xtype: true };

      await reduxDispatch(
        duplicateTemplate({ id: template.id, copySharedInfo: false, params }),
      ).unwrap();

      const publishedSearch = new URLSearchParams({
        status: TEMPLATE_STATUS.PUBLISHED,
      });

      showSuccessMessage(intl.formatMessage(messages.convertSuccessMessage));
      setLoadingStatus(LOAD_STATUS.RESOLVED);
      history.push({
        pathname: '/',
        search: String(publishedSearch),
      });
    } catch (e) {
      setLoadingStatus(LOAD_STATUS.REJECTED);
    }
  }, [history, intl, reduxDispatch, template]);

  return {
    template,
    appearanceSettings,
    haveChanges,
    show,
    isError: templateState.isError,
    isLoading: !templateState.isLoaded && templateState.isLoading,
    isSaveSettingsLoading:
      loadingStatus === templateLoadingStatuses.isSaveSettingsLoading,
    isUpdateLoading:
      loadingStatus === templateLoadingStatuses.isSaveTemplateLoading,
    isStatusLoading:
      loadingStatus === templateLoadingStatuses.isChangeStatusLoading,
    isConvertLoading:
      loadingStatus === templateLoadingStatuses.isConvertTemplateLoading,
    isChangeInfoLoading:
      loadingStatus === templateLoadingStatuses.isChangeInfoLoading,
    handleChangeTemplate,
    handleChangeInfo,
    handleChangeStatus,
    handleUploadImage,
    handleSaveSettings,
    handleSaveTemplate,
    handleConvertToSystem,
    handleShow,
    handleChangeAppearanceSettings,
    handleAddFont,
    ...domainMethods,
  };
};
