import { useToggleTypes } from 'hooks';
import { keyClean } from './config';

export default (editor) => {
  const cmd = editor.Commands;
  cmd.add('clean', {
    keyClean,
    run(editor) {
      this.target = editor.getModel();
      const target = this.target;
      target.get('onShow')(useToggleTypes.showClean);
    },
  });
};
