import openStyleManagerCustom from './OpenStyleManager';
import custom from './Custom';
import {
  addClickEventTraits,
  addCustomTypes,
  linkTraits,
} from './custom-traits';

export default (editor, config) => {
  addCustomTypes(editor);

  openStyleManagerCustom(editor, config);

  editor.DomComponents.getTypes().forEach((type) => {
    if (type.id === 'link' || type.id === 'link-button') {
      editor.DomComponents.addType(type.id, {
        model: {
          defaults: {
            traits:
              type.id === 'link-button'
                ? [...linkTraits(), addClickEventTraits]
                : [...linkTraits()],
          },
          init() {
            this.on('change:attributes:href', this.changeHref);
          },
          changeHref(el, value) {
            let href = !/^https?:\/\//i.test(value) ? `http://${value}` : value;

            el.addAttributes({ href });
          },
        },
      });
    }

    if (type.id === 'image') {
      editor.DomComponents.addType(type.id, {
        model: {
          defaults: {
            openTraitsOnSelect: true,
            traits: [
              {
                type: 'text',
                name: 'alt',
              },
              {
                type: 'text',
                label: 'Link (URL)',
                name: 'imageLink',
                changeProp: true,
              },
              {
                type: 'select',
                name: 'imageLinkTarget',
                label: 'target',
                default: '_self',
                options: [
                  { id: '_self', name: 'This window' },
                  { id: '_blank', name: 'New window' },
                ],
                changeProp: true,
              },
            ],
          },
        },
        view: {
          init() {
            this.listenTo(
              this.model,
              'change:imageLinkTarget',
              this.changeImageUrlTarget,
            );
            this.listenTo(this.model, 'change:imageLink', this.changeImageUrl);
          },
          changeImageUrlTarget(model, target) {
            const imageParentLink = model.closest('a');

            if (imageParentLink) {
              imageParentLink.addAttributes({ target });
            }
          },
          changeImageUrl(model, url) {
            const imageParentLink = model.closest('a');
            const imageLinkTargetTrait = model.getTrait('imageLinkTarget');
            const attributes = {
              href: url,
              target: imageLinkTargetTrait.get('value') || '_self',
            };

            if (!imageParentLink) {
              const newLink = {
                type: 'link',
                attributes,
                components: [model.clone()],
              };

              const newBlock = model.replaceWith(newLink);

              editor.select(newBlock.findType('image') || newBlock);
            } else {
              imageParentLink.addAttributes(attributes);
            }
          },
        },
      });
    }

    // Add animation settings for all component settings
    editor.DomComponents.addType(type.id, {
      model: {
        init() {
          this.on(
            'change:attributes:data-animation-type',
            this.updatAnimationType,
          );
          this.on('change:animationDelay', this.updatAnimationDelay);
          this.on('change:animationDuration', this.updatAnimationDuration);
          this.on('change:animationRepeat', this.updatAnimationRepeat);
        },
        toggleAnimationEffectClasses(cmp, animationType = 'none') {
          const classes = cmp.getClasses();
          const animation_classes = classes.filter((item) =>
            item.startsWith('animate__'),
          );

          // Remove old animation classes
          animation_classes.forEach((className) => {
            cmp.removeClass(className);
          });

          // Add new animation effect class
          cmp.addClass(['animate__animated', `animate__${animationType}`]);
        },
        applyAnimationExtra(extra = '', cmp, value) {
          const animationTypeValue =
            cmp.get('attributes')?.['data-animation-type'] || 'none';

          // remove old animation classes
          cmp.removeClass([
            'animate__animated',
            `animate__${animationTypeValue}`,
          ]);

          if (extra === 'duration' || extra === 'delay') {
            cmp.addStyle({ [`animation-${extra}`]: `${value || 0}s` });
          }

          if (extra === 'iteration-count') {
            if (value < 0) {
              cmp.addStyle({ [`animation-${extra}`]: 'infinite' });
            } else {
              cmp.addStyle({ [`animation-${extra}`]: `${value || 0}` });
            }
          }

          // recall animation effect
          if (animationTypeValue !== 'none') {
            this.toggleAnimationEffectClasses(cmp, animationTypeValue);
          }
        },
        updatAnimationType(cmp, type) {
          const animationDelayTrait = cmp.getTrait('animationDelay');
          const animationDurationTrait = cmp.getTrait('animationDuration');
          const animationRepeatTrait = cmp.getTrait('animationRepeat');

          if (type !== 'none') {
            animationDelayTrait.view.$el[0].style.display = 'block';
            animationDurationTrait.view.$el[0].style.display = 'block';
            animationRepeatTrait.view.$el[0].style.display = 'block';

            this.toggleAnimationEffectClasses(cmp, type);
          } else {
            animationDelayTrait.view.$el[0].style.display = 'none';
            animationDurationTrait.view.$el[0].style.display = 'none';
            animationRepeatTrait.view.$el[0].style.display = 'none';
          }
        },
        updatAnimationDelay(cmp, delay) {
          this.applyAnimationExtra('delay', cmp, delay);
        },
        updatAnimationDuration(cmp, duration) {
          this.applyAnimationExtra('duration', cmp, duration);
        },
        updatAnimationRepeat(cmp, repeat) {
          this.applyAnimationExtra('iteration-count', cmp, repeat);
        },
      },
    });
  });

  custom(editor, {});
};
