import { combineReducers } from 'redux';
import { createSlice } from '@reduxjs/toolkit';

import { LOAD_STATUS } from 'appConstants';
import {
  getWayMoreCategories,
  getWayMoreForms,
  getWayMoreProducts,
  getWayMoreStores,
  resetWayMoreProducts,
  selectWayMoreCategory,
  selectWayMoreProduct,
  selectWayMoreStore,
  getCalendar,
  getCalendarServices,
} from 'store/actions';

const initialFormsState = {
  data: [],
  status: LOAD_STATUS.IDLE,
};

const { reducer: formsReducer } = createSlice({
  name: 'forms',
  initialState: initialFormsState,
  extraReducers: (builder) => {
    builder.addCase(getWayMoreForms.pending, (state) => {
      state.status = LOAD_STATUS.LOADING;
    });

    builder.addCase(getWayMoreForms.fulfilled, (state, action) => {
      state.status = LOAD_STATUS.RESOLVED;
      state.data = action.payload;
    });

    builder.addCase(getWayMoreForms.rejected, (state) => {
      state.status = LOAD_STATUS.REJECTED;
    });
  },
});

const initialProductsState = {
  stores: [],
  categories: [],
  products: [],
  storesStatus: LOAD_STATUS.IDLE,
  categoriesStatus: LOAD_STATUS.IDLE,
  productsStatus: LOAD_STATUS.IDLE,
  storeSelectedItem: null,
  categorySelectedItem: null,
  productSelectedItem: null,
};

const { reducer: productsReducer } = createSlice({
  name: 'products',
  initialState: initialProductsState,
  extraReducers: (builder) => {
    builder
      .addCase(getWayMoreStores.pending, (state) => {
        state.storesStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getWayMoreStores.fulfilled, (state, action) => {
        state.storesStatus = LOAD_STATUS.RESOLVED;
        state.stores = action.payload;
      })
      .addCase(getWayMoreStores.rejected, (state) => {
        state.storesStatus = LOAD_STATUS.REJECTED;
      })
      .addCase(getWayMoreCategories.pending, (state) => {
        state.categoriesStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getWayMoreCategories.fulfilled, (state, action) => {
        state.categoriesStatus = LOAD_STATUS.RESOLVED;
        state.categories = action.payload;
      })
      .addCase(getWayMoreCategories.rejected, (state) => {
        state.categoriesStatus = LOAD_STATUS.REJECTED;
      })
      .addCase(getWayMoreProducts.pending, (state) => {
        state.productsStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getWayMoreProducts.fulfilled, (state, action) => {
        state.productsStatus = LOAD_STATUS.RESOLVED;
        state.products = action.payload;
      })
      .addCase(getWayMoreProducts.rejected, (state) => {
        state.productsStatus = LOAD_STATUS.REJECTED;
      })
      .addCase(selectWayMoreStore, (state, action) => {
        state.storeSelectedItem = action.payload;
        state.categories = [];
        state.products = [];
        state.categorySelectedItem = null;
        state.productSelectedItem = null;
      })
      .addCase(selectWayMoreCategory, (state, action) => {
        state.categorySelectedItem = action.payload;
        state.products = [];
        state.productSelectedItem = null;
      })
      .addCase(selectWayMoreProduct, (state, action) => {
        state.productSelectedItem = action.payload;
      })
      .addCase(resetWayMoreProducts, (state) => {
        state.productSelectedItem = null;
      });
  },
});

const initialCalendarState = {
  calendarData: [],
  status: LOAD_STATUS.IDLE,
};

const { reducer: calendarReducer } = createSlice({
  name: 'calendar',
  initialState: initialCalendarState,
  extraReducers: (builder) => {
    builder.addCase(getCalendar.pending, (state) => {
      state.status = LOAD_STATUS.LOADING;
    });

    builder.addCase(getCalendar.fulfilled, (state, action) => {
      state.status = LOAD_STATUS.RESOLVED;
      state.calendarData = action.payload;
    });

    builder.addCase(getCalendar.rejected, (state) => {
      state.status = LOAD_STATUS.REJECTED;
    });
  },
});

const initialCalendarServicesState = {
  calendarServicesData: [],
  status: LOAD_STATUS.IDLE,
};

const { reducer: calendarServicesReducer } = createSlice({
  name: 'calendarServices',
  initialState: initialCalendarServicesState,
  extraReducers: (builder) => {
    builder.addCase(getCalendarServices.pending, (state) => {
      state.status = LOAD_STATUS.LOADING;
    });

    builder.addCase(getCalendarServices.fulfilled, (state, action) => {
      state.status = LOAD_STATUS.RESOLVED;
      state.calendarServicesData = action.payload;
    });

    builder.addCase(getCalendarServices.rejected, (state) => {
      state.status = LOAD_STATUS.REJECTED;
    });
  },
});

const waymoreReducer = combineReducers({
  forms: formsReducer,
  products: productsReducer,
  calendar: calendarReducer,
  calendarServices: calendarServicesReducer,
});

export { waymoreReducer };
