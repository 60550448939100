import axios from 'axios';

const httpGetCustomBlocks = (params) => {
  return axios.get('/api/v2/customblocks', { params });
};

const httpCreateCustomBlock = (payload) => {
  return axios.post(`/api/v2/customblocks`, payload);
};

const httpUpdateCustomBlock = (payload) => {
  return axios.put(`/api/v2/customblocks/${payload.id}`, payload);
};

const httpDeleteCustomBlock = (id) => {
  return axios.delete(`/api/v2/customblocks/${id}`);
};

const httpGetCustomBlocksGroup = (params) => {
  return axios.get('/api/v2/categories', { params });
};

const httpCreateCustomBlocksGroup = (payload) => {
  return axios.post('/api/v2/categories', payload);
};

const httpUpdateCustomBlocksGroup = (payload) => {
  return axios.patch(`/api/v2/categories/${payload.id}`, payload);
};

const httpUpdateSystemCustomBlock = (payload) => {
  return axios.put(`/api/v2/customblocks/${payload.id}`, payload);
};

export {
  httpGetCustomBlocks,
  httpCreateCustomBlock,
  httpUpdateCustomBlock,
  httpDeleteCustomBlock,
  httpGetCustomBlocksGroup,
  httpCreateCustomBlocksGroup,
  httpUpdateCustomBlocksGroup,
  httpUpdateSystemCustomBlock,
};
