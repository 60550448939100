import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Logo from 'components/Logo';
import UserMenu from 'components/UserMenu';
import Breadcrumb from 'components/Breadcrumb';
import { VERSION } from 'appConstants';
import './Header.sass';

const Header = ({ setNewSessionId }) => {
  return (
    <>
      <header className="templates-header">
        <div className="templates-header__logo">
          <Logo />
          <Breadcrumb />
        </div>
        <div className="templates-header__info-menu">
          <div className="templates-header__info-menu__ver">v{VERSION}</div>
          <UserMenu setNewSessionId={setNewSessionId} />
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  setNewSessionId: PropTypes.func.isRequired,
};

export default injectIntl(Header);
