import axios from 'axios';

const httpGetPrincipalEmail = (payload) => {
  return axios.get('/api/v2/principal/email', payload);
};

const httpSearchPrincipal = (payload) => {
  return axios.post('/api/v2/principal/search', payload);
};

const httpGetPrincipalRoles = (payload) => {
  return axios.get('/api/v2/principal/role', payload);
};

const httpUpdatePrincipalRoles = (payload) => {
  return axios.put('/api/v2/principal', payload);
};

export {
  httpGetPrincipalEmail,
  httpSearchPrincipal,
  httpGetPrincipalRoles,
  httpUpdatePrincipalRoles,
};
