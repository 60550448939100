import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Tooltip from '@reach/tooltip';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { VERIFY_DOMAIN_STATUSES } from 'appConstants';

const messages = defineMessages({
  domainStatus: { id: 'page-settings-modal.domain.domain-status' },
});

function DomainStatusChip({ status }) {
  const intl = useIntl();

  const label = intl.formatMessage(messages.domainStatus, { status });

  switch (status) {
    case VERIFY_DOMAIN_STATUSES.ACTIVE:
    case VERIFY_DOMAIN_STATUSES.VERIFIED:
      return (
        <Tooltip label={label}>
          <div
            className={cx('page-settings-modal__domain-status-chip', {
              success: true,
            })}>
            <span className="icon icon-check" />
          </div>
        </Tooltip>
      );
    case VERIFY_DOMAIN_STATUSES.VERIFICATION_ERROR:
    case VERIFY_DOMAIN_STATUSES.ACTIVATION_ERROR:
    case VERIFY_DOMAIN_STATUSES.DEACTIVATION_ERROR:
      return (
        <Tooltip label={label}>
          <div
            className={cx('page-settings-modal__domain-status-chip', {
              error: true,
            })}>
            <span className="icon icon-info" />
          </div>
        </Tooltip>
      );
    case VERIFY_DOMAIN_STATUSES.PENDING_VERIFICATION:
      return (
        <Tooltip label={label}>
          <div
            className={cx('page-settings-modal__domain-status-chip', {
              pending: true,
            })}>
            <span className="icon icon-hourglass-empty" />
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip label={label}>
          <div
            className={cx('page-settings-modal__domain-status-chip', {
              loading: true,
            })}>
            <span className="icon icon-hourglass-empty" />
          </div>
        </Tooltip>
      );
  }
}

DomainStatusChip.propTypes = {
  status: PropTypes.number,
};

export default DomainStatusChip;
