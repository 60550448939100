import React from 'react';
import PropTypes from 'prop-types';
import { URL_REGEX_2, FORM_CATEGORIES } from 'appConstants';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { isEmptyObject } from 'helpers';
import { useFormContext, Controller } from 'react-hook-form';
import { useEditorContext } from 'routes/Editor/EditorContext';
import CheckBox from 'components/Forms/CheckBox';
import TextField from 'components/Forms/TextField';
import ReactTooltip from 'react-tooltip';
import SelectBox from 'components/SelectBox';
import { RadioGroup, RadioButton } from 'components/Forms/Radio';
import cx from 'classnames';
import { GroupCombobox, TagsMultiCombobox } from '../Forms';

function Forms({ subscriptionPlan }) {
  const intl = useIntl();
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const saveContactValue = watch('contactSettings.saveContact');
  const { customDomainEnabled } = subscriptionPlan;
  const {
    template: {
      settings: { domain },
    },
  } = useEditorContext();
  const { status: domainStatus, name: domainName } = domain;

  const messages = defineMessages({
    saveContactLable: {
      id: 'page-settings-modal.forms.save-contact-label',
    },
    policyOptionsLabel: {
      id: 'page-settings-modal.forms.policy-options-label',
    },
    policyOptionsDescription: {
      id: 'page-settings-modal.forms.policy-options-description',
    },
    policyOptionsKeepLabel: {
      id: 'page-settings-modal.forms.policy-options-keep-label',
    },
    policyOptionsKeepDescription: {
      id: 'page-settings-modal.forms.policy-options-keep-description',
    },
    policyOptionsReplaceLabel: {
      id: 'page-settings-modal.forms.policy-options-replace-label',
    },
    policyOptionsReplaceDescription: {
      id: 'page-settings-modal.forms.policy-options-replace-description',
    },
    policyOptionsIgnoreLabel: {
      id: 'page-settings-modal.forms.policy-options-ignore-label',
    },
    policyOptionsIgnoreescription: {
      id: 'page-settings-modal.forms.policy-options-ignore-description',
    },
    enableDoubleOptIn: {
      id: 'page-settings-modal.forms.enable-double-opt-in-label',
    },
    recaptchaEnableLabel: {
      id: 'page-settings-modal.forms.recaptcha-enable-label',
    },
    recaptchaSiteKeyLabel: {
      id: 'page-settings-modal.forms.recaptcha-site-key-label',
    },
    recaptchaSiteKeyPlaceholder: {
      id: 'page-settings-modal.forms.recaptcha-site-key-placeholder',
    },
    recaptchaSecretKeyLabel: {
      id: 'page-settings-modal.forms.recaptcha-secret-key-label',
    },
    recaptchaSecretKeyPlaceholder: {
      id: 'page-settings-modal.forms.recaptcha-secret-key-placeholder',
    },
    plansTooltip: {
      id: 'plans-tooltip',
    },
    notActiveCustomDomain: {
      id: 'page-settings-modal.forms.not-active-custom-domain',
    },
    webhookUrlLabel: {
      id: 'page-settings-modal.forms.webhook-url-label',
    },
    webhookUrlPlaceholder: {
      id: 'page-settings-modal.forms.webhook-url-placeholder',
    },
    webhookUrlDescription: {
      id: 'page-settings-modal.forms.webhook-url-description',
    },
    webhookUrlErrorMessage: {
      id: 'page-settings-modal.forms.webhook-url-error',
    },
    contactGroupValidation: {
      id: 'page-settings-modal.forms.group-validation',
    },
    formCategoryLabel: {
      id: 'page-settings-modal.forms.form-category-label',
    },
    formCategoryDescription: {
      id: 'page-settings-modal.forms.form-category-description',
    },
    formCategoryPlaceholder: {
      id: 'page-settings-modal.forms.form-category-placeholder',
    },
    formCategoryValidation: {
      id: 'page-settings-modal.forms.form-category-validation',
    },
  });

  const policyOptions = [
    {
      label: intl.formatMessage(messages.policyOptionsKeepLabel),
      description: intl.formatMessage(messages.policyOptionsKeepDescription),
      value: 'keep_as_new',
    },
    {
      label: 'Replace older entries',
      description: 'We will replace your older entries with the new ones.',
      value: 'replace_older',
    },
    {
      label: 'Ignore duplicates',
      description: 'We will not add the duplicate entries.',
      value: 'ignore_duplicates',
    },
  ];

  return (
    <div className="page-settings-modal__forms">
      <div className="page-settings-modal__forms__full-col">
        <div className="page-settings-modal__row">
          <Controller
            control={control}
            name="formSettings.formCategory"
            rules={{
              validate: (value) => {
                if (!value || isEmptyObject(value)) {
                  return intl.formatMessage(messages.formCategoryValidation);
                }
              },
            }}
            render={({ field: { onChange, value } }) => {
              let newValue = '';

              if (value) {
                const { id, name } = value;

                newValue = { value: id, label: name };
              }

              return (
                <SelectBox
                  isDisabled={false}
                  error={Boolean(errors?.formSettings?.formCategory)}
                  onChange={onChange}
                  loading={false}
                  options={FORM_CATEGORIES}
                  defaultValue={newValue}
                  tooltip=""
                  label={intl.formatMessage(messages.formCategoryLabel)}
                  placeholder={intl.formatMessage(
                    messages.formCategoryPlaceholder,
                  )}
                  description={intl.formatMessage(
                    errors?.formSettings?.formCategory
                      ? messages.formCategoryValidation
                      : messages.formCategoryDescription,
                  )}
                />
              );
            }}
          />
        </div>
        <hr />
        <div className="page-settings-modal__row">
          <Controller
            control={control}
            name="contactSettings.saveContact"
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <CheckBox
                    label={intl.formatMessage(messages.saveContactLable)}
                    fullWidth
                    checked={value || false}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                  {value && (
                    <Controller
                      control={control}
                      name="contactSettings.doubleOptIn"
                      render={({ field: { onChange, value } }) => (
                        <CheckBox
                          label={intl.formatMessage(messages.enableDoubleOptIn)}
                          fullWidth
                          checked={value || false}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                    />
                  )}
                </>
              );
            }}
          />
        </div>
        <div className="page-settings-modal__row">
          <Controller
            control={control}
            name="contactSettings.contactGroup"
            rules={{
              validate: (value) => {
                if (saveContactValue && (!value || isEmptyObject(value))) {
                  return intl.formatMessage(messages.contactGroupValidation);
                }
              },
            }}
            render={({ field: { onChange, value } }) =>
              saveContactValue && (
                <GroupCombobox
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors?.contactSettings?.contactGroup)}
                />
              )
            }
          />
          <Controller
            control={control}
            name="contactSettings.contactTags"
            render={({ field: { onChange, value } }) =>
              saveContactValue && (
                <TagsMultiCombobox isMulti value={value} onChange={onChange} />
              )
            }
          />
        </div>
        <div className="page-settings-modal__forms__policy-options">
          <Controller
            control={control}
            name="contactSettings.mergePolicy"
            render={({ field: { value, onChange } }) => (
              <RadioGroup
                value={value || 'keep_as_new'}
                label={intl.formatMessage(messages.policyOptionsLabel)}
                helperText={intl.formatMessage(
                  messages.policyOptionsDescription,
                )}
                name="lineSpacing"
                fullWidth
                onChange={onChange}>
                {policyOptions.map(({ value, label, description }) => (
                  <RadioButton
                    key={value}
                    label={
                      <div className="page-settings-modal__radio-item-label">
                        {label} <div className={label} />
                      </div>
                    }
                    description={description}
                    value={value}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </div>
        <hr />
        <div className="page-settings-modal__forms__checkbox">
          <Controller
            control={control}
            name="formSettings.recaptchaEnabled"
            render={({ field: { onChange, value } }) => (
              <div
                className={cx('page-settings-modal__forms__checkbox__control', {
                  'page-settings-modal__forms__checkbox__control--lock':
                    !customDomainEnabled || domainStatus !== 0,
                })}>
                <CheckBox
                  disabled={!customDomainEnabled || domainStatus !== 0}
                  label={`${intl.formatMessage(
                    messages.recaptchaEnableLabel,
                  )} ${domainName !== null ? `[${domainName}]` : ''}`}
                  fullWidth
                  checked={value || false}
                  onChange={(e) => onChange(e.target.checked)}
                />
                {(!customDomainEnabled || domainStatus !== 0) && (
                  <div className="page-settings-modal__lock-status__icon">
                    <span
                      className="icon icon-lock"
                      data-tip
                      data-for="global"
                    />
                    <ReactTooltip
                      id="global"
                      type="dark"
                      aria-haspopup="true"
                      place="left"
                      clickable={true}
                      delayHide={300}
                      border={true}
                      borderColor="#000"
                      effect="solid">
                      {!customDomainEnabled && (
                        <p>
                          <FormattedMessage
                            {...messages.plansTooltip}
                            values={{
                              p: (...chunks) => <p>{chunks}</p>,
                              br: <br />,
                            }}
                          />
                        </p>
                      )}
                      {domainStatus !== 0 && (
                        <p>
                          {intl.formatMessage(messages.notActiveCustomDomain)}
                        </p>
                      )}
                    </ReactTooltip>
                  </div>
                )}
                {customDomainEnabled && domainStatus === 0 && value && (
                  <div className="page-settings-modal__forms__checkbox__fields">
                    <TextField
                      {...register('formSettings.customDomainSiteKey')}
                      label={intl.formatMessage(messages.recaptchaSiteKeyLabel)}
                      placeholder={intl.formatMessage(
                        messages.recaptchaSiteKeyPlaceholder,
                      )}
                      fullWidth
                    />
                    <TextField
                      {...register('formSettings.customDomainSecretKey')}
                      label={intl.formatMessage(
                        messages.recaptchaSecretKeyLabel,
                      )}
                      placeholder={intl.formatMessage(
                        messages.recaptchaSecretKeyPlaceholder,
                      )}
                      fullWidth
                    />
                  </div>
                )}
              </div>
            )}
          />
        </div>
        <hr />
        <div className="page-settings-modal__forms__webhook">
          <TextField
            {...register('formSettings.webhookUrl', {
              pattern: URL_REGEX_2,
            })}
            label={intl.formatMessage(messages.webhookUrlLabel)}
            placeholder={intl.formatMessage(messages.webhookUrlPlaceholder)}
            fullWidth
            helperText={
              errors?.formSettings?.webhookUrl
                ? intl.formatMessage(messages.webhookUrlErrorMessage)
                : intl.formatMessage(messages.webhookUrlDescription)
            }
            error={Boolean(errors?.formSettings?.webhookUrl)}
          />
        </div>
      </div>
    </div>
  );
}

Forms.propTypes = {
  subscriptionPlan: PropTypes.object.isRequired,
};

export default Forms;
