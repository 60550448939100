import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  httpGetWayMoreCategories,
  httpGetWayMoreForms,
  httpGetWayMoreProducts,
  httpGetWayMoreStores,
  httpGetCalendar,
  httpGetCalendarServices,
} from 'api';
import { getErrorText, showErrorMessage } from 'helpers';

const getWayMoreForms = createAsyncThunk(
  'waymore/getWayMoreForms',
  async () => {
    try {
      const { data } = await httpGetWayMoreForms();

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getWayMoreStores = createAsyncThunk(
  'waymore/getWayMoreStores',
  async () => {
    try {
      const { data } = await httpGetWayMoreStores();

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getWayMoreCategories = createAsyncThunk(
  'waymore/getWayMoreCategories',
  async ({ storeId }) => {
    try {
      const { data } = await httpGetWayMoreCategories(storeId);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getWayMoreProducts = createAsyncThunk(
  'waymore/getWayMoreProducts',
  async ({ storeId, category }) => {
    try {
      const params = { 'return-deleted': false, 'category-name': category };

      const { data } = await httpGetWayMoreProducts(storeId, params);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const selectWayMoreStore = createAction('selectWayMoreStore');

const selectWayMoreCategory = createAction('selectWayMoreCategory');

const selectWayMoreProduct = createAction('selectWayMoreProduct');

const resetWayMoreProducts = createAction('resetWayMoreProducts');

const getCalendar = createAsyncThunk(
  'waymore/getCalendar',
  async (userEmail) => {
    try {
      const { data } = await httpGetCalendar(userEmail);

      return data;
    } catch (error) {
      showErrorMessage('There is no active calendar and services');
      throw error;
    }
  },
);

const getCalendarServices = createAsyncThunk(
  'waymore/getCalendarServices',
  async () => {
    try {
      const { data } = await httpGetCalendarServices();

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

export {
  getWayMoreForms,
  getWayMoreStores,
  getWayMoreCategories,
  getWayMoreProducts,
  selectWayMoreStore,
  selectWayMoreCategory,
  selectWayMoreProduct,
  resetWayMoreProducts,
  getCalendar,
  getCalendarServices,
};
