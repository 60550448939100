import {
  normalizeTemplatesResponse,
  normalizeSystemTemplatesResponse,
  normalizePagination,
} from 'helpers';
import {
  CATEGORY_GROUPS,
  LOAD_STATUS,
  TEMPLATE_STATUS,
  USER_ROLES,
  VERIFY_DOMAIN_STATUSES,
} from 'appConstants';
import { combineReducers } from 'redux';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getEmailTemplates,
  activateDomain,
  cleanTemplate,
  deactivateDomain,
  deleteDomain,
  getDomain,
  getSystemTemplates,
  getDeletedSystemTemplates,
  setNextDeletedSystemTemplatesPage,
  getSystemTemplate,
  getTemplate,
  getTemplates,
  getFavoritedTemplates,
  getScheduledTemplates,
  searchTemplates,
  searchSystemTemplates,
  setNextTemplatesPage,
  updateSystemTemplate,
  updateTemplate,
  getSharedTemplates,
} from 'store/actions';

const paginationInitialState = {
  firstPage: true,
  lastPage: true,
  totalPage: 0,
  totalElements: 0,
  elements: 0,
  currentPage: 0,
};

const emailTemplatesInitialState = {
  status: LOAD_STATUS.IDLE,
  isLoaded: false,
  published: {
    template: [],
    pagination: paginationInitialState,
  },
};

const { reducer: emailTemplates } = createSlice({
  name: 'emailTemplates',
  initialState: emailTemplatesInitialState,
  extraReducers: (builder) => {
    builder.addCase(getEmailTemplates.fulfilled, (state, action) => {
      let { published } = state;

      published = normalizeTemplatesResponse({
        state,
        payload: action.payload,
        key: TEMPLATE_STATUS.PUBLISHED,
      });

      state.status = LOAD_STATUS.RESOLVED;
      state.isLoaded = true;
      state.published = published;
    });
  },
});

const templateInitialState = {
  isLoading: false,
  isError: false,
  isLoaded: false,
  template: null,
};

const { reducer: template } = createSlice({
  name: 'template',
  initialState: templateInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSystemTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSystemTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.template = action.payload;
      })
      .addCase(getSystemTemplate.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(getTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.template = action.payload;
      })
      .addCase(getTemplate.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.template = action.payload;
      })
      .addCase(updateSystemTemplate.fulfilled, (state, action) => {
        state.template = action.payload;
      })
      .addCase(getDomain.fulfilled, (state, action) => {
        if (
          state.template.settings.domain.status ===
            VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS &&
          action.payload.status === VERIFY_DOMAIN_STATUSES.PENDING_VERIFICATION
        ) {
          state.template.settings.domain = {
            id: null,
            status: null,
            name: null,
          };
          return;
        }

        state.template.settings.domain = action.payload;
      })
      .addCase(activateDomain.fulfilled, (state) => {
        state.template.settings.domain.status =
          VERIFY_DOMAIN_STATUSES.ACTIVATION_IN_PROGRESS;
      })
      .addCase(deactivateDomain.fulfilled, (state) => {
        state.template.settings.domain.status =
          VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS;
      })
      .addCase(deleteDomain.fulfilled, (state) => {
        state.template.settings.domain = { id: null, status: null, name: null };
      })
      .addCase(cleanTemplate, () => {
        return templateInitialState;
      });
  },
});

const templatesInitialState = {
  status: LOAD_STATUS.IDLE,
  isLoaded: false,
  published: {
    template: [],
    pagination: paginationInitialState,
  },
  draft: {
    template: [],
    pagination: paginationInitialState,
  },
  deleted: {
    template: [],
    pagination: paginationInitialState,
  },
  shared: {
    template: [],
    pagination: paginationInitialState,
  },
  favorited: {
    template: [],
    pagination: paginationInitialState,
  },
  scheduled: {
    template: [],
    pagination: paginationInitialState,
  },
};

const { reducer: templates } = createSlice({
  name: 'templates',
  initialState: templatesInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSharedTemplates.fulfilled, (state, action) => {
        let { shared } = state;

        if (action.payload.draft) {
          shared = normalizeTemplatesResponse({
            state,
            payload: action.payload,
            key: TEMPLATE_STATUS.DRAFT,
          });
        }

        state.status = LOAD_STATUS.RESOLVED;
        state.isLoaded = true;
        state.shared = shared;
      })
      .addCase(getFavoritedTemplates.fulfilled, (state, action) => {
        const {
          payload: {
            data: {
              template,
              pagination: { 'first-page': firstPage, 'last-page': lastPage },
            },
          },
        } = action;

        state.status = LOAD_STATUS.RESOLVED;
        state['favorited'].template = firstPage
          ? template
          : [...state['favorited'].template, ...template];
        state['favorited'].pagination.lastPage = lastPage;
        state['favorited'].pagination.currentPage = firstPage
          ? 0
          : state['favorited'].pagination.currentPage;
        state['favorited'].pagination.totalElements =
          template?.length > 0 ? template?.length : 0;
      })
      .addCase(getScheduledTemplates.fulfilled, (state, action) => {
        const {
          payload: {
            data: {
              template,
              pagination: { 'first-page': firstPage, 'last-page': lastPage },
            },
          },
        } = action;

        state.status = LOAD_STATUS.RESOLVED;
        state['scheduled'].template = firstPage
          ? template
          : [...state['scheduled'].template, ...template];
        state['scheduled'].pagination.lastPage = lastPage;
        state['scheduled'].pagination.currentPage = firstPage
          ? 0
          : state['scheduled'].pagination.currentPage;
        state['scheduled'].pagination.totalElements =
          template?.length > 0 ? template?.length : 0;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        let { published, draft, deleted } = state;

        if (action.payload.published) {
          published = normalizeTemplatesResponse({
            state,
            payload: action.payload,
            key: TEMPLATE_STATUS.PUBLISHED,
          });
        }

        if (action.payload.draft) {
          draft = normalizeTemplatesResponse({
            state,
            payload: action.payload,
            key: TEMPLATE_STATUS.DRAFT,
          });
        }

        if (action.payload.deleted) {
          deleted = normalizeTemplatesResponse({
            state,
            payload: action.payload,
            key: TEMPLATE_STATUS.DELETED,
          });
        }

        state.status = LOAD_STATUS.RESOLVED;
        state.isLoaded = true;
        state.published = published;
        state.draft = draft;
        state.deleted = deleted;
      })
      .addCase(searchTemplates.fulfilled, (state, action) => {
        const {
          payload: {
            status,
            data: {
              template,
              pagination: { 'first-page': firstPage, 'last-page': lastPage },
            },
          },
        } = action;

        state.status = LOAD_STATUS.RESOLVED;
        state[status].template = firstPage
          ? template
          : [...state[status].template, ...template];
        state[status].pagination.lastPage = lastPage;
        state[status].pagination.currentPage = firstPage
          ? 0
          : state[status].pagination.currentPage;
      })
      .addCase(setNextTemplatesPage, (state, action) => {
        const { isSystem, status } = action.payload;

        if (isSystem) {
          return;
        }

        if (
          state[status].pagination.currentPage >=
          state[status].pagination.totalPage
        ) {
          state[status].pagination.lastPage = true;
        } else {
          state[status].pagination.currentPage++;
        }
      })
      .addMatcher(
        isAnyOf(getTemplates.pending, searchTemplates.pending),
        (state) => {
          state.status = LOAD_STATUS.LOADING;
        },
      )
      .addMatcher(
        isAnyOf(getTemplates.rejected, searchTemplates.rejected),
        (state) => {
          state.status = LOAD_STATUS.REJECTED;
          state.isLoaded = true;
        },
      );
  },
});

const systemTemplatesInitialState = {
  status: LOAD_STATUS.IDLE,
  isLoaded: false,
  deleted: {
    status: LOAD_STATUS.IDLE,
    isLoaded: false,
    template: [],
    pagination: paginationInitialState,
  },
  items: [],
  searchItems: [],
  pagination: paginationInitialState,
};

const { reducer: systemTemplates } = createSlice({
  name: 'systemTemplates',
  initialState: systemTemplatesInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSystemTemplates.pending, (state) => {
        state.status = LOAD_STATUS.LOADING;
      })
      .addCase(getSystemTemplates.fulfilled, (state, action) => {
        if (action.payload.group === CATEGORY_GROUPS.LAYOUTS) {
          return;
        }

        state.status = LOAD_STATUS.RESOLVED;
        state.isLoaded = true;
        state.items = action.payload.data.template.filter((template) => {
          // show templates without categories
          if (template.categories.length === 0) {
            return true;
          }

          const isSystemTemplate = template.categories.some(
            (category) =>
              category.group === CATEGORY_GROUPS.SYSTEM ||
              category.group === CATEGORY_GROUPS.LAYOUTS,
          );

          if (action.payload.role === USER_ROLES.ADMIN) {
            return isSystemTemplate;
          }

          const isHiddenTemplate = template.categories.every(
            (category) => category.hidden,
          );

          return isSystemTemplate && !isHiddenTemplate;
        });
        state.pagination = normalizePagination(action.payload.data.pagination);
      })
      .addCase(getDeletedSystemTemplates.pending, (state) => {
        state.deleted.status = LOAD_STATUS.LOADING;
      })
      .addCase(getDeletedSystemTemplates.fulfilled, (state, action) => {
        let { deleted } = state;

        deleted.status = LOAD_STATUS.RESOLVED;
        deleted.isLoaded = true;
        deleted = normalizeSystemTemplatesResponse({
          state,
          payload: action.payload,
        });

        state.deleted = deleted;
      })
      .addCase(setNextDeletedSystemTemplatesPage, (state) => {
        if (
          state.deleted.pagination.currentPage >=
          state.deleted.pagination.totalPage
        ) {
          state.deleted.pagination.lastPage = true;
        } else {
          state.deleted.pagination.currentPage++;
        }
      })
      .addCase(searchSystemTemplates.fulfilled, (state, action) => {
        const {
          payload: {
            data: {
              template,
              pagination: { 'first-page': firstPage, 'last-page': lastPage },
            },
            status,
          },
        } = action;

        state.status = LOAD_STATUS.RESOLVED;

        if (status === 'deleted') {
          state.deleted.template = firstPage
            ? template
            : [...state.deleted.template, ...template];
          state.deleted.pagination.lastPage = lastPage;
          state.deleted.pagination.currentPage = firstPage
            ? 0
            : state.deleted.pagination.currentPage;
        } else {
          state.searchItems = template;
        }
      })
      .addCase(getSystemTemplates.rejected, (state) => {
        state.status = LOAD_STATUS.REJECTED;
        state.isLoaded = true;
      })
      .addCase(getDeletedSystemTemplates.rejected, (state) => {
        state.deleted.status = LOAD_STATUS.REJECTED;
        state.deleted.isLoaded = true;
      });
  },
});

const templateReducer = combineReducers({
  emailTemplates,
  templates,
  systemTemplates,
  template,
});

export { templateReducer };
