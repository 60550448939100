export default (
  editor,
  {
    category,
    sliderType,
    sliderFrameType,
    sliderSlidesType,
    sliderSlideType,
    sliderPrevType,
    sliderNextType,
    tabsType,
  },
) => {
  const bm = editor.BlockManager;

  bm.add(sliderType, {
    label: 'Slider',
    category,
    content: `
      <div class="${sliderType}" data-type="${sliderType}">
        <div class="${sliderFrameType}" data-type="${sliderFrameType}">
          <div class="${sliderSlidesType}" data-type="${sliderSlidesType}">
            <img src="https://placehold.co/400x400?text=Slide+1" class="${sliderSlideType}" data-type="${sliderSlideType}" alt="Slider Image" />
            <img src="https://placehold.co/400x400?text=Slide+2" class="${sliderSlideType}" data-type="${sliderSlideType}" alt="Slider Image" />
            <img src="https://placehold.co/400x400?text=Slide+3" class="${sliderSlideType}" data-type="${sliderSlideType}" alt="Slider Image" />
          </div>
        </div>
        <img class="${sliderPrevType}" data-type="${sliderPrevType}" width="32" data-type="icon-wrapper" src="https://cdn1.iconfinder.com/data/icons/material-core/12/chevron-left-32.png" alt="Previous slider" />
        <img class="${sliderNextType}" data-type="${sliderNextType}" width="32" data-type="icon-wrapper" src="https://cdn1.iconfinder.com/data/icons/material-core/12/chevron-right-32.png" alt="Next slider" />
      </div>
    `,
    attributes: { class: 'bpreview-slider' },
  });

  bm.add(tabsType, {
    label: 'Tabs',
    category,
    attributes: { class: 'bpreview-tabs' },
    content: { type: tabsType },
  });
};
