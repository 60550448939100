import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FormControl from '../FormControl';
import Label from '../Label';
import FormHelperText from '../FormHelperText';
import './CheckBox.sass';

const CheckBox = React.forwardRef(function CheckBox(
  { label, checked, helperText, fullWidth, disabled, error, ...rest },
  ref,
) {
  return (
    <FormControl fullWidth={fullWidth}>
      <Label className={cx('dnd-checkbox__label', { disabled })}>
        <div className="dnd-checkbox__icon-wrapper">
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            className="dnd-checkbox__input"
            {...rest}
            ref={ref}
          />
          <div
            role="checkbox"
            tabIndex="0"
            aria-checked={checked}
            className={cx('dnd-checkbox__icon', { checked, disabled })}
          />
        </div>
        {label}
      </Label>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
});

CheckBox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default CheckBox;
