import { COMPONENT_CHANGE_STATUSES } from 'appConstants';
import { getSelectorByType } from 'helpers';
import { checkComponentType } from 'helpers/common';

const boxedTrait = {
  type: 'checkbox',
  label: 'Boxed',
  name: 'boxed',
};

const maxwidthTrait = {
  type: 'number',
  label: 'Max Width (px)',
  name: 'maxwidth',
  min: 150,
  max: 2500,
};

const verticalAlignTrait = {
  type: 'select',
  label: 'Vertical align',
  name: 'verticalalign',
  options: [
    { value: 'default', name: 'Default' },
    { value: 'top', name: 'Top' },
    { value: 'middle', name: 'Middle' },
    { value: 'bottom', name: 'Bottom' },
  ],
};

const overflowTrait = {
  type: 'select',
  label: 'Overflow',
  name: 'overflow',
  options: [
    { value: 'visible', name: 'Default' },
    { value: 'hidden', name: 'Hidden' },
  ],
};

const backgroundColorTrait = {
  type: 'color',
  name: 'backgroundcolor',
};

const hrefTrait = {
  type: 'text',
  name: 'href',
  label: 'Link (URL)',
};

const targetTrait = {
  type: 'select',
  name: 'target',
  label: 'target',
  options: [
    { id: '_self', name: 'This window' },
    { id: '_blank', name: 'New window' },
  ],
};

const addSpace = (editor, { spaceType }) => {
  const domc = editor.DomComponents;

  domc.addType(spaceType, {
    model: {
      defaults: {
        name: 'Space',
        droppable: false,
        resizable: false,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, spaceType)) {
        return { type: spaceType };
      }
    },
  });
};

const addSection = (editor, { sectionType, containerType }) => {
  const domc = editor.DomComponents;

  const traits = [
    { ...boxedTrait, changeProp: true },
    { ...maxwidthTrait, changeProp: true },
    verticalAlignTrait,
    overflowTrait,
    backgroundColorTrait,
  ];

  domc.addType(sectionType, {
    extends: 'default',
    model: {
      defaults: {
        name: 'Section',
        type: sectionType,
        traits,
        openTraitsOnSelect: true,
        droppable: false,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:status', this.initializeTraits);
        this.listenTo(this.model, 'change:boxed', this.updateBoxed);
        this.listenTo(this.model, 'change:maxwidth', this.updateMaxWidth);
        this.listenTo(
          this.model,
          'change:attributes:verticalalign',
          this.updateVerticalAlign,
        );
        this.listenTo(
          this.model,
          'change:attributes:overflow',
          this.updateOverflow,
        );
        this.listenTo(
          this.model,
          'change:attributes:backgroundcolor',
          this.updateBackgroundColor,
        );
      },
      initializeTraits(model, status) {
        if (status !== COMPONENT_CHANGE_STATUSES.SELECTED) {
          return;
        }

        const boxedTrait = model.getTrait('boxed');
        const maxWidthTrait = model.getTrait('maxwidth');

        const [container] = model.findType(containerType);

        if (container) {
          const { boxed, maxwidth } = container.getAttributes();

          boxedTrait.setTargetValue(boxed);
          maxWidthTrait.setTargetValue(maxwidth);
        }
      },
      updateBoxed(model, boxed) {
        const [container] = model.findType(containerType);

        if (container) {
          container.addAttributes({ boxed });
        }
      },
      updateMaxWidth(model, maxwidth) {
        const [container] = model.findType(containerType);

        if (container) {
          container.addAttributes({ maxwidth });
        }
      },
      updateVerticalAlign(model, verticalalign) {
        const style = { display: 'flex' };

        if (verticalalign === 'top') {
          style['align-items'] = 'flex-start';
        } else if (verticalalign === 'middle') {
          style['align-items'] = 'center';
        } else if (verticalalign === 'bottom') {
          style['align-items'] = 'flex-end';
        } else {
          style['align-items'] = 'stretch';
        }

        model.addStyle(style);
      },
      updateOverflow(model, overflow) {
        model.addStyle({ overflow });
      },
      updateBackgroundColor(model, backgroundcolor) {
        model.addStyle({ 'background-color': backgroundcolor });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sectionType)) {
        return { type: sectionType };
      }
    },
  });
};

const addContainer = (editor, { containerType }) => {
  const domc = editor.DomComponents;

  domc.addType(containerType, {
    extends: 'default',
    model: {
      defaults: {
        name: 'Container',
        type: containerType,
        openTraitsOnSelect: true,
        removable: false,
        copyable: false,
        draggable: false,
        droppable: false,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:attributes:boxed', this.updateBoxed);
        this.listenTo(
          this.model,
          'change:attributes:maxwidth',
          this.updateMaxWidth,
        );
      },
      updateBoxed(model, boxed) {
        const { maxwidth } = model.getAttributes();

        if (boxed) {
          model.addStyle({
            'max-width': maxwidth ? `${maxwidth}px` : '',
          });
        } else {
          model.addStyle({ 'max-width': 'initial' });
        }
      },
      updateMaxWidth(model, maxwidth) {
        const { boxed } = model.getAttributes();

        if (boxed) {
          model.addStyle({ 'max-width': `${maxwidth}px` });
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, containerType)) {
        return { type: containerType };
      }
    },
  });
};

const addRow = (editor, { rowType, columnType }) => {
  const domc = editor.DomComponents;

  domc.addType(rowType, {
    extends: 'default',
    model: {
      defaults: {
        name: 'Row',
        type: rowType,
        removable: false,
        copyable: false,
        draggable: false,
        layrable: false,
        droppable: getSelectorByType(columnType),
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:overflow',
          this.updateOverflow,
        );
      },
      updateOverflow(model, overflow) {
        model.addStyle({ overflow });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, rowType)) {
        return { type: rowType };
      }
    },
  });
};

const addColumn = (editor, { rowType, columnType }) => {
  const domc = editor.DomComponents;

  const traits = [
    {
      type: 'number',
      name: 'width',
      label: 'Column width (%)',
      min: 0,
      max: 100,
    },
    overflowTrait,
    backgroundColorTrait,
    {
      type: 'select',
      label: 'HTML tag',
      name: 'tag',
      changeProp: true,
      options: [
        { id: 'div', name: 'DIV' },
        { id: 'a', name: 'A' },
      ],
    },
  ];

  domc.addType(columnType, {
    extends: 'default',
    model: {
      defaults: {
        name: 'Column',
        type: columnType,
        traits,
        draggable: getSelectorByType(rowType),
        unstylable: ['width'],
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:status', this.initializeAttributes);
        this.listenTo(this.model, 'change:attributes:width', this.updateWidth);
        this.listenTo(
          this.model,
          'change:attributes:overflow',
          this.updateOverflow,
        );
        this.listenTo(
          this.model,
          'change:attributes:backgroundcolor',
          this.updateBackgroundColor,
        );
        this.listenTo(this.model, 'change:tag', this.updateTag);
      },
      initializeAttributes(model) {
        const tagName = model.get('tagName');

        const tagTrait = model.getTrait('tag');

        tagTrait.setTargetValue(tagName);

        if (tagName === 'a') {
          const existingHrefTrait = model.getTrait('href');
          const existingTargetTrait = model.getTrait('target');

          if (!existingHrefTrait) {
            model.addTrait(hrefTrait);
          }

          if (!existingTargetTrait) {
            model.addTrait(targetTrait);
          }
        }
      },
      updateWidth(model, width) {
        model.addStyle({ 'flex-basis': `${width}%` });
      },
      updateOverflow(model, overflow) {
        model.addStyle({ overflow });
      },
      updateBackgroundColor(model, backgroundcolor) {
        model.addStyle({ 'background-color': backgroundcolor });
      },
      updateTag(model, tagName) {
        const style = model.getStyle();

        model.set('tagName', tagName);
        // readding style because tag change clears all styles
        // this is fixed in new grapesjs version
        // TODO: remove after grapesjs version update
        model.addStyle(style);

        if (tagName === 'a') {
          model.addAttributes({ href: '#', target: '_self' });
          model.addTrait([hrefTrait, targetTrait]);
        } else {
          model.removeAttributes(['href', 'target']);
          model.removeTrait(['href', 'target']);
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, columnType)) {
        return { type: columnType };
      }
    },
  });
};

export default (editor, config) => {
  addSection(editor, config);
  addContainer(editor, config);
  addRow(editor, config);
  addColumn(editor, config);
  addSpace(editor, config);
};
