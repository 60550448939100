export default (editor) => {
  editor.on('component:remove', (component) => {
    if (component.is('waymore-calendar')) {
      const wrapper = editor.getWrapper();

      if (wrapper.findType('waymore-calendar').length === 0) {
        const grecaptchaBadges =
          editor.Canvas.getDocument().getElementsByClassName(
            'grecaptcha-badge',
          );

        for (let i = 0; i < grecaptchaBadges.length; i++) {
          grecaptchaBadges[i].parentElement.remove();
        }

        const scripts =
          editor.Canvas.getDocument().getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
          if (
            scripts[i].textContent.includes(
              'function(i,s,o,g,r,a,m){i["GoogleAnalyticsObject"]',
            ) ||
            scripts[i]
              .getAttribute('src')
              ?.includes('https://www.google.com/recaptcha/api.js')
          ) {
            scripts[i].remove();
          }
        }
      }
    }
  });
};
