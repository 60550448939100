import { checkComponentType, setRuleStyle } from 'helpers';
import {
  SOCIAL_STYLES,
  SOCIAL_SOCIAL_ICONS,
  SOCIAL_MESSENGER_ICONS,
  SOCIAL_OTHER_ICONS,
} from 'appConstants';
import { linkTraits } from '../../Panels/custom-traits';

export default (
  editor,
  {
    iconBlockType,
    iconWrapperType,
    imageBlockType,
    imageType,
    linkButtonType,
    linkedImageType,
    linkedImageImageType,
    linkType,
    modalButtonType,
    modalContentType,
    modalType,
    modalWrapperType,
    svgType,
    videoType,
    socialIconWrapperType,
    socialIconType,
    circleShapeType,
    squareShapeType,
  },
) => {
  const domc = editor.DomComponents;

  domc.addType(iconWrapperType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Icon',
        type: iconWrapperType,
        droppable: false,
        resizable: true,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:src', this.updateIcon);
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        editor.runCommand('open-assets', { isIcon: true });
      },
      updateIcon(model, src) {
        if (src) {
          editor.getSelected().addAttributes({ src });
          editor.getSelected().view.render();
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, iconWrapperType)) {
        return { type: iconWrapperType };
      }
    },
  });

  domc.addType('parallax', {
    extend: 'default',
    model: {
      defaults: {
        name: 'Parallax effect',
        type: 'parallax',
        tagName: 'div',
        void: false,
        droppable: true,
        script() {
          window.addEventListener('scroll', () => {
            var scrolledHeight = window.pageYOffset;

            this.style.backgroundPositionY =
              (scrolledHeight - this.offsetTop) / 1.5 + 'px';
          });
        },
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:src', this.updateImage);
      },
      events: {
        dblclick: 'onActive',
      },
      onActive(e) {
        // this function is run when user double clicks on the
        // component and also when user drops block on canvas
        // in case of dropping event is not passed to the function
        // so because of this we need this check
        if (!e || checkComponentType(e.target, 'parallax')) {
          editor.runCommand('open-assets', { isImage: true });
        }
      },
      updateImage(model, url) {
        if (url) {
          model.addStyle({
            'background-image': `url("${url}")`,
          });

          // workaround in order to update styles of component
          editor.selectToggle(model);
          editor.select(model);
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, 'parallax')) {
        return { type: 'parallax' };
      }
    },
  });

  domc.addType(imageType, {
    model: {
      defaults: {
        name: 'Image',
      },
    },
    view: {
      onActive() {
        if (this.model.get('editable')) {
          editor.runCommand('open-assets', { isImage: true });
        }
      },
    },
  });

  domc.addType(imageBlockType, {
    extend: 'default',
    model: {
      defaults() {
        return {
          name: 'Background image',
          type: imageBlockType,
          tagName: 'div',
          void: false,
          droppable: true,
        };
      },
    },
    isComponent(el) {
      if (checkComponentType(el, imageBlockType)) {
        return { type: imageBlockType };
      }
    },
  });

  domc.addType(linkedImageType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Linked image',
        droppable: false,
        resizable: true,
        type: linkedImageType,
        traits: [
          ...linkTraits(),
          {
            type: 'text',
            name: 'alt',
            label: 'Alternate text',
            placeholder: '',
            changeProp: true,
          },
        ],
      },
      init() {
        this.on('change:alt', this.updateImageAlt);
      },
      updateImageAlt(cmp, value) {
        if (cmp.findType('linked-image-image')) {
          const imgCmp = cmp.findType('linked-image-image')[0];

          imgCmp.set('alt', value);
          imgCmp.setAttributes({
            ...imgCmp.getAttributes(),
            alt: value,
          });
        }
      },
    },
    view: {
      onActive() {
        editor.runCommand('open-assets', { isLinkedImage: true });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, linkedImageType)) {
        return { type: linkedImageType };
      }
    },
  });

  domc.addType(linkedImageImageType, {
    extend: 'image',
    model: {
      defaults: {
        editable: true,
        collection: false,
        droppable: false,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: false,
        selectable: false,
        hoverable: false,
      },
    },
    view: {
      onActive() {
        editor.runCommand('open-assets', { isLinkedImage: true });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, linkedImageImageType)) {
        return { type: linkedImageImageType };
      }
    },
  });

  domc.addType(iconBlockType, {
    extend: 'default',
    model: {
      defaults() {
        return {
          name: 'Icon',
          type: iconBlockType,
          tagName: 'div',
          void: false,
          droppable: true,
          resizable: true,
        };
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:src', this.updateImage);
      },
      events: {
        dblclick: 'onActive',
      },
      onActive(e) {
        if (!e || checkComponentType(e.target, iconBlockType)) {
          editor.runCommand('open-assets', { isIcon: true });
        }
      },
      updateImage(model, src) {
        if (src) {
          editor.getSelected().addAttributes({ src });
          editor.getSelected().view.render();
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, iconBlockType)) {
        return { type: iconBlockType };
      }
    },
  });

  domc.addType(svgType, {
    view: {
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        editor.runCommand('open-svg-modal');
      },
    },
  });

  domc.addType(videoType, {
    model: {
      defaults: {
        openTraitsOnSelect: true,
      },
    },
  });

  domc.addType(linkType, {
    model: {
      defaults: {
        openTraitsOnSelect: true,
      },
    },
  });

  domc.addType(linkButtonType, {
    extend: 'link',
    model: {
      defaults: {
        openTraitsOnSelect: false,
        name: 'Button',
        type: linkButtonType,
        droppable: false,
        tagName: 'a',
      },
    },
    isComponent(el) {
      if (checkComponentType(el, linkButtonType)) {
        return { type: linkButtonType };
      }
    },
  });

  domc.addType(modalType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Modal',
        editable: false,
        collection: false,
        droppable: false,
        copyable: false,
        resizable: false,
        // eslint-disable-next-line object-shorthand
        script: function () {
          const parentIsEditor = this.closest(
            '#grapes-iframe-body[data-gjs-type=wrapper]',
          );
          const button = this.querySelector('.modal-open-button');
          const modalWrapper = this.querySelector('.modal-wrapper');
          const modalHeaderCloseButton = this.querySelector(
            '.modal-content__close-button',
          );
          const modalFooterCloseButton = this.querySelector(
            '.modal-content__footer__close-button',
          );

          this.onkeydown = function (e) {
            if (e.key === 'Escape') {
              modalWrapper.style.display = 'none';
            }
          };

          if (button) {
            button.addEventListener('click', (e) => {
              e.preventDefault();

              modalWrapper.style.display = 'block';
            });
          }

          if (modalHeaderCloseButton) {
            modalHeaderCloseButton.addEventListener('click', (e) => {
              e.preventDefault();

              modalWrapper.style.display = 'none';
            });
          }

          if (!parentIsEditor && modalFooterCloseButton) {
            modalFooterCloseButton.addEventListener('click', (e) => {
              e.preventDefault();

              modalWrapper.style.display = 'none';
            });
          }
        },
      },
    },
    isComponent(el) {
      if (checkComponentType(el, modalType)) {
        return { type: modalType };
      }
    },
  });

  domc.addType(modalWrapperType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Modal Wrapper',
        editable: false,
        collection: false,
        droppable: false,
        draggable: false,
        copyable: false,
        removable: false,
        resizable: false,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, modalWrapperType)) {
        return { type: modalWrapperType };
      }
    },
  });

  domc.addType(modalContentType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Modal Content',
        editable: false,
        collection: false,
        draggable: false,
        copyable: false,
        removable: false,
        resizable: false,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, modalContentType)) {
        return { type: modalContentType };
      }
    },
  });

  domc.addType(modalButtonType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Modal Button',
        collection: false,
        droppable: false,
        draggable: false,
        copyable: false,
        resizable: false,
        removable: false,
        traits: [
          {
            type: 'content',
            label: 'Button Text',
          },
        ],
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:content', this.updateContent);
      },
      updateContent() {
        this.el.innerHTML = this.model.get('content');
      },
    },
    isComponent(el) {
      if (checkComponentType(el, modalButtonType)) {
        return { type: modalButtonType };
      }
    },
  });

  domc.addType(socialIconWrapperType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Social',
        type: socialIconWrapperType,
        openTraitsOnSelect: true,
        traits: [
          {
            type: 'add-social-icons',
            name: 'socialIcon',
            label: 'Add icon',
            default: 'none',
            changeProp: true,
          },
          {
            type: 'number',
            label: 'Size (px)',
            name: 'data-social-icon-size',
            min: 12,
            max: 64,
            default: 18,
          },
          {
            type: 'style-social-icons',
            name: 'data-social-icon-style',
            label: 'Icons style',
            default: 'preview-32-logo-black',
          },
        ],
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:data-social-icon-size',
          this.updateSocialSize,
        );
        this.listenTo(
          this.model,
          'change:attributes:data-social-icon-style',
          this.updateSocialStyle,
        );
      },
      updateSocialSize(cmp, value) {
        const socialIcons = cmp.findType('social-icon');

        if (socialIcons) {
          socialIcons.forEach((icon) => {
            setRuleStyle(editor, `#${icon.getId()}`, {
              'font-size': `${value}px`,
            });
          });
        }
      },
      updateSocialStyle(cmp, value) {
        const socialIcons = cmp.findType('social-icon');
        const currentIconStyle = value.replace('preview-32-', '');
        const iconStyle = SOCIAL_STYLES.filter(
          (item) => item.style === currentIconStyle,
        );
        const defaultIconSize =
          cmp.getTrait('data-social-icon-size').get('value') || '18';

        if (socialIcons) {
          socialIcons.forEach((icon) => {
            const css = editor.CssComposer;
            const { title } = icon.get('attributes');
            const iconColor = [
              ...SOCIAL_SOCIAL_ICONS,
              ...SOCIAL_MESSENGER_ICONS,
              ...SOCIAL_OTHER_ICONS,
            ].filter(({ iconTitle }) => iconTitle === title);

            css.remove(css.getRules(`#${icon.getId()}`));

            setRuleStyle(editor, `#${icon.getId()}`, {
              display: 'flex',
              'align-items': 'center',
              'justify-content': 'center',
              'font-size': `${defaultIconSize}px`,
              width: '1em',
              height: '1em',
              margin: '0 4px',
              'text-decoration': 'none',
              ...iconStyle[0].css,
              ...(iconStyle[0].customColor && {
                color: iconColor[0].iconColor,
              }),
              ...(iconStyle[0].customBgColor && {
                'background-color': iconColor[0].iconColor,
              }),
              ...(iconStyle[0].customBorderColor && {
                'border-color': iconColor[0].iconColor,
              }),
            });
          });
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, socialIconWrapperType)) {
        return { type: socialIconWrapperType };
      }
    },
  });

  domc.addType(socialIconType, {
    extend: 'link',
    model: {
      defaults: {
        name: 'Social icon',
        type: socialIconType,
        tagName: 'a',
        traits: [
          {
            type: 'url',
            name: 'href',
            label: 'Link',
          },
        ],
        draggable: '[data-gjs-type="social-icon-wrapper"]',
        openTraitsOnSelect: true,
      },
    },
    isComponent(el) {
      const elementType = el.getAttribute && el.getAttribute('data-type');

      if (el.tagName === 'A' && elementType === socialIconType) {
        return { type: socialIconType };
      }
    },
  });

  domc.addType(circleShapeType, {
    model: {
      defaults: {
        name: 'Circle Shape',
        type: circleShapeType,
        droppable: false,
        resizable: true,
        attributes: { class: 'circle-shape' },
        styles: `
          .circle-shape {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            background-color: #d3d3d3;
          }
        `,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, circleShapeType)) {
        return { type: circleShapeType };
      }
    },
  });

  domc.addType(squareShapeType, {
    model: {
      defaults: {
        name: 'Square Shape',
        type: squareShapeType,
        droppable: false,
        resizable: true,
        attributes: { class: 'square-shape' },
        styles: `
          .square-shape {
            width: 300px;
            height: 150px;
            background-color: #d3d3d3;
          }
        `,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, squareShapeType)) {
        return { type: squareShapeType };
      }
    },
  });
};
