import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useCodemirror } from 'hooks';
import CodeMirrorHeader from 'components/CodeMirrorHeader';
import FormControl from 'components/Forms/FormControl';
import Button from 'components/Button';
import './CodeEditorModal.sass';

// Function to sanitize input by escaping HTML
const sanitizeInput = (input) => {
  return input.replace(/[<>"'&]/g, (match) => {
    switch (match) {
      case '<':
        return '\u003C';
      case '>':
        return '\u003E';
      case '"':
        return '\u0022';
      case "'":
        return '\u0027';
      case '&':
        return '\u0026';
      default:
        return match;
    }
  });
};

const CodeEditorModal = ({ editor, onClose }) => {
  const intl = useIntl();
  const htmlEditorRef = useRef();
  const selected = editor?.getSelected();

  const htmlEditor = useCodemirror(htmlEditorRef, {
    value: selected.get('attributes')?.onclick || '',
    mode: { name: 'javascript' },
  });

  const handleSave = () => {
    const userInput = htmlEditor.current.getValue();
    const sanitizedInput = sanitizeInput(userInput);

    selected.set('attributes', {
      ...selected.get('attributes'),
      onclick: sanitizedInput,
    });
    editor.runCommand('add-event-click');
    onClose();
  };

  return (
    <div className="custom-code-modal">
      <div className="custom-code-modal__row">
        <FormControl className="custom-code-modal__editor-wrapper">
          <CodeMirrorHeader title="Code" htmlEditor={htmlEditor} activeCode />
          <div className="custom-code-modal__editor" ref={htmlEditorRef} />
        </FormControl>
      </div>
      <div className="custom-code-modal__buttons">
        <Button
          onClick={handleSave}
          className="custom-code-modal__button"
          variant="contained">
          {intl.formatMessage({ id: 'button.save-codes' })}
        </Button>
      </div>
    </div>
  );
};

CodeEditorModal.propTypes = {
  editor: PropTypes.shape({
    runCommand: PropTypes.func,
    getSelected: PropTypes.func,
  }),
  onClose: PropTypes.func,
};

export default CodeEditorModal;
