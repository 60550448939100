import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';

export const SubscriptionPlanItem = ({
  plan,
  getsubscriptionPlanName,
  openSubscriptionPlanEdit,
  setCurrentPlanId,
}) => {
  const { subscriptionPlanNumber, type, id } = plan;

  return (
    <li data-testid="plans-list-item">
      <span className="subscription-plans-modal__plans-list__title">
        {`${subscriptionPlanNumber} - ${getsubscriptionPlanName(
          subscriptionPlanNumber,
        )}`}
        <span>({type})</span>
      </span>
      <div className="subscription-plans-modal__plans-list__actions">
        <IconButton
          className="subscription-plans-modal__plans-edit-btn"
          aria-label="Edit a subscription plan"
          onClick={() => {
            openSubscriptionPlanEdit();
            setCurrentPlanId(id);
          }}>
          <span className="icon icon-edit" />
        </IconButton>
      </div>
    </li>
  );
};

SubscriptionPlanItem.propTypes = {
  plan: PropTypes.object.isRequired,
  getsubscriptionPlanName: PropTypes.func.isRequired,
  openSubscriptionPlanEdit: PropTypes.func.isRequired,
  setCurrentPlanId: PropTypes.func.isRequired,
};

export default SubscriptionPlanItem;
