import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTemplateImages } from 'hooks';
import { Tabs, TabList, TabPanels, TabPanel, Tab } from '@reach/tabs';
import { FormattedMessage } from 'react-intl';
import { getSelectedImageUrl } from 'helpers';
import {
  AiImages,
  GlobalImages,
  IconImages,
  TemplateImages,
  UnsplashImages,
} from './components';
import './ImageModal.sass';

function ImageModal({ withIconsTab = false, onSetImageUrl, id, editor }) {
  const selected = editor?.getSelected();
  const wrapper = editor?.getWrapper();

  const {
    templateImagesState: {
      data: templateImagesData,
      loading: templateImagesLoading,
    },
    isConfirmTemplateDeleteModalOpen,
    openConfirmTemplateDeleteModal,
    closeConfirmTemplateDeleteModal,
    isTemplateImageDeleteLoading,
    handleDeleteImage,
    globalImagesState: { data: globalImagsData, loading: globalImagsLoading },
    isConfirmGlobalDeleteModalOpen,
    openConfirmGlobalDeleteModal,
    closeConfirmGlobalDeleteModal,
    isGlobalImageDeleteLoading,
    handleUploadImage,
    handleDeleteGlobalImage,
  } = useTemplateImages(id);

  const handleSetImageUrl = (url) => {
    onSetImageUrl({ url, isIcon: withIconsTab });
  };

  // Remove the thumbnail when a user has not selected any image/icon
  useEffect(() => {
    return () => {
      if (selected) {
        const { 'data-type': type, src } = selected.getAttributes();

        if (
          (type === 'image' || type === 'icon-wrapper') &&
          src.startsWith('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9')
        ) {
          selected.remove();
          editor.select(wrapper);
        }
      }
    };
  }, [selected, wrapper, editor]);

  return (
    <div className="image-modal">
      {withIconsTab ? (
        <IconImages onSetImageUrl={handleSetImageUrl} />
      ) : (
        <Tabs>
          <TabList>
            <>
              <Tab as="div">
                <FormattedMessage id="image-modal.global-images.tab" />
              </Tab>
              <Tab as="div" disabled={templateImagesData?.length === 0}>
                <FormattedMessage id="image-modal.template-images.tab" />
              </Tab>
              <Tab as="div">
                <FormattedMessage id="image-modal.free-images.tab" />
              </Tab>
              <Tab style={{ color: '#2684FF' }} as="div">
                <span className="icon icon-magic-wand" />
                <FormattedMessage id="image-modal.ai-images.tab" />
              </Tab>
            </>
          </TabList>
          <TabPanels>
            <>
              <TabPanel>
                <GlobalImages
                  selectedImageUrl={getSelectedImageUrl(selected)}
                  id={id}
                  onSetImageUrl={handleSetImageUrl}
                  data={globalImagsData}
                  loading={globalImagsLoading}
                  isConfirmGlobalDeleteModalOpen={
                    isConfirmGlobalDeleteModalOpen
                  }
                  openConfirmGlobalDeleteModal={openConfirmGlobalDeleteModal}
                  closeConfirmGlobalDeleteModal={closeConfirmGlobalDeleteModal}
                  isGlobalImageDeleteLoading={isGlobalImageDeleteLoading}
                  handleUploadImage={handleUploadImage}
                  handleDeleteGlobalImage={handleDeleteGlobalImage}
                />
              </TabPanel>
              <TabPanel>
                <TemplateImages
                  selectedImageUrl={getSelectedImageUrl(selected)}
                  id={id}
                  onSetImageUrl={handleSetImageUrl}
                  editor={editor}
                  data={templateImagesData}
                  loading={templateImagesLoading}
                  isConfirmTemplateDeleteModalOpen={
                    isConfirmTemplateDeleteModalOpen
                  }
                  openConfirmTemplateDeleteModal={
                    openConfirmTemplateDeleteModal
                  }
                  closeConfirmTemplateDeleteModal={
                    closeConfirmTemplateDeleteModal
                  }
                  isTemplateImageDeleteLoading={isTemplateImageDeleteLoading}
                  handleDeleteImage={handleDeleteImage}
                />
              </TabPanel>
              <TabPanel>
                <UnsplashImages onSetImageUrl={handleSetImageUrl} />
              </TabPanel>
              <TabPanel>
                <AiImages onSetImageUrl={handleSetImageUrl} />
              </TabPanel>
            </>
          </TabPanels>
        </Tabs>
      )}
    </div>
  );
}

ImageModal.propTypes = {
  editor: PropTypes.object,
  onSetImageUrl: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  withIconsTab: PropTypes.bool,
};

export default ImageModal;
