const PUBLIC_URL = process.env.PUBLIC_URL;
const API_HOST = process.env.REACT_APP_API_HOST;
const ASSETS_API_HOST = process.env.REACT_APP_ASSETS_API_HOST;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
const DISABLE_AUTH = process.env.REACT_APP_DISABLE_AUTH;
const CDN_HOST = process.env.REACT_APP_CDN_HOST;
const VERSION = process.env.REACT_APP_VERSION;
const NODE_ENV = process.env.NODE_ENV;

export {
  PUBLIC_URL,
  API_HOST,
  ASSETS_API_HOST,
  REDIRECT_URL,
  DISABLE_AUTH,
  CDN_HOST,
  VERSION,
  NODE_ENV,
};
