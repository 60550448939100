import { USER_ROLES } from 'appConstants';

export const principalListSelector = (state) => {
  return state.user.principalList;
};

export const isAdminSelector = (state) => {
  return state.user.role === USER_ROLES.ADMIN;
};

export const principalIdSelector = (state) => {
  return state.user.principalId;
};

export const subscriptionPlanSelector = (state) => {
  return state.user.subscriptionPlan;
};

export const tokenSelector = (state) => {
  return state.user.token;
};

export const userEmailSelector = (state) => {
  return state.user.email;
};
