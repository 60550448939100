import md5 from 'blueimp-md5';
import {
  API_HOST,
  DISABLE_AUTH,
  DOMAIN_REGEX,
  EMAIL_REGEX,
  ERROR_MESSAGES,
  REDIRECT_URL,
  SPECIAL_SIGNS,
  START_END_WITH_SPACE,
  TEMPLATE_STATUS,
  URL_REGEX,
  userIcon,
  VERIFY_DOMAIN_STATUSES,
} from 'appConstants';

/**
 * Function to build version preview url
 * @param {string} templateId Unique Id of the template
 * @param {string} versionDate date, time and timezone
 * @returns {string} Returns version preview url
 */
export const buildVersionPreviewUrl = (templateId, versionDate) => {
  return `${API_HOST}/api/v2/usertemplate/public/${templateId}/version?versionDate=${versionDate}`;
};

/**
 * Function to show principal full name
 * @param {Array} userListId List of users id
 * @param {Array} selectedUserList selected users list
 */
export const getListUsersFullName = (
  userListId = [],
  selectedUserList = [],
) => {
  if (userListId) {
    return (
      userListId
        .filter(({ id }) => selectedUserList.includes(id))
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(selectedUserList.length === 0 ? '' : ', ') || ''
    );
  }

  return '';
};

/**
 * Function to build shared template view url
 * @param {string} id Unique Id of the template
 * @returns {string} Returns shared template view url
 */
export const buildSharedTemplateViewUrl = (id) => {
  return `${API_HOST}/api/v2/usertemplate/sharing/show/${id}`;
};

/**
 * Function to check whether is string valid email or not
 * @param {string} email String to test whether it's email or not
 * @returns {boolean} Returns true if email is valid
 */
export const isEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

/**
 * Function to build global asset url
 * @param {string} userId user principal id
 * @param {string} name Name of the image
 * @returns {string} Returns url of the image
 */
export const buildGlobalAssetsUrl = (userId, name = '') =>
  `${API_HOST}/api/v2/assets/user/${userId}/asset/${name}`;

/**
 * Function to build asset url
 * @param {string} id Id of the template
 * @param {string} name Name of the image
 * @returns {string} Returns url of the image
 */
export const buildAssetsUrl = (id, name = '') =>
  `${API_HOST}/api/v2/assets/template/${id}/asset/${name}`;

/**
 * Function to build screenshot image url
 * @param {string} id Id of the template
 * @param {number} lastModified Timestamp of last modified action
 * @returns {string} Returns url of the screenshot image
 */
export const buildScreenshotUrl = (id, lastModified) =>
  `${API_HOST}/resources/${id}/screenshot.jpg?${lastModified}`;

/**
 * Function to build published url
 * @param {string} id Unique Id of the template
 * @returns {string} Returns published template url or null if id isn't provided
 */
export const buildPublishedUrl = (id, status, domain = {}) => {
  if (status !== TEMPLATE_STATUS.PUBLISHED) {
    return null;
  }

  if (
    [
      VERIFY_DOMAIN_STATUSES.ACTIVE,
      VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS,
      VERIFY_DOMAIN_STATUSES.DEACTIVATION_ERROR,
    ].includes(domain.status)
  ) {
    return `https://${domain.name}`;
  }

  return `${API_HOST}/public/${id}`;
};

let lastId = 0;

export const generateId = (prefix = 'id') => {
  lastId++;
  return `${prefix}-${lastId}`;
};

/**
 * Function to check whether is string valid template name or not
 * @param {string} str String to test whether it doesntot consist special characters
 * @returns {boolean} Returns true if it is valid
 */
export const isStringWithSpecialSigns = (str) => {
  return START_END_WITH_SPACE.test(str) || SPECIAL_SIGNS.test(str);
};

/**
 * Function to check whether is element type is equal passed type
 * @param {object} element Dom element which should be checked
 * @param {string} type Type which should be checked
 * @returns {boolean} Returns true if component type is equal passed type
 */
export const checkComponentType = (element, type) => {
  // TODO: it seems that this check isn't necessary since correct element
  // is always passed to the function. Check whether it's true or not
  if (typeof element !== 'object') {
    return false;
  }

  return element.dataset?.type === type;
};

/**
 * Function to check whether is string valid URL or not
 * @param {string} url String to test whether it is url or simple string
 * @returns {boolean} Returns true if it is valid
 */
export const isValidURL = (value) => {
  return URL_REGEX.test(value);
};

/**
 * Function to check whether string is valid domain name or not
 * @param {string} value Value to test
 * @returns {boolean} Returns true if it is valid
 */
export const isValidDomain = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  return DOMAIN_REGEX.test(value);
};

/**
 * Function to check whether template valid or not
 * @param {string} value Value to test
 * @returns {boolean} Returns true if it is valid
 */
export const isValidStatus = (status) => {
  return Object.values(TEMPLATE_STATUS).includes(status);
};

/**
 * Function to check whether auth disabled or not
 * @returns {boolean} Returns true if auth is disabled
 */
export const isDisableAuth = () => {
  return DISABLE_AUTH === 'true';
};

/**
 * Function to get cookie by name
 * @param {string} name Name of the cookie
 * @returns {string} Returns cookie value or undefined
 */
export const getCookie = (name) => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
};

/**
 * Function to delete cookie by name
 * @param {string} name Name of the cookie
 */
export const deleteCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};

/**
 * Function to get session id
 * @returns {string} Returns session id value
 */
export const getSessionId = () => {
  return !isDisableAuth()
    ? getCookie('sessionId')
    : localStorage.getItem('fakeSessionId') || 'session_id';
};

/**
 * Function for redirection to authenticate
 */
export const redirectToAuth = () => {
  window.location.replace(REDIRECT_URL);
};

/**
 * Function to resize image
 * @param {HTMLImageElement} image Origin image to resize
 * @param {number} width Target image width
 * @param {number} height Target image height
 * @returns {string} Returns Data URL containing a representation of the image
 */
export const resizeCanvasImage = (image, width, height) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);

  return canvas.toDataURL();
};

/**
 * Function to extract host
 * @param {string} name Domain from what should be extracted hostname
 * @returns {string} Returns host
 */
export const getHost = (name) => {
  if (!isValidDomain(name)) {
    return '@';
  }

  const parts = name.split('.');

  if (parts.length > 2) {
    return parts.slice(0, parts.length - 2).join('.');
  }

  return '@';
};
/**
 * Function to parse json string
 * @param {string} string String to parse
 * @returns {object | string} If passed string is json, it'll return parsed object.
 *                            Otherwise it'll return plain text
 */
export const parseJson = (string) => {
  try {
    const json = JSON.parse(string);

    return json;
  } catch (error) {
    return string;
  }
};

/**
 * Function to get full name based on first and last names
 * @param {string} firstName First name
 * @param {string} lastName Last name
 * @returns {string} Full name or default name
 */
export const getFullName = ({ firstName, lastName } = {}) => {
  if (!firstName && !lastName) {
    return 'User';
  }

  if (!firstName && lastName) {
    return lastName;
  }

  if (!lastName && firstName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};

/**
 * Function generate avatar url
 * @param {string} email Email of the user
 * @returns {string} Avatar url
 */
export const getAvatarUrl = (email) => {
  if (!email) {
    return userIcon();
  }

  const hash = md5(email.trim().toLocaleLowerCase());

  return `https://www.gravatar.com/avatar/${hash}`;
};

/**
 * Check whether is empty or not
 * @param {object} obj Object ot check
 * @returns {boolean} Return true if object is empty
 */
export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Get error message from error object
 * @param {object} error Error object to extract error message
 * @param {string} [fallbackErrorMessage] Fallback error message in case there is no message in error
 * @returns {string} Return error message
 */
export function getErrorText(
  error,
  fallbackErrorMessage = 'Something went wrong. Try again later',
) {
  const fallback = fallbackErrorMessage;

  if (!error?.message) {
    return fallback;
  }

  if (error?.response?.data?.code) {
    return ERROR_MESSAGES[error.response.data.code];
  }

  return error.message;
}

/**
 * Check whether content or it's descendants has focus or not
 * @param {Element} content HTML element to check
 * @returns {boolean} Returns true if content or some element inside of it has focus
 */
export function contentHasFocus(content) {
  return (
    document.activeElement === content ||
    content.contains(document.activeElement)
  );
}

/**
 * Get font links without duplicates
 * @param {Array} links List of existing template links
 * @param {Array} urls List of urls
 * @returns {Array} Returns new list of links
 */
export const getLinks = (links = [], urls = []) => {
  const newLinks = urls.reduce((acc, url) => {
    if (!url) {
      return acc;
    }

    const existingLink = links.find((link) => link.href === url);

    if (!existingLink) {
      acc.push({ href: url, rel: 'stylesheet' });
    }

    return acc;
  }, []);

  return [...links, ...newLinks];
};

export const getsubscriptionPlanName = (subscriptionPlanNumber) => {
  switch (subscriptionPlanNumber) {
    case 0:
      return 'Trial';

    case 1:
      return 'Free';

    case 2:
      return 'Pro';

    case 3:
      return 'Premium';

    default:
      return 'Custom';
  }
};
