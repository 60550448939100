import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BASE64_REGEX } from 'appConstants';
import { showErrorMessage, getErrorText } from 'helpers';
import { httpGetTemplateSize } from 'api';
import { useIntl } from 'react-intl';
import { useCodemirror } from 'hooks';
import CodeMirrorHeader from 'components/CodeMirrorHeader';
import CodeMirrorAllButtons from 'components/CodeMirrorAllButtons';
import FormControl from 'components/Forms/FormControl';
import Button from 'components/Button';
import './CodeImportModal.sass';

function CodeImportModal({ editor, onClose }) {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);

  const htmlEditorRef = useRef();
  const cssEditorRef = useRef();

  const htmlEditor = useCodemirror(htmlEditorRef, {
    value: editor.getHtml(),
    mode: 'htmlmixed',
  });

  const cssEditor = useCodemirror(cssEditorRef, {
    value: `<style>${editor.getCss()}</style>`,
    mode: 'css',
  });

  const findLine = (text, searchCases) => {
    let findedLines = [];
    const lines = text.split('\n');

    searchCases.forEach((searchCase) => {
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].includes(searchCase)) {
          findedLines.push(i + 1);
        }
      }
    });

    return findedLines
      ? [...new Set(findedLines)].map((line) => `#${line}`).join(', ')
      : null;
  };

  const handleSave = async () => {
    setIsLoading(true);

    const htmlCodes = htmlEditor?.current.getValue();
    const cssCodes = cssEditor?.current.getValue();
    const editedCodes = htmlCodes + cssCodes;
    const matchedBase64ForHtml = htmlCodes.match(BASE64_REGEX);
    const matchedBase64ForCss = cssCodes.match(BASE64_REGEX);

    // Search in HTML codes
    if (matchedBase64ForHtml) {
      const findLineResult = findLine(htmlCodes, matchedBase64ForHtml);

      if (findLineResult) {
        showErrorMessage(
          `Base64 encoded content is not allowed in the template. Please ensure it is not present in line(s) ${findLineResult} of the HTML code.`,
        );
      }
    }

    // Search in CSS codes
    if (matchedBase64ForCss) {
      const findLineResult = findLine(cssCodes, matchedBase64ForCss);

      if (findLineResult) {
        showErrorMessage(
          `Base64 encoded content is not allowed in the template. Please ensure it is not present in line(s) ${findLineResult} of the CSS code.`,
        );
      }
    }

    try {
      const {
        data: { size },
      } = await httpGetTemplateSize({
        templateType: 'page',
        html: htmlCodes,
        css: cssCodes,
      });

      if (size === 'oversize') {
        showErrorMessage('The template content exceeds the size limit.');
      }

      if (
        !matchedBase64ForHtml &&
        !matchedBase64ForCss &&
        size !== 'oversize'
      ) {
        editor.Css.clear();
        editor.setComponents(editedCodes);
        editor.refresh();

        onClose();
      }
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="code-import-modal">
      <div className="code-import-modal__row">
        <FormControl className="code-import-modal__editor-wrapper">
          <CodeMirrorHeader
            title="HTML"
            htmlEditor={htmlEditor}
            cssEditor={cssEditor}
            activeHtml
          />
          <div className="code-import-modal__editor" ref={htmlEditorRef} />
        </FormControl>
        <FormControl className="code-import-modal__editor-wrapper">
          <CodeMirrorHeader title="CSS" cssEditor={cssEditor} activeCss />
          <div className="code-import-modal__editor" ref={cssEditorRef} />
        </FormControl>
      </div>
      <div className="code-import-modal__buttons">
        <CodeMirrorAllButtons
          editor={editor}
          onClose={onClose}
          htmlEditor={htmlEditor}
          cssEditor={cssEditor}
        />
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSave}
          className="code-import-modal__button"
          variant="contained">
          {intl.formatMessage({ id: 'button.save-codes' })}
        </Button>
      </div>
    </div>
  );
}

CodeImportModal.propTypes = {
  editor: PropTypes.object,
  onClose: PropTypes.func,
};

export default CodeImportModal;
