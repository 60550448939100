import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './FormControl.sass';

const FormControl = React.forwardRef(function FormControl(
  { children, className, fullWidth, ...rest },
  ref,
) {
  return (
    <div
      className={cx('dnd-form-control', className, { fullWidth })}
      {...rest}
      ref={ref}>
      {children}
    </div>
  );
});

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default FormControl;
