import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscriptionPlan } from 'store/actions';
import { subscriptionPlansSelector } from 'store/selectors';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { showSuccessMessage, showErrorMessage, getErrorText } from 'helpers';
import Button from 'components/Button';
import Switch from 'components/Forms/Switch';
import Input from 'components/Forms/Input';
import TextField from 'components/Forms/TextField';
import './SubscriptionPlanCreateModal.sass';

export const SubscriptionPlanCreateModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const [isSaveLoading, setSaveLoading] = useState(false);
  const [currentPlanData, setCurrentPlanData] = useState({
    subscriptionPlanNumber: 1,
    type: 'page',
    publicPagesNumber: 0,
    unsplashEnabled: true,
    iconfinderEnabled: true,
    waymoreIntegration: false,
    customDomainEnabled: true,
    customDomainNumber: null,
    removeBranding: true,
    createCustomCode: false,
    editCustomCode: true,
  });

  const repetitivePlan = useSelector(subscriptionPlansSelector).filter(
    ({ type, subscriptionPlanNumber }) =>
      type === currentPlanData.type &&
      currentPlanData.subscriptionPlanNumber === subscriptionPlanNumber,
  );

  const messages = defineMessages({
    addingSuccessMessage: {
      id: 'modal-subscription-plan-create.adding-success-message',
    },
    subscriptionpPlanNumberError: {
      id: 'modal-subscription-plan-create.subscription-plan-number-error',
    },
    planTypeEmailLabel: {
      id: 'modal-subscription-plan-edit.plan-type-email-label',
    },
    planTypePageLabel: {
      id: 'modal-subscription-plan-edit.plan-type-page-label',
    },
    planTypeLabel: {
      id: 'modal-subscription-plan-edit.plan-type-label',
    },
    allowCustomDomainLabel: {
      id: 'modal-subscription-plan-edit.allow-custom-domain-label',
    },
    allowWatermarkLabel: {
      id: 'modal-subscription-plan-edit.allow-watermark-label',
    },
    allowCreateCustomCodeLabel: {
      id: 'modal-subscription-plan-edit.allow-create-custom-code-label',
    },
    allowEditCustomCodeLabel: {
      id: 'modal-subscription-plan-edit.allow-edit-custom-code-label',
    },
    allowFormsProductsIntegrationLabel: {
      id: 'modal-subscription-plan-edit.allow-forms-products-integration-label',
    },
    closeButton: { id: 'button.close' },
    saveAdd: { id: 'button.add' },
  });

  const handleSave = async () => {
    setSaveLoading(true);

    try {
      await dispatch(createSubscriptionPlan(currentPlanData)).unwrap();

      showSuccessMessage(intl.formatMessage(messages.addingSuccessMessage));
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      setSaveLoading(false);
      onClose();
    }
  };

  return (
    <form
      className="subscription-plan-create-modal__form"
      onSubmit={handleSubmit(handleSave)}>
      <div className="subscription-plan-create-modal__content">
        <div className="dnd-form-control">
          <label>{intl.formatMessage(messages.planTypeLabel)}</label>
          <div className="subscription-plan-create-modal__content__plan-type">
            <label htmlFor="email-plan-type">
              <input
                type="radio"
                name="plan-type"
                id="email-plan-type"
                value="email"
                defaultChecked={currentPlanData.type === 'email'}
                onChange={(e) =>
                  setCurrentPlanData({
                    ...currentPlanData,
                    type: e.target.value,
                  })
                }
              />
              {intl.formatMessage(messages.planTypeEmailLabel)}
            </label>
            <label htmlFor="page-plan-type">
              <input
                type="radio"
                name="plan-type"
                id="page-plan-type"
                value="page"
                defaultChecked={currentPlanData.type === 'page'}
                onChange={(e) =>
                  setCurrentPlanData({
                    ...currentPlanData,
                    type: e.target.value,
                  })
                }
              />
              {intl.formatMessage(messages.planTypePageLabel)}
            </label>
          </div>
        </div>
        <div className="dnd-form-control">
          <label htmlFor="subscription-plan-number">
            <FormattedMessage id="modal-subscription-plan-create.subscription-plan-number-label" />
          </label>
          <TextField
            id="subscription-plan-number"
            type="number"
            value={currentPlanData.subscriptionPlanNumber}
            error={repetitivePlan?.length > 0}
            helperText={
              repetitivePlan?.length > 0 &&
              intl.formatMessage(messages.subscriptionpPlanNumberError)
            }
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              setCurrentPlanData({
                ...currentPlanData,
                subscriptionPlanNumber: +e.target.value,
              })
            }
          />
        </div>
        <Switch
          label={intl.formatMessage(
            messages.allowFormsProductsIntegrationLabel,
          )}
          fullWidth
          disabled={false}
          checked={currentPlanData.waymoreIntegration}
          onChange={(value) =>
            setCurrentPlanData({
              ...currentPlanData,
              waymoreIntegration: value,
            })
          }
        />
        <Switch
          label={intl.formatMessage(messages.allowCreateCustomCodeLabel)}
          fullWidth
          disabled={false}
          checked={currentPlanData.createCustomCode}
          onChange={(value) =>
            setCurrentPlanData({
              ...currentPlanData,
              createCustomCode: value,
            })
          }
        />
        <Switch
          label={intl.formatMessage(messages.allowEditCustomCodeLabel)}
          fullWidth
          disabled={false}
          checked={currentPlanData.editCustomCode}
          onChange={(value) =>
            setCurrentPlanData({
              ...currentPlanData,
              editCustomCode: value,
            })
          }
        />
        {currentPlanData.type === 'page' && (
          <>
            <Switch
              label={intl.formatMessage(messages.allowCustomDomainLabel)}
              fullWidth
              disabled={false}
              checked={currentPlanData.customDomainEnabled}
              onChange={(value) =>
                setCurrentPlanData({
                  ...currentPlanData,
                  customDomainEnabled: value,
                })
              }
            />
            <Switch
              label={intl.formatMessage(messages.allowWatermarkLabel)}
              fullWidth
              disabled={false}
              checked={currentPlanData.removeBranding}
              onChange={(value) =>
                setCurrentPlanData({
                  ...currentPlanData,
                  removeBranding: value,
                })
              }
            />
          </>
        )}
        <div className="dnd-form-control">
          <label htmlFor="public-pages-number">
            <FormattedMessage id="modal-subscription-plan-edit.max-published-templates-label" />
          </label>
          <Input
            id="public-pages-number"
            type="number"
            value={currentPlanData.publicPagesNumber}
            onFocus={(e) => e.target.select()}
            error={
              +currentPlanData.publicPagesNumber < -1 ||
              currentPlanData.publicPagesNumber === ''
            }
            min={-1}
            onChange={(e) =>
              setCurrentPlanData({
                ...currentPlanData,
                publicPagesNumber: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="subscription-plan-create-modal__buttons">
        <Button type="button" onClick={onClose}>
          {intl.formatMessage(messages.closeButton)}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          loading={isSaveLoading}
          disabled={
            isSaveLoading ||
            repetitivePlan?.length > 0 ||
            +currentPlanData.publicPagesNumber < -1 ||
            currentPlanData.publicPagesNumber === ''
          }>
          {intl.formatMessage(messages.saveAdd)}
        </Button>
      </div>
    </form>
  );
};

SubscriptionPlanCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SubscriptionPlanCreateModal;
