import React, { useRef, useState, useEffect, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { resizeCanvasImage } from 'helpers';
import Button from '../Button';
import FileInput from '../Forms/FileInput';
import './Cropper.sass';

function Cropper({
  label,
  helperText,
  imageUrl,
  canvasSize,
  cropSize,
  border,
  loading,
  onSave,
}) {
  const editorRef = useRef(null);

  const [scale, setScale] = useState(1);

  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(null);
    setScale(1);
  }, [imageUrl]);

  const handleSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();

      const image = resizeCanvasImage(canvas, cropSize.width, cropSize.height);

      const res = await fetch(image);
      const file = await res.blob();

      onSave(file);
    }
  };

  const preventScroll = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleWheel = ({ deltaY }) => {
    setScale((prevScale) => {
      let nextScale = prevScale + deltaY * -0.002;

      // minScale = 1; maxScale = 4;
      nextScale = Math.min(Math.max(1, nextScale), 4);

      return nextScale;
    });
  };

  const handleDisableScroll = () => {
    document.addEventListener('wheel', preventScroll, { passive: false });
  };

  const handleEnableScroll = () => {
    document.removeEventListener('wheel', preventScroll);
  };

  const setEditorRef = (editor) => {
    editorRef.current = editor;
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
  };

  const cancelEditing = () => {
    setImage(null);
    setScale(1);
  };

  return (
    <div className="dnd-cropper">
      {!image ? (
        <div
          className="dnd-cropper__image-wrapper"
          style={{
            width: canvasSize.width + border * 2,
            height: canvasSize.height + border * 2,
          }}>
          <img
            className="dnd-cropper__image"
            src={imageUrl}
            alt="Placeholder"
          />
        </div>
      ) : (
        <div className="dnd-cropper__editor-wrapper">
          <AvatarEditor
            ref={setEditorRef}
            image={image}
            width={canvasSize.width}
            height={canvasSize.height}
            border={border}
            color={[38, 153, 251, 0.3]} // RGBA
            scale={scale}
            rotate={0}
            crossOrigin="anonymous"
            onWheel={handleWheel}
            onMouseEnter={handleDisableScroll}
            onMouseLeave={handleEnableScroll}
          />
        </div>
      )}
      <div className="dnd-cropper__controls">
        <div className="dnd-cropper__label">{label}</div>
        {helperText && (
          <div className="dnd-cropper__helper-text">{helperText}</div>
        )}
        <div className="dnd-cropper__buttons">
          {!image ? (
            <FileInput onChange={handleChangeImage} accept="image/*">
              <Button fullWidth variant="contained">
                <FormattedMessage id="button.upload" />
                <span className="icon icon-cloud-upload" />
              </Button>
            </FileInput>
          ) : (
            <>
              <Button fullWidth onClick={cancelEditing} disabled={loading}>
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSave}
                disabled={loading}
                loading={loading}>
                <FormattedMessage id="button.save" />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

Cropper.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  imageUrl: PropTypes.string,
  canvasSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  cropSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  border: PropTypes.number,
  loading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
};

export default Cropper;
