import { useReducer, useCallback } from 'react';

const initialState = {
  info: false,
  clean: false,
  showInfoModal: false,
  showQRModal: false,
  showImageModal: false,
  showIconModal: false,
  showPersonalNotesModal: false,
  showVersionHistory: false,
  showCustomModulCategoryModal: false,
  pageSettings: false,
  publishedModal: false,
  wayMoreFormModal: false,
  wayMoreProductModal: false,
  blockModal: false,
  codeImportModal: false,
  blockModalPayload: null,
  addClickEventModal: false,
};

export const useToggleTypes = {
  showClean: 'showClean',
  showSvgModal: 'showSvgModal',
  showInfoModal: 'showInfoModal',
  showQRModal: 'showQRModal',
  showImageModal: 'showImageModal',
  showIconModal: 'showIconModal',
  showPersonalNotesModal: 'showPersonalNotesModal',
  showVersionHistory: 'showVersionHistory',
  showCustomModulCategoryModal: 'showCustomModulCategoryModal',
  showPageSettings: 'showPageSettings',
  showPublishedModal: 'showPublishedModal',
  showWayMoreFormModal: 'showWayMoreFormModal',
  showWayMoreProductModal: 'showWayMoreProductModal',
  showWayMoreCalendarModal: 'showWayMoreCalendarModal',
  showBlockModal: 'showBlockModal',
  showCodeImportModal: 'showCodeImportModal',
  showViewCodeModal: 'showViewCodeModal',
  showCustomCodeModal: 'showCustomCodeModal',
  showAddClickEventModal: 'showAddClickEventModal',
};

const reducer = (state, action) => {
  switch (action.type) {
    case useToggleTypes.showClean:
      return { ...state, clean: !state.clean };
    case useToggleTypes.showInfoModal:
      return { ...state, showInfoModal: !state.showInfoModal };
    case useToggleTypes.showQRModal:
      return { ...state, showQRModal: !state.showQRModal };
    case useToggleTypes.showImageModal:
      return { ...state, showImageModal: !state.showImageModal };
    case useToggleTypes.showIconModal:
      return { ...state, showIconModal: !state.showIconModal };
    case useToggleTypes.showPersonalNotesModal:
      return {
        ...state,
        showPersonalNotesModal: !state.showPersonalNotesModal,
      };
    case useToggleTypes.showVersionHistory:
      return {
        ...state,
        showVersionHistory: !state.showVersionHistory,
      };
    case useToggleTypes.showCustomModulCategoryModal:
      return {
        ...state,
        showCustomModulCategoryModal: !state.showCustomModulCategoryModal,
      };
    case useToggleTypes.showPageSettings:
      return { ...state, pageSettings: !state.pageSettings };
    case useToggleTypes.showCodeImportModal:
      return { ...state, codeImportModal: !state.codeImportModal };
    case useToggleTypes.showViewCodeModal:
      return { ...state, viewCodeModal: !state.viewCodeModal };
    case useToggleTypes.showCustomCodeModal:
      return { ...state, customCodeModal: !state.customCodeModal };
    case useToggleTypes.showPublishedModal:
      return { ...state, publishedModal: !state.publishedModal };
    case useToggleTypes.showWayMoreFormModal:
      return { ...state, wayMoreFormModal: !state.wayMoreFormModal };
    case useToggleTypes.showWayMoreProductModal:
      return { ...state, wayMoreProductModal: !state.wayMoreProductModal };
    case useToggleTypes.showWayMoreCalendarModal:
      return { ...state, wayMoreCalendarModal: !state.wayMoreCalendarModal };
    case useToggleTypes.showSvgModal:
      return { ...state, svgModal: !state.svgModal };
    case useToggleTypes.showBlockModal:
      return {
        ...state,
        blockModal: !state.blockModal,
        blockModalPayload: action.payload || null,
      };
    case useToggleTypes.showAddClickEventModal:
      return { ...state, addClickEventModal: !state.addClickEventModal };
    default:
      throw new Error();
  }
};

export const useToggle = () => {
  const [show, dispatch] = useReducer(reducer, initialState);

  const handleShow = useCallback(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [dispatch],
  );

  return {
    show,
    handleShow,
  };
};
