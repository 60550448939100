import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Tag.sass';

const Tag = React.forwardRef(function Tag(
  { children, name, className, onDelete, ...rest },
  ref,
) {
  return (
    <div className={cx('dnd-tag', className)} {...rest} ref={ref}>
      <span>{children}</span>
      {onDelete && (
        <button
          aria-label={`delete ${name} tag`}
          type="button"
          className="dnd-tag__delete-button"
          onClick={onDelete}>
          <span className="icon icon-close" />
        </button>
      )}
    </div>
  );
});

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};

export default Tag;
