import { useCallback, useEffect, useRef, useState } from 'react';

import { contentHasFocus } from 'helpers';

export function usePopover({ triggerRef }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const trigger = triggerRef.current;

    if (isOpen && !contentHasFocus(ref.current)) {
      ref.current.focus({ preventScroll: true });
    }

    return () => {
      if (isOpen && trigger) {
        trigger.focus({ preventScroll: true });
      }
    };
  }, [isOpen, triggerRef]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    function listener(event) {
      if (!ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [isOpen]);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  }, []);

  return {
    data: {
      targetRef: triggerRef,
      isOpen,
      open,
      close,
    },
    props: {
      ref,
      hidden: !isOpen,
      tabIndex: '-1',
      onKeyDown,
    },
  };
}
