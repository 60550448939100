import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ButtonBase.sass';

const ButtonBase = React.forwardRef(function ButtonBase(
  { className, as: Comp, children, variant, color, loading, ...rest },
  ref,
) {
  return (
    <Comp
      className={cx('dnd-button-base', className, variant, color)}
      {...rest}
      aria-busy={loading}
      ref={ref}>
      {loading && (
        <div
          role="status"
          aria-live="assertive"
          className="dnd-button__spinner-wrapper">
          <span aria-label="loading" className="icon icon-spinner" />
        </div>
      )}
      <div
        className={cx('dnd-button__label')}
        style={{ opacity: loading ? 0 : null }}>
        {children}
      </div>
    </Comp>
  );
});

ButtonBase.propTypes = {
  as: PropTypes.oneOf(['button', 'a']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  type: PropTypes.oneOf(['button', 'submit']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

ButtonBase.defaultProps = {
  type: 'button',
  variant: 'text',
  as: 'button',
  color: 'default',
};

export default ButtonBase;
