import components from './components';
import blocks from './blocks';
import listeners from './listeners';

export default (editor, waymoreIntegration) => {
  const config = {
    wayMoreFormType: 'waymore-form',
    wayMoreProductType: 'waymore-product',
    wayMoreCalendarType: 'waymore-calendar',
    waymoreIntegration,
  };

  components(editor, config);
  blocks(editor, config);
  listeners(editor, config);
};
