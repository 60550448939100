export default (editor) => {
  const cmd = editor.Commands;

  cmd.add('help', {
    run(editor) {
      const onRestartEditorTour = editor.getModel().get('onRestartEditorTour');

      onRestartEditorTour();
    },
  });
};
