import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { assetsReducer } from './reducers/assets';
import { categoriesReducer } from './reducers/categories';
import { customBlocksReducer } from './reducers/blocks';
import { iconsReducer } from './reducers/icons';
import { subscriptionsReducer } from './reducers/subscriptions';
import { templateReducer } from './reducers/template';
import { userReducer } from './reducers/user';
import { waymoreReducer } from './reducers/waymore';

const rootReducer = combineReducers({
  icons: iconsReducer,
  blocks: customBlocksReducer,
  subscriptions: subscriptionsReducer,
  user: userReducer,
  categories: categoriesReducer,
  waymore: waymoreReducer,
  assets: assetsReducer,
  template: templateReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export { store, rootReducer };
