import axios from 'axios';

const httpGetNotes = (id, payload) => {
  return axios.get(`/api/v2/note/template/${id}`, payload);
};

const httpUpdateNotes = (payload) => {
  return axios.post('/api/v2/note', payload);
};

const httpDeleteNote = (noteId) => {
  return axios.delete(`/api/v2/note/${noteId}`);
};

const httpDeleteAllNotes = (templateId) => {
  return axios.delete(`/api/v2/note/template/${templateId}`);
};

export { httpGetNotes, httpUpdateNotes, httpDeleteNote, httpDeleteAllNotes };
