import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { buildPublishedUrl, getHost, isValidDomain } from 'helpers';
import CopyBox from 'components/CopyBox';
import CheckBox from 'components/Forms/CheckBox';
import Switch from 'components/Forms/Switch';
import TextField from 'components/Forms/TextField';
import { useEditorContext } from 'routes/Editor/EditorContext';
import Button from 'components/Button';
import {
  TEMPLATE_STATUS,
  VERIFY_DOMAIN_STATUSES,
  REDIRECT_URL,
} from 'appConstants';
import ConfirmModal from 'components/Modals/ConfirmModal';
import BaseModal from 'components/Modals/BaseModal';
import Label from 'components/Forms/Label';
import FormControl from 'components/Forms/FormControl';
import FormHelperText from 'components/Forms/FormHelperText';
import ReactTooltip from 'react-tooltip';
import DomainInfo from './DomainInfo';
import DomainStatusChip from './DomainStatusChip';

const messages = defineMessages({
  plansTooltip: { id: 'plans-tooltip' },
  setUrlLabel: { id: 'page-settings-modal-domain.page-url-label' },
  setUrlNotPublished: {
    id: 'page-settings-modal-domain.page-url-not-published',
  },
  noIndexLabel: { id: 'page-settings-modal.domain.name-label' },
  noIndexDescription: { id: 'page-settings-modal.domain.name-description' },
  customDomainLabel: { id: 'page-settings-modal.domain.custom-domain-label' },
  customDomainDescription: {
    id: 'page-settings-modal.domain.custom-domain-description',
  },
  customDomainActiveDomainError: {
    id: 'page-settings-modal.domain.custom-domain.active-domain-error',
  },
  customDomainUnpublishedTemplateError: {
    id: 'page-settings-modal.domain.custom-domain.unpublished-template-error',
  },
  domainLabel: { id: 'page-settings-modal.domain.domain-label' },
  domainDescription: { id: 'page-settings-modal.domain.domain-description' },
  buttonPlans: { id: 'button.plans' },
  buttonVerifyAndActivate: { id: 'button.verify-and-activate' },
  buttonActivate: { id: 'button.activate' },
  buttonDeactivate: { id: 'button.deactivate' },
  errorMessage: { id: 'toast.error-message' },
  invalidError: { id: 'page-settings-modal.domain.invalid-error' },
  confirmUnassignDescription: {
    id: 'page-settings-modal-domain.confirm-modal-description',
  },
  verifiedNameLabel: { id: 'page-settings-modal.domain.verified-name-label' },
  verifiedNameDescription: {
    id: 'page-settings-modal.domain.verified-name-description',
  },
  activeNameLabel: { id: 'page-settings-modal.domain.active-name-label' },
  activeNameDescription: {
    id: 'page-settings-modal.domain.active-name-description',
  },
});

function Domain({ subscriptionPlan }) {
  const intl = useIntl();
  const {
    register,
    unregister,
    watch,
    formState: { errors },
    control,
  } = useFormContext();

  const { customDomainEnabled } = subscriptionPlan;

  const {
    template: {
      id,
      status,
      settings: { domain },
    },
    enableCustomDomain,
    isConfirmModalOpen,
    isUpdateDomainLoading,
    isDeleteDomainLoading,
    handleGetDomain,
    handleVerifyDomain: onVerifyDomain,
    handleChangeCustomDomain: onChangeCustomDomain,
    handleToggleConfirmModal,
    handleActivateDomain,
    handleDeactivateDomain,
    handleDeleteDomain,
  } = useEditorContext();

  const { id: domainId, status: domainStatus, name } = domain;

  useEffect(() => {
    handleGetDomain();
    // get domain request should happen only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [useCustomDomainError, setUseCustomDomainError] = useState(null);

  const domainName = watch('settings.domain.name');

  const domainError = Boolean(errors.settings?.domain.name);

  const isTemplateUnpublished = status !== TEMPLATE_STATUS.PUBLISHED;
  const isDomainActive = [
    VERIFY_DOMAIN_STATUSES.ACTIVE,
    VERIFY_DOMAIN_STATUSES.ACTIVATION_IN_PROGRESS,
    VERIFY_DOMAIN_STATUSES.DEACTIVATION_ERROR,
    VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS,
  ].includes(domainStatus);

  useEffect(() => {
    if (!enableCustomDomain) {
      unregister('settings.domain.name', { keepDefaultValue: true });
    }
  }, [enableCustomDomain, unregister]);

  useEffect(() => {
    setUseCustomDomainError(null);
  }, [domainStatus]);

  const publishedUrl = buildPublishedUrl(id, status, domain);

  const handleVerifyDomain = async () => {
    if (domainError) {
      return;
    }

    const shouldSaveAfterVerify = !domainId;

    onVerifyDomain({ domainName, id, shouldSaveAfterVerify });
  };

  const handleChangeCustomDomain = (checked) => {
    if (isTemplateUnpublished) {
      setUseCustomDomainError(
        intl.formatMessage(messages.customDomainUnpublishedTemplateError),
      );
      return;
    }

    if (isDomainActive) {
      setUseCustomDomainError(
        intl.formatMessage(messages.customDomainActiveDomainError),
      );
      return;
    }

    if (domainId) {
      handleToggleConfirmModal();
      return;
    }

    setUseCustomDomainError(null);

    onChangeCustomDomain(checked);
  };

  const renderDomainButton = () => {
    switch (domainStatus) {
      case VERIFY_DOMAIN_STATUSES.ACTIVATION_IN_PROGRESS:
      case VERIFY_DOMAIN_STATUSES.ACTIVATION_ERROR:
      case VERIFY_DOMAIN_STATUSES.VERIFIED: {
        const isDisabled =
          domainStatus === VERIFY_DOMAIN_STATUSES.ACTIVATION_IN_PROGRESS ||
          status !== TEMPLATE_STATUS.PUBLISHED ||
          isUpdateDomainLoading;

        return (
          <Button
            className="page-settings-modal__domain-button"
            variant="contained"
            disabled={isDisabled}
            loading={isUpdateDomainLoading}
            onClick={handleActivateDomain}>
            {intl.formatMessage(messages.buttonActivate)}
          </Button>
        );
      }
      case VERIFY_DOMAIN_STATUSES.ACTIVE:
      case VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS:
      case VERIFY_DOMAIN_STATUSES.DEACTIVATION_ERROR: {
        const isDisabled =
          domainStatus === VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS ||
          isUpdateDomainLoading;

        return (
          <Button
            className="page-settings-modal__domain-button"
            variant="contained"
            disabled={isDisabled}
            loading={isUpdateDomainLoading}
            onClick={handleDeactivateDomain}>
            {intl.formatMessage(messages.buttonDeactivate)}
          </Button>
        );
      }
      default: {
        const disabled =
          domainStatus === VERIFY_DOMAIN_STATUSES.VERIFICATION_IN_PROGRESS ||
          isUpdateDomainLoading;

        const labelMessage =
          domainStatus === VERIFY_DOMAIN_STATUSES.PENDING_VERIFICATION
            ? messages.buttonActivate
            : messages.buttonVerifyAndActivate;

        return (
          <Button
            className="page-settings-modal__domain-button"
            variant="contained"
            disabled={disabled}
            loading={isUpdateDomainLoading}
            onClick={handleVerifyDomain}>
            {intl.formatMessage(labelMessage)}
          </Button>
        );
      }
    }
  };

  return (
    <>
      <div className="page-settings-modal__domain">
        <div className={cx('page-settings-modal__row', 'align-bottom')}>
          <div className="page-settings-modal__domain-published-url">
            {intl.formatMessage(messages.setUrlLabel)}
            <CopyBox value={publishedUrl}>
              <div className="page-settings-modal__domain-link-wrapper">
                {publishedUrl ? (
                  <>
                    <span className="icon icon-link" />
                    {publishedUrl}
                  </>
                ) : (
                  <>
                    <span className="icon icon-warning" />
                    {intl.formatMessage(messages.setUrlNotPublished)}
                  </>
                )}
              </div>
            </CopyBox>
          </div>
          <Controller
            control={control}
            name="settings.noIndex"
            render={({ field: { onChange, value } }) => (
              <CheckBox
                label={intl.formatMessage(messages.noIndexLabel)}
                helperText={intl.formatMessage(messages.noIndexDescription)}
                fullWidth
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        </div>
        <div
          className={cx('page-settings-modal__row', {
            'page-settings-modal__lock-status': !customDomainEnabled,
          })}>
          <Switch
            label={intl.formatMessage(messages.customDomainLabel)}
            fullWidth
            disabled={
              !customDomainEnabled ||
              isTemplateUnpublished ||
              isDomainActive ||
              Boolean(domainId)
            }
            error={Boolean(useCustomDomainError)}
            checked={enableCustomDomain}
            onChange={handleChangeCustomDomain}
            helperText={
              useCustomDomainError ||
              intl.formatMessage(messages.customDomainDescription)
            }
          />
          {!customDomainEnabled && (
            <div className="page-settings-modal__lock-status__icon">
              <span className="icon icon-lock" data-tip data-for="global" />
              <ReactTooltip
                id="global"
                type="light"
                aria-haspopup="true"
                place="right"
                clickable={true}
                delayHide={300}
                border={true}
                borderColor="#bab6c8"
                effect="solid">
                <span className="icon icon-lock" data-tip data-for="global" />
                <p>
                  <FormattedMessage
                    {...messages.plansTooltip}
                    values={{
                      p: (...chunks) => <p>{chunks}</p>,
                      br: <br />,
                    }}
                  />
                </p>
                <button
                  onClick={() =>
                    window.open(`${REDIRECT_URL}/settings/subscriptions`)
                  }>
                  {intl.formatMessage(messages.buttonPlans)}
                </button>
              </ReactTooltip>
            </div>
          )}
        </div>
        {enableCustomDomain && (
          <>
            <div className="page-settings-modal__row">
              {domainId ? (
                <>
                  <FormControl fullWidth>
                    <Label component="div">
                      {domainStatus === VERIFY_DOMAIN_STATUSES.ACTIVE
                        ? intl.formatMessage(messages.activeNameLabel)
                        : intl.formatMessage(messages.verifiedNameLabel)}
                    </Label>
                    <div className="page-settings-modal__domain-name-wrapper">
                      <div className="page-settings-modal__domain-name">
                        {name}
                      </div>
                      <DomainStatusChip status={domainStatus} />
                      {renderDomainButton()}
                    </div>
                    <FormHelperText>
                      {domainStatus === VERIFY_DOMAIN_STATUSES.ACTIVE
                        ? intl.formatMessage(messages.activeNameDescription)
                        : intl.formatMessage(messages.verifiedNameDescription)}
                    </FormHelperText>
                  </FormControl>
                </>
              ) : (
                <div className="page-settings-modal__domain-input-wrapper">
                  <TextField
                    {...register('settings.domain.name', {
                      validate: isValidDomain,
                    })}
                    label={intl.formatMessage(messages.domainLabel)}
                    placeholder="your.domain.com"
                    error={domainError}
                    helperText={
                      domainError
                        ? intl.formatMessage(messages.invalidError)
                        : intl.formatMessage(messages.domainDescription)
                    }
                    fullWidth
                  />
                  {renderDomainButton()}
                </div>
              )}
            </div>
            <div className="page-settings-modal__row">
              <DomainInfo host={getHost(domainName || name)} />
            </div>
          </>
        )}
      </div>
      <BaseModal
        isOpen={isConfirmModalOpen}
        headerTitle="page-settings-modal-domain.confirm-modal-header"
        maxWidth="600px"
        onClose={handleToggleConfirmModal}>
        <ConfirmModal
          loading={isDeleteDomainLoading}
          onClose={handleToggleConfirmModal}
          onConfirm={handleDeleteDomain}>
          {intl.formatMessage(messages.confirmUnassignDescription)}
        </ConfirmModal>
      </BaseModal>
    </>
  );
}

Domain.propTypes = {
  subscriptionPlan: PropTypes.object.isRequired,
};

export default Domain;
