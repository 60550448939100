import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAdminSelector,
  templatesNumbersSelector,
  subscriptionPlanSelector,
  principalIdSelector,
} from 'store/selectors';
import { updateSharedTemplateLockStatus } from 'store/actions';
import TemplateInfoModal from 'components/Modals/TemplateInfoModal';
import TemplateQRModal from 'components/Modals/TemplateQRModal';
import Logo from 'components/Logo';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import UserMenu from 'components/UserMenu';
import ReactTooltip from 'react-tooltip';
import {
  buildPublishedUrl,
  buildSharedTemplateViewUrl,
  showSuccessMessage,
  showWarningMessage,
} from 'helpers';
import { useQuery, useToggleTypes, useModal } from 'hooks';
import { useHistory } from 'react-router-dom';
import { TEMPLATE_STATUS, VERIFY_DOMAIN_STATUSES, VERSION } from 'appConstants';
import BaseModal from 'components/Modals/BaseModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import UpgradeModal from 'components/Modals/UpgradeModal';
import cx from 'classnames';
import './EditorHeader.sass';

function EditorHeader({
  template,
  isConvertLoading,
  isUpdateLoading,
  isStatusLoading,
  show,
  onShow,
  haveChanges,
  onConvertToSystem,
  onChangeStatus,
  onSaveTemplate,
  onChangeInfo,
  onTogglePreview,
}) {
  const { system } = useQuery();

  const dispatch = useDispatch();

  const publishedUrl = buildPublishedUrl(
    template.id,
    template.status,
    template.settings.domain,
  );

  const {
    isOpen: isOpenUpgradeModal,
    open: openUpgradeModal,
    close: closeUpgradeModal,
  } = useModal();

  const history = useHistory();

  const templateInfoBtnRef = useRef(null);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const isAdmin = useSelector(isAdminSelector);
  const templatesNumbers = useSelector(templatesNumbersSelector);
  const subscriptionPlan = useSelector(subscriptionPlanSelector);
  const currentUser = useSelector(principalIdSelector);

  const templateIsSharedWithCurrentUser =
    template.isShared &&
    template.userId !== currentUser &&
    template.userId !== template.collaboratorId &&
    !template.collaboratorsList.includes(template.userId);

  const templateIsSharedOwner =
    template.isShared && template.userId === currentUser;

  const intl = useIntl();

  const handleCloseInfo = () => {
    onShow(useToggleTypes.showInfoModal);
  };

  const handleCloseQR = () => {
    onShow(useToggleTypes.showQRModal);
  };

  const handlePublishedUrlBtnClick = async () => {
    try {
      await navigator.clipboard.writeText(publishedUrl);
      showSuccessMessage(
        intl.formatMessage({ id: 'toast.copy.success-message' }),
      );
    } catch (error) {
      // in case browser doesn't support clipboard api just open url
      // in new tab
      window.open(publishedUrl, '_blank');
    }
  };

  const handleChangeStatus = () => {
    if (
      template.status === TEMPLATE_STATUS.PUBLISHED &&
      [
        VERIFY_DOMAIN_STATUSES.ACTIVATION_IN_PROGRESS,
        VERIFY_DOMAIN_STATUSES.ACTIVE,
      ].includes(template.settings.domain.status)
    ) {
      handleToggleConfirmModal();
      return;
    }

    onChangeStatus();
  };

  const handleToggleConfirmModal = () => {
    setIsConfirmModalOpen((prevIsConfirmModalOpen) => !prevIsConfirmModalOpen);
  };

  const handleForceChangeStatus = () => {
    onChangeStatus({ showDomainWarning: true });

    handleToggleConfirmModal();
  };

  const loadingContent = () => {
    return (
      <>
        <FormattedMessage id="button.loading" />
        <span className="icon icon-spinner" />
      </>
    );
  };

  const renderUpdateButton = (templateIsSharedWithCurrentUser) => {
    return (
      <>
        <Button
          variant="contained"
          color={system ? 'secondary' : 'default'}
          className="editor-header__update-btn"
          disabled={isUpdateLoading}
          onClick={onSaveTemplate}>
          {isUpdateLoading ? (
            loadingContent()
          ) : (
            <>
              <FormattedMessage
                id={`${
                  templateIsSharedWithCurrentUser
                    ? 'button.save-changes'
                    : 'button.save'
                }`}
              />
              <span className="icon icon-save" />
            </>
          )}
        </Button>

        {template.status === TEMPLATE_STATUS.PUBLISHED && !system && (
          <Button
            variant="contained"
            className="editor-header__update-btn"
            disabled={isStatusLoading || haveChanges}
            onClick={handleChangeStatus}>
            {isStatusLoading ? (
              loadingContent()
            ) : (
              <>
                <FormattedMessage id="button.unpublish" />
                <span className="icon icon-unpublish" />
              </>
            )}
          </Button>
        )}

        {template.status === TEMPLATE_STATUS.DELETED && (
          <Button
            variant="contained"
            className="editor-header__update-btn"
            disabled={isStatusLoading}
            onClick={handleChangeStatus}>
            {isStatusLoading ? (
              loadingContent()
            ) : (
              <>
                <FormattedMessage id="button.restore" />
                <span className="icon icon-restore-from-trash" />
              </>
            )}
          </Button>
        )}

        {templateIsSharedWithCurrentUser &&
          template.status === TEMPLATE_STATUS.DRAFT &&
          !system && (
            <>
              <Button
                variant="contained"
                className="editor-header__view-btn"
                onClick={() =>
                  window.open(buildSharedTemplateViewUrl(template.id))
                }>
                <>
                  <span className="icon icon-visibility" />
                  <FormattedMessage id="button.view" />
                </>
              </Button>
              {template.isShared && template.userId !== currentUser && (
                <Button
                  variant="contained"
                  className="editor-header__unlock-btn"
                  onClick={() => {
                    dispatch(
                      updateSharedTemplateLockStatus({
                        id: template.id,
                        lockStatus: false,
                      }),
                    );

                    history.push({
                      pathname: '/',
                      search: String(
                        new URLSearchParams({
                          status: 'shared',
                        }),
                      ),
                    });
                  }}>
                  <>
                    <span className="icon icon-lock-open" />
                    <FormattedMessage id="button.unlock" />
                  </>
                </Button>
              )}
            </>
          )}

        {!template.isShared &&
          template.status === TEMPLATE_STATUS.DRAFT &&
          !system && (
            <Button
              variant="contained"
              className="editor-header__update-btn"
              disabled={isStatusLoading || haveChanges}
              onClick={
                subscriptionPlan?.publicPagesNumber !== -1 &&
                templatesNumbers?.published >=
                  subscriptionPlan?.publicPagesNumber
                  ? () => openUpgradeModal()
                  : handleChangeStatus
              }>
              {isStatusLoading ? (
                loadingContent()
              ) : (
                <>
                  <FormattedMessage id="button.publish" />
                  <span className="icon icon-publish" />
                </>
              )}
            </Button>
          )}
      </>
    );
  };

  return (
    <>
      <header
        className={cx('editor-header', {
          'template-shared-owner': templateIsSharedOwner,
          'template-shared': templateIsSharedWithCurrentUser,
        })}>
        <div className="editor-header__column editor-header__app-actions">
          <Logo />
          <Breadcrumb
            isTemplateOwner={templateIsSharedOwner}
            isEdit
            templateStatus={template.status}
            templateIsShared={template.isShared}
          />
        </div>
        <div className="editor-header__column editor-header__template-actions">
          {!templateIsSharedWithCurrentUser && (
            <div className="editor-header__panel-template-options">
              <span
                aria-label="show qr code"
                className="qr-pn-btn icon icon-qr-code-2"
                data-tooltip="Show QR"
                data-tooltip-pos="bottom"
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    template.status === TEMPLATE_STATUS.PUBLISHED
                      ? onShow(useToggleTypes.showQRModal)
                      : showWarningMessage(
                          'To show QR code you should publish template',
                        );
                  }
                }}
                onClick={() =>
                  template.status === TEMPLATE_STATUS.PUBLISHED
                    ? onShow(useToggleTypes.showQRModal)
                    : showWarningMessage(
                        'To show QR code you should publish template',
                      )
                }
              />
            </div>
          )}
          <div className="editor-header__template-info">
            {templateIsSharedWithCurrentUser ? (
              <div className="editor-header__template-info__shared-template-title">
                {template.name}
              </div>
            ) : (
              <>
                <Button
                  aria-label="view published link"
                  className="editor-header__simple-btn"
                  data-tooltip="View page"
                  data-tooltip-pos="bottom"
                  disabled={template.status !== TEMPLATE_STATUS.PUBLISHED}
                  onClick={() => window.open(publishedUrl)}>
                  <span className="icon icon-visibility" />
                </Button>
                <Button
                  aria-label="copy published link"
                  className="editor-header__simple-btn"
                  data-tooltip={`Published page URL: ${publishedUrl} (click to copy)`}
                  data-tooltip-pos="bottom"
                  disabled={template.status !== TEMPLATE_STATUS.PUBLISHED}
                  onClick={handlePublishedUrlBtnClick}>
                  <span className="icon icon-link" />
                </Button>
                <Button
                  tabIndex={0}
                  data-testid={template.name}
                  ref={templateInfoBtnRef}
                  className="editor-header__simple-btn editor-header__template-name"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onShow(useToggleTypes.showInfoModal);
                    }
                  }}
                  onClick={() => onShow(useToggleTypes.showInfoModal)}>
                  <span
                    data-tip
                    data-for="template-info-name-tooltip"
                    className="editor-header__template-name__name">
                    {template.name}
                  </span>
                  <ReactTooltip
                    id="template-info-name-tooltip"
                    class="title-tooltip"
                    type="dark"
                    aria-haspopup="true"
                    place="bottom"
                    clickable={false}
                    delayShow={500}
                    border={false}
                    effect="solid">
                    {template.name}
                  </ReactTooltip>
                  <span className="icon icon-expand-more" />
                </Button>
              </>
            )}
          </div>
          {!templateIsSharedWithCurrentUser && (
            <div className="editor-header__ver">v{VERSION}</div>
          )}
        </div>
        <div className="editor-header__column editor-header__user-actions">
          {isAdmin &&
            template.status === TEMPLATE_STATUS.PUBLISHED &&
            !template.isShared &&
            !system && (
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  isConvertLoading || isUpdateLoading || isStatusLoading
                }
                loading={isConvertLoading}
                onClick={onConvertToSystem}
                className="editor-header__convert-btn">
                <FormattedMessage id="button.convert-to-system" />
              </Button>
            )}
          {!templateIsSharedWithCurrentUser && (
            <Button
              className="editor-header__preview-btn"
              variant="contained"
              color="default"
              onClick={onTogglePreview}>
              <FormattedMessage id="button.preview" />
              <span className="icon icon-visibility" />
            </Button>
          )}
          {renderUpdateButton(templateIsSharedWithCurrentUser)}
          <UserMenu hiddenSwitchUsers />
        </div>
      </header>
      <BaseModal
        isOpen={isConfirmModalOpen}
        maxWidth="600px"
        headerTitle="confirm-unpublish-modal.title"
        onClose={handleToggleConfirmModal}>
        <ConfirmModal
          onClose={handleToggleConfirmModal}
          loading={isUpdateLoading}
          onConfirm={handleForceChangeStatus}>
          <FormattedMessage
            id="confirm-unpublish-modal.description"
            values={{ name: template.settings.domain.name }}
          />
        </ConfirmModal>
      </BaseModal>
      <BaseModal
        isOpen={show.showInfoModal}
        onClose={handleCloseInfo}
        iconTitleClass="icon icon-settings"
        style={{
          width: 500,
        }}
        headerTitle="template-info-modal.header-title"
        contentClass="template-info-modal">
        <TemplateInfoModal
          name={template.name}
          description={template.description}
          tags={template.tags}
          categories={template.categories}
          onClose={handleCloseInfo}
          onChange={onChangeInfo}
        />
      </BaseModal>
      <BaseModal
        isOpen={show.showQRModal}
        onClose={handleCloseQR}
        iconTitleClass="icon icon-qr-code-2"
        style={{
          width: 500,
        }}
        headerTitle="template-qr-code.header-title"
        contentClass="template-qr-code">
        <TemplateQRModal publishedUrl={publishedUrl} onClose={handleCloseQR} />
      </BaseModal>
      <BaseModal
        isOpen={isOpenUpgradeModal}
        onClose={closeUpgradeModal}
        maxWidth="350px"
        headerTitle="upgrade-modal.title">
        <UpgradeModal onClose={closeUpgradeModal} />
      </BaseModal>
    </>
  );
}

EditorHeader.propTypes = {
  template: PropTypes.object.isRequired,
  isConvertLoading: PropTypes.bool.isRequired,
  isUpdateLoading: PropTypes.bool.isRequired,
  isStatusLoading: PropTypes.bool.isRequired,
  show: PropTypes.object.isRequired,
  onShow: PropTypes.func.isRequired,
  haveChanges: PropTypes.bool.isRequired,
  onConvertToSystem: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onSaveTemplate: PropTypes.func.isRequired,
  onChangeInfo: PropTypes.func.isRequired,
  onTogglePreview: PropTypes.func.isRequired,
};

export default EditorHeader;
