import { sectionStyles } from '../../common';

export default function (
  editor,
  { sectionType, containerType, rowType, columnType, category },
) {
  const bm = editor.BlockManager;

  bm.add('column1', {
    label: '1 Column',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b1' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 100%" class="col" width="100" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add('column2', {
    label: '2 Columns',
    attributes: { class: 'gjs-fonts gjs-f-b2' },
    category,
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 50%;" class="col" width="50" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 50%;" class="col" width="50" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add('column3', {
    label: '3 Columns',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b3' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 33.33%;" class="col" width="33.33" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 33.33%;" class="col" width="33.33" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 33.33%;" class="col" width="33.33" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add('column4', {
    label: '4 Columns',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b3333' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 25%;" class="col" width="25" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 25%;" class="col" width="25" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 25%;" class="col" width="25" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 25%;" class="col" width="25" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add('column3-7', {
    label: '2 Columns (3/7)',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b37' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 30%;" class="col" width="30" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 70%;" class="col" width="70" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add('column8-4', {
    label: '2 Columns (8/4)',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b84' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
              <div data-type="${columnType}" style="flex-basis: 66.7%;" class="col" width="66.7" overflow="visible"></div>
              <div data-type="${columnType}" style="flex-basis: 33.3%;" class="col" width="33.3" overflow="visible"></div>
            </div>
          </div>
        </div>
        <style>
          ${sectionStyles}
        </style>
      `,
  });

  bm.add('column4-8', {
    label: '2 Columns (4/8)',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b48' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 33.3%;" class="col" width="33.3" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 66.7%;" class="col" width="66.7" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
      `,
  });

  bm.add('column9-3', {
    label: '2 Columns (9/3)',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b93' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 75%;" class="col" width="75" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 25%;" class="col" width="25" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
      `,
  });

  bm.add('column3-9', {
    label: '2 Columns (3/9)',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b39' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 25%;" class="col" width="25" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 75%;" class="col" width="75" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
      `,
  });
}
