import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useInView } from 'react-intersection-observer';

import LoadingSpinner from 'components/LoadingSpinner';
import './ImagesContent.sass';

function ImagesContent({
  showEmptyContent,
  showNotFound,
  showBigSpinner,
  showLoadMore,
  showLoadMoreSpinner,
  children,
  onLoadNextPage,
}) {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      onLoadNextPage();
    }
  }, [onLoadNextPage, inView]);

  if (showBigSpinner) {
    return (
      <div className="dnd-images-content__wrapper">
        <LoadingSpinner />
      </div>
    );
  }

  if (showEmptyContent) {
    return (
      <div className="dnd-images-content__wrapper">
        <span
          role="img"
          aria-label="no images"
          className="dnd-images-content__icon icon icon-photo-library"
        />
        <FormattedMessage id="message.type-to-search" />
      </div>
    );
  }

  if (showNotFound) {
    return (
      <div className="dnd-images-content__wrapper">
        <span
          role="img"
          aria-label="no images"
          className="dnd-images-content__icon icon icon-photo-library"
        />
        <FormattedMessage id="message.no-images" />
      </div>
    );
  }

  return (
    <>
      <div className="dnd-images-content__image-items">{children}</div>
      {showLoadMore && (
        <div ref={ref} className="dnd-images-content__load-more">
          <FormattedMessage id="message.load-more" />
        </div>
      )}
      {showLoadMoreSpinner && (
        <div className="dnd-images-content__load-more">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

ImagesContent.propTypes = {
  showEmptyContent: PropTypes.bool,
  showNotFound: PropTypes.bool,
  showBigSpinner: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  showLoadMoreSpinner: PropTypes.bool,
  children: PropTypes.node,
  onLoadNextPage: PropTypes.func,
};

export default ImagesContent;
