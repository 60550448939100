import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';

import Button from '../../Button';
import './ConfirmModal.sass';

const messages = defineMessages({
  cancelButton: {
    id: 'button.cancel',
    defaultMessage: 'Cancel',
  },
  confirmButton: {
    id: 'button.confirm',
    defaultMessage: 'Confirm',
  },
});

function ConfirmModal({
  children,
  intl,
  loading,
  buttonsColor,
  onClose,
  onConfirm,
}) {
  return (
    <div className="confirm-modal">
      <div className="confirm-modal__content">{children}</div>
      <div className="confirm-modal__buttons">
        <Button
          type="button"
          className="confirm-modal__button"
          color={buttonsColor}
          onClick={onClose}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
        <Button
          type="button"
          className="confirm-modal__button"
          variant="contained"
          color={buttonsColor}
          disabled={loading}
          loading={loading}
          onClick={onConfirm}>
          {intl.formatMessage(messages.confirmButton)}
        </Button>
      </div>
    </div>
  );
}

ConfirmModal.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  buttonsColor: PropTypes.oneOf(['default', 'primary', 'secondary']),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default injectIntl(ConfirmModal);
