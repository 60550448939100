import { createSlice } from '@reduxjs/toolkit';
import { getIconsCategories, getIconsStyles } from 'store/actions';

const initialState = {
  categories: [],
  styles: [],
};

const { reducer: iconsReducer } = createSlice({
  name: 'icons',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getIconsCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories;
      })
      .addCase(getIconsStyles.fulfilled, (state, action) => {
        state.styles = action.payload.styles;
      });
  },
});

export { iconsReducer };
