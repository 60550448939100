import { useToggleTypes } from 'hooks';

export default (editor) => {
  const cmd = editor.Commands;

  cmd.add('version-history', {
    run(editor) {
      const model = editor.getModel();

      const onShow = model.get('onShow');

      onShow(useToggleTypes.showVersionHistory);
    },
  });
};
