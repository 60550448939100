import axios from 'axios';
import { setRuleStyle, showErrorMessage } from 'helpers';

export const getFormHeaderSelector = () => '.form-header';
export const getFormSubHeaderSelector = () => '.form-subheader';
export const getFormGroupSelector = () => '.form-group';
export const getFormLabelSelector = () => '.label';
export const getFormInputSelector = () => '.input';
export const getFormInputPlaceholderSelector = () => '.input::placeholder';
export const getFormTextareaSelector = () => '.textarea';
export const getFormButtonSelector = () => '.button';
export const getFormDropdownSelector = () => '.form-dropdown';
export const getFromContentSelector = () => '.form-content';
export const getFormRadioSelector = () => '.radio';
export const getFormSuccessStateSelector = () => '.state-success';
export const getFormErrorStateSelector = () => '.state-error';
export const getEmailInputSelector = () => '.email-input';
export const getPhoneInputSelector = () => '.phone-input';

export const applyDefaultStyle = (
  editor,
  model,
  {
    showlabels,
    maxwidth,
    rowsgap,
    formbgcolor,
    labelcolor,
    inputbgcolor,
    inputbordercolor,
    buttonbgcolor,
    labelmargin,
  },
) => {
  const id = model.getId();

  model.addStyle({
    'background-color': formbgcolor,
    margin: '0 auto',
    'max-width': `${maxwidth}px`,
    display: 'flex',
    'flex-direction': 'column',
    'border-radius': '0',
  });

  setRuleStyle(editor, getFormSuccessStateSelector(id), {
    color: '#000',
  });

  setRuleStyle(editor, getFormHeaderSelector(id), {
    color: '#f96',
    'font-weight': 400,
    'font-size': '30px',
  });

  setRuleStyle(editor, getFormSubHeaderSelector(id), {
    margin: '5px 0 15px',
    'font-size': '13px',
  });

  setRuleStyle(editor, getFormGroupSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });

  setRuleStyle(editor, getFormLabelSelector(id), {
    'margin-bottom': `${labelmargin}px`,
    color: `${labelcolor}`,
    display: showlabels ? 'block' : 'none',
  });

  setRuleStyle(editor, getFormInputSelector(id), {
    width: '100%',
    border: `1px solid ${inputbordercolor}`,
    'background-color': inputbgcolor,
    'margin-bottom': '5px',
    padding: '10px',
    'border-radius': '0',
    color: '#000',
    transition: 'border-color 0.3s ease-in-out',
  });

  setRuleStyle(editor, getFormTextareaSelector(id), {
    resize: 'none',
  });

  setRuleStyle(editor, `${getFormInputSelector(id)}:hover`, {
    'border-color': '#aaa',
  });

  setRuleStyle(editor, getFormButtonSelector(id), {
    cursor: 'pointer',
    width: '100%',
    border: 'none',
    'background-color': buttonbgcolor,
    color: '#fff',
    margin: '0 0 5px',
    padding: '10px',
    'font-size': '15px',
    'border-radius': '0',
    transition: 'background-color 0.3s ease-in-out',
  });

  setRuleStyle(editor, `${getFormButtonSelector(id)}:hover`, {
    'background-color': '#09c',
  });

  setRuleStyle(editor, `${getFormButtonSelector(id)}:disabled`, {
    'background-color': '#cccccc',
    color: '#666666',
  });

  setRuleStyle(editor, getFormDropdownSelector(id), {
    width: '100%',
    border: `1px solid ${inputbordercolor}`,
    'background-color': inputbgcolor,
    padding: '10px',
  });

  setRuleStyle(editor, getEmailInputSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });

  setRuleStyle(editor, getPhoneInputSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });
};

export const applyMinimalStyle = (
  editor,
  model,
  {
    showlabels,
    maxwidth,
    rowsgap,
    formbgcolor,
    labelcolor,
    inputbgcolor,
    inputbordercolor,
    buttonbgcolor,
    labelmargin,
  },
) => {
  const id = model.getId();

  model.addStyle({
    'background-color': formbgcolor,
    padding: '25px',
    margin: '0 auto',
    'max-width': `${maxwidth}px`,
    'border-radius': '0',
  });

  setRuleStyle(editor, getFormSuccessStateSelector(id), {
    color: '#000',
  });

  setRuleStyle(editor, getFormHeaderSelector(id), {
    color: '#000',
    'font-weight': 600,
    'font-size': '30px',
  });

  setRuleStyle(editor, getFormSubHeaderSelector(id), {
    color: '#707070',
    'font-weight': 300,
    'font-size': '13px',
    margin: '5px 0 15px',
  });

  setRuleStyle(editor, getFormGroupSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });

  setRuleStyle(editor, getFormLabelSelector(id), {
    'margin-bottom': `${labelmargin}px`,
    display: showlabels ? 'block' : 'none',
    color: `${labelcolor}`,
  });

  setRuleStyle(editor, getFormInputSelector(id), {
    width: '100%',
    border: `1px solid ${inputbordercolor}`,
    'background-color': inputbgcolor,
    'margin-bottom': '5px',
    padding: '10px',
    'border-radius': '0',
    color: '#000',
    transition: 'border-color 0.3s ease-in-out',
  });

  setRuleStyle(editor, getFormTextareaSelector(id), {
    resize: 'none',
  });

  setRuleStyle(editor, `${getFormInputSelector(id)}:hover`, {
    'border-color': '#aaa',
  });

  setRuleStyle(editor, `${getFormInputSelector(id)}:focus`, {
    outline: 0,
    'border-color': '#999',
  });

  setRuleStyle(editor, getFormButtonSelector(id), {
    cursor: 'pointer',
    width: '100%',
    border: 'none',
    'background-color': buttonbgcolor,
    color: '#fff',
    margin: '0 0 5px',
    padding: '10px',
    'font-size': '15px',
    'border-radius': '0',
    transition: 'background-color 0.3s ease-in-out',
  });

  setRuleStyle(editor, `${getFormButtonSelector(id)}:hover`, {
    'background-color': '#ED2E2E',
  });

  setRuleStyle(editor, getFormDropdownSelector(id), {
    width: '100%',
    border: `1px solid ${inputbordercolor}`,
    'background-color': inputbgcolor,
    padding: '20px',
  });

  setRuleStyle(editor, getEmailInputSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });

  setRuleStyle(editor, getPhoneInputSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });
};

export const applyDarkStyle = (
  editor,
  model,
  {
    showlabels,
    maxwidth,
    rowsgap,
    formbgcolor,
    labelcolor,
    inputbgcolor,
    inputbordercolor,
    buttonbgcolor,
    labelmargin,
  },
) => {
  const id = model.getId();

  model.addStyle({
    'background-color': formbgcolor,
    padding: '25px',
    margin: '0 auto',
    'max-width': `${maxwidth}px`,
    'border-radius': '25px',
  });

  setRuleStyle(editor, getFormSuccessStateSelector(id), {
    color: '#fff',
  });

  setRuleStyle(editor, getFormHeaderSelector(id), {
    color: '#7E8CE0',
    'font-weight': 600,
    'font-size': '30px',
  });

  setRuleStyle(editor, getFormSubHeaderSelector(id), {
    color: '#36C7D0',
    'font-weight': 300,
    'font-size': '13px',
    margin: '5px 0 15px',
  });

  setRuleStyle(editor, getFormGroupSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });

  setRuleStyle(editor, getFormLabelSelector(id), {
    'margin-bottom': `${labelmargin}px`,
    display: showlabels ? 'block' : 'none',
    color: `${labelcolor}`,
  });

  setRuleStyle(editor, getFormInputSelector(id), {
    width: '100%',
    border: `1px solid ${inputbordercolor}`,
    'background-color': inputbgcolor,
    'margin-bottom': '5px',
    padding: '10px',
    'border-radius': '5px',
    transition: 'border-color 0.3s ease-in-out',
    color: '#fff',
  });

  setRuleStyle(editor, getFormTextareaSelector(id), {
    resize: 'none',
  });

  setRuleStyle(editor, `${getFormInputSelector(id)}:hover`, {
    'border-color': '#FFA48E',
  });

  setRuleStyle(editor, `${getFormInputSelector(id)}:focus`, {
    outline: 0,
    'border-color': '#FFA48E',
  });

  setRuleStyle(editor, getFormButtonSelector(id), {
    cursor: 'pointer',
    width: '100%',
    border: 'none',
    'background-color': buttonbgcolor,
    color: '#fff',
    margin: '0 0 5px',
    padding: '10px',
    'font-size': '15px',
    transition: 'background-color 0.3s ease-in-out',
    'border-radius': '5px',
  });

  setRuleStyle(editor, `${getFormButtonSelector(id)}:hover`, {
    'background-color': '#FFA48E',
  });

  setRuleStyle(editor, getFormDropdownSelector(id), {
    width: '100%',
    'border-radius': '5px',
    border: `1px solid ${inputbordercolor}`,
    'background-color': inputbgcolor,
    color: '#888',
    padding: '20px',
  });

  setRuleStyle(editor, getFormRadioSelector(id), {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  });

  setRuleStyle(editor, getEmailInputSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });

  setRuleStyle(editor, getPhoneInputSelector(id), {
    'margin-bottom': `${rowsgap}px`,
  });
};

export const customFieldsModal = async (editor, customFieldType) => {
  const cfModal = document.createElement('div');
  cfModal.className = 'cf-modal__form';

  const closeCustomFieldsModal = () => {
    const selectedItem = editor.getSelected();
    const selectedItemClosest = selectedItem.closest(
      `[data-type="${
        customFieldType === 'string' ? 'text' : 'number'
      }-custom-field"]`,
    );
    const fieldName = selectedItemClosest
      ? selectedItemClosest
          .find('input[data-field-type="custom-field"]')[0]
          .getEl().name
      : '';

    if (!fieldName || fieldName === '') {
      selectedItem.remove();
    }
  };

  const actionButtons = document.createElement('div');
  actionButtons.className = 'cf-modal__buttons';

  const saveButton = document.createElement('button');
  saveButton.type = 'button';
  saveButton.disabled = true;
  saveButton.className = 'dnd-button-base dnd-button contained default';
  saveButton.innerHTML = '<div class="dnd-button__label">Save</div>';
  saveButton.onclick = () => {
    const cfValue = document.querySelector('.cf-modal__cf-list').value;
    const selectedItem = editor.getSelected();

    if (selectedItem.find('input').length > 0) {
      selectedItem.find('input')[0].set('attributes', {
        ...selectedItem.find('input')[0].getAttributes(),
        name: cfValue,
        'data-field-type': 'custom-field',
      });
    }

    if (selectedItem.find('label').length > 0) {
      selectedItem.find('label')[0].components(cfValue || 'Label');
    }

    if (
      selectedItem.get('type') ===
      `${customFieldType === 'string' ? 'text' : 'number'}-custom-field-input`
    ) {
      selectedItem.set('attributes', {
        ...selectedItem.getAttributes(),
        name: cfValue,
        'data-field-type': 'custom-field',
      });

      if (selectedItem.parent().find('label').length > 0) {
        selectedItem
          .parent()
          .find('label')[0]
          .components(cfValue || 'Label');
      }
    }

    selectedItem.view.render();
    editor.Modal.close();
  };

  const cancelButton = document.createElement('button');
  cancelButton.type = 'button';
  cancelButton.className = 'dnd-button-base dnd-button text default';
  cancelButton.innerHTML = '<div class="dnd-button__label">Cancel</div>';
  cancelButton.onclick = () => editor.Modal.close();

  const selectListWrapper = document.createElement('div');
  const selectList = document.createElement('select');
  const noneOption = document.createElement('option');
  const selectListLable = document.createElement('label');
  selectListWrapper.className = 'cf-modal__select-wrapper';
  selectList.className = 'cf-modal__cf-list';
  selectList.id = 'cf-modal__cf-list';
  selectList.disabled = true;
  selectListLable.className = 'cf-modal__cf-list';
  selectListLable.className = 'dnd-label';
  selectListLable.setAttribute('for', 'cf-modal__cf-list');
  selectListLable.innerHTML = 'Select a custom field';
  noneOption.value = '';
  noneOption.text = 'Loading...';
  selectList.onchange = (e) => {
    if (e.target.value) {
      saveButton.disabled = false;
    } else {
      saveButton.disabled = true;
    }
  };

  selectList.appendChild(noneOption);
  cfModal.appendChild(selectListLable);
  selectListWrapper.appendChild(selectList);
  cfModal.appendChild(selectListWrapper);
  actionButtons.appendChild(cancelButton);
  actionButtons.appendChild(saveButton);
  cfModal.appendChild(actionButtons);

  editor.Modal.setTitle('Custom fields list')
    .setContent(cfModal)
    .open({
      attributes: { class: 'cf-modal' },
    });

  editor.Modal.onceClose(() => {
    closeCustomFieldsModal();
  });

  const modalSelectList = document.getElementById('cf-modal__cf-list');

  await axios
    .get('/api/v2/contacts/customfields')
    .then((res) => {
      let usedItems = [];
      const selectedItem = editor?.getSelected();
      const selectedItemClosest = selectedItem.closest(
        `[data-type="${
          customFieldType === 'string' ? 'text' : 'number'
        }-custom-field"]`,
      );
      const fieldName = selectedItemClosest
        ? selectedItemClosest
            .find('input[data-field-type="custom-field"]')[0]
            .getEl().name
        : '';
      const formClosest = selectedItem.closest('form');

      // Finding used text/number custom fields
      if (formClosest) {
        formClosest
          .findType(
            `${
              customFieldType === 'string' ? 'text' : 'number'
            }-custom-field-input`,
          )
          .forEach((item) => {
            const itemName = item.getAttributes().name;

            if (itemName !== '') {
              usedItems.push(itemName);
            }
          });
      }

      res.data
        .filter(({ type, name }) => {
          return type === customFieldType && !usedItems.includes(name);
        })
        .forEach(({ name }) => {
          const newOption = document.createElement('option');

          newOption.value = name;
          newOption.text = name;

          modalSelectList.appendChild(newOption);
        });

      const selectedFirstChild = document.querySelector(
        '#cf-modal__cf-list > option:first-child',
      );
      selectedFirstChild.text = fieldName || '';
      selectedFirstChild.disabled = fieldName ? true : false;

      modalSelectList.disabled = false;
    })
    .catch(() => {
      showErrorMessage('Something went wrong.');
      editor.Modal.close();
    });
};
