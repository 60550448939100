import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '../FormControl';
import Label from '../Label';
import FormHelperText from '../FormHelperText';
import { RadioGroupContext } from './RadioGroupContext';
import './RadioGroup.sass';

function RadioGroup({
  className,
  name,
  label,
  helperText,
  error,
  fullWidth,
  children,
  onChange,
  value,
}) {
  return (
    <RadioGroupContext.Provider value={{ name, onChange, value }}>
      <FormControl fullWidth={fullWidth} className={className}>
        {label && <Label component="legend">{label}</Label>}
        <div className="dnd-radio-group__input-wrapper">{children}</div>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </RadioGroupContext.Provider>
  );
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default RadioGroup;
