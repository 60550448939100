import React from 'react';
import PropTypes from 'prop-types';
import { useUnsplash } from 'hooks';
import { LOAD_STATUS } from 'appConstants';
import TextField from 'components/Forms/TextField';
import ImageItem from 'components/Images/ImageItem';
import ImagesContent from 'components/Images/ImagesContent';

function UnsplashImages({ onSetImageUrl }) {
  const {
    state: { data, searchTerm, status, totalPages, page },
    setSearchTerm,
    loadNextPage,
    downloadImage,
  } = useUnsplash();

  return (
    <div className="image-modal__content">
      <TextField
        className="image-modal__unsplash-search"
        placeholder="Type to search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
      />
      <div className="image-modal__images-wrapper">
        <ImagesContent
          showEmptyContent={status === LOAD_STATUS.IDLE}
          showNotFound={
            !data.length &&
            (status === LOAD_STATUS.RESOLVED || status === LOAD_STATUS.REJECTED)
          }
          showBigSpinner={!data.length && status === LOAD_STATUS.LOADING}
          showLoadMore={totalPages !== page && status !== LOAD_STATUS.LOADING}
          showLoadMoreSpinner={Boolean(
            data.length && status === LOAD_STATUS.LOADING,
          )}
          onLoadNextPage={loadNextPage}>
          {data.map((item) => (
            <ImageItem
              key={item.id}
              thumbUrl={item.thumbUrl}
              description={item.description}
              userUrl={item.userUrl}
              userName={item.userName}
              type="unsplash"
              onItemClick={() => {
                onSetImageUrl(item.url);
                downloadImage(item.downloadUrl);
              }}
            />
          ))}
        </ImagesContent>
      </div>
    </div>
  );
}

UnsplashImages.propTypes = {
  onSetImageUrl: PropTypes.func.isRequired,
};

export default UnsplashImages;
