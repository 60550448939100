import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from '@reach/menu-button';
import { FormattedMessage } from 'react-intl';
import {
  getsubscriptionPlanName,
  deleteCookie,
  getAvatarUrl,
  getFullName,
  redirectToAuth,
} from 'helpers';
import { useSelector } from 'react-redux';
import {
  isAdminSelector,
  subscriptionPlanSelector,
  subscriptionPlansSelector,
} from 'store/selectors';
import { useModal } from 'hooks';
import UserPermissions from 'components/Modals/UserPermissions';
import SubscriptionPlans from 'components/Modals/SubscriptionPlans';
import SwitchUsers from 'components/Modals/SwitchUsers';
import { REDIRECT_URL } from 'appConstants';

import IconButton from 'components/IconButton';
import BaseModal from 'components/Modals/BaseModal';
import './UserMenu.sass';

function UserMenu({ setNewSessionId, hiddenSwitchUsers = false }) {
  const { firstName, lastName, email, permissions } = useSelector(
    (state) => state.user,
  );

  const plans = useSelector(subscriptionPlansSelector);

  const {
    isOpen: isOpenUserPermissions,
    open: openUserPermissions,
    close: closeUserPermissions,
  } = useModal();
  const {
    isOpen: isOpenSubscriptionPlans,
    open: openSubscriptionPlans,
    close: closeSubscriptionPlans,
  } = useModal();
  const {
    isOpen: isOpenSwitchUsers,
    open: openSwitchUsers,
    close: closeSwitchUsers,
  } = useModal();

  const handleLogout = () => {
    deleteCookie('sessionId');

    localStorage.removeItem('token');

    redirectToAuth();
  };

  const userName = getFullName({ firstName, lastName });

  const isAdmin = useSelector(isAdminSelector);

  const subscriptionPlan = useSelector(subscriptionPlanSelector);

  return (
    <>
      <Menu>
        <MenuButton
          className="dnd-user-menu__user-btn"
          aria-label="open user menu"
          as={IconButton}>
          <img src={getAvatarUrl(email)} alt={userName} />
        </MenuButton>
        <MenuList className="dnd-user-menu__list">
          <h6 className="dnd-user-menu__title">{userName}</h6>
          <MenuLink
            className="dnd-user-menu__item dnd-user-menu__current-plan"
            href={`${REDIRECT_URL}/settings/subscriptions`}
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage id="user-menu.current-plan" />
            <span className="dnd-user-menu__current-plan__plan">
              {getsubscriptionPlanName(
                subscriptionPlan?.subscriptionPlanNumber,
              )}
            </span>
            <span className="icon icon-open_in_new" />
          </MenuLink>
          <MenuLink
            className="dnd-user-menu__item"
            href={`${REDIRECT_URL}/settings/general`}
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage id="user-menu.account-settings" />
            <span className="icon icon-open_in_new" />
          </MenuLink>
          <MenuLink
            className="dnd-user-menu__item"
            href="https://wayforms.routee.net/p52476408079"
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage id="user-menu.feedback" />
            <span className="icon icon-open_in_new" />
          </MenuLink>
          {isAdmin &&
            permissions &&
            permissions?.includes('ADMIN_CAN_SET_USER_ROLES') && (
              <>
                <hr />
                <MenuLink
                  className="dnd-user-menu__item"
                  rel="noopener noreferrer"
                  onSelect={openUserPermissions}>
                  <FormattedMessage id="user-menu.user-permissions" />
                </MenuLink>
              </>
            )}
          {isAdmin && (
            <>
              <hr />
              <MenuLink
                className="dnd-user-menu__item"
                rel="noopener noreferrer"
                onSelect={openSubscriptionPlans}>
                <FormattedMessage id="user-menu.user-subscription-plan" />
              </MenuLink>
            </>
          )}
          {!hiddenSwitchUsers &&
            (window.location.hostname === 'localhost' ||
              window.location.hostname === 'dev2-dnd.waymore.io') && (
              <>
                <hr />
                <MenuLink
                  className="dnd-user-menu__item"
                  rel="noopener noreferrer"
                  onSelect={openSwitchUsers}>
                  <FormattedMessage id="user-menu.switch-users" />
                </MenuLink>
              </>
            )}
          <hr />
          <MenuItem className="dnd-user-menu__item" onSelect={handleLogout}>
            <FormattedMessage id="user-menu.logout" />
          </MenuItem>
        </MenuList>
      </Menu>
      <BaseModal
        isOpen={isOpenUserPermissions}
        maxWidth="600px"
        headerTitle="user-menu.modal-user-permissions"
        onClose={closeUserPermissions}>
        <UserPermissions onClose={closeUserPermissions} />
      </BaseModal>
      <BaseModal
        isOpen={isOpenSubscriptionPlans}
        maxWidth="500px"
        headerTitle="modal-subscription-plan.modal-header"
        onClose={closeSubscriptionPlans}>
        <SubscriptionPlans plans={plans} />
      </BaseModal>
      {!hiddenSwitchUsers &&
        (window.location.hostname === 'localhost' ||
          window.location.hostname === 'dev2-dnd.waymore.io') && (
          <BaseModal
            isOpen={isOpenSwitchUsers}
            maxWidth="500px"
            headerTitle="modal-switch-users.modal-header"
            onClose={closeSwitchUsers}>
            <SwitchUsers
              setNewSessionId={setNewSessionId}
              closeSwitchUsers={closeSwitchUsers}
            />
          </BaseModal>
        )}
    </>
  );
}

UserMenu.propTypes = {
  setNewSessionId: PropTypes.func,
  hiddenSwitchUsers: PropTypes.bool,
};

export default UserMenu;
