import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { GOOGLE_FONTS_CATEGORIES } from 'appConstants';
import Input from 'components/Forms/Input';
import Select from 'components/Forms/Select';

const messages = defineMessages({
  TypeTextInputPlaceholder: {
    id: 'page-settings-modal-fonts.filters.type-input-placeholder',
  },
  TypeTextInputAriaLabel: {
    id: 'page-settings-modal-fonts.filters.type-input-arial-label',
  },
  searchInputPlaceholder: {
    id: 'page-settings-modal-fonts.filters.search-input-placeholder',
  },
  searchInputAriaLabel: {
    id: 'page-settings-modal-fonts.filters.search-input-arial-label',
  },
  allCategoriesText: {
    id: 'page-settings-modal-fonts.filters.all-categories-text',
  },
});

function FontFilters({ setPreviewText, setSearchValue, setCategoryValue }) {
  const intl = useIntl();

  return (
    <div className="page-settings-modal__fonts__filters">
      <Input
        data-testid="font-filter-preview-input"
        aria-label={intl.formatMessage(messages.TypeTextInputAriaLabel)}
        className="page-settings-modal__fonts__filters__type"
        placeholder={intl.formatMessage(messages.TypeTextInputPlaceholder)}
        onChange={(e) => setPreviewText(e.target.value)}
      />
      <Input
        data-testid="font-filter-search-input"
        aria-label={intl.formatMessage(messages.searchInputAriaLabel)}
        className="page-settings-modal__fonts__filters__search"
        placeholder={intl.formatMessage(messages.searchInputPlaceholder)}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Select
        data-testid="font-filter-category"
        className="page-settings-modal__fonts__filters__category"
        label=""
        defaultValue="all"
        onChange={(e) => setCategoryValue(e.target.value)}>
        <option
          data-testid="font-filter-category-option"
          key="all-categories-font"
          value="all">
          {intl.formatMessage(messages.allCategoriesText)}
        </option>
        {GOOGLE_FONTS_CATEGORIES.map((fontCategory) => (
          <option key={`${fontCategory}-font`} value={fontCategory}>
            {fontCategory}
          </option>
        ))}
      </Select>
    </div>
  );
}

FontFilters.propTypes = {
  setPreviewText: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setCategoryValue: PropTypes.func.isRequired,
};

export default FontFilters;
