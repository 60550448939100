import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import TextField from 'components/Forms/TextField';
import Button from 'components/Button';
import { createCategory, updateCategory } from 'store/actions';
import './CategoryModal.sass';

function CategoryModal({ category = {}, categoriesNames, onClose }) {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: category.name,
    },
    mode: 'onBlur',
  });

  const handleSave = async (values) => {
    setIsLoading(true);

    try {
      if (category.id) {
        await dispatch(
          updateCategory({
            id: category.id,
            name: values.name.trim(),
          }),
        ).unwrap();
      } else {
        await dispatch(createCategory({ name: values.name.trim() })).unwrap();
      }

      onClose();
    } catch {
      // catching error and doing nothing
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        className="category-modal__form"
        onSubmit={handleSubmit(handleSave)}>
        <TextField
          {...register('name', {
            validate: (value) => {
              const trimmedValue = value.trim();

              if (!trimmedValue) {
                return intl.formatMessage({
                  id: 'category-modal.required-error',
                });
              }

              if (categoriesNames.includes(value.trim())) {
                return intl.formatMessage({
                  id: 'category-modal.category-exists-error',
                });
              }
            },
          })}
          fullWidth
          placeholder={intl.formatMessage({ id: 'category-modal.placeholder' })}
          helperText={
            errors.name?.message ||
            intl.formatMessage({ id: 'category-modal.description' })
          }
          error={Boolean(errors.name)}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isLoading}
          loading={isLoading}>
          <FormattedMessage id="button.save" />
        </Button>
      </form>
    </>
  );
}

CategoryModal.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  categoriesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CategoryModal;
