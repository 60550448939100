import cleaner from './Cleaner';
import customCode from './CustomCode';
import help from './Help';
import qr from './QR';
import image from './Image';
import importHtml from './Import';
import pageSettings from './PageSettings';
import personalNotes from './PersonalNotes';
import historyPanel from './VersionHistory';
import viewCode from './ViewCode';
import waymore from './WayMore';
import zoom from './Zoom';

export default (editor, config = {}) => {
  cleaner(editor, config);
  customCode(editor, config);
  help(editor, config);
  image(editor, config);
  importHtml(editor, config);
  pageSettings(editor, config);
  personalNotes(editor, config);
  historyPanel(editor, config);
  qr(editor, config);
  viewCode(editor, config);
  waymore(editor, config);
  zoom(editor, config);
};
