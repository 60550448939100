export const customScript = (
  templateId,
  wrapperFormTypeCount,
  wrapperDiscountTypeCount,
) => {
  let result = '';

  const generalForms = `
  var generatedToken = '';
  var siteKey = '';
  const forms = document.querySelectorAll('form');

  Array.from(document.getElementsByTagName('script')).forEach(item => {
    if(item.src && item.src.startsWith('https://www.google.com/recaptcha/api.js?render=')) {
      siteKey = item.src.replace('https://www.google.com/recaptcha/api.js?render=','');
    }
  });

  if(window.grecaptcha && siteKey) {
    window.grecaptcha.ready(function () {
      window.grecaptcha
      .execute(siteKey, {
          action: "submit"
      })
      .then(function (token) {

        forms.forEach((form) => {
          generatedToken = token;
        })

      });
    });
  }

  window.addEventListener('load', function () {
    forms.forEach((form) => {
      let phoneInput = form.querySelector('.phone-input input[type=tel]');

      if(phoneInput) {
        window.intlTelInput(phoneInput, {});
      }
      if(phoneInput && phoneInput.hasAttribute('autofocus')) {
        setTimeout(function() {
          phoneInput.focus();
        }, 1000);
      }
    })
  })

  function fadeOut(el) {
    el.style.opacity = 1;
    (function fade() {
      if ((el.style.opacity -= 0.1) < 0) {
        el.style.display = 'none';
      } else {
        requestAnimationFrame(fade);
      }
    })();
  }

  function fadeIn(el, display) {
    el.style.opacity = 0;
    el.style.display = display || 'block';
    (function fade() {
      var val = parseFloat(el.style.opacity);
      if (!((val += 0.1) > 1)) {
        el.style.opacity = val;
        requestAnimationFrame(fade);
      }
    })();
  }

  forms.forEach((form) => {
    if(form.querySelector('.state-error')) {
      form.querySelector('.state-error').style.display = "none";
    }

    if(form.querySelector('.state-success')) {
      form.querySelector('.state-success').style.display = "none";
    }

    if(form.querySelector('.email-error')) {
      form.querySelector('.email-error').style.display = "none";
    }

    if(form.querySelector('.form-content')) {
      form.querySelector('.form-content').style.display = "block";
    }


    form.addEventListener('submit', function (e) {
      e.preventDefault();

      const formData = new FormData(e.target);
      const formValues = Object.fromEntries(formData.entries());
      const submitBtn = this.querySelector('button[type=submit]');
      const errorState = this.querySelector('.state-error:not(.email-error)');
      const emailError = this.querySelector('.email-error');
      const successState = this.querySelector('.state-success');
      const formContent = this.querySelector('.form-content');
      const redirectUrl = this.getAttribute('redirect') || '';
      const formItems = this.querySelectorAll('*');
      const hasEmailType = Array.from(this).some(
        (item) => item.nodeName === 'INPUT' && item.type === 'email',
      );
      const formSubmitAction = '/form/submit';

      // Show error when Google ReCaptcha went wrong
      if (!generatedToken || !siteKey) {
        fadeIn(errorState);
        setTimeout(function () {
          fadeOut(emailError);
        }, 3000);

        return false;
      }

      // Show error when form have not at least one email type
      if (!hasEmailType) {
        fadeIn(emailError);
        setTimeout(function () {
          fadeOut(emailError);
        }, 3000);

        return false;
      }

      // Disable submit button before fetch data
      submitBtn.disabled = true;

      fetch(formSubmitAction, {
        method: 'POST',
        body: JSON.stringify({
          formData: {
            ...formValues,
          },
          recaptchaToken: generatedToken,
          templateId: '${templateId}',
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then(function (formSubmitResponse) {
          generatedToken = '';

          if(formSubmitResponse && formSubmitResponse.ok) {
            formContent.style.display = 'none';
            errorState.style.display = 'none';
            fadeIn(successState);
          } else {
            fadeIn(errorState);
          }
        })
        .catch((err) => {
          console.log(err);
          fadeIn(errorState);
        })
        .finally(function() {
          submitBtn.disabled = false;

          //Redirect to specified URL
          if(redirectUrl) {
            setTimeout(function() {
                window.location.href = redirectUrl;
            }, 2000)
          }
        })
    });
  });
  `;

  const discountCodes = `
    const discountCodes = Array.from(
      document.querySelectorAll('[data-type="discount-code"]'),
    );

    discountCodes.forEach((code) => {
      const codeInput = code.querySelector('input');
      const codeButton = code.querySelector('button');

      codeButton.addEventListener('click', () => {
        if (navigator.permissions) {
          navigator.permissions
            .query({
              name: 'clipboard-write',
            })
            .then(async (result) => {
              if (result.state !== 'denied') {
                await navigator.clipboard.writeText(
                  codeInput?.value || '',
                );

                code.classList.add('copied');
                codeButton.innerText = 'copied';
              }
            })
            .catch((err) => console.error(err));
        }
      });
    });
  `;

  if (wrapperFormTypeCount > 0) {
    result += generalForms;
  }

  if (wrapperDiscountTypeCount > 0) {
    result += discountCodes;
  }

  return result;
};
