import 'intersection-observer';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import '@reach/tabs/styles.css';
import '@reach/menu-button/styles.css';
import '@reach/tooltip/styles.css';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import axios from 'axios';
import { API_HOST, REDIRECT_URL } from 'appConstants';
import { useSelector, useDispatch } from 'react-redux';
import Loading from 'components/Loading';
import Home from './routes/Home';
import Editor from './routes/Editor';
import { getCredentials } from './store/actions';
import { getSessionId, redirectToAuth } from './helpers';
import './App.sass';

// configuring axios
axios.defaults.baseURL = API_HOST;
axios.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    redirectToAuth();
  }

  return Promise.reject(error);
});
// configuring react-toastify
toast.configure();
// configuring react-modal (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function App() {
  const { theme } = useSelector((state) => state.user);
  const subscriptionPlanNumber = useSelector(
    (state) => state.user?.subscriptionPlan?.subscriptionPlanNumber,
  );
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [showAlertInfo, setShowAlertInfo] = useState(true);
  const [newSessionId, setNewSessionId] = useState('');

  useEffect(() => {
    const sessionId = getSessionId();

    if (newSessionId) {
      setLoaded(false);
    }

    const requestCredentials = async () => {
      await dispatch(getCredentials(newSessionId || sessionId));
      setLoaded(true);
    };

    if (!sessionId) {
      return redirectToAuth();
    }

    requestCredentials();
  }, [dispatch, newSessionId]);

  let themeClass = `${theme}-theme`;

  return loaded ? (
    <div id="layout" className={themeClass}>
      {showAlertInfo && subscriptionPlanNumber === 0 && (
        <div className="alert-info" role="alert">
          <div className="alert-info__content">
            <span className="icon icon-info" />
            <span>
              You are currenlty trying out our Premium Features. Your Free Trial
              ends soon!
            </span>
            <button
              onClick={() =>
                window.open(`${REDIRECT_URL}/settings/subscriptions`)
              }>
              Upgrade Now
            </button>
          </div>
          <span
            className="icon icon-close"
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowAlertInfo(false);
              }
            }}
            onClick={() => setShowAlertInfo(false)}
          />
        </div>
      )}
      <Switch>
        <Route path="/edit/:id">
          <Editor />
        </Route>
        <Route path="/" exact>
          <Home setNewSessionId={setNewSessionId} />
        </Route>
        <Redirect to="/" />
      </Switch>
    </div>
  ) : (
    <Loading />
  );
}

export default App;
