import {
  FLOAT_OPTIONS,
  FONTS,
  TEXT_ALIGN_OPTIONS,
  VISIBILITY_OPTIONS,
} from 'appConstants';

const floatProperty = {
  name: 'Float',
  property: 'float',
  type: 'radio',
  default: 'none',
  options: FLOAT_OPTIONS,
};

const visibilityProperty = {
  name: 'Visibility',
  property: 'visibility',
  type: 'select',
  defaults: 'visible',
  options: VISIBILITY_OPTIONS,
};

const fontFamilyProperty = {
  name: 'Font family',
  type: 'select',
  property: 'font-family',
  default: FONTS[0].value,
};

const fontWeightProperty = {
  name: 'Font weight',
  property: 'font-weight',
};

const textAlignProperty = {
  property: 'text-align',
  name: 'Text alignment',
  type: 'radio',
  default: 'left',
  options: TEXT_ALIGN_OPTIONS,
};

const opacityProperty = {
  name: 'Opacity',
  type: 'slider',
  property: 'opacity',
  default: 1,
  step: 0.01,
  max: 1,
  min: 0,
};

const textDecorationProperty = {
  property: 'text-decoration',
  type: 'radio',
  default: 'none',
  options: [
    { value: 'none', name: 'None', className: 'icon icon-close' },
    {
      value: 'underline',
      name: 'underline',
      className: 'icon icon-format-underlined',
    },
    {
      value: 'line-through',
      name: 'Line-through',
      className: 'icon icon-strikethrough',
    },
  ],
};

const textShadowProperty = {
  property: 'text-shadow',
  properties: [
    { name: 'X position', property: 'text-shadow-h' },
    { name: 'Y position', property: 'text-shadow-v' },
    { name: 'Blur', property: 'text-shadow-blur' },
    { name: 'Color', property: 'text-shadow-color' },
  ],
};

export const setWrapperSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background-color'],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setVideoSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: ['width', 'height'],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setImageBlockSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background', 'opacity'],
      properties: [opacityProperty],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setImageSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: ['float', 'opacity'],
      properties: [opacityProperty, floatProperty],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setIconSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      properties: ['width', 'height'],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setSectionSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background-color'],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setColumnSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background-color'],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setTextSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: [
        'font-size',
        'font-weight',
        'letter-spacing',
        'color',
        'visibility',
        'line-height',
        'text-align',
        'text-decoration',
        'text-shadow',
      ],
      properties: [
        visibilityProperty,
        fontFamilyProperty,
        fontWeightProperty,
        textAlignProperty,
        floatProperty,
        textDecorationProperty,
        textShadowProperty,
      ],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setButtonSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: [
        'font-size',
        'font-weight',
        'letter-spacing',
        'line-height',
        'color',
        'visibility',
        'text-align',
        'text-decoration',
        'text-shadow',
        'background-color',
        'float',
      ],
      properties: [
        visibilityProperty,
        fontFamilyProperty,
        textAlignProperty,
        textDecorationProperty,
        textShadowProperty,
        floatProperty,
      ],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const setWayMoreFormSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick settings',
      id: 'quickSector',
      open: true,
      buildProps: ['width', 'height', 'min-width', 'min-height'],
    },
    ...editor.StyleManager.getConfig().sectors,
  ]);
};

export const generateSelectedFontsList = async (editor, family = {}) => {
  const styleManager = editor.StyleManager;
  const selected = editor?.getSelected();
  const wrapper = editor.getWrapper();
  const fontsList = wrapper.get('fontsList');
  const selectedFontsList = Array.from(JSON.parse(fontsList));

  const quickfontFamilyProperty =
    styleManager.getSectors().find(({ id }) => id === 'quickSector') &&
    styleManager?.getProperty('quickSector', 'font-family');
  const typographyFamilyProperty =
    styleManager.getSectors().find(({ id }) => id === 'typography') &&
    styleManager?.getProperty('typography', 'font-family');
  const selectedOptions = (selectedFontsList) => {
    return selectedFontsList.map(({ name, value }) => {
      return { name, value };
    });
  };
  const familyCompatibleObject = (family, full = true) => {
    const { name, value, category } = family;

    return full
      ? {
          ...family,
          value: `${/\s/g.test(value.trim()) ? `"${value}"` : value}, ${
            family.category
          }`,
        }
      : {
          name,
          value: `${
            /\s/g.test(value.trim()) ? `"${value}"` : value
          }, ${category}`,
        };
  };

  if (Object.keys(family).length > 0) {
    wrapper.set(
      'fontsList',
      JSON.stringify([...selectedFontsList, familyCompatibleObject(family)]),
    );
  }

  // Quick Sector
  if (quickfontFamilyProperty && selected) {
    if (Object.keys(family).length > 0) {
      selectedFontsList.push(familyCompatibleObject(family, false));
    }

    quickfontFamilyProperty.set('options', selectedOptions(selectedFontsList));
  }

  // Typography Sector
  if (typographyFamilyProperty && selected) {
    if (Object.keys(family).length > 0) {
      selectedFontsList.push(familyCompatibleObject(family, false));
    }

    typographyFamilyProperty.set('options', selectedOptions(selectedFontsList));
  }
};

export const setButtonClickAttribute = async (editor, flag) => {
  const iframe = editor.Canvas.getFrameEl();
  const iframeDocument = iframe.contentDocument;
  const linkBtn = iframeDocument.querySelectorAll('[data-type="link-button"]');
  const getAttributeName = flag === 'design' ? 'onclick' : 'click';
  const setAttributeName = flag === 'design' ? 'click' : 'onclick';

  linkBtn.forEach((item) => {
    const clickEvent = item.getAttribute(getAttributeName);
    if (clickEvent) {
      item.setAttribute(setAttributeName, clickEvent);
      item.removeAttribute(getAttributeName);
    }
  });
};
