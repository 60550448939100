export const socialFollowTypes = [
  'facebook',
  'instagram',
  'linkedin',
  'x',
  'vk',
  'youtube',
  'pinterest',
];

export const socialShareTypes = [
  'facebook',
  'linkedin',
  'x',
  'vk',
  'pinterest',
];

export const socialShapes = ['square', 'round'];

export const socialColors = ['color', 'black', 'grey', 'white', 'transparent'];

export const socialHexColor = {
  facebook: '#4267B2',
  instagram: '#C13584',
  linkedin: '#2867B2',
  x: '#0f1419',
  vk: '#4c75a3',
  youtube: '#FF0000',
  pinterest: '#e60023',
};
