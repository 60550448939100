import { createSlice } from '@reduxjs/toolkit';
import { LOAD_STATUS } from 'appConstants';
import {
  createCategory,
  updateCategory,
  deleteCategory,
  getCategories,
} from 'store/actions';

/* example of categories state:
  {
    51asd235a: {
      id: "51asd235a",
      name: "CAT",
      group: "system",
      type: 'page',
      hidden: true,
    },
    51asd235b: {
      id: "51asd235b",
      name: "CAT2",
      group: "system",
      type: 'page',
      hidden: false,
    },
  }
*/
const initialState = {
  status: LOAD_STATUS.IDLE,
  isLoaded: false,
  items: {},
};

const { reducer: categoriesReducer } = createSlice({
  name: 'categories',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.fulfilled, (state, action) => {
        let res = action.payload.reduce((acc, category) => {
          acc[category.id] = category;
          return acc;
        }, state.items);

        state.status = LOAD_STATUS.RESOLVED;
        state.isLoaded = true;
        state.items = { ...res };
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.items[action.payload.id] = action.payload;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.items[action.payload.id] = {
          ...state[action.payload.id],
          ...action.payload,
        };
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        delete state.items[action.payload.id];
      });
  },
});

export { categoriesReducer };
