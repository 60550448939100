import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Header.sass';

function Header({ children, className, iconTitleClass, onClose, headerStyle }) {
  return (
    <div style={{ ...headerStyle }} className={cx('modal-header', className)}>
      <h2 className="modal-header__title">
        {iconTitleClass && (
          <i className={cx('modal-header__title-icon', iconTitleClass)} />
        )}
        {children}
      </h2>
      <button
        aria-label="close"
        className="modal-header__shut"
        type="button"
        onClick={onClose}
      />
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconTitleClass: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  headerStyle: PropTypes.object,
};

export default Header;
