import {
  ANIMATION_EFFECTS_LIST,
  SOCIAL_MESSENGER_ICONS,
  SOCIAL_OTHER_ICONS,
  SOCIAL_PREVIEW_ICONS,
  SOCIAL_SOCIAL_ICONS,
  SOCIAL_STYLES,
} from 'appConstants';

export const addNewNavbarItemTrait = {
  type: 'button',
  name: 'add-new-navbar-item-type',
  text: 'Add new item',
  full: true,
  command: (editor) => {
    const selected = editor?.getSelected();
    const navbarMenu = selected.findType('navbar-nav-menu');
    const navbarMenuLinkItems = selected.findType('navbar-nav-menu-link');
    const navbarMenuLen = navbarMenuLinkItems?.length || 0;

    // Add new menu link
    navbarMenu[0].components().add(
      {
        type: 'navbar-nav-menu-link',
        content: 'New Menu',
      },
      { at: navbarMenuLen },
    );
  },
};

export const animationTypeTrait = {
  type: 'select-with-group',
  name: 'data-animation-type',
  label: 'Animation type',
  options: ANIMATION_EFFECTS_LIST,
  default: 'none',
};

export const animationDelayTrait = {
  type: 'number',
  name: 'animationDelay',
  label: 'Delay',
  default: 0,
  step: 0.1,
  min: 0,
  changeProp: true,
  attributes: { style: 'display: none' },
};

export const animationDurationTrait = {
  type: 'number',
  name: 'animationDuration',
  label: 'Duration',
  default: 0,
  step: 0.1,
  min: 0,
  changeProp: true,
  attributes: { style: 'display: none' },
};

export const animationRepeatTrait = {
  type: 'number',
  name: 'animationRepeat',
  label: 'Repeat (set "&#45;1" for "infinite" repetition)',
  default: 1,
  step: 1,
  min: -1,
  changeProp: true,
  attributes: { style: 'display: none' },
};

export const linkTraits = () => {
  return [
    {
      type: 'text',
      name: 'href',
      label: 'Link (URL)',
    },
    {
      type: 'select',
      name: 'target',
      label: 'target',
      default: '_self',
      options: [
        { id: '_self', name: 'This window' },
        { id: '_blank', name: 'New window' },
      ],
    },
  ];
};

export const addCustomTypes = (editor) => {
  editor.TraitManager.addType('text-next', {
    templateInput: `
      <div class="custom-input-wrapper">
        <div data-input></div>
      </div>
    `,
    eventCapture: ['input'],
  });

  editor.TraitManager.addType('content', {
    events: {
      keyup: 'onChange',
    },
    getInputEl() {
      if (!this.inputEl) {
        const input = document.createElement('textarea');
        const targetElementValue = this.target
          .getView()
          .model.components()
          .models[0].get('content');

        input.value = targetElementValue;
        input.setAttribute('placeholder', '');
        input.style.background = 'white';
        input.style.color = 'black';
        input.style.width = '100%';

        this.inputEl = input;
      }

      return this.inputEl;
    },
    onValueChange() {
      this.target
        .getView()
        .model.components()
        .models[0].set('content', this.model.get('value'));

      editor.getSelected().view.render();
    },
  });

  editor.TraitManager.addType('select-option', {
    events: {
      keyup: 'onChange',
    },

    onValueChange() {
      const optionsStr = this.model.get('value').trim();
      const options = optionsStr.split('\n');
      const optComps = [];

      for (let i = 0; i < options.length; i++) {
        const optionStr = options[i];
        const option = optionStr.split('::');
        const opt = {
          tagName: 'option',
          attributes: {},
        };
        if (option[1]) {
          opt.content = option[1];
          opt.attributes.value = option[0];
        } else {
          opt.content = option[0];
          opt.attributes.value = option[0];
        }
        optComps.push(opt);
      }

      const comps = this.target.get('components');
      comps.reset(optComps);
      this.target.view.render();
    },

    getInputEl() {
      if (!this.$input) {
        let optionsStr = '';
        const trg = this.target;
        const options = trg.get('components');

        for (let i = 0; i < options.length; i++) {
          const option = options.models[i];
          const optAttr = option.get('attributes');
          const optValue = optAttr.value || '';
          optionsStr += `${optValue}::${option.get('content')}\n`;
        }

        this.$input = document.createElement('textarea');
        this.$input.value = optionsStr;
        this.$input.placeholder = `Add new options started on new line\nvalue1::text1\nvalue2::text2`;
      }
      return this.$input;
    },
  });

  editor.TraitManager.addType('add-social-icons', {
    eventCapture: ['click'],
    createInput() {
      const el = document.createElement('div');

      el.className = 'add-social-icons-component';
      el.innerHTML = `
        <div class="tabset">
          <input type="radio" name="tabset" id="tab1" aria-controls="social-icons" checked>
          <label for="tab1">Social</label>
          <input type="radio" name="tabset" id="tab2" aria-controls="messenger-icons">
          <label for="tab2">Chat</label>
          <input type="radio" name="tabset" id="tab3" aria-controls="other-icons">
          <label for="tab3">Other</label>
          <div class="tab-panels">
            <section id="social-icons" class="tab-panel">
            ${SOCIAL_SOCIAL_ICONS.map(
              ({ iconName, iconTitle }) =>
                `<button data-add-social-icon-button="${iconName}" data-social-cat="social-icons" title="${iconTitle}">
                    <span class="${iconName}"></span>
                </button>`,
            ).join('')}
            </section>
            <section id="messenger-icons" class="tab-panel">
            ${SOCIAL_MESSENGER_ICONS.map(
              ({ iconName, iconTitle }) =>
                `<button data-add-social-icon-button="${iconName}" data-social-cat="messenger-icons" title="${iconTitle}">
                  <span class="${iconName}"></span>
                </button>`,
            ).join('')}
            </section>
            <section id="other-icons" class="tab-panel">
              ${SOCIAL_OTHER_ICONS.map(
                ({ iconName, iconTitle }) =>
                  `<button data-add-social-icon-button="${iconName}" data-social-cat="other-icons" title="${iconTitle}">
                    <span class="${iconName}"></span>
                  </button>`,
              ).join('')}
            </section>
          </div>
        </div>
      `;

      const allAddSocialIcons = el.querySelectorAll(
        '.add-social-icons-component .tab-panel > button',
      );

      if (allAddSocialIcons) {
        allAddSocialIcons.forEach((button) =>
          button.addEventListener('click', function () {
            const iconName = this?.dataset?.addSocialIconButton;
            const currentIconTitle = this.getAttribute('title');
            const { 'data-social-icon-style': socialIconStyle } = editor
              .getSelected()
              .get('attributes');
            const currentStyle = SOCIAL_STYLES.filter(
              ({ style }) =>
                style === socialIconStyle.replace('preview-32-', ''),
            );
            const defaultIconSize =
              editor
                .getSelected()
                .getTrait('data-social-icon-size')
                .get('value') || '18';
            const iconColor = [
              ...SOCIAL_SOCIAL_ICONS,
              ...SOCIAL_MESSENGER_ICONS,
              ...SOCIAL_OTHER_ICONS,
            ].filter(({ iconTitle }) => iconTitle === currentIconTitle);

            const newAddedItem = editor
              .getSelected()
              .components()
              .add(
                {
                  type: 'social-icon',
                  attributes: {
                    'data-type': 'social-icon',
                    href: '',
                    target: '_blank',
                    title: currentIconTitle,
                    class: iconName,
                  },
                },
                { at: -1 },
              );

            newAddedItem.setStyle({
              display: 'flex',
              'align-items': 'center',
              'justify-content': 'center',
              'font-size': `${defaultIconSize}px`,
              width: '1em',
              height: '1em',
              margin: '0 4px',
              'text-decoration': 'none',
              color: '#333',
              ...currentStyle[0].css,
              ...(currentStyle[0].customColor && {
                color: iconColor[0].iconColor,
              }),
              ...(currentStyle[0].customBgColor && {
                'background-color': iconColor[0].iconColor,
              }),
              ...(currentStyle[0].customBorderColor && {
                'border-color': iconColor[0].iconColor,
              }),
            });
          }),
        );
      }

      return el;
    },
  });

  editor.TraitManager.addType('style-social-icons', {
    eventCapture: ['click'],
    createInput() {
      const el = document.createElement('div');
      el.className = 'style-social-icons-component';
      el.innerHTML = `
          ${SOCIAL_PREVIEW_ICONS.map(
            ({ iconName, iconTitle }) =>
              `<button data-style-social-icon-button="${iconName}">
                  <img
                    src="https://cdn11.waymore.io/dnd/images/social/preview/${iconName}.png"
                    alt="${iconTitle}"
                  />
                  <span>${iconTitle}</span>
                </button>`,
          ).join('')}
      `;

      const allSocialStyleIcons = el.querySelectorAll(
        '.style-social-icons-component > button',
      );
      allSocialStyleIcons.forEach((item) => {
        item.addEventListener('click', function () {
          allSocialStyleIcons.forEach((item) =>
            item.classList.remove('selected'),
          );
          this.classList.add('selected');
        });
      });

      return el;
    },
    onEvent({ elInput, component, trait }) {
      const name = trait?.get('name');
      const allSocialStyleIcons = elInput.querySelectorAll(
        '.style-social-icons-component > button',
      );

      allSocialStyleIcons.forEach((item) => {
        if (item.classList.contains('selected')) {
          if (name) {
            component.addAttributes({
              [name]: item?.dataset?.styleSocialIconButton,
            });
          }
        }
      });
    },
    onUpdate({ elInput, component, trait }) {
      const name = trait?.get('name');

      if (name) {
        const socialStyleIcon = component.getAttributes()[name];
        const allSocialStyleIcons = elInput.querySelectorAll(
          '.style-social-icons-component > button',
        );

        if (socialStyleIcon) {
          allSocialStyleIcons.forEach((item) => {
            if (item?.dataset?.styleSocialIconButton === socialStyleIcon) {
              item.classList.add('selected');
            } else {
              item.classList.remove('selected');
            }
          });
        }
      }
    },
  });

  editor.TraitManager.addType('select-with-group', {
    templateInput: `
      <div class="gjs-field-wrp--select">
        <div class="gjs-field gjs-field-select">
          <div data-input></div>
        </div>
      </div>
    `,
    createInput({ trait }) {
      const { options } = trait.attributes;
      const el = document.createElement('select');

      options.forEach((optionsData) => {
        if ('groupName' in optionsData || 'optionsList' in optionsData) {
          const optGroupElement = document.createElement('optgroup');

          optGroupElement.label = optionsData.groupName;

          el.appendChild(optGroupElement);

          optionsData.optionsList.forEach(({ id, name }) => {
            const optionElement = document.createElement('option');

            optionElement.textContent = name;
            optionElement.value = id;

            optGroupElement.appendChild(optionElement);
          });
        } else {
          const optionElement = document.createElement('option');

          optionElement.textContent = optionsData.name;
          optionElement.value = optionsData.id;

          el.appendChild(optionElement);
        }
      });

      return el;
    },
    onEvent({ component, event }) {
      component.addAttributes({ value: event.target.value });
    },
    onUpdate({ elInput, component, trait }) {
      if (component.get('attributes')) {
        elInput.value =
          component.get('attributes')?.value ||
          trait?.attributes?.default ||
          '';
      }
    },
  });
};

export const addClickEventTraits = {
  name: 'add-click-event',
  type: 'button',
  text: 'Add click event',
  full: true,
  command: 'open-add-event-click-modal',
};
