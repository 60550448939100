import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useRadioGroup } from './RadioGroupContext';

function RadioButton({ value: itemValue, label, description }) {
  const { value, onChange, name } = useRadioGroup();

  const checked = itemValue === value;

  return (
    <label
      aria-label={itemValue}
      className={cx('dnd-radio-button__label', { active: checked })}>
      {label}
      {description && (
        <p className="dnd-radio-button__label__description">{description}</p>
      )}
      <input
        className="dnd-radio-button__input"
        name={name}
        type="radio"
        value={itemValue}
        checked={checked}
        onChange={onChange}
      />
    </label>
  );
}

RadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  description: PropTypes.node,
};

export default RadioButton;
