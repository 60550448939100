import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = (e) => {
    e.preventDefault();
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = (e) => {
    e.preventDefault();
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <nav className="page-settings-modal__fonts__selected__table-footer__nav">
      <button disabled={currentPage === 1} onClick={prevPage}>
        «
      </button>
      {pageNumbers.map((pgNumber) => (
        <button
          key={pgNumber}
          className={cx({
            active: currentPage === pgNumber,
          })}
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(pgNumber);
          }}>
          {pgNumber}
        </button>
      ))}
      <button disabled={currentPage === nPages} onClick={nextPage}>
        »
      </button>
    </nav>
  );
};

Pagination.propTypes = {
  nPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default Pagination;
