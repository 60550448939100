import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LOAD_STATUS } from 'appConstants';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from 'store/actions';
import { categoriesSelector, isAdminSelector } from 'store/selectors';
import NewCategoryItem from 'components/NewCategoryItem';
import MultipleCombobox from 'components/MultipleCombobox';

const messages = defineMessages({
  categoriesLabel: { id: 'page-settings-modal.page.categories-label' },
  categoriesPlaceholder: {
    id: 'page-settings-modal.page.categories-placeholder',
  },
  categoriesDescription: {
    id: 'page-settings-modal.page.categories-description',
  },
});

const categoryItemToString = (item) => (item ? item.name : '');
const normalizeCategoryItem = (item) => ({ key: item.id, label: item.name });
function CategoriesCombobox({ showNewCategoryItem, value, onChange }) {
  const isAdmin = useSelector(isAdminSelector);

  const intl = useIntl();

  const dispatch = useDispatch();

  useEffect(() => {
    let promise;

    promise = dispatch(getCategories());

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch]);

  let categories = useSelector(categoriesSelector);
  const status = useSelector((state) => state.categories.status);

  if (!isAdmin) {
    categories = categories.filter((item) => item.group !== 'layouts');
  }

  const loading = status === LOAD_STATUS.IDLE || status === LOAD_STATUS.LOADING;

  return (
    <MultipleCombobox
      label={intl.formatMessage(messages.categoriesLabel)}
      placeholder={intl.formatMessage(messages.categoriesPlaceholder)}
      helperText={intl.formatMessage(messages.categoriesDescription)}
      loading={loading}
      items={categories}
      itemToString={categoryItemToString}
      normalizeItem={normalizeCategoryItem}
      // searching for selected categories in categories array
      // because MultipleCombobox compares selected categories with shallow
      // equal and in order to show correct results we need use the same
      // objects
      selectedItems={categories.filter((category) =>
        value.find((item) => item.id === category.id),
      )}
      onSelectedItemsChange={({ selectedItems }) => {
        onChange(selectedItems);
      }}
      footerElement={
        showNewCategoryItem && (
          <NewCategoryItem
            categoriesNames={Object.values(categories).map(
              (category) => category.name,
            )}
          />
        )
      }
      fullWidth
    />
  );
}

CategoriesCombobox.propTypes = {
  showNewCategoryItem: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CategoriesCombobox;
