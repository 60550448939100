import React from 'react';
import PropTypes from 'prop-types';

import { LOAD_STATUS } from 'appConstants';
import TextField from 'components/Forms/TextField';
import { useIconFinder } from 'hooks';
import ImagesContent from 'components/Images/ImagesContent';
import ImageItem from 'components/Images/ImageItem';
import './SvgModal.sass';

function SvgModal({ onSetSvgIcon }) {
  const {
    state: { data, searchTerm, status, totalCount, count, offset },
    setSearchTerm,
    loadNextPage,
  } = useIconFinder({ vector: true });

  return (
    <div className="svg-modal">
      <div className="svg-modal__content">
        <TextField
          placeholder="Type to search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
        />
        <div className="svg-modal__images-wrapper">
          <ImagesContent
            showEmptyContent={status === LOAD_STATUS.IDLE}
            showNotFound={
              !data.length &&
              (status === LOAD_STATUS.RESOLVED ||
                status === LOAD_STATUS.REJECTED)
            }
            showBigSpinner={!data.length && status === LOAD_STATUS.LOADING}
            showLoadMore={
              totalCount - count > offset && status !== LOAD_STATUS.LOADING
            }
            showLoadMoreSpinner={Boolean(
              data.length && status === LOAD_STATUS.LOADING,
            )}
            onLoadNextPage={loadNextPage}>
            {data.map((item) => (
              <ImageItem
                key={item.id}
                thumbUrl={item.url}
                description={item.description}
                type="icon"
                onItemClick={() => {
                  onSetSvgIcon(item.svgDownloadUrl);
                }}
              />
            ))}
          </ImagesContent>
        </div>
      </div>
    </div>
  );
}

SvgModal.propTypes = {
  onSetSvgIcon: PropTypes.func.isRequired,
};

export default SvgModal;
