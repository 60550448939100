import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import TextField from 'components/Forms/TextField';
import Cropper from 'components/Cropper';
import { useEditorContext } from 'routes/Editor/EditorContext';

const messages = defineMessages({
  description: { id: 'page-settings-modal.social.description' },
  titleLabel: { id: 'page-settings-modal.social.title-label' },
  titlePlaceholder: { id: 'page-settings-modal.social.title-placeholder' },
  titleDescription: { id: 'page-settings-modal.social.title-description' },
  typeLabel: { id: 'page-settings-modal.social.type-label' },
  typePlaceholder: { id: 'page-settings-modal.social.type-placeholder' },
  typeDescription: { id: 'page-settings-modal.social.type-description' },
  imageLabel: { id: 'page-settings-modal.social.image-label' },
  imageDescription: { id: 'page-settings-modal.social.image-description' },
  descriptionLabel: { id: 'page-settings-modal.social.description-label' },
  descriptionPlaceholder: {
    id: 'page-settings-modal.social.description-placeholder',
  },
  descriptionDescription: {
    id: 'page-settings-modal.social.description-description',
  },
});

function Social() {
  const intl = useIntl();
  const { register } = useFormContext();

  const {
    template: {
      settings: {
        social: { image },
      },
    },
    onUploadImage,
  } = useEditorContext();

  const [isSocialImageUploading, setIsSocialImageUploading] = useState(false);

  const handleUploadSocialImage = async (file) => {
    setIsSocialImageUploading(true);
    await onUploadImage('social', file);
    setIsSocialImageUploading(false);
  };

  return (
    <div className="page-settings-modal__social">
      <div className="page-settings-modal__row">
        <div className="page-settings-modal__sub-title">
          {intl.formatMessage(messages.description)}
        </div>
      </div>
      <div className="page-settings-modal__row">
        <div className="page-settings-modal__social-inputs">
          <TextField
            {...register('settings.social.title')}
            label={intl.formatMessage(messages.titleLabel)}
            placeholder={intl.formatMessage(messages.titlePlaceholder)}
            helperText={intl.formatMessage(messages.titleDescription)}
            fullWidth
          />
          <TextField
            {...register('settings.social.type')}
            label={intl.formatMessage(messages.typeLabel)}
            placeholder={intl.formatMessage(messages.typePlaceholder)}
            helperText={
              <>
                {intl.formatMessage(messages.typeDescription)} (
                <a
                  href="https://ogp.me/#types"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://ogp.me/#types
                </a>
                )
              </>
            }
            fullWidth
          />
        </div>
        <Cropper
          label={intl.formatMessage(messages.imageLabel)}
          helperText={intl.formatMessage(messages.imageDescription)}
          imageUrl={image}
          canvasSize={{ width: 240, height: 126 }}
          cropSize={{ width: 1200, height: 630 }}
          border={5}
          loading={isSocialImageUploading}
          onSave={handleUploadSocialImage}
        />
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('settings.social.description')}
          label={intl.formatMessage(messages.descriptionLabel)}
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          helperText={intl.formatMessage(messages.descriptionDescription)}
          multiline
          fullWidth
        />
      </div>
    </div>
  );
}

export default Social;
