import { checkComponentType } from 'helpers';
import { useToggleTypes } from 'hooks';

const addWayMoreForm = (editor, { wayMoreFormType }) => {
  const defaultType = editor.DomComponents.getType('default');

  const traits = [
    {
      type: 'checkbox',
      name: 'scrolling',
      label: 'Allow scroll',
      valueTrue: 'auto',
      valueFalse: 'no',
    },
  ];

  editor.DomComponents.addType(wayMoreFormType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'WayMore Form',
        type: wayMoreFormType,
        src: '',
        droppable: false,
        resizable: true,
        tagName: 'iframe',
        attributes: {
          'data-type': wayMoreFormType,
        },
        traits,
      },
      init() {
        this.listenTo(this, 'change:src', this.updateSrc);
      },
      updateSrc(model, src) {
        this.addAttributes({ src });
      },
    },
    view: {
      tagName: 'div',
      events: {
        dblclick: 'onActive',
      },
      init() {
        this.listenTo(this.model, 'change:src', this.updateSrc);
        this.listenTo(
          this.model,
          'change:attributes:scrolling',
          this.updateScrolling,
        );
      },
      updateSrc(model, src) {
        const iframe = this.getIframe();

        iframe.src = src;
      },
      updateScrolling(model, scrolling) {
        const iframe = this.getIframe();

        iframe.scrolling = scrolling;
      },
      onActive() {
        const onShow = editor.getModel().get('onShow');

        onShow(useToggleTypes.showWayMoreFormModal);
      },
      getIframe() {
        if (!this.iframe) {
          var iframe = document.createElement('iframe');
          iframe.src = this.model.get('src');
          iframe.style.height = '100%';
          iframe.style.width = '100%';
          iframe.style.border = 0;
          this.iframe = iframe;
        }

        return this.iframe;
      },
      render(...args) {
        defaultType.view.prototype.render.apply(this, args);

        this.el.appendChild(this.getIframe());

        return this;
      },
    },
    isComponent(el) {
      if (checkComponentType(el, wayMoreFormType)) {
        return { type: wayMoreFormType, src: el.src };
      }
    },
  });
};

const addWayMoreProduct = (editor, { wayMoreProductType }) => {
  editor.DomComponents.addType(wayMoreProductType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Waymore product',
      },
    },
    view: {
      events: {
        dblclick: 'onActive',
      },
      onActive(e) {
        const onShow = editor.getModel().get('onShow');

        if (
          !e ||
          checkComponentType(e.target, wayMoreProductType) ||
          checkComponentType(e.target, 'container') ||
          checkComponentType(e.target, 'column')
        ) {
          onShow(useToggleTypes.showWayMoreProductModal);

          editor.select(this.model.closest('.product-section'));
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, wayMoreProductType)) {
        return { type: wayMoreProductType };
      }
    },
  });
};

const addWayMoreCalendar = (editor, { wayMoreCalendarType }) => {
  editor.DomComponents.addType(wayMoreCalendarType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Waymore calendar',
      },
    },
    view: {
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        const onShow = editor.getModel().get('onShow');

        onShow(useToggleTypes.showWayMoreCalendarModal);
      },
    },
    isComponent(el) {
      if (checkComponentType(el, wayMoreCalendarType)) {
        return { type: wayMoreCalendarType };
      }
    },
  });
};

export default (editor, config) => {
  addWayMoreForm(editor, config);
  addWayMoreProduct(editor, config);
  addWayMoreCalendar(editor, config);
};
