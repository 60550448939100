import { useState } from 'react';
import { GOOGLE_FONTS_WEIGHTS } from 'appConstants';
import { useDispatch } from 'react-redux';
import { updateTemplate, updateSystemTemplate } from 'store/actions';
import { useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { showErrorMessage, showSuccessMessage, getErrorText } from 'helpers';
import { useIntl } from 'react-intl';

export const useFont = () => {
  const reduxDispatch = useDispatch();
  const intl = useIntl();
  const { id } = useParams();
  const { system } = useQuery();

  const [isLoading, setIsLoading] = useState(false);

  const updateRequest = system ? updateSystemTemplate : updateTemplate;

  const saveFontSetting = async (payload) => {
    try {
      setIsLoading(true);

      await reduxDispatch(updateRequest({ id, payload })).unwrap();

      showSuccessMessage(
        intl.formatMessage({
          id: 'page-settings-modal-fonts.selected-items.fond-added-message',
        }),
      );
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFontSetting = async (payload) => {
    try {
      setIsLoading(true);

      await reduxDispatch(updateRequest({ id, payload })).unwrap();

      showSuccessMessage(
        intl.formatMessage({
          id: 'page-settings-modal-fonts.selected-items.fond-removed-message',
        }),
      );
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const generateGoogleFontUrl = (family, subsets, variantsList) => {
    const wghtList = [];

    GOOGLE_FONTS_WEIGHTS.forEach((item) => {
      if (variantsList.includes(item)) {
        wghtList.push(
          item === 'regular'
            ? '0,400'
            : item === 'italic'
            ? '1,400'
            : item.indexOf('italic') === 3
            ? `1,${item.replace('italic', '')}`
            : `0,${item}`,
        );
      }
    });

    const fontFamily = family.trim().replace(/\+/g, ' ');
    const fontWght = wghtList.join(';');
    const fontSubset = subsets.join(',');
    const fontUrl = `https://fonts.googleapis.com/css2?family=${fontFamily}:ital,wght@${fontWght}&subset=${fontSubset}&display=swap`;

    return fontUrl;
  };

  const addStylesheetURL = (fonts, selectedFamily = '') => {
    if (fonts) {
      const fontsResult = selectedFamily
        ? fonts.filter(({ family }) => family === selectedFamily)
        : fonts;

      fontsResult &&
        fontsResult.forEach(({ family }) => {
          let fontDetail = '';

          switch (family) {
            case 'Buda':
              fontDetail = 'wght@300';
              break;

            case 'Molle':
              fontDetail = 'wght@400';
              break;

            case 'Sunflower':
              fontDetail = 'wght@300';
              break;

            case 'UnifrakturCook':
              fontDetail = 'wght@700';
              break;

            default:
              fontDetail = 'wght@400';
          }

          const link = document.createElement('link');
          const href = `https://fonts.googleapis.com/css2?family=${family}:${fontDetail}&display=swap`;

          link.rel = 'stylesheet';
          link.dataset.id = 'cgfont';
          link.href = href;

          const shouldAddFont =
            document
              .querySelector('head')
              .querySelectorAll(`link[href='${href}']`).length === 0;

          if (shouldAddFont) {
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        });
    }
  };

  return {
    isLoading,
    saveFontSetting,
    deleteFontSetting,
    generateGoogleFontUrl,
    addStylesheetURL,
  };
};
