import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function LoadingSpinner({ className, ...rest }) {
  return (
    <span
      aria-label="loading"
      className={cx('dnd-loading-spinner icon icon-spinner', className)}
      {...rest}
    />
  );
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};

export default LoadingSpinner;
