import { FONTS, BLOCKS_CATEGORIES } from 'appConstants';
import {
  generateSelectedFontsList,
  setButtonClickAttribute,
  setButtonSector,
  setColumnSector,
  setIconSector,
  setImageBlockSector,
  setImageSector,
  setSectionSector,
  setTextSector,
  setVideoSector,
  setWayMoreFormSector,
  setWrapperSector,
} from '../../utils';
import {
  addNewNavbarItemTrait,
  animationDelayTrait,
  animationDurationTrait,
  animationRepeatTrait,
  animationTypeTrait,
} from '../Panels/custom-traits';

export const listeners = (editor, selectedFontsList) => {
  const pn = editor.Panels;

  editor
    .getWrapper()
    .set('stylable', [
      'background',
      'background-color',
      'background-image',
      'background-repeat',
      'background-attachment',
      'background-position',
      'background-size',
      'overflow',
      'overflow-x',
      'overflow-y',
    ]);

  editor.Canvas.setCustomBadgeLabel((component) => {
    const element = component.getView().el;

    if (!element || component.get('wrapper')) {
      return component.getName();
    }

    return `${component.getName()} <small>${element.offsetWidth}x${
      element.offsetHeight
    }<small>`;
  });

  const addCustomToolbarButtons = (component) => {
    const model = component.model;
    const id = component.id;
    const init = model.prototype.init;

    editor.DomComponents.addType(id, {
      model: {
        init(...args) {
          init.apply(this, args);
          const toolbar = this.get('toolbar');
          const blockType = this.get('type');

          toolbar.forEach((button) => {
            switch (button.command) {
              case 'tlb-move':
                button.attributes = {
                  ...button.attributes,
                  title: 'Move',
                };
                break;
              case 'tlb-clone':
                button.attributes = {
                  ...button.attributes,
                  title: 'Duplicate',
                };
                break;
              case 'tlb-delete':
                button.attributes = {
                  ...button.attributes,
                  title: 'Delete',
                };
                break;
              case 'tui-image-editor':
                button.attributes = {
                  ...button.attributes,
                  title: 'Edit image',
                };
                break;
              case 'custom-code-modal':
                button.attributes.title = 'Edit custom code';
                break;
              case 'custom-code-lock':
                button.attributes.title =
                  'Edit custom code is not enabled in your plan';
                break;
              default:
                // because "select parent" button doesn't have string command
                // we use default case
                if (button.attributes) {
                  button.attributes = {
                    ...button.attributes,
                    title: 'Select parent component',
                  };
                }
                break;
            }
          });

          // Remove edit image
          if (blockType === 'image') {
            const index = toolbar.findIndex(
              (item) => item.command === 'tui-image-editor',
            );

            if (index !== -1) {
              toolbar.splice(index, 1);
            }
          }

          // Remove duplicate button for email input | form
          if (blockType === 'email-input' || blockType === 'form') {
            const index = toolbar.findIndex(
              (item) => item.command === 'tlb-clone',
            );

            if (index !== -1) {
              toolbar.splice(index, 1);
            }
          }

          // Remove duplicate button for phone input
          if (blockType === 'phone-input') {
            const index = toolbar.findIndex(
              (item) => item.command === 'tlb-clone',
            );

            if (index !== -1) {
              toolbar.splice(index, 1);
            }
          }

          // adding edit product button
          const editProductCommand = 'edit-product';
          const hasEditProductButton = toolbar.some(function (button) {
            return button.command === editProductCommand;
          });

          if (!hasEditProductButton && blockType === 'waymore-product') {
            toolbar.push({
              attributes: {
                class: 'icon icon-edit',
                title: 'Edit product',
              },
              command: editProductCommand,
            });
          }

          // Adding toggle drag mode button
          const toggleDragModeCommand = 'toggle-abs-move';
          const draggable = this.get('draggable');
          const hasToggleDragModeButton = toolbar.some(function (button) {
            return button.command === toggleDragModeCommand;
          });

          if (draggable && !hasToggleDragModeButton && blockType !== 'image') {
            toolbar.push({
              attributes: {
                class: 'icon icon-drag-mode',
                title: 'Enable drag mode for this',
              },
              command: toggleDragModeCommand,
            });
          }

          // Adding move up button
          const hasMoveUpButton = toolbar.some(function (button) {
            return button.command === 'move-up-block';
          });

          if (
            !hasMoveUpButton &&
            blockType !== 'circle-shape' &&
            blockType !== 'square-shape' &&
            blockType !== 'navbar-container' &&
            blockType !== 'navbar-nav-menu' &&
            blockType !== 'lory-slider-frame' &&
            blockType !== 'lory-slider-slides' &&
            blockType !== 'lory-slider-slide' &&
            blockType !== 'lory-slider-prev' &&
            blockType !== 'lory-slider-next' &&
            this.get('name') !== 'Floating image'
          ) {
            toolbar.push({
              attributes: {
                class: 'icon-move-selection-up',
                title: 'Move up',
              },
              command: 'move-up-block',
            });
          }

          // Adding move down button
          const hasMoveDownButton = toolbar.some(function (button) {
            return button.command === 'move-down-block';
          });

          if (
            !hasMoveDownButton &&
            blockType !== 'circle-shape' &&
            blockType !== 'square-shape' &&
            blockType !== 'navbar-container' &&
            blockType !== 'navbar-nav-menu' &&
            blockType !== 'lory-slider-frame' &&
            blockType !== 'lory-slider-slides' &&
            blockType !== 'lory-slider-slide' &&
            blockType !== 'lory-slider-prev' &&
            blockType !== 'lory-slider-next' &&
            this.get('name') !== 'Floating image'
          ) {
            toolbar.push({
              attributes: {
                class: 'icon-move-selection-down',
                title: 'Move down',
              },
              command: 'move-down-block',
            });
          }

          // Remove clone for slider images
          if (
            blockType === 'lory-slider-slide' ||
            blockType === 'lory-slider-prev' ||
            blockType === 'lory-slider-next'
          ) {
            const index = toolbar.findIndex(
              (item) => item.command === 'tui-image-editor',
            );

            if (index !== -1) {
              toolbar.splice(index, 1);
            }
          }

          // Adding create custom block button
          const createBlockCommand = 'create-block';
          const hasCreateBlockButton = toolbar.some(function (button) {
            return button.command === createBlockCommand;
          });

          // Adding save as custom module button when block have children
          if (
            !hasCreateBlockButton &&
            ((this.get('tagName') === 'div' && blockType === '') ||
              blockType === 'box' ||
              blockType === 'waymore-product' ||
              blockType === 'tabs' ||
              blockType === 'table' ||
              blockType === 'cell' ||
              blockType === 'form' ||
              blockType === 'column' ||
              blockType === 'section')
          ) {
            toolbar.push({
              attributes: {
                class: 'icon icon-save',
                title: 'Save as Custom Module',
              },
              command: createBlockCommand,
            });
          }

          // Adding block settings box button
          const showBlockSettingsBoxCommand = 'show-block-settings-box';
          const hasBlockSettingsBoxButton = toolbar.some(function (button) {
            return button.command === showBlockSettingsBoxCommand;
          });

          if (!hasBlockSettingsBoxButton && blockType === 'form') {
            toolbar.push({
              attributes: {
                class: 'icon icon-settings',
                title: 'Show form settings',
              },
              command: showBlockSettingsBoxCommand,
            });
          }
        },
      },
    });
  };

  editor.DomComponents.getTypes().forEach(addCustomToolbarButtons);
  editor.on('component:type:add', addCustomToolbarButtons);

  editor.on('component:resize', () => {
    const cmp = editor.getSelected();

    if (cmp.get('type') === 'linked-image') {
      const cmpStyle = cmp.getStyle();

      cmp.findType('linked-image-image')[0].setStyle({
        width: `${cmpStyle?.width || '100%'}`,
        height: `${cmpStyle?.height || 'auto'}`,
      });
    }
  });

  editor.on('component:styleUpdate', (cmp) => {
    if (cmp.get('type') === 'linked-image') {
      const cmpStyle = cmp.getStyle();

      cmp.findType('linked-image-image')[0].setStyle({
        width: `${cmpStyle?.width || '100%'}`,
        height: `${cmpStyle?.height || 'auto'}`,
      });
    }
  });

  editor.on('component:styleUpdate:float', (model, style) => {
    let marginTop = '0';
    let marginRight = '0';
    let marginBottom = '0';
    let marginLeft = '0';

    if (model.getStyle()?.margin) {
      const [
        currentMarginTop,
        currentMarginRight,
        currentMarginBottom,
        currentMarginLeft,
      ] = model.getStyle()?.margin.split(' ');

      marginTop = currentMarginTop;
      marginRight = currentMarginRight;
      marginBottom = currentMarginBottom;
      marginLeft = currentMarginLeft;
    }

    if (style?.style?.float === 'inherit') {
      model.setStyle({
        ...model.getStyle(),
        display: 'block',
        margin: `${marginTop} auto ${marginBottom} auto`,
      });
    } else {
      model.setStyle({
        ...model.getStyle(),
        margin: `${marginTop} ${
          marginRight === 'auto' ? '0' : marginRight
        } ${marginBottom} ${marginLeft === 'auto' ? '0' : marginRight}`,
      });
    }
  });

  editor.on('component:update', (cmp) => {
    if (cmp.getName() === 'Button') {
      setButtonClickAttribute(editor, 'design');
    }
  });

  // adding listener for undo/redo changes
  // here undo/redo buttons disabled/enabled depending whether they
  // have actions to undo/redo or not
  editor.on('update', () => {
    const um = editor.UndoManager;
    const undoButton = editor.Panels.getButton('canvas-history', 'undo');
    const redoButton = editor.Panels.getButton('canvas-history', 'redo');

    if (um.hasUndo()) {
      undoButton.set('disable', false);
    } else {
      undoButton.set('disable', true);
    }

    if (um.hasRedo()) {
      redoButton.set('disable', false);
    } else {
      redoButton.set('disable', true);
    }
  });

  const weakMap = new WeakMap();

  editor.on('run:preview', () => {
    editor.DomComponents.getWrapper().onAll((comp) => {
      const attr = { ...comp.attributes };
      weakMap.set(comp, attr);
      const noEdit = {
        ...attr,
        editable: false,
        collection: false,
        droppable: false,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: false,
        selectable: false,
        hoverable: false,
        resizable: false,
      };
      comp.set(noEdit);
      setButtonClickAttribute(editor, 'preview');
    });
  });

  editor.on('stop:preview', () => {
    editor.DomComponents.getWrapper().onAll((comp) => {
      comp.set(weakMap.get(comp));
    });
    setButtonClickAttribute(editor, 'design');
  });

  // do not select classes when user selects component
  editor.on('selector:add', (selector) =>
    selector.set({
      active: false,
    }),
  );

  editor.on('block:drag:stop', (model) => {
    editor.select(model);
  });

  editor.on('component:remove:before', (model) => {
    if (model.getName() === 'Form') {
      editor.Css.remove('.form-content, .state-success, .state-error');
      const setFormState = editor?.getModel()?.get('setFormState');

      setFormState('normal');

      if (document.querySelector('.block-settings-box')) {
        document.querySelector('.block-settings-box').style.display = 'none';
      }
    }
  });

  editor.on('component:remove:before', (model) => {
    if (model.get('type') === 'tabs-list-item') {
      const removedTabId = model.getId();
      const tabsListItems = model
        .closestType('tabs')
        .findType('tabs-list-item');
      const removedTabIndex = tabsListItems.findIndex(
        ({ ccid }) => ccid === removedTabId,
      );
      const tabsListItemsIds = tabsListItems
        .map(({ ccid }) => ccid)
        .filter((item) => item !== removedTabId);

      // Active first tab
      if (
        tabsListItemsIds &&
        editor.Canvas.getDocument().getElementById(`${tabsListItemsIds[0]}`)
      ) {
        editor.Canvas.getDocument()
          .getElementById(`${tabsListItemsIds[0]}`)
          .classList.add('is--active');
      }

      // Remove tab content item
      const tabsContentItems = model
        .closestType('tabs')
        .findType('tabs-content-item');

      const removedTabContent = tabsContentItems[removedTabIndex].remove();

      const tabsContentItemsIds = tabsContentItems
        .map(({ ccid }) => ccid)
        .filter((item) => item !== removedTabContent.getId());

      // Active first tab content
      if (
        tabsContentItemsIds &&
        editor.Canvas.getDocument().getElementById(`${tabsContentItemsIds[0]}`)
      ) {
        editor.Canvas.getDocument()
          .getElementById(`${tabsContentItemsIds[0]}`)
          .classList.add('is--active');
      }
    }
  });

  editor.on('load', () => {
    const wrapper = editor.getWrapper();

    const modifiedSelectedFontsList = selectedFontsList.map(
      ({ name, value, category, ...rest }) => {
        const newValue = /\s/g.test(value.trim()) ? `"${value}"` : value;

        return { name, value: `${newValue}, ${category}`, category, ...rest };
      },
    );

    wrapper.set(
      'fontsList',
      JSON.stringify([...FONTS, ...modifiedSelectedFontsList]),
    );
    wrapper.set('attributes', {
      id: 'grapes-iframe-body',
    });
    setButtonClickAttribute(editor, 'design');
  });

  editor.on('block:drag:start', (target) => {
    if (
      target &&
      (target?.id === 'single-floating-image' ||
        target?.id === 'circle-shape' ||
        target?.id === 'square-shape')
    ) {
      editor.getModel().set('dmode', 'absolute');
    }
  });

  editor.on('block:drag:stop', (target) => {
    if (
      target &&
      typeof target.get === 'function' &&
      (target?.get('type') === 'image' ||
        target?.get('type') === 'circle-shape' ||
        target?.get('type') === 'square-shape') &&
      (target?.get('name') === 'Floating image' ||
        target?.get('name') === 'Circle Shape' ||
        target?.get('name') === 'Square Shape')
    ) {
      editor.getModel().set('dmode', '');
    }
  });

  // Component and Quick Settings
  editor.on('load', () => {
    editor.on('component:selected', () => {
      const selected = editor?.getSelected();
      const quickSector = document.querySelector('.gjs-sm-sector__quickSector');
      const quickSectorProperties = document.querySelector(
        '.gjs-sm-sector__quickSector > .gjs-sm-properties',
      );
      const selectImage = document.querySelector(
        '.gjs-sm-sector__quickSector .select-image-btn',
      );
      const selectProduct = document.querySelector(
        '.gjs-sm-sector__quickSector .select-product-btn',
      );
      const { 'data-drag-status': dataDragStatus } = selected.getAttributes();
      const discountCodeWrapper = selected.closest(
        '[data-gjs-type="discount-code"]',
      );

      if (dataDragStatus === 'absolute') {
        selected.set('dmode', 'absolute');
      }

      selected.get('components').each((child) => {
        // Discount Code children
        if (discountCodeWrapper) {
          child.set({
            selectable: false,
            editable: false,
            collection: false,
            droppable: false,
            draggable: false,
            badgable: false,
            copyable: false,
            removable: false,
            hoverable: false,
            resizable: false,
            highlightable: false,
            layerable: false,
          });
        }
      });

      generateSelectedFontsList(editor);

      if (
        selected?.get('type') === 'form' ||
        (selected?.closest('form') &&
          selected?.closest('form').get('type') === 'form')
      ) {
        editor.BlockManager.getCategories().each((ctg) => {
          if (ctg.id === BLOCKS_CATEGORIES.blocks[2]) {
            ctg.set('open', true);
          } else {
            ctg.set('open', false);
          }
        });
      }

      if (
        selected?.closest('form') &&
        selected?.closest('form').get('type') === 'form'
      ) {
        const setFormState = editor?.getModel()?.get('setFormState');

        if (editor?.getSelected().closest('form')) {
          const { state } = editor
            .getSelected()
            .closest('form')
            .getAttributes();

          setFormState(state);
        }

        editor.runCommand('show-block-settings-box', { status: 'open' });
      } else {
        editor.runCommand('show-block-settings-box', { status: '' });
      }

      const productBlock = selected?.view?.el?.closest('.product-section');

      // Show button under Quick Settings to show product modal
      if (selected && productBlock && quickSectorProperties && !selectProduct) {
        const selectProductBtnWrapper = document.createElement('div');
        const selectProductBtn = document.createElement('button');
        const productName = productBlock?.dataset?.productName || '';

        selectProductBtnWrapper.className =
          'gjs-field gjs-field-full-width select-product-btn';
        selectProductBtn.textContent = 'Edit product';
        selectProductBtn.title = productName;
        selectProductBtn.addEventListener('click', async function (e) {
          e.preventDefault();

          editor.select(productBlock);
          editor.runCommand('edit-product');
        });
        selectProductBtnWrapper.append(selectProductBtn);
        quickSectorProperties.prepend(selectProductBtnWrapper);
      }

      // Show button under Quick Settings to show image/icon modal
      if (
        selected &&
        quickSectorProperties &&
        !selectImage &&
        (selected?.get('type') === 'image' ||
          selected?.get('type') === 'icon-wrapper')
      ) {
        const selectedType = selected?.get('type');
        const selectImageBtnWrapper = document.createElement('div');
        const selectImageBtn = document.createElement('button');
        const editImageBtn = document.createElement('button');

        editImageBtn.textContent = 'Edit image';
        selectImageBtnWrapper.className =
          'gjs-field gjs-field-full-width select-image-btn';
        selectImageBtn.textContent =
          selectedType === 'icon-wrapper' ? 'Choose icon' : 'Choose image';
        if (!selected.view.el.src.includes('data:image/svg+xml;base64')) {
          selectImageBtn.textContent =
            selectedType === 'icon-wrapper' ? 'Change icon' : 'Change image';
        }
        selectImageBtn.addEventListener('click', async function (e) {
          e.preventDefault();

          if (selectedType === 'icon-wrapper') {
            editor.runCommand('open-assets', { isIcon: true });
          } else {
            editor.runCommand('open-assets', { isImage: true });
          }
        });

        editImageBtn.addEventListener('click', async function (e) {
          e.preventDefault();

          editor.runCommand('tui-image-editor');
        });
        selectImageBtnWrapper.append(selectImageBtn);
        if (selectedType === 'image') {
          selectImageBtnWrapper.append(editImageBtn);
        }
        quickSectorProperties.prepend(selectImageBtnWrapper);
      }

      const setIsComponentSettingsCollapsed = editor
        .getModel()
        .get('setIsComponentSettingsCollapsed');

      if (!quickSector) {
        setIsComponentSettingsCollapsed(true);
      } else {
        if (
          selected?.get('type') === 'image' ||
          selected?.get('type') === 'link-button' ||
          selected?.get('type') === 'button'
        ) {
          setIsComponentSettingsCollapsed(true);
        } else {
          setIsComponentSettingsCollapsed(false);
        }
      }

      if (quickSector && quickSectorProperties?.childNodes.length === 0) {
        quickSector.style.display = 'none';
        setIsComponentSettingsCollapsed(true);
      }
    });
  });

  editor.on('component:selected', (selected) => {
    const selectedType = selected.get('type');
    const openSmBtn = pn.getButton('traits-tabs', 'open-sm-custom');
    const openLayersBtn = pn.getButton('traits-tabs', 'open-layers');
    const animationTypeValue =
      selected.get('attributes')?.['data-animation-type'] || 'none';

    if (
      selectedType === 'navbar' &&
      !selected.getTrait('add-new-navbar-item-type')
    ) {
      selected.addTrait(addNewNavbarItemTrait);
    }

    if (!selected.getTrait('data-animation-type')) {
      selected.addTrait(animationTypeTrait);
      selected.addTrait(animationDelayTrait);
      selected.addTrait(animationDurationTrait);
      selected.addTrait(animationRepeatTrait);
    }

    if (selected?.getTrait('animationDelay')?.view?.$el[0]) {
      selected.getTrait('animationDelay').view.$el[0].style.display =
        animationTypeValue !== 'none' ? 'block' : 'none';
    }

    if (selected?.getTrait('animationDuration')?.view?.$el[0]) {
      selected.getTrait('animationDuration').view.$el[0].style.display =
        animationTypeValue !== 'none' ? 'block' : 'none';
    }

    if (selected?.getTrait('animationRepeat')?.view?.$el[0]) {
      selected.getTrait('animationRepeat').view.$el[0].style.display =
        animationTypeValue !== 'none' ? 'block' : 'none';
    }

    if (selectedType === 'social-icon') {
      selected.set({
        editable: false,
      });
    }

    if (openLayersBtn && openLayersBtn.get('active')) {
      document.getElementById('component-settings').style.display = 'none';
    }

    // Don't switch when the Layer Manager is on or
    // there is no selected component
    if (openLayersBtn && !openLayersBtn.get('active')) {
      openSmBtn.set('active', true);
    }

    // Remove Move up & Move down actions from toolbar
    if (
      selected.closestType('countdown') ||
      selected.closestType('tabs') ||
      selected.closestType('table') ||
      selected.closestType('lory-slider') ||
      ['section-row', 'container'].includes(selected.get('type'))
    ) {
      const moveDownPos = selected
        .get('toolbar')
        .findIndex((item) => item?.command === 'move-up-block');

      selected.get('toolbar').splice(moveDownPos, 2);
    }

    // Image have default link
    if (selectedType === 'image' && selected.isChildOf('link')) {
      const linkHref = selected?.parent().get('attributes').href || '';
      const linkTarget = selected?.parent().get('attributes').target || '_self';

      selected.getTrait('imageLinkTarget').setTargetValue(linkTarget);

      if (linkHref) {
        selected.getTrait('imageLink').setTargetValue(linkHref);
      }
    }

    // Image have default link
    if (selectedType === 'image' && selected.isChildOf('link')) {
      const linkHref = selected?.parent().get('attributes').href || '';
      const linkTarget = selected?.parent().get('attributes').target || '_self';

      selected.getTrait('imageLinkTarget').setTargetValue(linkTarget);

      if (linkHref) {
        selected.getTrait('imageLink').setTargetValue(linkHref);
      }
    }

    // Show extra animation options
    selected.traits.forEach((trait) => {
      if (
        (trait.attributes.name === 'animationDelay' ||
          trait.attributes.name === 'animationDuration' ||
          trait.attributes.name === 'animationRepeat') &&
        selected.getAttributes().value &&
        selected.getAttributes().value !== 'none'
      ) {
        trait.set('attributes', {
          style: 'display: block',
        });
      }
    });

    // Remove title trait
    if (selectedType === 'space' || selectedType === 'box') {
      selected.removeTrait('title');
    }

    if (selected.attributes.attributes.isspace || selectedType === 'section') {
      selected.attributes.resizable = true;
    }

    switch (selectedType) {
      case 'wrapper':
        setWrapperSector(editor);
        break;
      case 'video':
        setVideoSector(editor);
        break;
      case 'image-block':
        setImageBlockSector(editor);
        break;
      case 'image':
        setImageSector(editor);
        break;
      case 'text':
        setTextSector(editor);
        break;
      case 'section':
        setSectionSector(editor);
        break;
      case 'column':
        setColumnSector(editor);
        break;
      case 'icon-wrapper':
        setIconSector(editor);
        break;
      case 'link-button':
      case 'button':
        setButtonSector(editor);
        break;
      case 'waymore-form':
        setWayMoreFormSector(editor);
        break;
      default: {
        if (editor.StyleManager.getSector('quickSector')) {
          editor.StyleManager.removeSector('quickSector');
        }
      }
    }

    // Hide display property
    if (
      selected.getName() === 'Form content' ||
      selected.getName() === 'Thank you' ||
      selected.getName() === 'Error'
    ) {
      if (editor?.StyleManager?.getProperty('general', 'display')) {
        editor.StyleManager.getProperty(
          'general',
          'display',
        ).view.$el[0].style.display = 'none';
      }
    } else {
      if (editor?.StyleManager?.getProperty('general', 'display')) {
        editor.StyleManager.getProperty(
          'general',
          'display',
        ).view.$el[0].style.display = 'block';
      }
    }
  });

  editor.on('style:property:update', (property) => {
    const wrapper = editor.getWrapper();
    if (property.property.id === 'font-family' && property.value) {
      const font = Array.from(JSON.parse(wrapper.get('fontsList'))).find(
        ({ value }) => value === property.value,
      );

      if (font?.url) {
        const onAddFont = editor.getModel().get('onAddFont');

        onAddFont(font);
        editor.runCommand('add-font', { url: font.url });
      }
    }
  });

  editor.on('rte:enable', (element, toolbar) => {
    const rect = element?.$el[0].getBoundingClientRect();

    if (toolbar) {
      toolbar.actionbar.parentNode.classList.remove('gjs-rte-toolbar__reverse');
    }

    if (toolbar && element?.$el[0].offsetWidth < 250 && rect.left > 250) {
      toolbar.actionbar.parentNode.classList.add('gjs-rte-toolbar__reverse');
    }
  });

  editor.Panels.getButton('traits-tabs', 'open-sm-custom').set('active', true);
  editor.on('load', () => {
    const wrapper = editor.getWrapper();
    const setFormState = editor?.getModel()?.get('setFormState');

    if (wrapper.findType('form')) {
      editor.select(wrapper.find('form')[0]);

      editor.runCommand('change-block-settings-box-status', {
        state: 'normal',
      });

      setFormState('normal');
    }

    editor.Panels.getButton('blocks-tabs', 'blocks').set('active', true);
    editor.Panels.getButton('canvas-controls', 'sw-visibility').set(
      'active',
      false,
    );
  });

  document.addEventListener('keydown', function (event) {
    if (
      event.shiftKey &&
      event.ctrlKey &&
      (event.keyCode === 187 || event.keyCode === 107)
    ) {
      event.preventDefault();
      editor.runCommand('editor-zoom-in');
    }

    if (
      event.shiftKey &&
      event.ctrlKey &&
      (event.key === '-' || event.key === '_')
    ) {
      event.preventDefault();
      editor.runCommand('editor-zoom-out');
    }

    if (
      event.shiftKey &&
      event.ctrlKey &&
      (event.key === '0' || event.key === ')')
    ) {
      event.preventDefault();
      editor.runCommand('editor-zoom-reset');
    }
  });
};
