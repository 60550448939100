import {
  CURSOR_OPTIONS,
  FLOAT_OPTIONS,
  FONTS,
  TEXT_ALIGN_OPTIONS,
  VISIBILITY_OPTIONS,
} from 'appConstants';

export const styleManagerConfig = {
  sectors: [
    {
      name: 'General',
      open: false,
      buildProps: [
        'display',
        'position',
        'top',
        'right',
        'left',
        'bottom',
        'overflow-x',
        'overflow-y',
        'z-index',
        'visibility',
        'cursor',
      ],
      properties: [
        {
          name: 'Display',
          property: 'display',
          type: 'select',
          defaults: 'block',
          options: [
            { value: 'block' },
            { value: 'inline' },
            { value: 'inline-block' },
            { value: 'table' },
            { value: 'flex' },
            { value: 'none' },
          ],
        },
        {
          name: 'Position',
          property: 'position',
          type: 'select',
          defaults: 'static',
          options: [
            { value: 'static' },
            { value: 'relative' },
            { value: 'absolute' },
            { value: 'fixed' },
            { value: 'sticky' },
          ],
        },
        {
          name: 'Float',
          property: 'float',
          type: 'radio',
          defaults: 'none',
          options: FLOAT_OPTIONS,
        },
        { property: 'position', type: 'select' },
        {
          name: 'Visibility',
          property: 'visibility',
          type: 'select',
          defaults: 'visible',
          options: VISIBILITY_OPTIONS,
        },
        {
          name: 'Cursor',
          property: 'cursor',
          type: 'select',
          defaults: 'auto',
          options: CURSOR_OPTIONS,
        },
      ],
    },
    {
      name: 'Dimension',
      open: false,
      buildProps: [
        'width',
        'height',
        'max-width',
        'min-height',
        'margin',
        'padding',
      ],
      properties: [
        {
          id: 'flex-width',
          type: 'integer',
          name: 'Width',
          units: ['px', '%'],
          property: 'flex-basis',
          toRequire: 1,
        },
        {
          property: 'margin',
          properties: [
            { name: 'Top', property: 'margin-top' },
            { name: 'Right', property: 'margin-right' },
            { name: 'Bottom', property: 'margin-bottom' },
            { name: 'Left', property: 'margin-left' },
          ],
        },
        {
          property: 'padding',
          properties: [
            { name: 'Top', property: 'padding-top' },
            { name: 'Right', property: 'padding-right' },
            { name: 'Bottom', property: 'padding-bottom' },
            { name: 'Left', property: 'padding-left' },
          ],
        },
      ],
    },
    {
      name: 'Typography',
      open: false,
      buildProps: [
        'font-size',
        'font-weight',
        'letter-spacing',
        'color',
        'line-height',
        'text-shadow',
      ],
      properties: [
        {
          name: 'Font family',
          type: 'select',
          property: 'font-family',
          default: FONTS[0].value,
        },
        { name: 'Font weight', property: 'font-weight' },
        { name: 'Font color', property: 'color' },
        {
          property: 'text-align',
          type: 'radio',
          defaults: 'left',
          list: TEXT_ALIGN_OPTIONS,
        },
        {
          property: 'text-decoration',
          type: 'radio',
          defaults: 'none',
          list: [
            { value: 'none', name: 'None', className: 'icon icon-close' },
            {
              value: 'underline',
              name: 'underline',
              className: 'icon icon-format-underlined',
            },
            {
              value: 'line-through',
              name: 'Line-through',
              className: 'icon icon-strikethrough',
            },
          ],
        },
        {
          property: 'text-shadow',
          properties: [
            { name: 'X position', property: 'text-shadow-h' },
            { name: 'Y position', property: 'text-shadow-v' },
            { name: 'Blur', property: 'text-shadow-blur' },
            { name: 'Color', property: 'text-shadow-color' },
          ],
        },
      ],
    },
    {
      name: 'Decorations',
      open: false,
      buildProps: [
        'opacity',
        'border-radius-c',
        'background-color',
        'border-radius',
        'border',
        'box-shadow',
        'background',
      ],
      properties: [
        {
          type: 'slider',
          property: 'opacity',
          defaults: 1,
          step: 0.01,
          max: 1,
          min: 0,
        },
        {
          property: 'border-radius',
          properties: [
            { name: 'Top Left', property: 'border-top-left-radius' },
            { name: 'Top Right', property: 'border-top-right-radius' },
            { name: 'Bottom Right', property: 'border-bottom-right-radius' },
            { name: 'Bottom Left', property: 'border-bottom-left-radius' },
          ],
        },
        {
          property: 'box-shadow',
          properties: [
            { name: 'X position', property: 'box-shadow-h' },
            { name: 'Y position', property: 'box-shadow-v' },
            { name: 'Blur', property: 'box-shadow-blur' },
            { name: 'Spread', property: 'box-shadow-spread' },
            { name: 'Color', property: 'box-shadow-color' },
            { name: 'Shadow type', property: 'box-shadow-type' },
          ],
        },
      ],
    },
    {
      name: 'Extra',
      open: false,
      buildProps: ['transition', 'perspective', 'transform'],
      properties: [
        {
          name: 'Perspective',
          property: 'perspective',
          full: true,
        },
      ],
    },
    {
      name: 'Flex',
      open: false,
      properties: [
        {
          name: 'Direction',
          property: 'flex-direction',
          type: 'radio',
          defaults: 'row',
          requires: { display: ['flex'] },
          list: [
            {
              value: 'row',
              name: 'Row',
              className: 'icon icon-flex-direction-row',
              title: 'Row',
            },
            {
              value: 'row-reverse',
              name: 'Row reverse',
              className: 'icon icon-flex-direction-row-reverse',
              title: 'Row reverse',
            },
            {
              value: 'column',
              name: 'Column',
              title: 'Column',
              className: 'icon icon-flex-direction-column',
            },
            {
              value: 'column-reverse',
              name: 'Column reverse',
              title: 'Column reverse',
              className: 'icon icon-flex-direction-column-reverse',
            },
          ],
        },
        {
          name: 'Justify content',
          property: 'justify-content',
          type: 'radio',
          defaults: 'flex-start',
          requires: { display: ['flex'] },
          list: [
            {
              value: 'flex-start',
              title: 'Start',
              className: 'icon icon-justify-content-flex-start',
            },
            {
              value: 'flex-end',
              title: 'End',
              className: 'icon icon-justify-content-flex-end',
            },
            {
              value: 'center',
              title: 'Center',
              className: 'icon icon-justify-content-center',
            },
            {
              value: 'space-between',
              title: 'Space between',
              className: 'icon icon-justify-content-space-between',
            },
            {
              value: 'space-around',
              title: 'Space around',
              className: 'icon icon-justify-content-space-around',
            },
          ],
        },
        {
          name: 'Align items',
          property: 'align-items',
          type: 'radio',
          defaults: 'stretch',
          requires: { display: ['flex'] },
          list: [
            {
              value: 'flex-start',
              title: 'Start',
              className: 'icon icon-align-items-flex-start',
            },
            {
              value: 'flex-end',
              title: 'End',
              className: 'icon icon-align-items-flex-end',
            },
            {
              value: 'center',
              title: 'Center',
              className: 'icon icon-align-items-center',
            },
            {
              value: 'stretch',
              title: 'Stretch',
              className: 'icon icon-align-items-stretch',
            },
            {
              value: 'baseline',
              title: 'Baseline',
              className: 'icon icon-align-items-baseline',
            },
          ],
        },
        {
          name: 'Align content',
          property: 'align-content',
          type: 'radio',
          defaults: 'flex-start',
          requires: { display: ['flex'] },
          list: [
            {
              value: 'flex-start',
              title: 'Start',
              className: 'icon icon-align-content-start',
            },
            {
              value: 'flex-end',
              title: 'End',
              className: 'icon icon-align-content-end',
            },
            {
              value: 'center',
              title: 'Center',
              className: 'icon icon-align-content-center',
            },
            {
              value: 'stretch',
              title: 'Stretch',
              className: 'icon icon-align-content-stretch',
            },
            {
              value: 'space-between',
              title: 'Space between',
              className: 'icon icon-align-content-space-between',
            },
            {
              value: 'space-around',
              title: 'Space around',
              className: 'icon icon-align-content-space-around',
            },
          ],
        },
        {
          name: 'Wrap',
          property: 'flex-wrap',
          type: 'radio',
          defaults: 'nowrap',
          requires: { display: ['flex'] },
          list: [
            {
              value: 'nowrap',
              title: 'No Wrap',
              className: 'icon icon-flex-nowrap',
            },
            {
              value: 'wrap',
              title: 'Wrap',
              className: 'icon icon-flex-wrap',
            },
            {
              value: 'wrap-reverse',
              title: 'Wrap Reverse',
              className: 'icon icon-flex-wrap-reverse',
            },
          ],
        },
        {
          name: 'Order',
          property: 'order',
          type: 'integer',
          defaults: 0,
          min: 0,
          requiresParent: { display: ['flex'] },
        },
        {
          name: 'Flex',
          property: 'flex',
          type: 'composite',
          requiresParent: { display: ['flex'] },
          properties: [
            {
              name: 'Grow',
              property: 'flex-grow',
              type: 'integer',
              defaults: 0,
              min: 0,
            },
            {
              name: 'Shrink',
              property: 'flex-shrink',
              type: 'integer',
              defaults: 0,
              min: 0,
            },
            {
              name: 'Basis',
              property: 'flex-basis',
              type: 'integer',
              units: ['px', '%', ''],
              unit: '',
              defaults: 'auto',
            },
          ],
        },
        {
          name: 'Align',
          property: 'align-self',
          type: 'radio',
          defaults: 'auto',
          requiresParent: { display: ['flex'] },
          list: [
            {
              value: 'auto',
              name: 'Auto',
            },
            {
              value: 'flex-start',
              title: 'Start',
              className: 'icon icon-align-items-flex-start',
            },
            {
              value: 'flex-end',
              title: 'End',
              className: 'icon icon-align-items-flex-end',
            },
            {
              value: 'center',
              title: 'Center',
              className: 'icon icon-align-items-center',
            },
            {
              value: 'stretch',
              title: 'Stretch',
              className: 'icon icon-align-items-stretch',
            },
          ],
        },
      ],
    },
  ],
};

export const baseCss = `
  * {
    box-sizing: border-box;
  }
  html, body, [data-gjs-type=wrapper] {
    min-height: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }
  body {
    margin: 0;
    height: 100%;
    background-color: #fff;
  }
  body [data-gjs-type=text] > :not(font)[data-gjs-type=text] {
    font-family: inherit;
  }
  input[type=text],
  input[type=email],
  input[type=radio],
  input[type=checkbox],
  button[type=submit],
  select,
  textarea {
    font-family: Arial, Helvetica, sans-serif;
  }

  * ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1)
  }

  * ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2)
  }

  * ::-webkit-scrollbar {
    width: 10px
  }
`;

export const canvasCss = `
  .gjs-selected {
    outline-offset: 0 !important;
  }

  [data-gjs-type=wrapper] {
    padding-bottom: 10px;
  }

  [data-gjs-type="column"]:empty:before,
  [data-gjs-type="box"]:empty:before {
    background-color: #ddd;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    opacity: 0.3;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    content: "Drop something here";
  }

  [data-gjs-type="box"]:empty:before {
    height: auto;
  }
`;

export const protectedCss = '* { box-sizing: border-box; } body { margin: 0; }';
