import { checkComponentType, setRuleStyle } from 'helpers';
import {
  FORM_STYLES,
  FORM_DARK_ATTRIBUTES,
  FORM_DEFAULT_ATTRIBUTES,
  FORM_MINIMAL_ATTRIBUTES,
} from './constants';
import {
  applyDarkStyle,
  applyDefaultStyle,
  applyMinimalStyle,
  customFieldsModal,
  getFormButtonSelector,
  getFormErrorStateSelector,
  getFormInputPlaceholderSelector,
  getFormInputSelector,
  getFormLabelSelector,
  getFormSuccessStateSelector,
  getFromContentSelector,
} from './utils';

const nameTrait = {
  name: 'name',
  label: 'Name',
};

const placeholderTrait = {
  name: 'placeholder',
  label: 'Placeholder',
};

const requiredTrait = {
  type: 'checkbox',
  name: 'required',
  label: 'Required',
};

const autofocusTrait = {
  type: 'checkbox',
  name: 'autofocus',
  label: 'Autofocus',
};

const hideLabelTrait = {
  type: 'checkbox',
  name: 'hideLabel',
  label: 'Hide label',
};

const addForm = (editor, { formType }) => {
  const domc = editor.DomComponents;

  const traits = [
    {
      type: 'select',
      label: 'Form Style',
      name: 'formstyle',
      changeProp: true,
      options: FORM_STYLES.map((style) => ({ value: style, name: style })),
    },
    {
      type: 'color',
      name: 'placeholdercolor',
      label: 'Placeholder color',
    },
    {
      type: 'checkbox',
      name: 'showlabels',
      label: 'Show Labels',
      changeProp: true,
    },
    {
      type: 'number',
      name: 'maxwidth',
      label: 'Box minimum width (px)',
      changeProp: true,
      min: 300,
      max: 1920,
    },
    {
      type: 'number',
      name: 'labelmargin',
      label: 'Label distance (px)',
      changeProp: true,
      min: 0,
      max: 50,
    },
    {
      type: 'number',
      name: 'rowsgap',
      label: 'Rows Gap (px)',
      changeProp: true,
      min: 0,
      max: 50,
    },
    {
      type: 'color',
      name: 'formbgcolor',
      label: 'Form Container Color',
      changeProp: true,
    },
    {
      type: 'color',
      name: 'inputbgcolor',
      label: 'Input Field Background Color',
      changeProp: true,
    },
    {
      type: 'color',
      name: 'inputbordercolor',
      label: 'Input Field Border',
      changeProp: true,
    },
    {
      type: 'color',
      name: 'labelcolor',
      label: 'Label Color',
      changeProp: true,
    },
    {
      type: 'color',
      name: 'buttonbgcolor',
      label: 'Submit Button Color',
      changeProp: true,
    },
    {
      type: 'select',
      label: 'State',
      name: 'state',
      options: [
        { value: 'normal', name: 'Normal' },
        { value: 'success', name: 'Success' },
        { value: 'error', name: 'Error' },
      ],
    },
    {
      type: 'text',
      label: 'Redirect URL',
      name: 'redirect',
    },
  ];

  domc.addType(formType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Form',
        type: formType,
        tagName: 'form',
        droppable: ':not(form)',
        draggable: ':not(form)',
        openTraitsOnSelect: true,
        traits,
        attributes: FORM_DEFAULT_ATTRIBUTES,
      },
    },
    view: {
      init() {
        const {
          buttonbgcolor,
          formbgcolor,
          labelcolor,
          formstyle,
          inputbgcolor,
          inputbordercolor,
          labelmargin,
          maxwidth,
          placeholdercolor,
          redirect,
          rowsgap,
          showlabels,
          state,
        } = this.model.getAttributes();

        this.model.attributes.buttonbgcolor = buttonbgcolor;
        this.model.attributes.formbgcolor = formbgcolor;
        this.model.attributes.labelcolor = labelcolor;
        this.model.attributes.formstyle = formstyle;
        this.model.attributes.inputbgcolor = inputbgcolor;
        this.model.attributes.inputbordercolor = inputbordercolor;
        this.model.attributes.labelmargin = labelmargin;
        this.model.attributes.maxwidth = maxwidth;
        this.model.attributes.placeholdercolor = placeholdercolor;
        this.model.attributes.redirect = redirect;
        this.model.attributes.rowsgap = rowsgap;
        this.model.attributes.showlabels = showlabels;
        this.model.attributes.state = state;

        this.listenTo(
          this.model,
          'change:attributes:placeholdercolor',
          this.updatePlaceholderColor,
        );
        this.listenTo(this.model, 'change:formstyle', this.updateFormStyle);
        this.listenTo(this.model, 'change:showlabels', this.updateShowLabels);
        this.listenTo(this.model, 'change:maxwidth', this.updateMaxWidth);
        this.listenTo(this.model, 'change:labelmargin', this.updateLabelMargin);
        this.listenTo(this.model, 'change:rowsgap', this.updateRowsGap);
        this.listenTo(this.model, 'change:formbgcolor', this.updateFormBGColor);
        this.listenTo(this.model, 'change:labelcolor', this.updateLabelColor);
        this.listenTo(
          this.model,
          'change:inputbgcolor',
          this.updateInputBGColor,
        );
        this.listenTo(
          this.model,
          'change:inputbordercolor',
          this.updateInputBorderColor,
        );
        this.listenTo(
          this.model,
          'change:buttonbgcolor',
          this.updateButtonBGColor,
        );
        this.listenTo(this.model, 'change:attributes:state', this.updateState);
      },
      updatePlaceholderColor(model, placeholderColor) {
        model.addAttributes({ placeholderColor });

        const selector = getFormInputPlaceholderSelector(model.getId());

        const style = {
          color: placeholderColor,
        };

        setRuleStyle(editor, selector, style);
      },
      updateFormStyle(model, formstyle) {
        let attributes;

        if (formstyle === 'default') {
          attributes = FORM_DEFAULT_ATTRIBUTES;
          applyDefaultStyle(editor, model, FORM_DEFAULT_ATTRIBUTES);
        }

        if (formstyle === 'minimal') {
          attributes = FORM_MINIMAL_ATTRIBUTES;
          applyMinimalStyle(editor, model, FORM_MINIMAL_ATTRIBUTES);
        }

        if (formstyle === 'dark') {
          attributes = FORM_DARK_ATTRIBUTES;
          applyDarkStyle(editor, model, FORM_DARK_ATTRIBUTES);
        }

        model.addAttributes(attributes);

        Object.entries(attributes).forEach(([attribute, value]) => {
          const trait = model.getTrait(attribute);
          trait.set('value', value);
        });
      },
      updateShowLabels(model, showlabels) {
        model.addAttributes({ showlabels });

        const selector = getFormLabelSelector(model.getId());

        const style = {
          display: showlabels ? 'block' : 'none',
        };

        setRuleStyle(editor, selector, style);
      },
      updateMaxWidth(model, maxwidth) {
        model.addAttributes({ maxwidth });

        model.addStyle({ 'max-width': `${maxwidth}px` });
      },
      updateLabelMargin(model, labelmargin) {
        model.addAttributes({ labelmargin });

        const selector = getFormLabelSelector(model.getId());

        const style = {
          'margin-bottom': `${labelmargin}px`,
        };

        setRuleStyle(editor, selector, style);
      },
      updateRowsGap(model, rowsgap) {
        model.addAttributes({ rowsgap });

        const selector = getFormInputSelector(model.getId());

        const style = {
          'margin-bottom': `${rowsgap}px`,
        };

        setRuleStyle(editor, selector, style);
      },
      updateFormBGColor(model, formbgcolor) {
        model.addAttributes({ formbgcolor });

        model.addStyle({ 'background-color': formbgcolor });
      },
      updateLabelColor(model, labelcolor) {
        model.addAttributes({ labelcolor });

        const selector = getFormLabelSelector(model.getId());

        const style = {
          color: labelcolor,
        };

        setRuleStyle(editor, selector, style);
      },
      updateInputBGColor(model, inputbgcolor) {
        model.addAttributes({ inputbgcolor });

        const selector = getFormInputSelector(model.getId());

        const style = {
          'background-color': inputbgcolor,
        };

        setRuleStyle(editor, selector, style);
      },
      updateInputBorderColor(model, inputbordercolor) {
        model.addAttributes({ inputbordercolor });

        const selector = getFormInputSelector(model.getId());

        const style = {
          'border-color': inputbordercolor,
        };

        setRuleStyle(editor, selector, style);
      },
      updateButtonBGColor(model, buttonbgcolor) {
        model.addAttributes({ buttonbgcolor });

        const selector = getFormButtonSelector(model.getId());

        const style = {
          'background-color': buttonbgcolor,
        };

        setRuleStyle(editor, selector, style);
      },
      updateState(model, state) {
        model.addAttributes({ state });

        const successSelector = getFormSuccessStateSelector(model.getId());
        const errorSelector = getFormErrorStateSelector(model.getId());
        const fromContentSelector = getFromContentSelector(model.getId());

        const formContetStyle = {
          display: state === 'normal' || state === 'error' ? 'block' : 'none',
        };

        const successStyle = {
          display: state === 'success' ? 'block' : 'none',
        };

        const errorStyle = {
          display: state === 'error' ? 'block' : 'none',
        };

        setRuleStyle(editor, fromContentSelector, formContetStyle);
        setRuleStyle(editor, successSelector, successStyle);
        setRuleStyle(editor, errorSelector, errorStyle);
      },
    },
    isComponent(el) {
      if (checkComponentType(el, formType)) {
        return { type: formType };
      }
    },
  });
};

export const addTextCustomFieldInput = (
  editor,
  { textCustomFieldInputType },
) => {
  const domc = editor.DomComponents;

  domc.addType(textCustomFieldInputType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Text Input (Custom Field)',
        type: textCustomFieldInputType,
        droppable: false,
        draggable: 'form, form *',
        tagName: 'input',
        openTraitsOnSelect: true,
        traits: [placeholderTrait, requiredTrait, autofocusTrait],
      },
    },
    isComponent(el) {
      if (checkComponentType(el, textCustomFieldInputType)) {
        return { type: textCustomFieldInputType };
      }
    },
  });
};

export const addTextCustomField = (editor, { textCustomFieldType }) => {
  const domc = editor.DomComponents;

  domc.addType(textCustomFieldType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Text (Custom Field)',
        type: textCustomFieldType,
        droppable: false,
        draggable: 'form, form *',
        tagName: 'input',
        openTraitsOnSelect: true,
        traits: [hideLabelTrait],
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:hideLabel',
          this.updateHideLabel,
        );
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        customFieldsModal(editor, 'string');
      },
      updateHideLabel(model, value) {
        model.get('components').each((child) => {
          const childType = child.get('type');

          if (childType === 'label') {
            setRuleStyle(editor, `#${child.ccid}`, {
              display: value ? 'none' : 'block',
            });
          }
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, textCustomFieldType)) {
        return { type: textCustomFieldType };
      }
    },
  });
};

export const addNumberCustomFieldInput = (
  editor,
  { numberCustomFieldInputType },
) => {
  const domc = editor.DomComponents;

  domc.addType(numberCustomFieldInputType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Number Input (Custom Field)',
        type: numberCustomFieldInputType,
        droppable: false,
        draggable: 'form, form *',
        tagName: 'input',
        openTraitsOnSelect: true,
        traits: [placeholderTrait, requiredTrait, autofocusTrait],
      },
    },

    isComponent(el) {
      if (checkComponentType(el, numberCustomFieldInputType)) {
        return { type: numberCustomFieldInputType };
      }
    },
  });
};

export const addNumberCustomField = (editor, { numberCustomFieldType }) => {
  const domc = editor.DomComponents;

  domc.addType(numberCustomFieldType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Number (Custom Field)',
        type: numberCustomFieldType,
        droppable: false,
        draggable: 'form, form *',
        tagName: 'input',
        openTraitsOnSelect: true,
        traits: [hideLabelTrait],
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:hideLabel',
          this.updateHideLabel,
        );
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        customFieldsModal(editor, 'number');
      },
      updateHideLabel(model, value) {
        model.get('components').each((child) => {
          const childType = child.get('type');

          if (childType === 'label') {
            setRuleStyle(editor, `#${child.ccid}`, {
              display: value ? 'none' : 'block',
            });
          }
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, numberCustomFieldType)) {
        return { type: numberCustomFieldType };
      }
    },
  });
};

export const addTextarea = (editor, { textareaType }) => {
  const domc = editor.DomComponents;

  domc.addType(textareaType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Textarea',
        type: textareaType,
        droppable: false,
        draggable: 'form, form *',
        tagName: 'textarea',
        openTraitsOnSelect: true,
        traits: [nameTrait, placeholderTrait, requiredTrait],
      },
    },
    isComponent(el) {
      if (el.tagName === 'TEXTAREA') {
        return { type: textareaType };
      }
    },
  });
};

export const addButton = (editor, { buttonType }) => {
  const domc = editor.DomComponents;

  domc.addType(buttonType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Button',
        type: buttonType,
        droppable: false,
        tagName: 'button',
        openTraitsOnSelect: true,
        traits: [
          {
            type: 'content',
            label: 'Text',
          },
          {
            label: 'Type',
            type: 'select',
            name: 'type',
            options: [
              { value: 'submit', name: 'Submit' },
              { value: 'reset', name: 'Reset' },
              { value: 'button', name: 'Button' },
            ],
          },
        ],
      },
    },
    view: {
      events: {
        click: 'handleClick',
      },
      init() {
        this.listenTo(this.model, 'change:content', this.updateContent);
      },
      updateContent() {
        this.el.innerHTML = this.model.get('content');
      },
      handleClick(e) {
        e.preventDefault();
      },
    },
    isComponent(el) {
      if (el.tagName === 'BUTTON') {
        return { type: buttonType };
      }
    },
  });
};

export const addFormButton = (editor, { formButtonType, buttonType }) => {
  const domc = editor.DomComponents;

  domc.addType(formButtonType, {
    extend: buttonType,
    model: {
      defaults: {
        openTraitsOnSelect: true,
        draggable: 'form, form *',
        traits: [
          {
            type: 'content',
            label: 'Text',
          },
          {
            label: 'Type',
            type: 'select',
            name: 'type',
            options: [
              { value: 'submit', name: 'Submit' },
              { value: 'reset', name: 'Reset' },
              { value: 'button', name: 'Button' },
            ],
          },
        ],
      },
    },
    isComponent(el) {
      if (checkComponentType(el, formButtonType)) {
        return { type: formButtonType };
      }
    },
  });
};

export const addLabel = (editor, { labelType }) => {
  const domc = editor.DomComponents;

  domc.addType(labelType, {
    extend: 'text',
    model: {
      defaults: {
        name: 'Label',
        type: labelType,
        tagName: 'label',
        draggable: 'form, form *',
        openTraitsOnSelect: true,
        traits: [],
      },
    },
    isComponent(el) {
      if (el.tagName === 'LABEL') {
        return { type: labelType };
      }
    },
  });
};

export const addFormGroup = (editor, { formGroupType }) => {
  const domc = editor.DomComponents;

  domc.addType(formGroupType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Form Group',
        attributes: { class: 'form-group' },
        draggable: 'form, form *',
        traits: [hideLabelTrait],
        styles: `
          .form-group:empty {
            height: 50px;
            border: 1px dashed #999;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
          }
          .form-group:empty:before {
            content: 'Drag the form elements in here';
            color: #999;
          }
        `,
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:hideLabel',
          this.updateHideLabel,
        );
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        editor.select(editor.getSelected().parent());
      },
      updateHideLabel(model, value) {
        model.get('components').each((child) => {
          const childType = child.get('type');

          if (childType === 'label') {
            setRuleStyle(editor, `#${child.ccid}`, {
              display: value ? 'none' : 'block',
            });
          }
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, formGroupType)) {
        return { type: 'form-group' };
      }
    },
  });
};

export const addContactInput = (editor, { contactInputType }) => {
  const domc = editor.DomComponents;

  domc.addType(contactInputType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Input',
        draggable: 'form, form *',
        traits: [placeholderTrait, requiredTrait, autofocusTrait],
      },
    },
  });
};

export const addEmailInput = (editor, { emailInputType }) => {
  const domc = editor.DomComponents;

  domc.addType(emailInputType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Email Input',
        draggable: 'form, form *',
        traits: [hideLabelTrait],
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:hideLabel',
          this.updateHideLabel,
        );
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        editor.select(editor.getSelected().parent());
      },
      updateHideLabel(model, value) {
        model.get('components').each((child) => {
          const childType = child.get('type');

          if (childType === 'label') {
            setRuleStyle(editor, `#${child.ccid}`, {
              display: value ? 'none' : 'block',
            });
          }
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, emailInputType)) {
        return { type: 'email-input' };
      }
    },
  });
};

export const addPhoneInput = (editor, { phoneInputType }) => {
  const domc = editor.DomComponents;

  domc.addType(phoneInputType, {
    extend: 'default',
    model: {
      defaults: {
        draggable: 'form, form *',
        traits: [hideLabelTrait],
        'script-iti':
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/intlTelInput.min.js',
        // eslint-disable-next-line object-shorthand
        script: function () {
          setTimeout(() => {
            const el = this;
            const phoneInput = el.querySelector('.phone-input input[type=tel]');
            let iti = '{[ script-iti ]}';

            let initPhoneInputScript = function () {
              window.intlTelInput(phoneInput, {});
            };

            if (iti && typeof intlTelInput === 'undefined') {
              let script = document.createElement('script');

              script.src = iti;
              script.onload = initPhoneInputScript;

              document.head.appendChild(script);
            } else {
              initPhoneInputScript();
            }
          }, 1000);
        },
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:hideLabel',
          this.updateHideLabel,
        );
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        editor.select(editor.getSelected().parent());
      },
      updateHideLabel(model, value) {
        model.get('components').each((child) => {
          const childType = child.get('type');

          if (childType === 'label') {
            setRuleStyle(editor, `#${child.ccid}`, {
              display: value ? 'none' : 'block',
            });
          }
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, phoneInputType)) {
        return { type: 'phone-input' };
      }
    },
  });
};

export const addNameInput = (editor, { nameInputType }) => {
  const domc = editor.DomComponents;

  domc.addType(nameInputType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Name Input',
        draggable: 'form, form *',
        traits: [hideLabelTrait],
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:attributes:hideLabel',
          this.updateHideLabel,
        );
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        editor.select(editor.getSelected().parent());
      },
      updateHideLabel(model, value) {
        model.get('components').each((child) => {
          const childType = child.get('type');

          if (childType === 'label') {
            setRuleStyle(editor, `#${child.ccid}`, {
              display: value ? 'none' : 'block',
            });
          }
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, nameInputType)) {
        return { type: 'name-input' };
      }
    },
  });
};

export default (editor, config) => {
  addButton(editor, config);
  addContactInput(editor, config);
  addEmailInput(editor, config);
  addForm(editor, config);
  addFormButton(editor, config);
  addFormGroup(editor, config);
  addLabel(editor, config);
  addNameInput(editor, config);
  addNumberCustomField(editor, config);
  addNumberCustomFieldInput(editor, config);
  addPhoneInput(editor, config);
  addTextarea(editor, config);
  addTextCustomField(editor, config);
  addTextCustomFieldInput(editor, config);
};
