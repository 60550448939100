import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  httpGetSubscriptionPlans,
  httpUpdateSubscriptionPlans,
  httpCreateSubscriptionPlan,
} from 'api';
import { getErrorText, showErrorMessage } from 'helpers';

const getSubscriptionPlans = createAsyncThunk(
  'subscriptionPlans/getSubscriptionPlans',
  async () => {
    try {
      const { data } = await httpGetSubscriptionPlans();

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateSubscriptionPlans = createAsyncThunk(
  'subscriptionPlans/updateSubscriptionPlans',
  async (payload) => {
    try {
      await httpUpdateSubscriptionPlans(payload);

      return payload;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const createSubscriptionPlan = createAsyncThunk(
  'subscriptionPlans/createSubscriptionPlan',
  async (payload) => {
    try {
      const { data } = await httpCreateSubscriptionPlan(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

export {
  getSubscriptionPlans,
  updateSubscriptionPlans,
  createSubscriptionPlan,
};
