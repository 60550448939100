import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { showErrorMessage } from 'helpers';
import { useFont } from 'hooks';
import { find } from 'lodash';
import Button from 'components/Button';
import CheckBox from 'components/Forms/CheckBox';
import cx from 'classnames';
import { generateSelectedFontsList } from '../../../../../routes/Editor/utils';

const messages = defineMessages({
  SelectButtonLabel: { id: 'button.select' },
  closeButtonLabel: { id: 'button.close' },
  addButtonLabel: { id: 'button.add' },
  addedButtonLabel: { id: 'button.added' },
  variantsLabel: { id: 'page-settings-modal-fonts.filters.variants-label' },
  subsetsLabel: { id: 'page-settings-modal-fonts.filters.subsets-label' },
  variantsErrorMessage: {
    id: 'page-settings-modal-fonts.selected-items.variants-error-message',
  },
  subsetsErrorMessage: {
    id: 'page-settings-modal-fonts.selected-items.subsets-error-message',
  },
});

function FontItem({
  editor,
  font,
  previewText,
  selectedItems,
  setSelectedItems,
}) {
  const intl = useIntl();

  const { family, category, variants, subsets } = font;
  const currentFont = selectedItems.find((item) => item.family === family);

  const [variantsList, setVariantsList] = useState(
    currentFont ? currentFont.variants : [],
  );
  const [subsetsList, setSubsetsList] = useState(
    currentFont ? currentFont.subsets : [],
  );
  const [actionToggleFamily, setActionToggleFamily] = useState('');

  const { isLoading, saveFontSetting, generateGoogleFontUrl } = useFont();

  const isFamilyExist = find(selectedItems, { family });

  return (
    <div
      className={cx('page-settings-modal__fonts__list__item', {
        'selected-item': family === actionToggleFamily,
      })}>
      <div className="page-settings-modal__fonts__list__item__font">
        <div
          style={{
            fontFamily: `${family}, ${category}`,
          }}
          className="page-settings-modal__fonts__list__item__font__preview">
          {previewText ? previewText : family}
          {isFamilyExist && <span className="icon icon-check" />}
        </div>
        <div className="page-settings-modal__fonts__list__item__font__details">
          <div className="page-settings-modal__fonts__list__item__font__details__info">
            <div className="page-settings-modal__fonts__list__item__font__details__info__name">
              <span>{family}</span>
              <span>{category}</span>
            </div>
            <Button
              variant="contained"
              onClick={() =>
                family === actionToggleFamily
                  ? setActionToggleFamily('')
                  : setActionToggleFamily(family)
              }>
              {intl.formatMessage(
                family === actionToggleFamily
                  ? messages.closeButtonLabel
                  : messages.SelectButtonLabel,
              )}
            </Button>
          </div>
          {family === actionToggleFamily && (
            <div className="page-settings-modal__fonts__list__item__font__details__actions">
              <div className="page-settings-modal__fonts__list__item__font__details__actions__variants">
                <label>{intl.formatMessage(messages.variantsLabel)}</label>
                {variants.map((variant, index) => (
                  <CheckBox
                    key={`variant-${index}`}
                    label={variant}
                    value={variant}
                    checked={variantsList.includes(variant)}
                    onChange={(e) => {
                      !variantsList.includes(variant)
                        ? setVariantsList([...variantsList, e.target.value])
                        : setVariantsList([
                            ...variantsList.filter(
                              (item) => item !== e.target.value,
                            ),
                          ]);
                    }}
                  />
                ))}
              </div>
              <div className="page-settings-modal__fonts__list__item__font__details__actions__subsets">
                <label> {intl.formatMessage(messages.subsetsLabel)}</label>
                {subsets.map((subset, index) => (
                  <CheckBox
                    key={`subset-${index}`}
                    label={subset}
                    value={subset}
                    checked={subsetsList.includes(subset)}
                    onChange={(e) => {
                      !subsetsList.includes(subset)
                        ? setSubsetsList([...subsetsList, e.target.value])
                        : setSubsetsList([
                            ...subsetsList.filter(
                              (item) => item !== e.target.value,
                            ),
                          ]);
                    }}
                  />
                ))}
              </div>
              <Button
                className="page-settings-modal__fonts__list__item__font__details__actions__add-btn"
                variant="contained"
                disabled={isLoading || isFamilyExist}
                onClick={() => {
                  if (variantsList.length <= 0) {
                    showErrorMessage(
                      intl.formatMessage(messages.variantsErrorMessage),
                    );
                  } else if (subsetsList.length <= 0) {
                    showErrorMessage(
                      intl.formatMessage(messages.subsetsErrorMessage),
                    );
                  } else {
                    const selectedFont = {
                      family,
                      variants: variantsList,
                      subsets: subsetsList,
                      category,
                      url: generateGoogleFontUrl(
                        family,
                        subsetsList,
                        variantsList,
                      ),
                    };

                    generateSelectedFontsList(editor, {
                      name: family,
                      value: family,
                      category,
                      url: generateGoogleFontUrl(
                        family,
                        subsetsList,
                        variantsList,
                      ),
                    });
                    setSelectedItems([...selectedItems, selectedFont]);
                    saveFontSetting({
                      fonts: [...selectedItems, selectedFont],
                    });
                  }
                }}>
                {isLoading ? (
                  <span className="icon icon-spinner" />
                ) : (
                  intl.formatMessage(
                    isFamilyExist
                      ? messages.addedButtonLabel
                      : messages.addButtonLabel,
                  )
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FontItem.propTypes = {
  editor: PropTypes.object,
  font: PropTypes.shape({
    family: PropTypes.string.isRequired,
    variants: PropTypes.array.isRequired,
    subsets: PropTypes.array.isRequired,
    version: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    files: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    menu: PropTypes.string.isRequired,
  }).isRequired,
  previewText: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
};

export default FontItem;
