import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { LOAD_STATUS } from 'appConstants';
import { wayMoreFormsSelector } from 'store/selectors';
import { getWayMoreForms } from 'store/actions';
import Button from '../../Button';
import Combobox from '../../Combobox';
import './WayMoreFormModal.sass';

const itemToString = (item) => (item ? item.name : '');
const normalizeItem = (item) => ({ key: item.url, label: item.name });

function WayMoreFormModal({ onSetWayMoreFormSrc }) {
  const intl = useIntl();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const dispatch = useDispatch();
  const items = useSelector((state) => wayMoreFormsSelector(state, searchTerm));

  const status = useSelector((state) => state.waymore.forms.status);

  useEffect(() => {
    dispatch(getWayMoreForms());
  }, [dispatch, intl]);

  const loading = status === LOAD_STATUS.IDLE || status === LOAD_STATUS.LOADING;

  return (
    <>
      <div className="waymore-form-modal__content-wrapper">
        <Combobox
          label={intl.formatMessage({ id: 'waymore-form-modal.form-label' })}
          placeholder={intl.formatMessage({
            id: 'waymore-form-modal.form-placeholder',
          })}
          items={items}
          itemToString={itemToString}
          normalizeItem={normalizeItem}
          loading={loading}
          onInputValueChange={({ inputValue }) => setSearchTerm(inputValue)}
          onSelectedItemChange={(changes) =>
            setSelectedItem(changes.selectedItem)
          }
          fullWidth
        />
      </div>
      <div className="waymore-form-modal__buttons">
        <Button
          type="button"
          variant="contained"
          disabled={!selectedItem || loading}
          onClick={() => onSetWayMoreFormSrc(selectedItem.url)}>
          {intl.formatMessage({ id: 'button.add' })}
        </Button>
      </div>
    </>
  );
}

WayMoreFormModal.propTypes = {
  onSetWayMoreFormSrc: PropTypes.func.isRequired,
};

export default WayMoreFormModal;
