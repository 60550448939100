export const ANIMATE_CSS_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

export const FONTAWESOME_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css';

export const DEFAULT_USERS = [
  {
    name: 'Product Owner Test Account',
    email: 'potests@amdtelecom.net',
    role: 'admin',
    image: 'https://cdn11.waymore.io/dnd/images/img_avatar_1.png',
    id: 6997,
    sessionId: 'session_id',
  },
  {
    name: 'Andreas Sidiropoulos',
    email: 'asidiropoulos@waymore.io',
    role: 'admin',
    image: 'https://cdn11.waymore.io/dnd/images/img_avatar_2.png',
    id: 16090,
    sessionId: 'session_id2',
  },
  {
    email: 'gkrousiarlis@waymore.io',
    id: '26728',
    image: 'https://cdn11.waymore.io/dnd/images/img_avatar_3.png',
    name: 'Grigorios Krousiarlis',
    role: 'user',
    sessionId: 'session_id3',
  },
];

export const GOOGLE_FONTS_WEIGHTS = [
  '100',
  '200',
  '300',
  'regular',
  '500',
  '600',
  '700',
  '800',
  '900',
  '100italic',
  '200italic',
  '300italic',
  'italic',
  '500italic',
  '600italic',
  '700italic',
  '800italic',
  '900italic',
];

export const GOOGLE_FONTS_CATEGORIES = [
  'sans-serif',
  'serif',
  'display',
  'handwriting',
  'monospace',
];

export const CUSTOM_MODULES_SELECTED_CATEGORIES = [
  'headers',
  'preheaders',
  'banners',
  'content',
  'set of blog posts',
  'blog post',
  'products',
  'footers',
];

export const LOAD_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
};

export const VERIFY_DOMAIN_STATUSES = {
  ACTIVE: 0,
  ACTIVATION_IN_PROGRESS: 1,
  ACTIVATION_ERROR: 2,
  DEACTIVATION_IN_PROGRESS: 3,
  DEACTIVATION_ERROR: 4,
  PENDING_VERIFICATION: 10,
  VERIFICATION_IN_PROGRESS: 11,
  VERIFIED: 12,
  VERIFICATION_ERROR: 13,
};

export const USER_GUIDE_KEYS = {
  DASHBOARD_TOUR: 'isDashboardTourFinished',
  EDITOR_TOUR: 'isEditorTourFinished',
};

export const USER_ROLES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const CATEGORY_GROUPS = {
  SYSTEM: 'system',
  LAYOUTS: 'layouts',
};

export const ERROR_MESSAGES = {
  'DNDB-DOM-400-01': 'Unknown name of domain',
  'DNDB-DOM-400-02': 'Domain already exists',
  'DNDB-DOM-404-01': 'Domain not found',
  'DNDB-DOM-424-01': 'Domain should be verified before activation',
  'DNDB-DOM-424-02': 'Only domain in status active can be deactivated',
  'DNDB-DOM-424-03': 'Domain should be deactivated first',
};

export const FORM_CATEGORIES = [
  { label: 'Lead gen form', value: 'lead_capture' },
  { label: 'Feedback form', value: 'feedback' },
  { label: 'Contact form', value: 'contact_form' },
  { label: 'Quiz', value: 'quiz' },
  { label: 'Registration form', value: 'registration' },
  { label: 'Order form', value: 'order_form' },
  { label: 'Payment form', value: 'payment_form' },
  { label: 'Poll', value: 'poll' },
  { label: 'ًeport', value: 'report' },
  { label: 'Request form', value: 'request' },
  { label: 'Research survey', value: 'research' },
  { label: 'Giveaway', value: 'giveaway' },
  { label: 'Application form', value: 'application' },
  { label: 'Checklist', value: 'checklist' },
  { label: 'Other', value: 'other' },
];

export const SOCIAL_STYLES = [
  {
    style: 'circle-black-bordered',
    css: {
      width: '1.8em',
      height: '1.8em',
      color: '#333',
      border: '1px solid #333',
      'border-radius': '50%',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'circle-black',
    css: {
      width: '1.8em',
      height: '1.8em',
      color: '#fff',
      'border-radius': '50%',
      'background-color': '#333',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'circle-colored',
    css: {
      width: '1.8em',
      height: '1.8em',
      color: '#fff',
      'border-radius': '50%',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: true,
  },
  {
    style: 'circle-colored-bordered',
    css: {
      width: '1.8em',
      height: '1.8em',
      'border-radius': '50%',
      'border-width': '2px',
      'border-style': 'solid',
    },
    customColor: true,
    customBorderColor: true,
    customBgColor: false,
  },
  {
    style: 'circle-gray-bordered',
    css: {
      width: '1.8em',
      height: '1.8em',
      color: '#ccc',
      border: '1px solid #ccc',
      'border-radius': '50%',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'circle-gray',
    css: {
      width: '1.8em',
      height: '1.8em',
      color: '#fff',
      'background-color': '#ccc',
      'border-radius': '50%',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'circle-white-bordered',
    css: {
      width: '1.8em',
      height: '1.8em',
      color: '#fff',
      border: '2px solid #fff',
      'border-radius': '50%',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'circle-white',
    css: {
      width: '1.8em',
      height: '1.8em',
      color: '#333',
      'background-color': '#fff',
      'border-radius': '50%',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'logo-black',
    css: {
      color: '#333',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'logo-colored-bordered',
    css: {},
    customColor: true,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'logo-colored',
    css: {},
    customColor: true,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'logo-gray',
    css: {
      color: '#ccc',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'logo-white',
    css: {
      color: '#fff',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'rounded-black-bordered',
    css: {
      color: '#333',
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
      border: '2px solid #333',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'rounded-black',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
      'background-color': '#333',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'rounded-colored-bordered',
    css: {
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
      'border-width': '2px',
      'border-style': 'solid',
    },
    customColor: true,
    customBorderColor: true,
    customBgColor: false,
  },
  {
    style: 'rounded-colored',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: true,
  },
  {
    style: 'rounded-gray-bordered',
    css: {
      color: '#ccc',
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
      border: '2px solid #ccc',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'rounded-gray',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
      'background-color': '#ccc',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'rounded-white-bordered',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'rounded-white',
    css: {
      color: '#333',
      width: '1.8em',
      height: '1.8em',
      'border-radius': '6px',
      'background-color': '#fff',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'square-black-bordered',
    css: {
      color: '#333',
      width: '1.8em',
      height: '1.8em',
      border: '1px solid #333',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'square-black',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
      'background-color': '#333',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'square-colored-bordered',
    css: {
      width: '1.8em',
      height: '1.8em',
      'border-width': '2px',
      'border-style': 'solid',
    },
    customColor: true,
    customBorderColor: true,
    customBgColor: false,
  },
  {
    style: 'square-colored',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: true,
  },
  {
    style: 'square-gray-bordered',
    css: {
      color: '#ccc',
      width: '1.8em',
      height: '1.8em',
      border: '2px solid #ccc',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'square-gray',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
      'background-color': '#ccc',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'square-white-bordered',
    css: {
      color: '#fff',
      width: '1.8em',
      height: '1.8em',
      border: '2px solid #fff',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
  {
    style: 'square-white',
    css: {
      color: '#333',
      width: '1.8em',
      height: '1.8em',
      'background-color': '#fff',
    },
    customColor: false,
    customBorderColor: false,
    customBgColor: false,
  },
];

export const SOCIAL_PREVIEW_ICONS = [
  { iconName: 'preview-32-logo-colored', iconTitle: 'Colored logos' },
  { iconName: 'preview-32-logo-black', iconTitle: 'Black logos' },
  { iconName: 'preview-32-logo-gray', iconTitle: 'Gray logos' },
  { iconName: 'preview-32-logo-white', iconTitle: 'White logos' },
  { iconName: 'preview-32-circle-colored', iconTitle: 'Circle color logos' },
  {
    iconName: 'preview-32-circle-colored-bordered',
    iconTitle: 'Circle color with border',
  },
  { iconName: 'preview-32-rounded-colored', iconTitle: 'Rounded color logos' },
  {
    iconName: 'preview-32-rounded-colored-bordered',
    iconTitle: 'Rounded color with border',
  },
  { iconName: 'preview-32-square-colored', iconTitle: 'Square color logos' },
  {
    iconName: 'preview-32-square-colored-bordered',
    iconTitle: 'Square color with border',
  },
  { iconName: 'preview-32-circle-black', iconTitle: 'Circle black logos' },
  {
    iconName: 'preview-32-circle-black-bordered',
    iconTitle: 'Circle black with border',
  },
  { iconName: 'preview-32-rounded-black', iconTitle: 'Rounded black logos' },
  {
    iconName: 'preview-32-rounded-black-bordered',
    iconTitle: 'Rounded black with border',
  },
  { iconName: 'preview-32-square-black', iconTitle: 'Square black logos' },
  {
    iconName: 'preview-32-square-black-bordered',
    iconTitle: 'Square black with border',
  },
  { iconName: 'preview-32-circle-gray', iconTitle: 'Circle gray logos' },
  {
    iconName: 'preview-32-circle-gray-bordered',
    iconTitle: 'Circle gray with border',
  },
  { iconName: 'preview-32-rounded-gray', iconTitle: 'Rounded gray logos' },
  {
    iconName: 'preview-32-rounded-gray-bordered',
    iconTitle: 'Rounded gray with border',
  },
  { iconName: 'preview-32-square-gray', iconTitle: 'Square gray logos' },
  {
    iconName: 'preview-32-square-gray-bordered',
    iconTitle: 'Square gray with border',
  },
  { iconName: 'preview-32-circle-white', iconTitle: 'Circle white logos' },
  {
    iconName: 'preview-32-circle-white-bordered',
    iconTitle: 'Circle white with border',
  },
  { iconName: 'preview-32-rounded-white', iconTitle: 'Rounded white logos' },
  {
    iconName: 'preview-32-rounded-white-bordered',
    iconTitle: 'Rounded white with border',
  },
  { iconName: 'preview-32-square-white', iconTitle: 'Square white logos' },
  {
    iconName: 'preview-32-square-white-bordered',
    iconTitle: 'Square white with border',
  },
];

export const SOCIAL_SOCIAL_ICONS = [
  {
    iconName: 'fa-brands fa-behance',
    iconTitle: 'Behance',
    iconColor: '#0055F8',
  },
  {
    iconName: 'fa-brands fa-dribbble',
    iconTitle: 'Dribbble',
    iconColor: '#E44A85',
  },
  {
    iconName: 'fa-brands fa-facebook-f',
    iconTitle: 'Facebook',
    iconColor: '#0862F8',
  },
  {
    iconName: 'fa-brands fa-flickr',
    iconTitle: 'Flickr',
    iconColor: '#F80080',
  },
  {
    iconName: 'fa-brands fa-foursquare',
    iconTitle: 'Foursquare',
    iconColor: '#F83F77',
  },
  {
    iconName: 'fa-brands fa-instagram',
    iconTitle: 'Instagram',
    iconColor: '#853FA2',
  },
  {
    iconName: 'fa-solid fa-podcast',
    iconTitle: 'Apple podcasts',
    iconColor: '#A51CCD',
  },
  {
    iconName: 'fa-brands fa-lastfm',
    iconTitle: 'Last.fm',
    iconColor: '#DD1B23',
  },
  {
    iconName: 'fa-brands fa-linkedin-in',
    iconTitle: 'LinkedIn',
    iconColor: '#007AB7',
  },
  {
    iconName: 'fa-brands fa-pinterest',
    iconTitle: 'Pinterest',
    iconColor: '#E00019',
  },
  {
    iconName: 'fa-brands fa-soundcloud',
    iconTitle: 'Soundcloud',
    iconColor: '#F85319',
  },
  {
    iconName: 'fa-brands fa-spotify',
    iconTitle: 'Spotify',
    iconColor: '#1DD15E',
  },
  {
    iconName: 'fa-brands fa-tiktok',
    iconTitle: 'Tiktok',
    iconColor: '#000000',
  },
  {
    iconName: 'fa-brands fa-tumblr',
    iconTitle: 'Tumblr',
    iconColor: '#304156',
  },
  {
    iconName: 'fa-brands fa-x-twitter',
    iconTitle: 'X Twitter',
    iconColor: '#000000',
  },
  {
    iconName: 'fa-brands fa-vimeo-v',
    iconTitle: 'Vimeo',
    iconColor: '#01A9E9',
  },
  { iconName: 'fa-brands fa-weibo', iconTitle: 'Weibo', iconColor: '#E0152C' },
  {
    iconName: 'fa-brands fa-youtube',
    iconTitle: 'YouTube',
    iconColor: '#F80001',
  },
  { iconName: 'fa-brands fa-xing', iconTitle: 'Xing', iconColor: '#005D60' },
  {
    iconName: 'fa-brands fa-meetup',
    iconTitle: 'Meetup',
    iconColor: '#E71B3E',
  },
  {
    iconName: 'fa-brands fa-threads',
    iconTitle: 'Threads',
    iconColor: '#000000',
  },
  {
    iconName: 'fa-brands fa-twitch',
    iconTitle: 'Twitch',
    iconColor: '#8C44F8',
  },
  {
    iconName: 'fa-brands fa-yammer',
    iconTitle: 'Yammer',
    iconColor: '#0078C2',
  },
  {
    iconName: 'fa-brands fa-reddit',
    iconTitle: 'Reddit',
    iconColor: '#F83C18',
  },
  {
    iconName: 'fa-brands fa-strava',
    iconTitle: 'Strava',
    iconColor: '#F75B01',
  },
  {
    iconName: 'fa-brands fa-goodreads-g',
    iconTitle: 'Goodreads',
    iconColor: '#583E2F',
  },
  { iconName: 'fa-brands fa-yelp', iconTitle: 'Yelp', iconColor: '#F8191A' },
  {
    iconName: 'fa-brands fa-mastodon',
    iconTitle: 'Mastodon',
    iconColor: '#2E83CE',
  },
];

export const SOCIAL_MESSENGER_ICONS = [
  {
    iconName: 'fa-brands fa-facebook-messenger',
    iconTitle: 'Facebook messenger',
    iconColor: '#4187F7',
  },
  { iconName: 'fa-brands fa-skype', iconTitle: 'Skype', iconColor: '#00AAEA' },
  {
    iconName: 'fa-brands fa-snapchat',
    iconTitle: 'Snapchat',
    iconColor: '#F8F500',
  },
  {
    iconName: 'fa-brands fa-telegram',
    iconTitle: 'Telegram',
    iconColor: '#25A3E3',
  },
  { iconName: 'fa-brands fa-viber', iconTitle: 'Viber', iconColor: '#7157F1' },
  {
    iconName: 'fa-brands fa-weixin',
    iconTitle: 'WeChat',
    iconColor: '#00BE25',
  },
  {
    iconName: 'fa-brands fa-whatsapp',
    iconTitle: 'whatsApp',
    iconColor: '#24CD63',
  },
  { iconName: 'fa-brands fa-slack', iconTitle: 'Slack', iconColor: '#000' },
  {
    iconName: 'fa-brands fa-discord',
    iconTitle: 'Discord',
    iconColor: '#5661EC',
  },
  { iconName: 'fa-brands fa-line', iconTitle: 'Line', iconColor: '#05C252' },
];

export const SOCIAL_OTHER_ICONS = [
  {
    iconName: 'fa-brands fa-blogger',
    iconTitle: 'Blogger',
    iconColor: '#EA6733',
  },
  {
    iconName: 'fa-solid fa-envelope',
    iconTitle: 'Envelope',
    iconColor: '#333',
  },
  { iconName: 'fa-solid fa-link', iconTitle: 'Link', iconColor: '#333' },
  {
    iconName: 'fa-solid fa-location-dot',
    iconTitle: 'Location',
    iconColor: '#333',
  },
  { iconName: 'fa-solid fa-globe', iconTitle: 'Globe', iconColor: '#333' },
  {
    iconName: 'fa-solid fa-map-location-dot',
    iconTitle: 'Map location',
    iconColor: '#333',
  },
  { iconName: 'fa-solid fa-phone', iconTitle: 'Phone', iconColor: '#333' },
  { iconName: 'fa-solid fa-share', iconTitle: 'Share', iconColor: '#33' },
  { iconName: 'fa-solid fa-rss', iconTitle: 'RSS', iconColor: '#F8A100' },
  { iconName: 'fa-brands fa-apple', iconTitle: 'Apple', iconColor: '#AEAFAE' },
  {
    iconName: 'fa-brands fa-google-play',
    iconTitle: 'Google Play',
    iconColor: '#333',
  },
  {
    iconName: 'fa-brands fa-windows',
    iconTitle: 'Windows',
    iconColor: '#0175CF',
  },
  { iconName: 'fa-brands fa-medium', iconTitle: 'Medium', iconColor: '#000' },
  {
    iconName: 'fa-brands fa-dropbox',
    iconTitle: 'Dropbox',
    iconColor: '#015EF7',
  },
  {
    iconName: 'fa-brands fa-google-drive',
    iconTitle: 'Google Drive',
    iconColor: '#333',
  },
  {
    iconName: 'fa-brands fa-github',
    iconTitle: 'GitHub',
    iconColor: '#0C0F17',
  },
  { iconName: 'fa-solid fa-file-pdf', iconTitle: 'PDF', iconColor: '' },
  { iconName: 'fa-solid fa-file-word', iconTitle: 'Office', iconColor: '' },
  { iconName: 'fa-solid fa-file-excel', iconTitle: 'Excel', iconColor: '' },
  {
    iconName: 'fa-solid fa-file-powerpoint',
    iconTitle: 'PowerPoint',
    iconColor: '',
  },
  {
    iconName: 'fa-brands fa-patreon',
    iconTitle: 'Patreon',
    iconColor: '#F16452',
  },
  { iconName: 'fa-brands fa-google', iconTitle: 'Google', iconColor: '#333' },
];

export const ANIMATION_EFFECTS_LIST = [
  { id: 'none', name: 'None' },
  {
    groupName: 'Attention seekers',
    optionsList: [
      { id: 'bounce', name: 'Bounce' },
      { id: 'flash', name: 'Flash' },
      { id: 'pulse', name: 'Pulse' },
      { id: 'rubberBand', name: 'Rubber band' },
      { id: 'shakeX', name: 'Shake X' },
      { id: 'shakeY', name: 'Shake Y' },
      { id: 'headShake', name: 'Head shake' },
      { id: 'swing', name: 'Swing' },
      { id: 'tada', name: 'Tada' },
      { id: 'wobble', name: 'Wobble' },
      { id: 'jello', name: 'Jello' },
      { id: 'heartBeat', name: 'Heartbeat' },
    ],
  },
  {
    groupName: 'Back entrances',
    optionsList: [
      { id: 'backInDown', name: 'Back in down' },
      { id: 'backInLeft', name: 'back in left' },
      { id: 'backInRight', name: 'back in right' },
      { id: 'backInUp', name: 'back in up' },
    ],
  },
  {
    groupName: 'Back exits',
    optionsList: [
      { id: 'backOutDown', name: 'Back out down' },
      { id: 'backOutLeft', name: 'Back out left' },
      { id: 'backOutRight', name: 'Back out right' },
      { id: 'backOutUp', name: 'Back out up' },
    ],
  },
  {
    groupName: 'Bouncing entrances',
    optionsList: [
      { id: 'bounceIn', name: 'Bounce in' },
      { id: 'bounceInDown', name: 'Bounce in down' },
      { id: 'bounceInLeft', name: 'Bounce in left' },
      { id: 'bounceInRight', name: 'Bounce in right' },
      { id: 'bounceInUp', name: 'Bounce in up' },
    ],
  },
  {
    groupName: 'Bouncing exits',
    optionsList: [
      { id: 'bounceOut', name: 'Bounce out' },
      { id: 'bounceOutDown', name: 'Bounce out down' },
      { id: 'bounceOutLeft', name: 'Bounce out left' },
      { id: 'bounceOutRight', name: 'Bounce out right' },
      { id: 'bounceOutUp', name: 'Bounce out up' },
    ],
  },
  {
    groupName: 'Fading entrances',
    optionsList: [
      { id: 'fadeIn', name: 'Fade in' },
      { id: 'fadeInDown', name: 'Fade in down' },
      { id: 'fadeInDownBig', name: 'Fade in down big' },
      { id: 'fadeInLeft', name: 'Fade in left' },
      { id: 'fadeInLeftBig', name: 'Fade in left big' },
      { id: 'fadeInRight', name: 'Fade in right' },
      { id: 'fadeInRightBig', name: 'Fade in right big' },
      { id: 'fadeInUp', name: 'Fade in up' },
      { id: 'fadeInUpBig', name: 'Fade in up big' },
      { id: 'fadeInTopLeft', name: 'Fade in top left' },
      { id: 'fadeInTopRight', name: 'Fade in top right' },
      { id: 'fadeInBottomLeft', name: 'Fade in bottom left' },
      { id: 'fadeInBottomRight', name: 'Fade in bottom right' },
    ],
  },
  {
    groupName: 'Fading exits',
    optionsList: [
      { id: 'fadeOutDown', name: 'Fade out' },
      { id: 'fadeOutDownBig', name: 'Fade out down big' },
      { id: 'fadeOutLeft', name: 'Fade out left' },
      { id: 'fadeOutLeftBig', name: 'Fade out left big' },
      { id: 'fadeOutRight', name: 'Fade out right' },
      { id: 'fadeOutRightBig', name: 'Fade out right big' },
      { id: 'fadeOutUp', name: 'Fade out up' },
      { id: 'fadeOutUpBig', name: 'Fade out up big' },
      { id: 'fadeOutTopLeft', name: 'Fade out top left' },
      { id: 'fadeOutTopRight', name: 'Fade out top right' },
      { id: 'fadeOutBottomRight', name: 'Fade out bottom right' },
      { id: 'fadeOutBottomLeft', name: 'Fade out bottom left' },
    ],
  },
  {
    groupName: 'Flippers',
    optionsList: [
      { id: 'flip', name: 'Flip' },
      { id: 'flipInX', name: 'Flip in X' },
      { id: 'flipInY', name: 'Flip in Y' },
      { id: 'flipOutX', name: 'Flip out X' },
      { id: 'flipOutY', name: 'Flip out Y' },
    ],
  },
  {
    groupName: 'Lightspeed',
    optionsList: [
      { id: 'lightSpeedInRight', name: 'Light speed in right' },
      { id: 'lightSpeedInLeft', name: 'Light speed in left' },
      { id: 'lightSpeedOutRight', name: 'Light speed out right' },
      { id: 'lightSpeedOutLeft', name: 'Light speed out left' },
    ],
  },
  {
    groupName: 'Rotating entrances',
    optionsList: [
      { id: 'rotateIn', name: 'Rotate in' },
      { id: 'rotateInDownLeft', name: 'Rotate in down left' },
      { id: 'rotateInDownRight', name: 'Rotate in down right' },
      { id: 'rotateInUpLeft', name: 'Rotate in up left' },
      { id: 'rotateInUpRight', name: 'Rotate in up right' },
    ],
  },
  {
    groupName: 'Rotating exits',
    optionsList: [
      { id: 'rotateOut', name: 'Rotate out ' },
      { id: 'rotateOutDownLeft', name: 'Rotate out down left' },
      { id: 'rotateOutDownRight', name: 'Rotate out down right' },
      { id: 'rotateOutUpLeft', name: 'Rotate out up left' },
      { id: 'rotateOutUpRight', name: 'Rotate out up right' },
    ],
  },
  {
    groupName: 'Specials',
    optionsList: [
      { id: 'hinge', name: 'Hinge' },
      { id: 'jackInTheBox', name: 'Jack in the box' },
      { id: 'rollIn', name: 'Roll in' },
      { id: 'rollOut', name: 'Roll out' },
    ],
  },
  {
    groupName: 'Zooming entrances',
    optionsList: [
      { id: 'zoomIn', name: 'Zoon in' },
      { id: 'zoomInDown', name: 'Zoon in down' },
      { id: 'zoomInLeft', name: 'Zoon in left' },
      { id: 'zoomInRight', name: 'Zoon in right' },
      { id: 'zoomInUp', name: 'Zoon in up' },
    ],
  },
  {
    groupName: 'Zooming exits',
    optionsList: [
      { id: 'zoomOut', name: 'Zoom out' },
      { id: 'zoomOutDown', name: 'Zoom out down' },
      { id: 'zoomOutLeft', name: 'Zoom out left' },
      { id: 'zoomOutRight', name: 'Zoom out right' },
      { id: 'zoomOutUp', name: 'Zoom out up' },
    ],
  },
  {
    groupName: 'Sliding entrances',
    optionsList: [
      { id: 'slideInDown', name: 'Slide In down' },
      { id: 'slideInLeft', name: 'Slide In left' },
      { id: 'slideInRight', name: 'Slide In right' },
      { id: 'slideInUp', name: 'Slide In up' },
    ],
  },
  {
    groupName: 'Sliding exits',
    optionsList: [
      { id: 'slideOutDown', name: 'Slide out down' },
      { id: 'slideOutLeft', name: 'Slide out left' },
      { id: 'slideOutRight', name: 'Slide out right' },
      { id: 'slideOutUp', name: 'Slide out up' },
    ],
  },
];

export const TIME_ZONE = [
  {
    name: '(UTC-11:00) Pacific/Pago_Pago',
    value: '-11:00',
  },
  {
    name: '(UTC-11:00) Pacific/Niue',
    value: '-11:00',
  },
  {
    name: '(UTC-11:00) Pacific/Midway',
    value: '-11:00',
  },
  {
    name: '(UTC-10:00) Pacific/Honolulu',
    value: '-10:00',
  },
  {
    name: '(UTC-10:00) Pacific/Tahiti',
    value: '-10:00',
  },
  {
    name: '(UTC-10:00) Pacific/Rarotonga',
    value: '-10:00',
  },
  {
    name: '(UTC-10:00) US/Hawaii',
    value: '-10:00',
  },
  {
    name: '(UTC-09:30) Pacific/Marquesas',
    value: '-09:30',
  },
  {
    name: '(UTC-09:00) America/Adak',
    value: '-09:00',
  },
  {
    name: '(UTC-09:00) Pacific/Gambier',
    value: '-09:00',
  },
  {
    name: '(UTC-08:00) America/Yakutat',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Anchorage',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Sitka',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) US/Alaska',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Nome',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Metlakatla',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Juneau',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) Pacific/Pitcairn',
    value: '-08:00',
  },
  {
    name: '(UTC-07:00) America/Whitehorse',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) Canada/Pacific',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Dawson',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Tijuana',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) US/Pacific',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Los_Angeles',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Vancouver',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Creston',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Hermosillo',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Dawson_Creek',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Fort_Nelson',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) US/Arizona',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Phoenix',
    value: '-07:00',
  },
  {
    name: '(UTC-06:00) America/Cambridge_Bay',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Ojinaga',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Chihuahua',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Denver',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) Canada/Mountain',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Edmonton',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Mazatlan',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Yellowknife',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Inuvik',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Boise',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) US/Mountain',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Tegucigalpa',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Costa_Rica',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Regina',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Belize',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/El_Salvador',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Swift_Current',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Guatemala',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Managua',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) Pacific/Galapagos',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) Pacific/Easter',
    value: '-06:00',
  },
  {
    name: '(UTC-05:00) Canada/Central',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/North_Dakota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Resolute',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Bahia_Banderas',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Monterrey',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Rankin_Inlet',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Mexico_City',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Rainy_River',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Merida',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Menominee',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Winnipeg',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Matamoros',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) US/Central',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/North_Dakota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/North_Dakota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Indiana',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Indiana',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Chicago',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Cayman',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Jamaica',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Lima',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Guayaquil',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Rio_Branco',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Atikokan',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Bogota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Panama',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Cancun',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Eirunepe',
    value: '-05:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Havana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Iqaluit',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) US/Eastern',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Kentucky',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Kentucky',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Toronto',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Thunder_Bay',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) Canada/Eastern',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Port',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Pangnirtung',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Detroit',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Nipigon',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/New_York',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Nassau',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Montserrat',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Curacao',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Caracas',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Grand_Turk',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Grenada',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Guadeloupe',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Martinique',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Port_of_Spain',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Porto_Velho',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Puerto_Rico',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Boa_Vista',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Blanc',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Barbados',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Marigot',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Manaus',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Dominica',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Aruba',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Antigua',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Anguilla',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Barthelemy',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Kitts',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Lucia',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Thomas',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Vincent',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Lower_Princes',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Guyana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Kralendijk',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/La_Paz',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Tortola',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Santo_Domingo',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Cuiaba',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Asuncion',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Santiago',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Campo_Grande',
    value: '-04:00',
  },
  {
    name: '(UTC-03:00) America/Moncton',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Atlantic/Bermuda',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Glace_Bay',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Goose_Bay',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Halifax',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Canada/Atlantic',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Thule',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Punta_Arenas',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Atlantic/Stanley',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Recife',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Antarctica/Palmer',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Bahia',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Santarem',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Antarctica/Rothera',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Araguaina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Belem',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Montevideo',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Fortaleza',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Cayenne',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Paramaribo',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Maceio',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Sao_Paulo',
    value: '-03:00',
  },
  {
    name: '(UTC-02:30) Canada/Newfoundland',
    value: '-02:30',
  },
  {
    name: '(UTC-02:30) America/St_Johns',
    value: '-02:30',
  },
  {
    name: '(UTC-02:00) America/Miquelon',
    value: '-02:00',
  },
  {
    name: '(UTC-02:00) America/Godthab',
    value: '-02:00',
  },
  {
    name: '(UTC-02:00) America/Noronha',
    value: '-02:00',
  },
  {
    name: '(UTC-02:00) Atlantic/South_Georgia',
    value: '-02:00',
  },
  {
    name: '(UTC-01:00) Atlantic/Cape_Verde',
    value: '-01:00',
  },
  {
    name: '(UTC+00:00) America/Scoresbysund',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Atlantic/Azores',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Abidjan',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Atlantic/Reykjavik',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Banjul',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) GMT</',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Lome',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Bissau',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Freetown',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Bamako',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Monrovia',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Conakry',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) America/Danmarkshavn',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Dakar',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Atlantic/St_Helena',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Nouakchott',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Ouagadougou',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Accra',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) UTC</',
    value: '+00:00',
  },
  {
    name: '(UTC+00:00) Africa/Sao_Tome',
    value: '+00:00',
  },
  {
    name: '(UTC+01:00) Atlantic/Madeira',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Atlantic/Faroe',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Europe/London',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Europe/Lisbon',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Europe/Jersey',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Europe/Isle_of_Man',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Europe/Guernsey',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Europe/Dublin',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/El_Aaiun',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Casablanca',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Atlantic/Canary',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Bangui',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Brazzaville',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Douala',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Algiers',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Kinshasa',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Lagos',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Libreville',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Luanda',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Tunis',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Porto',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Niamey',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Ndjamena',
    value: '+01:00',
  },
  {
    name: '(UTC+01:00) Africa/Malabo',
    value: '+01:00',
  },
  {
    name: '(UTC+02:00) Antarctica/Troll',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Malta',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Prague',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Ceuta',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Zurich',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Zagreb',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Warsaw',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Vienna',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Vatican',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Vaduz',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Tirane',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Stockholm',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Skopje',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Sarajevo',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/San_Marino',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Rome',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Arctic/Longyearbyen',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Podgorica',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Paris',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Oslo',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Monaco',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Madrid',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Luxembourg',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Ljubljana',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Gibraltar',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Copenhagen',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Busingen',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Budapest',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Brussels',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Bratislava',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Berlin',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Belgrade',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Andorra',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Amsterdam',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Bujumbura',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Cairo',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Mbabane',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Europe/Kaliningrad',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Blantyre',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Lubumbashi',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Gaborone',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Lusaka',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Harare',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Johannesburg',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Khartoum',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Windhoek',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Tripoli',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Kigali',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Maputo',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Maseru',
    value: '+02:00',
  },
  {
    name: '(UTC+02:00) Africa/Juba',
    value: '+02:00',
  },
  {
    name: '(UTC+03:00) Europe/Mariehamn',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Jerusalem',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Vilnius',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Beirut',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Kyiv',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Uzhgorod',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Helsinki',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Tallinn',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Chisinau',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Sofia',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Bucharest',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Damascus',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Nicosia',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Famagusta',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Riga',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Gaza',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Hebron',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Athens',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Amman',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Zaporizhia',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Antarctica/Syowa',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Bahrain',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Riyadh',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Minsk',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Africa/Addis_Ababa',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Kuwait',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Africa/Dar_es_Salaam',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Simferopol',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Africa/Kampala',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Africa/Djibouti',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Kirov',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Volgograd',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Indian/Mayotte',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Indian/Comoro',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Istanbul',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Baghdad',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Africa/Asmara',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Aden',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Indian/Antananarivo',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Europe/Moscow',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Asia/Qatar',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Africa/Mogadishu',
    value: '+03:00',
  },
  {
    name: '(UTC+03:00) Africa/Nairobi',
    value: '+03:00',
  },
  {
    name: '(UTC+03:30) Asia/Tehran',
    value: '+03:30',
  },
  {
    name: '(UTC+04:00) Europe/Ulyanovsk',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Indian/Reunion',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Asia/Dubai',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Indian/Mauritius',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Indian/Mahe',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Asia/Baku',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Europe/Saratov',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Asia/Muscat',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Europe/Samara',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Asia/Tbilisi',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Europe/Astrakhan',
    value: '+04:00',
  },
  {
    name: '(UTC+04:00) Asia/Yerevan',
    value: '+04:00',
  },
  {
    name: '(UTC+04:30) Asia/Kabul',
    value: '+04:30',
  },
  {
    name: '(UTC+05:00) Asia/Tashkent',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Oral',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Karachi',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Aqtau',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Aqtobe',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Ashgabat',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Atyrau',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Antarctica/Mawson',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Yekaterinburg',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Dushanbe',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Indian/Maldives',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Indian/Kerguelen',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Samarkand',
    value: '+05:00',
  },
  {
    name: '(UTC+05:00) Asia/Qyzylorda',
    value: '+05:00',
  },
  {
    name: '(UTC+05:30) Asia/Colombo',
    value: '+05:30',
  },
  {
    name: '(UTC+05:30) Asia/Kolkata',
    value: '+05:30',
  },
  {
    name: '(UTC+05:45) Asia/Kathmandu',
    value: '+05:45',
  },
  {
    name: '(UTC+06:00) Indian/Chagos',
    value: '+06:00',
  },
  {
    name: '(UTC+06:00) Asia/Thimphu',
    value: '+06:00',
  },
  {
    name: '(UTC+06:00) Asia/Urumqi',
    value: '+06:00',
  },
  {
    name: '(UTC+06:00) Antarctica/Vostok',
    value: '+06:00',
  },
  {
    name: '(UTC+06:00) Asia/Almaty',
    value: '+06:00',
  },
  {
    name: '(UTC+06:00) Asia/Dhaka',
    value: '+06:00',
  },
  {
    name: '(UTC+06:00) Asia/Omsk',
    value: '+06:00',
  },
  {
    name: '(UTC+06:00) Asia/Bishkek',
    value: '+06:00',
  },
  {
    name: '(UTC+06:30) Indian/Cocos',
    value: '+06:30',
  },
  {
    name: '(UTC+06:30) Asia/Yangon',
    value: '+06:30',
  },
  {
    name: '(UTC+07:00) Asia/Pontianak',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Phnom_Penh',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Tomsk',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Vientiane',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Novosibirsk',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Novokuznetsk',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Indian/Christmas',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Ho_Chi_Minh',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Hovd',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Jakarta',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Antarctica/Davis',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Bangkok',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Barnaul',
    value: '+07:00',
  },
  {
    name: '(UTC+07:00) Asia/Krasnoyarsk',
    value: '+07:00',
  },
  {
    name: '(UTC+08:00) Asia/Kuala_Lumpur',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Australia/Perth',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Ulaanbaatar',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Macau',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Taipei',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Brunei',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Makassar',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Choibalsan',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Singapore',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Kuching',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Shanghai',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Irkutsk',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Manila',
    value: '+08:00',
  },
  {
    name: '(UTC+08:00) Asia/Hong_Kong',
    value: '+08:00',
  },
  {
    name: '(UTC+08:45) Australia/Eucla',
    value: '+08:45',
  },
  {
    name: '(UTC+09:00) Asia/Pyongyang',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Asia/Tokyo',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Pacific/Palau',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Asia/Khandyga',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Asia/Yakutsk',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Asia/Chita',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Asia/Jayapura',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Asia/Dili',
    value: '+09:00',
  },
  {
    name: '(UTC+09:00) Asia/Seoul',
    value: '+09:00',
  },
  {
    name: '(UTC+09:30) Australia/Darwin',
    value: '+09:30',
  },
  {
    name: '(UTC+09:30) Australia/Broken_Hill',
    value: '+09:30',
  },
  {
    name: '(UTC+09:30) Australia/Adelaide',
    value: '+09:30',
  },
  {
    name: '(UTC+10:00) Pacific/Guam',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Pacific/Saipan',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Antarctica/DumontDUrville',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Asia/Ust',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Asia/Vladivostok',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Pacific/Chuuk',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Australia/Lindeman',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Australia/Brisbane',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Pacific/Port_Moresby',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Australia/Sydney',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Australia/Melbourne',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Australia/Currie',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Australia/Hobart',
    value: '+10:00',
  },
  {
    name: '(UTC+10:00) Antarctica/Macquarie',
    value: '+10:00',
  },
  {
    name: '(UTC+10:30) Australia/Lord_Howe',
    value: '+10:30',
  },
  {
    name: '(UTC+11:00) Asia/Srednekolymsk',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Guadalcanal',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Bougainville',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Kosrae',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Pohnpei',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Efate',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Asia/Magadan',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Asia/Sakhalin',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Norfolk',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Noumea',
    value: '+11:00',
  },
  {
    name: '(UTC+11:00) Antarctica/Casey',
    value: '+11:00',
  },
  {
    name: '(UTC+12:00) Pacific/Wallis',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Nauru',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Kwajalein',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Asia/Anadyr',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Asia/Kamchatka',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Majuro',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Tarawa',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Wake',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Funafuti',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Fiji',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Auckland',
    value: '+12:00',
  },
  {
    name: '(UTC+12:00) Antarctica/McMurdo',
    value: '+12:00',
  },
  {
    name: '(UTC+12:45) Pacific/Chatham',
    value: '+12:45',
  },
  {
    name: '(UTC+13:00) Pacific/Tongatapu',
    value: '+13:00',
  },
  {
    name: '(UTC+13:00) Pacific/Fakaofo',
    value: '+13:00',
  },
  {
    name: '(UTC+13:00) Pacific/Enderbury',
    value: '+13:00',
  },
  {
    name: '(UTC+13:00) Pacific/Apia',
    value: '+13:00',
  },
  {
    name: '(UTC+14:00) Pacific/Kiritimati',
    value: '+14:00',
  },
];
