import { createSlice } from '@reduxjs/toolkit';

import { getCustomBlocksGroup } from 'store/actions';

const initialState = {
  customBlockCategories: {},
};

const { reducer: customBlocksReducer } = createSlice({
  name: 'blocks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomBlocksGroup.fulfilled, (state, action) => {
      state.customBlockCategories = action.payload;
    });
  },
});

export { customBlocksReducer };
