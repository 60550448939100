import { useLocation } from 'react-router-dom';

export function useQuery() {
  const { search } = useLocation();

  const res = {};

  if (!search) {
    return res;
  }

  return search
    .substring(1)
    .split('&')
    .reduce((acc, param) => {
      const [key, value = ''] = param.split('=');

      acc[key] = value;

      return acc;
    }, res);
}
