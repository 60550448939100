import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LOAD_STATUS } from 'appConstants';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getCalendar, getCalendarServices } from 'store/actions';
import { userEmailSelector } from 'store/selectors';
import Switch from 'components/Forms/Switch';
import Combobox from '../../Combobox';
import Button from '../../Button';
import Header from '../Header';
import './WayMoreCalendarModal.sass';

function WayMoreCalendarModal({ onSaveWayMoreCalendar, onClose }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const messages = defineMessages({
    servicesLabel: { id: 'waymore-calendar-modal.services-label' },
    servicesPlaceholder: { id: 'waymore-calendar-modal.services-placeholder' },
    noHeaderLabel: { id: 'waymore-calendar-modal.noHeader-label' },
    noHeaderDescription: {
      id: 'waymore-calendar-modal.noHeader-description',
    },
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [noHeader, setNoHeader] = useState(false);

  const userEmail = useSelector(userEmailSelector);

  const calendarStatus = useSelector((state) => state.waymore.calendar.status);
  const calendarServicesStatus = useSelector(
    (state) => state.waymore.calendarServices.status,
  );
  const { id: calendarId, services } = useSelector(
    (state) => state?.waymore?.calendar?.calendarData,
  );
  const calendarServices = useSelector((state) => {
    const currentService =
      state?.waymore?.calendarServices?.calendarServicesData.filter((item) =>
        services?.includes(item.id),
      );

    return currentService.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  });

  useEffect(() => {
    dispatch(getCalendar(userEmail));
    dispatch(getCalendarServices());
  }, [dispatch, intl, userEmail]);

  const loading =
    (calendarStatus === LOAD_STATUS.IDLE ||
      calendarStatus === LOAD_STATUS.LOADING) &&
    (calendarServicesStatus === LOAD_STATUS.IDLE ||
      calendarServicesStatus === LOAD_STATUS.LOADING);

  const itemToString = (item) => (item ? item.name : '');
  const normalizeItem = (item) => ({ key: item.uuid, label: item.name });
  const finalCalendarServices = [
    {
      id: 0,
      uuid: 'all',
      name: 'All services',
    },
    ...calendarServices,
  ];

  return (
    <>
      <Header className="waymore-calendar-modal__header" onClose={onClose}>
        <FormattedMessage id="waymore-calendar-modal.title" />
      </Header>
      <div className="waymore-calendar-modal__content-wrapper">
        <Combobox
          label={intl.formatMessage(messages.servicesLabel)}
          placeholder={intl.formatMessage(messages.servicesPlaceholder)}
          items={finalCalendarServices}
          itemToString={itemToString}
          normalizeItem={normalizeItem}
          loading={loading}
          disabled={calendarServices?.length <= 0}
          fullWidth
          onInputValueChange={({ inputValue }) => setSearchTerm(inputValue)}
          onSelectedItemChange={(changes) =>
            setSelectedItem(changes.selectedItem)
          }
        />
        <Switch
          label={intl.formatMessage(messages.noHeaderLabel)}
          helperText={intl.formatMessage(messages.noHeaderDescription)}
          checked={noHeader}
          disabled={false}
          fullWidth
          onChange={(checked) => setNoHeader(checked)}
        />
        <div className="waymore-calendar-modal__buttons">
          <Button
            type="button"
            variant="contained"
            disabled={!selectedItem || loading}
            onClick={() =>
              onSaveWayMoreCalendar(calendarId, selectedItem.uuid, noHeader)
            }>
            {intl.formatMessage({ id: 'button.add' })}
          </Button>
        </div>
      </div>
    </>
  );
}

WayMoreCalendarModal.propTypes = {
  onSaveWayMoreCalendar: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WayMoreCalendarModal;
