export const panelsConfig = {
  defaults: [
    {
      id: 'template-options',
      el: '.editor-header__panel-template-options',
      buttons: [
        {
          id: 'page-settings',
          className: 'icon icon-settings',
          command: 'open-page-settings',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Page Settings',
          },
        },
        {
          id: 'view-code-modal',
          className: 'icon icon-code',
          command: 'view-code-modal',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'View Code',
          },
        },
        {
          id: 'open-import-modal',
          className: 'icon icon-cloud-download',
          command: 'open-import-modal',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Import ',
          },
        },
        {
          id: 'canvas-clear',
          className: 'icon icon-delete',
          command: 'clean',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Clean ',
          },
        },
        {
          id: 'show-help',
          className: 'icon icon-help',
          command: 'help',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Help ',
          },
        },
      ],
    },
    {
      id: 'blocks-tabs',
      el: '.editor-content__panel-blocks-tabs',
      buttons: [
        {
          id: 'blocks',
          label: '<span class="icon icon-extension"></span>Blocks',
          command: 'custom-open-blocks',
          togglable: false,
        },
        {
          id: 'modules',
          label: '<span class="icon icon-view-compact"></span>Modules',
          command: 'custom-open-blocks',
          togglable: false,
        },
        {
          id: 'waymore',
          label: '<span class="icon icon-electric-bolt"></span>Advanced',
          command: 'custom-open-blocks',
          togglable: false,
        },
      ],
    },
    {
      id: 'canvas-controls',
      el: '.editor-content__panel-canvas-controls',
      buttons: [
        {
          id: 'sw-visibility',
          command: 'sw-visibility',
          className: 'icon icon-border-clear',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Show Borders',
          },
        },
        {
          id: 'fullscreen',
          command: {
            run(editor) {
              editor.runCommand('fullscreen', { target: 'body' });
            },
            stop(editor) {
              editor.stopCommand('fullscreen');
            },
          },
          className: 'icon icon-fullscreen',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Fullscreen ',
          },
        },
        {
          id: 'change-drag-mode',
          context: 'change-drag-mode',
          className: 'icon icon-drag-mode',
          command: {
            run(editor) {
              editor.getModel().set('dmode', 'absolute');
            },
            stop(editor) {
              editor.getModel().set('dmode', null);
            },
          },
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Drag Mode ',
          },
        },
        {
          id: 'personal-note',
          command: (e) => e.runCommand('personal-notes'),
          className: 'icon icon-add-comment',
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Personal note',
          },
        },
      ],
    },
    {
      id: 'canvas-history',
      el: '.editor-content__panel-canvas-history',
      buttons: [
        {
          id: 'undo',
          className: 'icon icon-undo',
          command: 'core:undo',
          disable: true,
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Undo',
          },
        },
        {
          id: 'history',
          className: 'icon icon-history',
          command: (e) => e.runCommand('version-history'),
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Version history',
          },
        },
        {
          id: 'redo',
          className: 'icon icon-redo',
          command: 'core:redo',
          disable: true,
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Redo',
          },
        },
      ],
    },
    {
      id: 'responsive-actions',
      el: '.editor-content__panel-responsive-actions',
      buttons: [
        {
          id: 'preview-desktop',
          className: 'icon icon-desktop-windows',
          command: 'set-desktop',
          togglable: false,
          active: true,
        },
        {
          id: 'preview-tablet',
          className: 'icon icon-tablet-mac',
          command: 'set-tablet',
          togglable: false,
        },
        {
          id: 'preview-mobile',
          className: 'icon icon-phone-iphone',
          command: 'set-mobile',
          togglable: false,
        },
      ],
    },
    {
      id: 'zoom-actions',
      el: '.editor-content__panel-zoom-actions',
      buttons: [
        {
          id: 'editor-zoom-reset',
          className: 'icon icon-youtube-searched-for',
          command: 'editor-zoom-reset',
          togglable: false,
          active: false,
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Zoom reset',
          },
        },
        {
          id: 'editor-zoom-in',
          className: 'icon icon-zoom-in',
          command: 'editor-zoom-in',
          togglable: false,
          active: false,
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Zoom in',
          },
        },
        {
          id: 'editor-zoom-out',
          className: 'icon icon-zoom-out',
          command: 'editor-zoom-out',
          togglable: false,
          active: false,
          attributes: {
            'data-tooltip-pos': 'bottom',
            'data-tooltip': 'Zoom out',
          },
        },
      ],
    },
    {
      id: 'traits-tabs',
      el: '.editor-content__panel-traits-tabs',
      buttons: [
        {
          id: 'open-sm-custom',
          command: 'open-sm-custom',
          label:
            '<span class="icon icon-settings"></span><span>Settings & Styles</span>',
          togglable: false,
        },
        {
          id: 'open-layers',
          command: 'open-layers',
          label: '<span class="icon icon-layers"></span><span>Layers</span>',
          togglable: false,
        },
      ],
    },
    {
      id: 'views-container',
      el: '.editor-content__traits-container',
    },
  ],
};
