import cx from 'classnames';
import { useToggleTypes } from 'hooks';
import { setButtonClickAttribute } from 'routes/Editor/utils';

export const commandsConfig = {
  defaults: [
    {
      id: 'editor-zoom-reset',
      run(editor) {
        editor.Canvas.setZoom('100');
        document.querySelector(
          '.editor-content__panel-zoom-actions__view > span',
        ).innerHTML = '100';
      },
    },
    {
      id: 'editor-zoom-in',
      run(editor) {
        const zoom = editor.Canvas.getZoom();

        editor.Canvas.setZoom(`${zoom + 5}`);
        document.querySelector(
          '.editor-content__panel-zoom-actions__view > span',
        ).innerHTML = editor?.Canvas?.getZoom();
      },
    },
    {
      id: 'editor-zoom-out',
      run(editor) {
        const zoom = editor.Canvas.getZoom();

        editor.Canvas.setZoom(`${zoom - 5}`);
        document.querySelector(
          '.editor-content__panel-zoom-actions__view > span',
        ).innerHTML = editor?.Canvas?.getZoom();
      },
    },
    {
      id: 'set-desktop',
      run(editor) {
        editor.setDevice('Desktop');
      },
      // Without the 'stop' method an active tab won't be highlighted
      stop() {},
    },
    {
      id: 'set-tablet',
      run(editor) {
        editor.setDevice('Tablet');
      },
      // Without the 'stop' method an active tab won't be highlighted
      stop() {},
    },
    {
      id: 'set-mobile',
      run(editor) {
        editor.setDevice('Mobile portrait');
      },
      // Without the 'stop' method an active tab won't be highlighted
      stop() {},
    },
    {
      id: 'custom-open-blocks',
      run(editor, { id }) {
        // clear search blocks input on tab changes
        const setSearchBlocksTerm = editor
          .getModel()
          .get('setSearchBlocksTerm');

        const setBlocks = editor.getModel().get('setBlocks');
        const setBlocksTab = editor.getModel().get('setBlocksTab');
        const setCustomModulesGroupActions = editor
          .getModel()
          .get('setCustomModulesGroupActions');
        const deleteModuleCategoryConfirmModal = editor
          .getModel()
          .get('deleteModuleCategoryConfirmModal');
        const editModuleCategoryModal = editor
          .getModel()
          .get('editModuleCategoryModal');

        setSearchBlocksTerm('');

        editor.getModel().set('blocksType', id);

        setBlocks(editor);

        setBlocksTab(id);

        if (id === 'modules') {
          const categories = editor.BlockManager.getCategories();

          setCustomModulesGroupActions(
            categories,
            deleteModuleCategoryConfirmModal,
            editModuleCategoryModal,
          );
        }
      },
      // Without the 'stop' method an active tab won't be highlighted
      stop() {},
    },
    {
      id: 'toggle-abs-move',
      run(editor) {
        const prevStyleKey = '_prev-style';
        const selected = editor.getSelected();
        const toolbarButtons = selected.get('toolbar');
        const toggleFreeMoveButton = toolbarButtons.find(
          (button) => button.command === 'toggle-abs-move',
        );
        let active = false;

        if (selected.get('dmode')) {
          const prevStyle = selected.get(prevStyleKey);

          selected.addStyle(prevStyle);

          selected.setDragMode();
        } else {
          const {
            position = '',
            top = '',
            left = '',
            height = '',
            width = '',
          } = selected.getStyle();

          active = true;

          selected.set(prevStyleKey, { position, top, left, height, width });
          selected.setDragMode('absolute');
        }

        if (toggleFreeMoveButton) {
          toggleFreeMoveButton.attributes.class = cx('icon icon-drag-mode', {
            active,
          });

          // grapesjs doesn't allow to update toolbar buttons right away and
          // there is no method to update it programmatically. In order to update
          // component should be selected again. This workaround do the thing
          editor.selectToggle(selected);
          setTimeout(() => {
            editor.selectToggle(selected);
          }, 0);
        }
      },
    },
    {
      id: 'create-block',
      run(editor) {
        const model = editor.getModel();

        const payload = editor.runCommand('export-component');

        model.get('onShow')(useToggleTypes.showBlockModal, payload);
      },
    },
    {
      id: 'move-up-block',
      run(editor) {
        const wrapper = editor?.getWrapper();
        const selected = editor.getSelected();
        const nextIndex = selected.index() === 0 ? 0 : selected.index() - 1;
        const selectedParent = selected.parent();
        const oldStyles = selected.getStyle();

        // temporary option will avoid removing component related styles
        selected.remove({ temporary: true });

        const addedBlock = selectedParent.append(selected, { at: nextIndex });

        editor.select(wrapper);
        editor.select(addedBlock);
        editor.getSelected().setStyle({ ...oldStyles });
      },
    },
    {
      id: 'move-down-block',
      run(editor) {
        const wrapper = editor?.getWrapper();
        const selected = editor.getSelected();
        const prevIndex = selected.index() + 1;
        const selectedParent = selected.parent();
        const oldStyles = selected.getStyle();

        // temporary option will avoid removing component related styles
        selected.remove({ temporary: true });

        const addedBlock = selectedParent.append(selected, { at: prevIndex });

        editor.select(wrapper);
        editor.select(addedBlock);
        editor.getSelected().setStyle({ ...oldStyles });
      },
    },
    {
      id: 'edit-product',
      run(editor) {
        const onShow = editor.getModel().get('onShow');

        onShow(useToggleTypes.showWayMoreProductModal);
      },
    },
    {
      id: 'change-block-settings-box-status',
      run(editor, sender, { state }) {
        if (editor?.getSelected()?.closest('form')) {
          editor.getSelected().closest('form').addAttributes({ state });
        }
      },
    },
    {
      id: 'show-block-settings-box',
      run(editor, sender, { status = 'open' }) {
        document.querySelector('.block-settings-box').style.display =
          status === 'open' ? 'block' : 'none';
      },
    },
    {
      id: 'export-css',
      run(editor, _, { target }) {
        const rules = editor.CssComposer.getAll();

        const splittedRules = this.splitRules(this.matchRules(target, rules));

        let notAtRulesCSS = splittedRules.notAtRules.reduce((acc, rule) => {
          acc += rule.toCSS();

          return acc;
        }, '');

        const atRulesCSS = this.sortMediaObject(splittedRules.atRules).reduce(
          (acc, [key, value]) => {
            let temp = '';

            value.forEach((rule) => {
              const declaration = rule.getDeclaration();

              if (rule.get('singleAtRule')) {
                acc += `${key}{${declaration}}`;
              } else {
                temp += declaration;
              }
            });

            acc += `${key}{${temp}}`;

            return acc;
          },
          '',
        );

        return notAtRulesCSS + atRulesCSS;
      },
      matchRules(target, rules) {
        const element = target.getEl();

        // searching for rules that match components
        let matched = rules.reduce((acc, rule) => {
          const isMatching = rule
            .selectorsToString()
            .split(',')
            .some((selector) => {
              try {
                if (!selector || !element.matches) {
                  return false;
                }

                return element.matches(this.getCleanSelector(selector));
              } catch {
                return false;
              }
            });

          if (isMatching) {
            acc.push(rule);
          }

          return acc;
        }, []);

        return target.components().reduce((acc, component) => {
          return acc.concat(this.matchRules(component, rules));
        }, matched);
      },
      getCleanSelector(selector) {
        return selector
          .split(' ')
          .map((part) => part.split(':')[0])
          .join(' ');
      },
      splitRules(rules) {
        const res = {
          atRules: {},
          notAtRules: [],
        };

        rules.forEach((rule) => {
          const atRule = rule.getAtRule();

          if (!atRule) {
            res.notAtRules.push(rule);
          } else if (res.atRules[atRule]) {
            res.atRules[atRule].push(rule);
          } else {
            res.atRules[atRule] = [rule];
          }
        });

        return res;
      },
      getQueryLength(query) {
        const queryRegex = /(-?\d*\.?\d+)\w{0,}/.exec(query);
        return queryRegex ? parseFloat(queryRegex[1]) : Number.MAX_VALUE;
      },
      sortMediaObject(atRules) {
        return Object.entries(atRules).sort(
          (a, b) => this.getQueryLength(b[0]) - this.getQueryLength(a[0]),
        );
      },
    },
    {
      id: 'export-component',
      run(editor) {
        const res = {
          html: '',
          css: '',
          js: '',
        };

        const selected = editor.getSelected();

        res.html = selected.toHTML();
        res.css = editor.runCommand('export-css', { target: selected });
        res.js = editor.CodeManager.getCode(selected, 'js').trim();

        return res;
      },
    },
    {
      id: 'add-font',
      run(editor, _, { url }) {
        const shouldAddFont =
          editor.Canvas.getDocument().querySelectorAll(`link[href='${url}']`)
            .length === 0;

        if (shouldAddFont) {
          const link = document.createElement('link');

          link.href = url;
          link.rel = 'stylesheet';

          editor.Canvas.getDocument().head.appendChild(link);
        }
      },
    },
    {
      id: 'add-event-click',
      run(editor) {
        setButtonClickAttribute(editor, 'design');
      },
      // Without the 'stop' method an active tab won't be highlighted
      stop() {},
    },
    {
      id: 'open-add-event-click-modal',
      run(editor) {
        const model = editor.getModel();
        model.get('onShow')(useToggleTypes.showAddClickEventModal);
      },
    },
  ],
};
