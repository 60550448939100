import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { generateId } from 'helpers';
import FormControl from '../FormControl';
import Label from '../Label';
import Input from '../Input';
import FormHelperText from '../FormHelperText';

const TextField = React.forwardRef(function TextField(
  {
    type,
    className,
    label,
    helperText,
    error,
    id,
    multiline,
    fullWidth,
    before,
    after,
    ...rest
  },
  ref,
) {
  const inputId = useRef(generateId('dnd-text-field'));

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && <Label htmlFor={inputId.current}>{label}</Label>}
      <Input
        id={inputId.current}
        error={error}
        type={type}
        before={before}
        after={after}
        multiline={multiline}
        fullWidth={fullWidth}
        aria-describedby={`${inputId.current}-helper-text`}
        {...rest}
        ref={ref}
      />
      {helperText && (
        <FormHelperText id={`${inputId.current}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

TextField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.node,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'number',
    'email',
    'datetime-local',
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  before: PropTypes.node,
  after: PropTypes.node,
};

TextField.defaultProps = {
  type: 'text',
};

export default TextField;
