import { toast } from 'react-toastify';

const defaultConfig = {
  theme: 'colored',
  position: 'bottom-right',
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
};

/**
 *
 * @param {string} msg Message to show
 * @param {object} config Config for toast
 */
export const showSuccessMessage = (msg, config = {}) => {
  toast.success(msg, {
    ...defaultConfig,
    ...config,
  });
};

/**
 *
 * @param {string} msg Message to show
 * @param {object} config Config for toast
 */
export const showWarningMessage = (msg, config = {}) => {
  toast.warn(msg, {
    ...defaultConfig,
    ...config,
  });
};

/**
 *
 * @param {string} msg Message to show
 * @param {object} config Config for toast
 */
export const showErrorMessage = (msg, config = {}) => {
  toast.error(msg, {
    ...defaultConfig,
    ...config,
  });
};
