export const normalizeUnsplashResponse = (data) => {
  return data.map((item) => ({
    id: item.id,
    thumbUrl: item.urls.thumb,
    description:
      item.alt_description || item.description || `by ${item.user.name}`,
    userUrl: `${item.user.links.html}?utm_source=routee&utm_medium=email`,
    userName: item.user.name,
    url: item.urls.regular,
    downloadUrl: item.links.download_location,
  }));
};

export const normalizeIconFinderResponse = (data) => {
  return data.reduce((acc, item) => {
    // taking url of the largest icon size
    const url = item.raster_sizes
      ? item.raster_sizes[item.raster_sizes.length - 1]?.formats[0].preview_url
      : null;

    const svgDownloadUrl = item.vector_sizes
      ? item.vector_sizes[item.vector_sizes.length - 1]?.formats[0].download_url
      : null;

    if (url) {
      acc.push({
        id: item.icon_id,
        url,
        description: item.tags.join(' '),
        svgDownloadUrl,
      });
    }

    return acc;
  }, []);
};

export const normalizePagination = (pagination) => ({
  firstPage: pagination['first-page'],
  lastPage: pagination['last-page'],
  totalPage: pagination['total-page'],
  totalElements: pagination['total-elements'],
  elements: pagination.elements,
});

export const normalizeTemplatesResponse = ({ state, payload, key }) => ({
  template: payload[key].pagination['first-page']
    ? payload[key].template
    : [...state[key].template, ...payload[key].template],
  pagination: {
    ...state[key].pagination,
    currentPage: payload[key].pagination['first-page']
      ? 0
      : state[key].pagination.currentPage,
    ...normalizePagination(payload[key].pagination),
  },
});

export const normalizeSystemTemplatesResponse = ({ state, payload }) => ({
  template: payload.pagination['first-page']
    ? payload.template
    : [...state.deleted.template, ...payload.template],
  pagination: {
    ...state.deleted.pagination,
    currentPage: payload.pagination['first-page']
      ? 0
      : state.deleted.pagination.currentPage,
    ...normalizePagination(payload.pagination),
  },
});
