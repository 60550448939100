import React from 'react';
import PropTypes from 'prop-types';
import { TEMPLATE_STATUS } from 'appConstants';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { isAdminSelector } from 'store/selectors';
import { useModal } from 'hooks';
import BaseModal from 'components/Modals/BaseModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import cx from 'classnames';
import './BulkActions.sass';

const BulkActions = ({
  status,
  isSystem,
  bulkActionTemplatesList,
  setBulkActionTemplatesList,
  bulkAction,
  setBulkAction,
  handleBulkUnpublishTemplatesClick,
  handleBulkSoftDeleteTemplatesClick,
  handleBulkHardDeleteTemplatesClick,
  handleBulkSoftDeleteSystemTemplatesClick,
  handleBulkHardDeleteSystemTemplatesClick,
}) => {
  const isAdmin = useSelector(isAdminSelector);

  const {
    isOpen: isConfirmDeleteModalOpen,
    open: openConfirmDeleteModal,
    close: closeConfirmDeleteModal,
  } = useModal();

  const resetBulkActions = () => {
    setBulkAction(false);
    setBulkActionTemplatesList([]);
  };

  const handleSaveBulkAction = (type) => {
    // user published templates -> move to draft
    if (!isSystem && type === 'unpublish') {
      handleBulkUnpublishTemplatesClick(bulkActionTemplatesList);
      resetBulkActions();
    }

    // user published/drafted templates -> move to deleted
    if (!isSystem && type === 'remove') {
      handleBulkSoftDeleteTemplatesClick(bulkActionTemplatesList);
      resetBulkActions();
    }

    // user deleted templates -> remove forever
    if (!isSystem && type === 'delete-forever') {
      openConfirmDeleteModal();
    }

    // published system templates -> move to deleted
    if (isSystem && type === 'remove') {
      handleBulkSoftDeleteSystemTemplatesClick(bulkActionTemplatesList);
      resetBulkActions();
    }

    // deleted system templates -> remove forever
    if (isSystem && type === 'delete-forever') {
      openConfirmDeleteModal();
    }
  };

  return (
    <div className="bulk-actions">
      <button
        className={cx('bulk-actions__bulk-button', {
          'admin-action': isAdmin && isSystem,
        })}
        onClick={() => {
          if (bulkAction) {
            setBulkActionTemplatesList([]);
          }

          setBulkAction(!bulkAction);
        }}>
        {!bulkAction ? (
          <>
            <span className="icon icon-magic-wand" />
            <span>
              <FormattedMessage id="editor-header.filters.bulk-button.active-title" />
            </span>
          </>
        ) : (
          <>
            <span className="icon icon-close" />
            <span>
              <FormattedMessage id="editor-header.filters.bulk-button.cancel-title" />
            </span>
          </>
        )}
      </button>
      {bulkAction && (
        <div className="bulk-actions__actions">
          <span
            data-testid="bulk-actions__actions__selected-items"
            className="bulk-actions__actions__selected-items">
            <FormattedMessage id="editor-header.filters.bulk-button.selected-items-title" />
            : {bulkActionTemplatesList?.length || 0}
          </span>
          <div className="bulk-actions__actions__buttons">
            {status === TEMPLATE_STATUS.PUBLISHED && !isSystem && (
              <button
                className="bulk-actions__actions__buttons__unpublish"
                onClick={() => handleSaveBulkAction('unpublish')}
                disabled={bulkActionTemplatesList?.length === 0}>
                <FormattedMessage id="editor-header.filters.bulk-button.unpublish-title" />
              </button>
            )}
            {status !== TEMPLATE_STATUS.DELETED && (
              <button
                className="bulk-actions__actions__buttons__remove"
                onClick={() => handleSaveBulkAction('remove')}
                disabled={bulkActionTemplatesList?.length === 0}>
                <FormattedMessage id="editor-header.filters.bulk-button.remove-title" />
              </button>
            )}
            {status === TEMPLATE_STATUS.DELETED && (
              <button
                className="bulk-actions__actions__buttons__delete-forever"
                onClick={() => handleSaveBulkAction('delete-forever')}
                disabled={bulkActionTemplatesList?.length === 0}>
                <FormattedMessage id="editor-header.filters.bulk-button.delete-forever-title" />
              </button>
            )}
          </div>
        </div>
      )}
      <BaseModal
        isOpen={isConfirmDeleteModalOpen}
        headerTitle="templates.confirm-remove-header"
        maxWidth="600px"
        onClose={closeConfirmDeleteModal}>
        <ConfirmModal
          loading={false}
          buttonsColor={isSystem ? 'secondary' : 'primary'}
          onClose={closeConfirmDeleteModal}
          onConfirm={() => {
            if (isSystem) {
              handleBulkHardDeleteSystemTemplatesClick(bulkActionTemplatesList);
            } else {
              handleBulkHardDeleteTemplatesClick(bulkActionTemplatesList);
            }

            resetBulkActions();
            closeConfirmDeleteModal();
          }}>
          {isSystem ? (
            <FormattedMessage id="templates.confirm-remove-system-description" />
          ) : (
            <FormattedMessage id="templates.confirm-remove-description" />
          )}
        </ConfirmModal>
      </BaseModal>
    </div>
  );
};

BulkActions.propTypes = {
  status: PropTypes.string.isRequired,
  isSystem: PropTypes.bool.isRequired,
  bulkActionTemplatesList: PropTypes.array.isRequired,
  setBulkActionTemplatesList: PropTypes.func.isRequired,
  bulkAction: PropTypes.bool.isRequired,
  setBulkAction: PropTypes.func.isRequired,
  handleBulkUnpublishTemplatesClick: PropTypes.func.isRequired,
  handleBulkSoftDeleteTemplatesClick: PropTypes.func.isRequired,
  handleBulkHardDeleteTemplatesClick: PropTypes.func.isRequired,
  handleBulkSoftDeleteSystemTemplatesClick: PropTypes.func.isRequired,
  handleBulkHardDeleteSystemTemplatesClick: PropTypes.func.isRequired,
};

export default BulkActions;
