import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  httpCreateCustomBlock,
  httpDeleteCustomBlock,
  httpGetCustomBlocks,
  httpUpdateCustomBlock,
  httpGetCustomBlocksGroup,
  httpCreateCustomBlocksGroup,
  httpUpdateSystemCustomBlock,
  httpUpdateCustomBlocksGroup,
} from 'api';
import { getErrorText, showErrorMessage } from 'helpers';

const getCustomBlocks = createAsyncThunk(
  'blocks/getCustomBlocks',
  async ({ type = 'page' } = {}) => {
    try {
      const { data } = await httpGetCustomBlocks({ type });

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const createCustomBlock = createAsyncThunk(
  'blocks/createCustomBlock',
  async (payload) => {
    try {
      const { data } = await httpCreateCustomBlock(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateCustomBlock = createAsyncThunk(
  'blocks/updateCustomBlock',
  async (payload) => {
    try {
      const { data } = await httpUpdateCustomBlock(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateSystemCustomBlock = createAsyncThunk(
  'blocks/updateCustomBlock',
  async (payload) => {
    try {
      const { data } = await httpUpdateSystemCustomBlock(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const deleteCustomBlock = createAsyncThunk(
  'blocks/deleteCustomBlock',
  async (id) => {
    try {
      await httpDeleteCustomBlock(id);
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getCustomBlocksGroup = createAsyncThunk(
  'blocks/getCustomBlocksGroup',
  async ({ type = 'page_cb', group = 'system', hidden } = {}) => {
    try {
      let params = { type, group, hidden };

      const { data } = await httpGetCustomBlocksGroup(params);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const createCustomBlocksGroup = createAsyncThunk(
  'blocks/createCustomBlocksGroup',
  async (payload) => {
    try {
      const { data } = await httpCreateCustomBlocksGroup({
        ...payload,
        hidden: false,
        type: 'page_cb',
        group: 'system', // TODO: remove this when category type will be added to CategoryModal
      });

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateCustomBlocksGroup = createAsyncThunk(
  'blocks/updateCustomBlocksGroup',
  async (payload) => {
    try {
      const { data } = await httpUpdateCustomBlocksGroup(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

export {
  getCustomBlocks,
  createCustomBlock,
  updateCustomBlock,
  deleteCustomBlock,
  getCustomBlocksGroup,
  createCustomBlocksGroup,
  updateSystemCustomBlock,
  updateCustomBlocksGroup,
};
