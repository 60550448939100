import React, { useState, useEffect } from 'react';
import { LOAD_STATUS } from 'appConstants';
import PropTypes from 'prop-types';
import { useEditorContext } from 'routes/Editor/EditorContext';
import { buildScreenshotUrl } from 'helpers';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailTemplates } from 'store/actions';
import { emailTemplatesSelector } from 'store/selectors';
import Combobox from 'components/Combobox';

function EmailTemplatesCombobox({ onChange, error }) {
  const intl = useIntl();

  const itemToString = (item) => (item ? item.name : '');
  const normalizeItem = (item) => ({ key: item.id, label: item.name });

  const { template } = useEditorContext();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [templateImage, setTemplateImage] = useState(
    template?.emailSettings?.emailTemplate?.id
      ? buildScreenshotUrl(
          template?.emailSettings?.emailTemplate?.id,
          template?.emailSettings?.emailTemplate?.lastModified,
        )
      : null,
  );

  useEffect(() => {
    let promise;

    promise = dispatch(
      getEmailTemplates({
        limit: 1000,
        status: 'published',
      }),
    );

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch]);

  const items = useSelector((state) =>
    emailTemplatesSelector(state, searchTerm),
  );
  const status = useSelector((state) => state.template.emailTemplates.status);

  const messages = defineMessages({
    emailTemplateLabel: {
      id: 'page-settings-modal.forms.email-template-label',
    },
    emailTemplateDescription: {
      id: 'page-settings-modal.forms.email-template-description',
    },
    emailTemplatePlaceholder: {
      id: 'page-settings-modal.forms.email-template-placeholder',
    },
  });

  const loading = status === LOAD_STATUS.IDLE || status === LOAD_STATUS.LOADING;

  return (
    <>
      <Combobox
        error={error}
        initialSelectedItem={
          template?.emailSettings?.emailTemplate?.id
            ? template?.emailSettings?.emailTemplate
            : ''
        }
        label={intl.formatMessage(messages.emailTemplateLabel)}
        placeholder={intl.formatMessage(messages.emailTemplatePlaceholder)}
        helperText={intl.formatMessage(messages.emailTemplateDescription)}
        loading={loading}
        items={items || []}
        itemToString={itemToString}
        normalizeItem={normalizeItem}
        onInputValueChange={({ inputValue }) => setSearchTerm(inputValue)}
        onSelectedItemChange={({ selectedItem }) => {
          onChange(selectedItem || {});
          setTemplateImage(
            selectedItem
              ? buildScreenshotUrl(selectedItem?.id, selectedItem?.lastModified)
              : null,
          );
        }}
        fullWidth
      />
      <div className="email-template-preview__frame">
        {templateImage && <img src={templateImage} alt="Preview" />}
      </div>
    </>
  );
}

EmailTemplatesCombobox.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default EmailTemplatesCombobox;
