import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { useIntl, defineMessages } from 'react-intl';
import Label from 'components/Forms/Label';
import Button from 'components/Button';
import './CodeMirrorHeader.sass';

function CodeMirrorHeader({
  title = 'Code',
  htmlEditor,
  cssEditor,
  disablePaste = false,
  activeHtml = false,
  activeCss = false,
  activeCode = false,
}) {
  const intl = useIntl();

  const messages = defineMessages({
    copyCodeTitle: { id: 'code-mirror.code-clipboard-copy' },
    copyCssTitle: { id: 'code-mirror.css-clipboard-copy' },
    copyHtmlTitle: { id: 'code-mirror.html-clipboard-copy' },
    pasteTitle: { id: 'code-mirror.clipboard-paste' },
  });

  const handleCopy = (type) => {
    if (navigator.permissions) {
      navigator.permissions
        .query({
          name: 'clipboard-write',
        })
        .then(async ({ state }) => {
          if (state !== 'denied') {
            try {
              await navigator.clipboard.writeText(
                type === 'all'
                  ? cssEditor?.current.getValue() +
                      htmlEditor?.current.getValue()
                  : type === 'html'
                  ? htmlEditor?.current.getValue()
                  : cssEditor?.current.getValue(),
              );

              showSuccessMessage('Copied successfully');
            } catch (error) {
              showErrorMessage('Something went wrong. Could not copy');
            }
          }
        })
        .catch(() => {});
    }
  };

  const handlePaste = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({
          name: 'clipboard-read',
        })
        .then(async ({ state }) => {
          if (state !== 'denied') {
            try {
              const clipboardText = await navigator.clipboard.readText();

              activeHtml || activeCode
                ? htmlEditor.current.setValue(clipboardText)
                : cssEditor.current.setValue(clipboardText);
            } catch (error) {
              showErrorMessage('Something went wrong. Could not paste');
            }
          }
        })
        .catch(() => {});
    }
  };

  const SearchTip = () => (
    <>
      <span
        className="icon icon-info"
        data-tip
        data-for="code-mirror-search-tip"
      />
      <ReactTooltip
        id="code-mirror-search-tip"
        className="code-mirror-search-tip"
        type="dark"
        aria-haspopup="true"
        place="right"
        delayHide={300}
        backgroundColor="#000000"
        effect="solid">
        <dl>
          <dt>Ctrl-F / Cmd-F</dt>
          <dd>Start searching</dd>
          <dt>Ctrl-G / Cmd-G</dt>
          <dd>Find next</dd>
          <dt>Shift-Ctrl-G / Shift-Cmd-G</dt>
          <dd>Find previous</dd>
          {!disablePaste && (
            <>
              <dt>Shift-Ctrl-F / Cmd-Option-F</dt>
              <dd>Replace</dd>
              <dt>Shift-Ctrl-R / Shift-Cmd-Option-F</dt>
              <dd>Replace all</dd>
            </>
          )}
          <dt>Alt-F</dt>
          <dd>
            Persistent search (dialog doesn't autoclose, enter to find next,
            Shift-Enter to find previous)
          </dd>
          <dt>Alt-G</dt>
          <dd>Jump to line</dd>
        </dl>
      </ReactTooltip>
    </>
  );

  return (
    <div className="code-mirror-header">
      <Label className="code-mirror-header__title">
        {title}
        <SearchTip />
      </Label>
      <div className="code-mirror-header__clipboard-buttons">
        {activeHtml && (
          <Button variant="contained" onClick={() => handleCopy('html')}>
            {intl.formatMessage(messages.copyHtmlTitle)}
          </Button>
        )}
        {activeCss && (
          <Button variant="contained" onClick={() => handleCopy('css')}>
            {intl.formatMessage(messages.copyCssTitle)}
          </Button>
        )}
        {activeCode && (
          <Button variant="contained" onClick={() => handleCopy('html')}>
            {intl.formatMessage(messages.copyCodeTitle)}
          </Button>
        )}
        {!disablePaste && (
          <Button variant="contained" onClick={() => handlePaste()}>
            {intl.formatMessage(messages.pasteTitle)}
          </Button>
        )}
      </div>
    </div>
  );
}

CodeMirrorHeader.propTypes = {
  title: PropTypes.string.isRequired,
  htmlEditor: PropTypes.object,
  cssEditor: PropTypes.object,
  disablePaste: PropTypes.bool,
  activeHtml: PropTypes.bool,
  activeCss: PropTypes.bool,
  activeCode: PropTypes.bool,
};

export default CodeMirrorHeader;
