import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@reach/tooltip';

function InfoTooltip({ label }) {
  return (
    <Tooltip label={label}>
      <span className="icon icon-info" />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InfoTooltip;
