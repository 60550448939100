import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { subscriptionPlansSelector } from 'store/selectors';
import { updateSubscriptionPlans } from 'store/actions';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { showSuccessMessage, showErrorMessage, getErrorText } from 'helpers';
import Button from 'components/Button';
import Switch from 'components/Forms/Switch';
import Input from 'components/Forms/Input';
import './SubscriptionPlanEditModal.sass';

export const SubscriptionPlanEditModal = ({
  onClose,
  currentPlanId,
  getsubscriptionPlanName,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const currentPlan = useSelector(subscriptionPlansSelector).filter(
    ({ id }) => id === currentPlanId,
  );

  const [currentPlanData, setCurrentPlanData] = useState(currentPlan[0]);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const messages = defineMessages({
    editingSuccessMessage: {
      id: 'modal-subscription-plan-edit.editing-success-message',
    },
    allowCustomDomainLabel: {
      id: 'modal-subscription-plan-edit.allow-custom-domain-label',
    },
    allowWatermarkLabel: {
      id: 'modal-subscription-plan-edit.allow-watermark-label',
    },
    allowCreateCustomCodeLabel: {
      id: 'modal-subscription-plan-edit.allow-create-custom-code-label',
    },
    allowEditCustomCodeLabel: {
      id: 'modal-subscription-plan-edit.allow-edit-custom-code-label',
    },
    allowFormsProductsIntegrationLabel: {
      id: 'modal-subscription-plan-edit.allow-forms-products-integration-label',
    },
    closeButton: { id: 'button.close' },
    saveButton: { id: 'button.save' },
  });

  const handleSave = async () => {
    setSaveLoading(true);

    try {
      await dispatch(updateSubscriptionPlans(currentPlanData)).unwrap();

      showSuccessMessage(intl.formatMessage(messages.editingSuccessMessage));
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      setSaveLoading(false);
      onClose();
    }
  };

  return currentPlanData ? (
    <form
      className="subscription-plan-edit-modal__form"
      onSubmit={handleSubmit(handleSave)}>
      <div className="subscription-plan-edit-modal__content">
        <p className="subscription-plan-edit-modal__content__form-title">
          {`${currentPlanData.type} ${getsubscriptionPlanName(
            currentPlanData.subscriptionPlanNumber,
          )} plan settings`}
        </p>
        <Switch
          label={intl.formatMessage(
            messages.allowFormsProductsIntegrationLabel,
          )}
          fullWidth
          disabled={false}
          checked={currentPlanData.waymoreIntegration}
          onChange={(value) =>
            setCurrentPlanData({
              ...currentPlanData,
              waymoreIntegration: value,
            })
          }
        />
        <Switch
          label={intl.formatMessage(messages.allowCreateCustomCodeLabel)}
          fullWidth
          disabled={false}
          checked={currentPlanData.createCustomCode}
          onChange={(value) =>
            setCurrentPlanData({
              ...currentPlanData,
              createCustomCode: value,
            })
          }
        />
        <Switch
          label={intl.formatMessage(messages.allowEditCustomCodeLabel)}
          fullWidth
          disabled={false}
          checked={currentPlanData.editCustomCode}
          onChange={(value) =>
            setCurrentPlanData({
              ...currentPlanData,
              editCustomCode: value,
            })
          }
        />
        {currentPlanData.type === 'page' && (
          <div>
            <Switch
              label={intl.formatMessage(messages.allowCustomDomainLabel)}
              fullWidth
              disabled={false}
              checked={currentPlanData.customDomainEnabled}
              onChange={(value) =>
                setCurrentPlanData({
                  ...currentPlanData,
                  customDomainEnabled: value,
                })
              }
            />
            <Switch
              label={intl.formatMessage(messages.allowWatermarkLabel)}
              fullWidth
              disabled={false}
              checked={currentPlanData.removeBranding}
              onChange={(value) =>
                setCurrentPlanData({
                  ...currentPlanData,
                  removeBranding: value,
                })
              }
            />
          </div>
        )}
        <div className="dnd-form-control">
          <label htmlFor="public-pages-number">
            <FormattedMessage id="modal-subscription-plan-edit.max-published-templates-label" />
          </label>
          <Input
            id="public-pages-number"
            type="number"
            value={currentPlanData.publicPagesNumber}
            onFocus={(e) => e.target.select()}
            error={
              +currentPlanData.publicPagesNumber < -1 ||
              currentPlanData.publicPagesNumber === ''
            }
            min={-1}
            onChange={(e) =>
              setCurrentPlanData({
                ...currentPlanData,
                publicPagesNumber: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="subscription-plan-edit-modal__buttons">
        <Button type="button" onClick={onClose}>
          {intl.formatMessage(messages.closeButton)}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          loading={isSaveLoading}
          disabled={
            isSaveLoading ||
            +currentPlanData.publicPagesNumber < -1 ||
            currentPlanData.publicPagesNumber === ''
          }>
          {intl.formatMessage(messages.saveButton)}
        </Button>
      </div>
    </form>
  ) : (
    <p>{getErrorText()}</p>
  );
};

SubscriptionPlanEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentPlanId: PropTypes.string.isRequired,
  getsubscriptionPlanName: PropTypes.func.isRequired,
};

export default SubscriptionPlanEditModal;
