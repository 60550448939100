import { REDIRECT_URL } from 'appConstants';

export default function (
  editor,
  {
    boxType,
    category,
    countdownType,
    createCustomCode,
    customCodeType,
    discountCodeType,
    isAdmin,
    listItemsType,
    quoteType,
    textBasicType,
  },
) {
  const bm = editor.BlockManager;

  bm.add(boxType, {
    label: 'New Box',
    attributes: { class: 'dnd-new-box-block' },
    category,
    content: `<div data-type="${boxType}"></div>`,
  });

  bm.add(countdownType, {
    label: 'Countdown',
    category,
    attributes: { class: 'bpreview-countdown' },
    content: `
      <span class="countdown" data-type="${countdownType}">
        <span class="countdown-block">
          <span data-countdown="day" class="countdown-digit"></span>
          <span class="countdown-label">days</span>
        </span>
        <span class="countdown-block">
          <span data-countdown="hour" class="countdown-digit"></span>
          <span class="countdown-label">hours</span>
        </span>
        <span class="countdown-block">
          <span data-countdown="minute" class="countdown-digit"></span>
          <span class="countdown-label">minutes</span>
        </span>
        <span class="countdown-block">
          <span data-countdown="second" class="countdown-digit"></span>
          <span class="countdown-label">seconds</span>
        </span>
        <span data-countdown="endtext" class="countdown-endtext"></span>
      </span>
      <style>
        .countdown {
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .countdown span {
          display: block;
        }
        .countdown-block {
          margin: 0 10px;
          padding: 10px;
        }
        .countdown-block.expired {
          display: none;
        }
        .countdown-digit {
          font-size: 5rem;
        }
        .countdown-endtext {
          font-size: 5rem;
        }
      </style>
    `,
  });

  bm.add(listItemsType, {
    label: 'List Items',
    attributes: { class: 'bpreview-list-items' },
    category,
    content: `<section class="list-items" data-type="box">
      <table class="list-item-content">
        <tr class="list-item-row">
          <td class="list-cell-left">
            <img class="list-item-image" src="" style="width: 100px; height:100px; object-fit: cover" alt="Image"/>
          </td>
          <td class="list-cell-right">
            <h1 class="card-title">Title here</h1>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt</p>
          </td>
        </tr>
      </table>
      <table class="list-item-content">
        <tr class="list-item-row">
          <td class="list-cell-left">
            <img class="list-item-image" src="" style="width: 100px; height:100px; object-fit: cover" alt="Image"/>
          </td>
          <td class="list-cell-right">
            <h1 class="card-title">Title here</h1>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt</p>
          </td>
        </tr>
      </table>
    </section>
  `,
  });

  bm.add(customCodeType, {
    label: 'Custom Code',
    category,
    activate: true,
    select: true,
    content: { type: customCodeType },
    attributes: {
      class: `bpreview-custom-code ${
        !createCustomCode ? 'gjs-block--lock' : ''
      }`,
    },
    disable: !createCustomCode,
    media: !createCustomCode
      ? `<a target="_blank" title="Not enabled in your plan" href="${REDIRECT_URL}/settings/subscriptions" draggable="false"><span class="icon icon-lock"></span></a>`
      : '',
  });

  bm.add(quoteType, {
    category,
    label: 'Quote',
    attributes: { class: 'bpreview-quote' },
    content: `<blockquote class="quote">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore ipsum dolor sit
      </blockquote>`,
  });

  bm.add(textBasicType, {
    category,
    label: 'Text section',
    attributes: { class: 'gjs-fonts gjs-f-h1p bpreview-text-section' },
    content: `<section class="bdg-sect" data-type="box">
      <h1 class="heading">Insert title here</h1>
      <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
      </section>
      <style>
        .bdg-sect {
          padding: 10px;
        }
        .heading {
          font-size: 28px;
          margin: 0px;
        }
        .paragraph {
          padding: 10px;
        }
      </style>
      `,
  });

  bm.add(discountCodeType, {
    label: 'Discount Code',
    category,
    content: { type: discountCodeType },
    attributes: {
      class: 'bpreview-discount-code',
    },
  });
}
