export const overlayStyle = {
  zIndex: 100,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  overflowY: 'auto',
  display: 'flex',
};

export const contentStyle = {
  position: 'relative',
  top: 'auto',
  left: 'auto',
  right: 'auto',
  bottom: 'auto',
  margin: 'auto',
  width: '90%',
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.4)',
};
