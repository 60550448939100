import React from 'react';
import { REDIRECT_URL } from 'appConstants';
import { useIntl, defineMessages } from 'react-intl';
import { useFormContext, Controller } from 'react-hook-form';
import { isEmptyObject } from 'helpers';
import ReactTooltip from 'react-tooltip';
import TextField from 'components/Forms/TextField';
import CheckBox from 'components/Forms/CheckBox';
import {
  EmailFromCombobox,
  EmailsSendMultiCombobox,
  EmailTemplatesCombobox,
} from '../EmailSettings';

function EmailSettings() {
  const intl = useIntl();
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const enableSendEmailValue = watch('emailSettings.enableSendEmail');

  const messages = defineMessages({
    subjectMaxValidation: {
      id: 'page-settings-modal.forms.email-subject-max-validation',
    },
    subjectValidation: {
      id: 'page-settings-modal.forms.email-subject-validation',
    },
    enableSendEmailLable: {
      id: 'page-settings-modal.forms.enable-send-email-label',
    },
    enableSendEmailToSubmitterLabel: {
      id: 'page-settings-modal.forms.enable-send-email-to-submitter-label',
    },
    enableSendEmailLinkLable: {
      id: 'page-settings-modal.forms.enable-send-email-link-label',
    },
    enableSendEmailTooltip: {
      id: 'page-settings-modal.forms.enable-send-email-tooltip',
    },
    emailSubjectLabel: {
      id: 'page-settings-modal.forms.email-subject-label',
    },
    emailSubjectDescription: {
      id: 'page-settings-modal.forms.email-subject-description',
    },
    emailSubjectPlaceholder: {
      id: 'page-settings-modal.forms.email-subject-placeholder',
    },
    nameFromLabel: {
      id: 'page-settings-modal.forms.name-from-label',
    },
    nameFromDescription: {
      id: 'page-settings-modal.forms.name-from-description',
    },
    nameFromPlaceholder: {
      id: 'page-settings-modal.forms.name-from-placeholder',
    },
    emailFromValidation: {
      id: 'page-settings-modal.forms.email-from-validation',
    },
  });

  return (
    <div className="page-settings-modal__forms">
      <div className="page-settings-modal__forms__full-col">
        <div className="page-settings-modal__row">
          <Controller
            control={control}
            name="emailSettings.enableSendEmail"
            render={({ field: { onChange, value } }) => (
              <div className="enable-send-email">
                <CheckBox
                  label={intl.formatMessage(messages.enableSendEmailLable)}
                  fullWidth
                  checked={value || false}
                  onChange={(e) => onChange(e.target.checked)}
                />
                <a
                  href={`${REDIRECT_URL}/settings/domains`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {intl.formatMessage(messages.enableSendEmailLinkLable)}
                </a>
                <span
                  className="icon icon-info"
                  data-tip
                  data-for="enable-send-email"
                />
                <ReactTooltip
                  id="enable-send-email"
                  type="dark"
                  aria-haspopup="true"
                  place="right"
                  delayHide={300}
                  backgroundColor="#000000"
                  effect="solid">
                  <p>{intl.formatMessage(messages.enableSendEmailTooltip)}</p>
                </ReactTooltip>
              </div>
            )}
          />
        </div>
      </div>
      <div className="page-settings-modal__forms__col">
        <div className="page-settings-modal__row">
          <Controller
            control={control}
            name="emailSettings.emailFrom"
            rules={{
              validate: (value) => {
                if (
                  enableSendEmailValue &&
                  (isEmptyObject(value) || !value?.email)
                ) {
                  return intl.formatMessage(messages.emailFromValidation);
                }
              },
            }}
            render={({ field: { onChange } }) => (
              <EmailFromCombobox
                onChange={onChange}
                error={Boolean(errors?.emailSettings?.emailFrom)}
              />
            )}
          />
        </div>
        <div className="page-settings-modal__row">
          <TextField
            {...register('emailSettings.nameFrom')}
            label={intl.formatMessage(messages.nameFromLabel)}
            helperText={intl.formatMessage(messages.nameFromDescription)}
            placeholder={intl.formatMessage(messages.nameFromPlaceholder)}
            fullWidth
          />
        </div>
        <div className="page-settings-modal__row">
          <TextField
            {...register('emailSettings.emailSubject', {
              validate: (value) => {
                if (enableSendEmailValue && !value) {
                  return intl.formatMessage(messages.subjectValidation);
                }

                if (enableSendEmailValue && value?.length > 90) {
                  return intl.formatMessage(messages.subjectMaxValidation);
                }
              },
            })}
            maxLength={90}
            error={Boolean(errors?.emailSettings?.emailSubject)}
            label={intl.formatMessage(messages.emailSubjectLabel)}
            helperText={
              errors?.emailSettings?.emailSubject
                ? errors?.emailSettings?.emailSubject?.message
                : intl.formatMessage(messages.emailSubjectDescription)
            }
            placeholder={intl.formatMessage(messages.emailSubjectPlaceholder)}
            fullWidth
          />
        </div>
        <div className="page-settings-modal__row">
          <Controller
            control={control}
            name="emailSettings.sendToSubmitter"
            render={({ field: { onChange, value } }) => (
              <CheckBox
                label={intl.formatMessage(
                  messages.enableSendEmailToSubmitterLabel,
                )}
                fullWidth
                checked={value || false}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        </div>
        <div className="page-settings-modal__row">
          <EmailsSendMultiCombobox />
        </div>
      </div>
      <div className="page-settings-modal__forms__col">
        <div className="page-settings-modal__row">
          <div className="email-template-preview">
            <Controller
              control={control}
              name="emailSettings.emailTemplate"
              rules={{
                validate: (value) => {
                  if (
                    enableSendEmailValue &&
                    (isEmptyObject(value) || !value?.id)
                  ) {
                    return intl.formatMessage(messages.emailFromValidation);
                  }
                },
              }}
              render={({ field: { onChange } }) => (
                <EmailTemplatesCombobox
                  onChange={onChange}
                  error={Boolean(errors?.emailSettings?.emailTemplate)}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailSettings;
