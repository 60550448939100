import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { NODE_ENV } from 'appConstants';
import Header from '../Header';
import { contentStyle as defaultContentStyle, overlayStyle } from './config';
import './BaseModal.sass';

function BaseModal({
  children,
  headerTitle,
  headerTitleValues,
  iconTitleClass,
  isOpen,
  maxWidth,
  onClose,
  style,
  contentClass,
  contentStyle,
  headerStyle,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      // ariaHideApp true by default, for test it should be false to suppress a11y errors
      ariaHideApp={NODE_ENV !== 'test'}
      style={{
        overlay: overlayStyle,
        content: {
          ...defaultContentStyle,
          maxWidth,
          ...style,
        },
      }}>
      <Header
        headerStyle={{
          ...headerStyle,
        }}
        className="base-modal__header"
        iconTitleClass={iconTitleClass}
        onClose={onClose}>
        <FormattedMessage id={headerTitle} values={headerTitleValues} />
      </Header>
      <div
        style={{
          ...contentStyle,
        }}
        className={cx('base-modal__content-wrapper', contentClass)}>
        {children}
      </div>
    </Modal>
  );
}

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string,
  headerTitleValues: PropTypes.object,
  iconTitleClass: PropTypes.string,
  isOpen: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  style: PropTypes.object,
  contentClass: PropTypes.string,
  contentStyle: PropTypes.object,
  headerStyle: PropTypes.object,
};

BaseModal.defaultProps = {
  headerTitle: 'Title',
  maxWidth: '1024px',
};

export default BaseModal;
