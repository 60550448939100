import React from 'react';
import PropTypes from 'prop-types';
import { REDIRECT_URL } from 'appConstants';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import './UpgradeModal.sass';

function UpgradeModal({ onClose }) {
  return (
    <div className="upgrade-modal">
      <img width={32} src="/images/upgrade.svg" alt="Upgrade" />
      <p>
        <FormattedMessage
          id="upgrade-modal.message"
          values={{
            bold: (
              <strong>
                <FormattedMessage id="upgrade-modal.message-bold" />
              </strong>
            ),
          }}
        />
      </p>
      <div className="upgrade-modal__buttons">
        <Button type="button" onClick={() => onClose()}>
          <FormattedMessage id="upgrade-modal.hide-btn-title" />
        </Button>
        <Button
          variant="contained"
          onClick={() => window.open(`${REDIRECT_URL}/settings/subscriptions`)}>
          <FormattedMessage id="upgrade-modal.show-btn-title" />
        </Button>
      </div>
    </div>
  );
}

UpgradeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UpgradeModal;
