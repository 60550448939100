import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMultipleSelection, useCombobox } from 'downshift';
import { useIntl } from 'react-intl';
import { useVirtual } from 'react-virtual';
import cx from 'classnames';

import { List, ListItem } from 'components/List';
import FormControl from 'components/Forms/FormControl';
import Label from 'components/Forms/Label';
import Input from 'components/Forms/Input';
import Button from 'components/Button';
import FormHelperText from 'components/Forms/FormHelperText';
import Tag from 'components/Tag/Tag';
import './MultipleCombobox.sass';

function MultipleCombobox({
  items,
  itemToString,
  normalizeItem,
  disabled,
  fullWidth,
  label,
  placeholder,
  helperText,
  renderOption,
  loading,
  itemHeight = 22,
  selectedItems: selectedItemsProp,
  onSelectedItemsChange,
  footerElement,
  ...rest
}) {
  const [inputValue, setInputValue] = useState('');

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    onSelectedItemsChange,
    selectedItems: selectedItemsProp,
  });

  const intl = useIntl();

  const listRef = useRef();
  const inputRef = useRef();

  const filteredItems = items.filter(
    (item) =>
      !selectedItems.includes(item) &&
      itemToString(item).toLowerCase().includes(inputValue.toLowerCase()),
  );

  const { virtualItems, totalSize, scrollToIndex } = useVirtual({
    size: filteredItems.length,
    parentRef: listRef,
    estimateSize: React.useCallback(() => itemHeight, [itemHeight]),
    overscan: 2,
  });

  const {
    isOpen,
    getComboboxProps,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    openMenu,
  } = useCombobox({
    inputValue,
    items: filteredItems,
    itemToString,
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
    onHighlightedIndexChange: ({ highlightedIndex }) => {
      if (highlightedIndex >= 0) {
        scrollToIndex(highlightedIndex);
      }
    },
    ...rest,
  });

  const handleInputFocus = () => {
    if (!isOpen) {
      openMenu();
    }
  };

  let menuContent = (
    <div className="dnd-multiple-combobox__empty-list">
      {intl.formatMessage({ id: 'combobox.empty-list' })}
    </div>
  );

  if (virtualItems.length) {
    menuContent = (
      <div
        key="total-size"
        style={{ height: totalSize, width: '100%', position: 'relative' }}>
        {virtualItems.map(({ index, size, start }) => {
          const item = filteredItems[index];
          const { key, label } = normalizeItem(item);

          return (
            <ListItem
              className={cx('dnd-multiple-combobox__list-item', {
                highlighted: highlightedIndex === index,
              })}
              key={key}
              {...getItemProps({
                item,
                index,
                style: {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: size,
                  transform: `translateY(${start}px)`,
                },
              })}>
              {renderOption ? renderOption(item) : label}
            </ListItem>
          );
        })}
      </div>
    );
  }

  if (loading) {
    menuContent = (
      <div className="dnd-multiple-combobox__empty-list">
        {intl.formatMessage({ id: 'combobox.loading' })}
      </div>
    );
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      className="dnd-multiple-combobox"
      {...getComboboxProps()}>
      {label && <Label {...getLabelProps()}>{label}</Label>}
      <Input
        fullWidth={fullWidth}
        placeholder={placeholder}
        before={
          !loading &&
          selectedItems.map((selectedItem, index) => (
            <Tag
              key={normalizeItem(selectedItem).key}
              name={normalizeItem(selectedItem).label}
              {...getSelectedItemProps({ selectedItem, index })}
              onDelete={(e) => {
                e.stopPropagation();
                removeSelectedItem(selectedItem);
              }}
              className="dnd-multiple-combobox__tag">
              {normalizeItem(selectedItem).label}
            </Tag>
          ))
        }
        after={
          <>
            {loading && (
              <div className="dnd-multiple-combobox__loading">
                <span className="icon icon-spinner" aria-label="loading" />
              </div>
            )}
            <Button
              type="button"
              className="dnd-multiple-combobox__button"
              {...getToggleButtonProps({ disabled })}
              aria-label="toggle menu">
              <span className="icon icon-expand-more" />
            </Button>
          </>
        }
        {...getInputProps(
          getDropdownProps({
            preventKeyAction: isOpen,
            ref: inputRef,
            disabled,
            onFocus: handleInputFocus,
          }),
        )}
      />
      <div
        className={cx('dnd-multiple-combobox__menu', { isOpen })}
        {...getMenuProps()}>
        <List className="dnd-multiple-combobox__list" ref={listRef}>
          {isOpen && menuContent}
        </List>
        {!loading && footerElement}
      </div>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

MultipleCombobox.propTypes = {
  items: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  itemToString: PropTypes.func.isRequired,
  normalizeItem: PropTypes.func.isRequired, // (item: any) => { key: string; label: string }
  renderOption: PropTypes.func,
  itemHeight: PropTypes.number,
  selectedItems: PropTypes.array,
  onSelectedItemsChange: PropTypes.func,
  footerElement: PropTypes.node,
};

export default MultipleCombobox;
