import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'hooks';
import {
  showErrorMessage,
  showSuccessMessage,
  getErrorText,
  buildAssetsUrl,
} from 'helpers';
import imageCompression from 'browser-image-compression';
import {
  deleteGlobalAsset,
  deleteAsset,
  uploadAssets,
  clearTemplateImages,
  clearGlobalImages,
  getAssets,
  getGlobalAssets,
} from 'store/actions';
import { globalImageSelector, templateImageSelector } from 'store/selectors';

export const useTemplateImages = (id) => {
  const dispatch = useDispatch();

  const {
    isOpen: isConfirmTemplateDeleteModalOpen,
    open: openConfirmTemplateDeleteModal,
    close: closeConfirmTemplateDeleteModal,
  } = useModal();

  const {
    isOpen: isConfirmGlobalDeleteModalOpen,
    open: openConfirmGlobalDeleteModal,
    close: closeConfirmGlobalDeleteModal,
  } = useModal();

  const [isTemplateImageDeleteLoading, setIsTemplateImageDeleteLoading] =
    useState(false);
  const [isGlobalImageDeleteLoading, setIsGlobalImageDeleteLoading] =
    useState(false);

  const templateImagesState = useSelector(templateImageSelector);
  const globalImagesState = useSelector(globalImageSelector);

  useEffect(() => {
    dispatch(getAssets(id));
    dispatch(getGlobalAssets());

    // clearing assets on modal unmount
    return () => {
      dispatch(clearGlobalImages());
      dispatch(clearTemplateImages());
    };
  }, [id, dispatch]);

  const handleUploadImage = async (files) => {
    const compressOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressAllFiles = async (file) => {
      const fileSizeMB = (file.size / 1024 / 1024).toFixed(2);

      try {
        const compressedBlob = await imageCompression(file, compressOptions);
        const compressedSizeMB = (compressedBlob.size / 1024 / 1024).toFixed(2);
        const compressedFile = new File([compressedBlob], file.name);

        showSuccessMessage(
          `${file?.name} compressed from ${fileSizeMB}MB to ${compressedSizeMB}MB.`,
        );

        return compressedFile;
      } catch {
        showErrorMessage('Compression error while reducing file size.');
      }
    };

    const compressAllFilesPromises = files.map(compressAllFiles);

    Promise.allSettled(compressAllFilesPromises)
      .then((results) => {
        const formData = new FormData();

        results.forEach(({ status, value: compressedFile }) => {
          if (status === 'fulfilled') {
            formData.append('files[]', compressedFile);
          }
        });

        dispatch(uploadAssets({ payload: formData }));
      })
      .catch(() => {
        showErrorMessage('Image upload unsuccessful. Please try again.');
      });
  };

  const handleDeleteImage = async (editor, name) => {
    try {
      setIsTemplateImageDeleteLoading(true);

      await dispatch(deleteAsset({ id, name })).then(
        ({ meta: { requestStatus } }) => {
          if (requestStatus === 'fulfilled') {
            const wrapper = editor.getWrapper();
            const allImagesExist = wrapper?.findType('image') || [];

            allImagesExist.forEach((img) => {
              const { src } = img.getAttributes();

              if (src === buildAssetsUrl(id, name)) {
                img.remove();
              }
            });
          }
        },
      );
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      setIsTemplateImageDeleteLoading(false);
      closeConfirmTemplateDeleteModal();
    }
  };

  const handleDeleteGlobalImage = async (assetName) => {
    try {
      setIsGlobalImageDeleteLoading(true);

      await dispatch(deleteGlobalAsset({ assetName }));
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      setIsGlobalImageDeleteLoading(false);
      closeConfirmGlobalDeleteModal();
    }
  };

  return {
    isConfirmTemplateDeleteModalOpen,
    openConfirmTemplateDeleteModal,
    closeConfirmTemplateDeleteModal,
    isTemplateImageDeleteLoading,
    isConfirmGlobalDeleteModalOpen,
    openConfirmGlobalDeleteModal,
    closeConfirmGlobalDeleteModal,
    isGlobalImageDeleteLoading,
    templateImagesState,
    globalImagesState,
    handleUploadImage,
    handleDeleteImage,
    handleDeleteGlobalImage,
  };
};
