import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@reach/popover';
import cx from 'classnames';

import Header from 'components/Modals/Header';
import './BasePopover.sass';

const BasePopover = React.forwardRef(function BasePopover(
  { targetRef, className, isOpen, title, children, onClose, ...rest },
  ref,
) {
  return (
    <Popover
      className={cx('base-popover', className)}
      targetRef={targetRef}
      {...rest}
      ref={ref}>
      {isOpen && (
        <>
          {title && (
            <Header className="base-popover__header" onClose={onClose}>
              {title}
            </Header>
          )}
          <div className="base-popover__content-wrapper">{children}</div>
        </>
      )}
    </Popover>
  );
});

BasePopover.propTypes = {
  targetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default BasePopover;
