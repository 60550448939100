import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { USER_GUIDE_KEYS } from 'appConstants';
import { useModal, useTemplates } from 'hooks';
import { templatesNumbersSelector } from 'store/selectors';
import Loading from 'components/Loading';
import BaseModal, {
  contentStyle,
  overlayStyle,
} from 'components/Modals/BaseModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import NewTemplateModal from 'components/Modals/NewTemplateModal';
import TemplateSettingsModal from 'components/Modals/TemplateSettingsModal';
import TemplateSharingModal from 'components/Modals/TemplateSharingModal';
import DashboardTourModal from 'components/Modals/DashboardTourModal';
import Header from './Header';
import Templates from './Templates';
import Filters from './Filters';
import { Sidebar } from './Sidebar';
import './Home.sass';

const Home = ({ setNewSessionId }) => {
  const {
    status,
    category,
    sortBy,
    searchTerm,
    lastPage,
    isLoading,
    isLoadingMore,
    showBigLoader,
    isError,
    items,
    isConfirmRemoveOpen,
    showSystemTemplateRemoveDescription,
    handleChange,
    handleSetNextTemplatesPage,
    handleSetNextDeletedSystemTemplatesPage,
    handleEditTemplateClick,
    handleUnpublishTemplate,
    handleSelectSystemTemplateClick,
    handleDeleteClick,
    handleRestoreClick,
    handleDuplicateClick,
    handleUpdateTemplateClick,
    handleRemoveClick,
    handleCloseRemoveModal,
    handleConfirmRemove,
    handleRemoveAllClick,
    handleRemoveSystemTemplateClick,
    handleRemoveForeverSystemTemplateClick,
    handlePreviewSystemTemplateClick,
    handleBulkUnpublishTemplatesClick,
    handleBulkSoftDeleteTemplatesClick,
    handleBulkHardDeleteTemplatesClick,
    handleBulkSoftDeleteSystemTemplatesClick,
    handleBulkHardDeleteSystemTemplatesClick,
    handleUpdateSharedTemplateCollaborators,
    handleUpdateSharedTemplateLockStatus,
    handleUpdateFavoriteStatus,
    handleSendUnlockRequestSharedTemplateClick,
  } = useTemplates();

  const { deleted } = useSelector(templatesNumbersSelector);

  const {
    isOpen: isNewTemplateModalOpen,
    open: handleOpenNewTemplateModal,
    close: handleCloseNewTemplateModal,
  } = useModal();
  const {
    isOpen: isTemplateSettingsModalOpen,
    open: handleOpenTemplateSettingsModal,
    close: handleCloseTemplateSettingsModal,
    meta: templateSettingsModalMeta,
  } = useModal();
  const {
    isOpen: isTemplateSharingModalOpen,
    open: handleOpenTemplateSharingModal,
    close: handleCloseTemplateSharingModal,
    meta: templateSharingModalMeta,
  } = useModal();

  const [currentSharedTemplateName, setCurrentSharedTemplateName] =
    useState('');
  const [bulkAction, setBulkAction] = useState(false);
  const [templatesView, setTemplatesView] = useState(() => {
    return localStorage.getItem('templatesViewType') === null
      ? 'grid-view'
      : localStorage.getItem('templatesViewType');
  });
  const [bulkActionTemplatesList, setBulkActionTemplatesList] = useState([]);
  const [isDashboardTourFinished, setIsDashboardTourFinished] = useState(() => {
    const initialIsDashboardTourFinished = localStorage.getItem(
      USER_GUIDE_KEYS.DASHBOARD_TOUR,
    );

    return initialIsDashboardTourFinished === 'true';
  });

  const handleFinishDashboardTour = () => {
    setIsDashboardTourFinished(true);
    localStorage.setItem(USER_GUIDE_KEYS.DASHBOARD_TOUR, true);
  };

  if (isLoading) {
    return <Loading />;
  }

  const isSystem = Boolean(category);

  return (
    <div className="home">
      <Header setNewSessionId={setNewSessionId} />
      <div className="home__content">
        <Sidebar
          status={status}
          category={category}
          setBulkAction={setBulkAction}
          setBulkActionTemplatesList={setBulkActionTemplatesList}
        />
        <div className="home__content__inner">
          <Filters
            status={status}
            isSystem={isSystem}
            onOpenNewTemplateModal={handleOpenNewTemplateModal}
            sortBy={sortBy}
            searchTerm={searchTerm}
            numberRemovedItems={deleted}
            onChange={handleChange}
            onRemoveAllClick={handleRemoveAllClick}
            bulkActionTemplatesList={bulkActionTemplatesList}
            setBulkActionTemplatesList={setBulkActionTemplatesList}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
            templatesView={templatesView}
            setTemplatesView={setTemplatesView}
            handleBulkUnpublishTemplatesClick={
              handleBulkUnpublishTemplatesClick
            }
            handleBulkSoftDeleteTemplatesClick={
              handleBulkSoftDeleteTemplatesClick
            }
            handleBulkHardDeleteTemplatesClick={
              handleBulkHardDeleteTemplatesClick
            }
            handleBulkSoftDeleteSystemTemplatesClick={
              handleBulkSoftDeleteSystemTemplatesClick
            }
            handleBulkHardDeleteSystemTemplatesClick={
              handleBulkHardDeleteSystemTemplatesClick
            }
          />
          <Templates
            status={status}
            isSystem={isSystem}
            showBigLoader={showBigLoader}
            isError={isError}
            items={items}
            lastPage={lastPage}
            isLoadingMore={isLoadingMore}
            onSetNextTemplatesPage={handleSetNextTemplatesPage}
            onSetNextDeletedSystemTemplatesPage={
              handleSetNextDeletedSystemTemplatesPage
            }
            onDeleteClick={handleDeleteClick}
            onDuplicateClick={handleDuplicateClick}
            onEditTemplateClick={handleEditTemplateClick}
            onSelectSystemTemplateClick={handleSelectSystemTemplateClick}
            onRemoveSystemTemplateClick={handleRemoveSystemTemplateClick}
            onRemoveSystemForeverTemplateClick={
              handleRemoveForeverSystemTemplateClick
            }
            onPreviewSystemTemplateClick={handlePreviewSystemTemplateClick}
            onRestoreClick={handleRestoreClick}
            onRemoveClick={handleRemoveClick}
            onOpenTemplateSettingsModal={handleOpenTemplateSettingsModal}
            onOpenTemplateSharingModal={handleOpenTemplateSharingModal}
            onOpenNewTemplateModal={handleOpenNewTemplateModal}
            bulkActionTemplatesList={bulkActionTemplatesList}
            setBulkActionTemplatesList={setBulkActionTemplatesList}
            bulkAction={bulkAction}
            templatesView={templatesView}
            onUpdateSharedTemplateLockStatus={
              handleUpdateSharedTemplateLockStatus
            }
            onUnpublishTempalte={handleUnpublishTemplate}
            onUpdateFavoriteStatus={handleUpdateFavoriteStatus}
            onSendUnlockRequestSharedTemplateClick={
              handleSendUnlockRequestSharedTemplateClick
            }
          />
        </div>
      </div>
      <BaseModal
        isOpen={isConfirmRemoveOpen}
        headerTitle="templates.confirm-remove-header"
        onClose={handleCloseRemoveModal}
        maxWidth="600px">
        <ConfirmModal
          onClose={handleCloseRemoveModal}
          onConfirm={handleConfirmRemove}>
          <FormattedMessage
            id="templates.confirm-remove-description"
            values={{ isSystem: showSystemTemplateRemoveDescription }}
          />
        </ConfirmModal>
      </BaseModal>
      <BaseModal
        isOpen={isNewTemplateModalOpen}
        onClose={handleCloseNewTemplateModal}
        style={{ width: '80%', maxWidth: '100%' }}
        headerTitle="new-template-modal.new">
        <NewTemplateModal onClose={handleCloseNewTemplateModal} />
      </BaseModal>
      <BaseModal
        isOpen={isTemplateSettingsModalOpen}
        onClose={handleCloseTemplateSettingsModal}
        style={{ width: '600px', overflow: 'visible' }}
        iconTitleClass="icon icon-settings"
        headerTitle="template-settings-modal.title"
        contentClass="template-settings-modal">
        <TemplateSettingsModal
          template={templateSettingsModalMeta?.template}
          isSystem={templateSettingsModalMeta?.isSystem}
          onSave={handleUpdateTemplateClick}
          onClose={handleCloseTemplateSettingsModal}
        />
      </BaseModal>
      <BaseModal
        isOpen={isTemplateSharingModalOpen}
        onClose={handleCloseTemplateSharingModal}
        style={{ width: '600px', overflow: 'visible' }}
        iconTitleClass="icon icon-share"
        headerTitle="template-share-modal.title"
        headerTitleValues={{ name: currentSharedTemplateName }}
        contentClass="template-share-modal">
        <TemplateSharingModal
          template={templateSharingModalMeta?.template}
          onShareTemplateClick={handleUpdateSharedTemplateCollaborators}
          setCurrentSharedTemplateName={setCurrentSharedTemplateName}
          onClose={handleCloseTemplateSharingModal}
        />
      </BaseModal>
      <Modal
        isOpen={!isDashboardTourFinished}
        onRequestClose={handleFinishDashboardTour}
        style={{
          overlay: overlayStyle,
          content: {
            ...contentStyle,
            maxWidth: 900,
            padding: 0,
            border: 0,
          },
        }}>
        <DashboardTourModal
          onFinishDashboardTour={handleFinishDashboardTour}
          onOpenNewTemplateModal={handleOpenNewTemplateModal}
        />
      </Modal>
    </div>
  );
};

Home.propTypes = {
  setNewSessionId: PropTypes.func.isRequired,
};

export default Home;
