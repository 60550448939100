import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { httpSearchPrincipal } from 'api/principal.api';
import { showErrorMessage, getErrorText } from 'helpers';
import { useIntl, FormattedMessage } from 'react-intl';
import SelectBox from 'components/SelectBox';
import Button from 'components/Button';
import './TemplateSharingModal.sass';

const TemplateSharingModal = ({
  template,
  onShareTemplateClick,
  setCurrentSharedTemplateName,
  onClose,
}) => {
  const intl = useIntl();

  const [loading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const handleSave = (payload) => {
    onShareTemplateClick({ id: template?.id, payload });

    onClose();
  };

  useEffect(() => {
    setCurrentSharedTemplateName(template?.name);

    setIsLoading(true);

    const getUsers = async () => {
      try {
        let newData = [];

        const body = {
          orderBy: 'firstName',
          criteria: [
            {
              key: 'email',
              value: '@waymore.io',
              operation: 'CONTAINS',
              operator: 'OR',
            },
            {
              key: 'email',
              value: '@amdtelecom.net',
              operation: 'CONTAINS',
              operator: 'OR',
            },
          ],
        };
        const { data } = await httpSearchPrincipal(body);

        data.principals.map(({ id, firstName, lastName, email }) =>
          newData.push({
            value: id,
            label: `${firstName} ${lastName} (${email})`,
          }),
        );

        setSelectedUsers(
          newData.filter(({ value }) =>
            template?.collaboratorsList.includes(value),
          ),
        );
        setUsers(newData);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    getUsers();

    return () => {
      setCurrentSharedTemplateName('');
      setIsLoading(false);
    };
  }, [setCurrentSharedTemplateName, template]);

  return (
    <form
      className="template-sharing-modal__form"
      onSubmit={handleSubmit(handleSave)}>
      <div className="template-sharing-modal__content">
        {!loading ? (
          <>
            {template?.collaboratorsList.length > 0 && (
              <div className="template-sharing-modal__content__info-msg">
                <span className="icon icon-info" />
                <p>
                  <FormattedMessage id="template-share-modal.unshare-message" />
                </p>
              </div>
            )}
            <Controller
              control={control}
              name="collaboratorsList"
              render={({ field: { onChange } }) => (
                <SelectBox
                  isMulti
                  isDisabled={loading}
                  onChange={onChange}
                  loading={loading}
                  options={users}
                  defaultValue={selectedUsers}
                  tooltip=""
                  label={intl.formatMessage({
                    id: 'template-share-modal.people-select.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'template-share-modal.people-select.placeholder',
                  })}
                  description={intl.formatMessage(
                    {
                      id: 'template-share-modal.people-select.description',
                    },
                    { name: template?.name },
                  )}
                />
              )}
            />
          </>
        ) : (
          <span aria-label="loading" className="icon icon-spinner" />
        )}
      </div>
      <div className="template-sharing-modal__buttons">
        <Button type="button" onClick={onClose}>
          <FormattedMessage id="button.close" />
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={!isDirty || !isValid}>
          <FormattedMessage id="button.save" />
        </Button>
      </div>
    </form>
  );
};

TemplateSharingModal.propTypes = {
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    collaboratorsList: PropTypes.array.isRequired,
  }),
  onShareTemplateClick: PropTypes.func.isRequired,
  setCurrentSharedTemplateName: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TemplateSharingModal;
