import React from 'react';
import { REDIRECT_URL } from 'appConstants';

import './Logo.sass';

function Logo() {
  return (
    <div role="banner" aria-label="waymore" className="dnd-logo">
      <a href={REDIRECT_URL}>
        <img src="/images/logo.svg" alt="Logo" />
      </a>
    </div>
  );
}

export default Logo;
