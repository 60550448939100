import { createSlice } from '@reduxjs/toolkit';

import {
  getSubscriptionPlans,
  updateSubscriptionPlans,
  createSubscriptionPlan,
} from 'store/actions';

const initialState = {
  subscriptionPlans: [],
};

const { reducer: subscriptionsReducer } = createSlice({
  name: 'subscriptions',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionPlans.fulfilled, (state, action) => {
        state.subscriptionPlans = [...action.payload];
      })
      .addCase(updateSubscriptionPlans.fulfilled, (state, action) => {
        state.subscriptionPlans = [
          ...state.subscriptionPlans.filter(
            (item) => item.id !== action.payload.id,
          ),
          action.payload,
        ];
      })
      .addCase(createSubscriptionPlan.fulfilled, (state, action) => {
        state.subscriptionPlans = [...state.subscriptionPlans, action.payload];
      });
  },
});

export { subscriptionsReducer };
