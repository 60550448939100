import { httpGetIconsCategories, httpGetIconsStyles } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorText, showErrorMessage } from 'helpers';

const getIconsCategories = createAsyncThunk(
  'assets/getIconsCategories',
  async () => {
    try {
      const { data } = await httpGetIconsCategories();

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getIconsStyles = createAsyncThunk('assets/getIconsStyles', async () => {
  try {
    const { data } = await httpGetIconsStyles();

    return data;
  } catch (error) {
    showErrorMessage(getErrorText());
    throw error;
  }
});

export { getIconsCategories, getIconsStyles };
