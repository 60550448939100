import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './FormHelperText.sass';

function FormHelperText({ children, error, ...rest }) {
  return (
    <div
      className={cx('dnd-form-helper-text', {
        error,
      })}
      {...rest}>
      {children}
    </div>
  );
}

FormHelperText.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
};

export default FormHelperText;
