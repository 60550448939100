import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { useFont } from 'hooks';
import { useDebounce } from 'use-debounce';
import Button from 'components/Button';
import { httpGetFonts } from 'api/fonts.api';
import FontsList from './FontsList';
import FontFilters from './FontFilters';
import SelectedFonts from './SelectedFonts';
import './FontManager.sass';

const messages = defineMessages({
  fontsListModalTitle: { id: 'page-settings-modal-fonts.modal-title' },
  showButtonLabel: { id: 'page-settings-modal-fonts.show-button-label' },
  hideButtonLabel: { id: 'page-settings-modal-fonts.hide-button-label' },
});

function FontManager({ editor, userSelectedFonts }) {
  const intl = useIntl();

  const { addStylesheetURL } = useFont();

  const [isLoading, setIsLoading] = useState(false);
  const [listToggle, setListToggle] = useState(false);
  const [fonts, setFonts] = useState([]);
  const [previewText, setPreviewText] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [categoryValue, setCategoryValue] = useState('all');
  const [selectedItems, setSelectedItems] = useState(userSelectedFonts || []);
  const [searchValueDebounce] = useDebounce(searchValue, 600);

  useEffect(() => {
    setIsLoading(true);

    const fontsDataFetch = async () => {
      const { data } = await httpGetFonts();

      setFonts(data?.items || []);
      setIsLoading(false);
    };

    fontsDataFetch();

    return () => {
      setFonts([]);
    };
  }, []);

  return (
    <div className="page-settings-modal__fonts">
      <div className="page-settings-modal__sub-title">
        {intl.formatMessage(messages.fontsListModalTitle)}
      </div>
      <Button
        data-testid="font-list-toggle-btn"
        variant="contained"
        onClick={() => setListToggle(!listToggle)}>
        {intl.formatMessage(
          listToggle ? messages.hideButtonLabel : messages.showButtonLabel,
        )}
      </Button>
      {listToggle && (
        <>
          <FontFilters
            setPreviewText={setPreviewText}
            setSearchValue={setSearchValue}
            setCategoryValue={setCategoryValue}
          />
          <FontsList
            editor={editor}
            isLoading={isLoading}
            fonts={fonts}
            previewText={previewText}
            categoryValue={categoryValue}
            searchValueDebounce={searchValueDebounce}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            addStylesheetURL={addStylesheetURL}
          />
        </>
      )}
      <SelectedFonts
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </div>
  );
}

FontManager.propTypes = {
  editor: PropTypes.object,
  userSelectedFonts: PropTypes.array,
};

export default FontManager;
