import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';

import Button from '../../Button';
import './YesNoConfirmModal.sass';

const messages = defineMessages({
  confirmButton: {
    id: 'button.yes',
    defaultMessage: 'Yes',
  },
  denyButton: {
    id: 'button.no',
    defaultMessage: 'No',
  },
});

function YesNoConfirmModal({
  children,
  intl,
  loading,
  buttonsColor,
  onConfirm,
  onDeny,
}) {
  return (
    <div className="yes-no-confirm-modal">
      <div className="yes-no-confirm-modal__content">{children}</div>
      <div className="yes-no-confirm-modal__buttons">
        <Button
          data-testid="confirm-button"
          type="button"
          className="yes-no-confirm-modal__button"
          variant="contained"
          color={buttonsColor}
          disabled={loading}
          loading={loading}
          onClick={onConfirm}>
          {intl.formatMessage(messages.confirmButton)}
        </Button>
        <Button
          data-testid="deny-button"
          type="button"
          className="yes-no-confirm-modal__button"
          variant="contained"
          color={buttonsColor}
          disabled={loading}
          loading={loading}
          onClick={onDeny}>
          {intl.formatMessage(messages.denyButton)}
        </Button>
      </div>
    </div>
  );
}

YesNoConfirmModal.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  buttonsColor: PropTypes.oneOf(['default', 'primary', 'secondary']),
  onConfirm: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
};

export default injectIntl(YesNoConfirmModal);
