export const FONTS = [
  {
    name: 'Arial',
    value: 'Arial, Helvetica, sans-serif',
  },
  {
    name: 'Arial Black',
    value: '"Arial Black", Gadget, sans-serif',
  },
  {
    name: 'Brush Script MT',
    value: '"Brush Script MT", sans-serif',
  },
  {
    name: 'Comic Sans MS',
    value: '"Comic Sans MS", cursive',
  },
  {
    name: 'Courier New',
    value: '"Courier New", Courier, monospace',
  },
  {
    name: 'Gerogia',
    value: 'Georgia, serif',
  },
  {
    name: 'Helvetica',
    value: 'Helvetica, sans-serif',
  },
  {
    name: 'Impact',
    value: 'Impact, Charcoal, sans-serif',
  },
  {
    name: 'Lucida Sans Unicode',
    value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
  },
  {
    name: 'Tahoma',
    value: 'Tahoma, Geneva, sans-serif',
  },
  {
    name: 'Times New Roman',
    value: '"Times New Roman", Times, serif',
  },
  {
    name: 'Trebuchet MS',
    value: '"Trebuchet MS", Helvetica, sans-serif',
  },
  {
    name: 'Verdana',
    value: 'Verdana, Geneva, sans-serif',
  },
];

export const HEADER_SELECTOR = 'h1, h2, h3, h4, h5, h6';
export const PARAGRAPH_SELECTOR = 'p, span, div';

export const PREVIEW_SIZES = ['pc', 'tablet', 'mobile'];

export const BLOCKS_CATEGORIES = {
  blocks: ['Basic', 'Navigation', 'Forms', 'Sections', 'Extra', 'Social'],
  waymore: ['WayMore Forms', 'WayMore Products', 'WayMore Calendar'],
};

export const COMPONENT_CHANGE_STATUSES = {
  SELECTED: 'selected',
};

export const RTE_BUTTON_STATE = {
  ACTIVE: 1,
  INACTIVE: 0,
  DISABLED: -1,
};

export const VISIBILITY_OPTIONS = [
  { name: 'Visible', value: 'visible' },
  { name: 'Hidden', value: 'hidden' },
  { name: 'Collapse', value: 'collapse' },
];

export const CURSOR_OPTIONS = [
  { name: 'alias', value: 'alias' },
  { name: 'all-scroll', value: 'all-scroll' },
  { name: 'auto', value: 'auto' },
  { name: 'cell', value: 'cell' },
  { name: 'col-resize', value: 'col-resize' },
  { name: 'context-menu', value: 'context-menu' },
  { name: 'copy', value: 'copy' },
  { name: 'crosshair', value: 'crosshair' },
  { name: 'default', value: 'default' },
  { name: 'e-resize', value: 'e-resize' },
  { name: 'ew-resize', value: 'ew-resize' },
  { name: 'help', value: 'help' },
  { name: 'move', value: 'move' },
  { name: 'n-resize', value: 'n-resize' },
  { name: 'ne-resize', value: 'ne-resize' },
  { name: 'nesw-resize', value: 'nesw-resize' },
  { name: 'ns-resize', value: 'ns-resize' },
  { name: 'nw-resize', value: 'nw-resize' },
  { name: 'nwse-resize', value: 'nwse-resize' },
  { name: 'no-drop', value: 'no-drop' },
  { name: 'none', value: 'none' },
  { name: 'not-allowed', value: 'not-allowed' },
  { name: 'pointer', value: 'pointer' },
  { name: 'progress', value: 'progress' },
  { name: 'row-resize', value: 'row-resize' },
  { name: 's-resize', value: 's-resize' },
  { name: 'se-resize', value: 'se-resize' },
  { name: 'sw-resize', value: 'sw-resize' },
  { name: 'text', value: 'text' },
  { name: 'w-resize', value: 'w-resize' },
  { name: 'wait', value: 'wait' },
  { name: 'zoom-in', value: 'zoom-in' },
  { name: 'zoom-out', value: 'zoom-out' },
];

export const FLOAT_OPTIONS = [
  { value: 'none', className: 'icon icon-close' },
  { value: 'left', className: 'icon icon-format-align-left' },
  { value: 'inherit', className: 'icon icon-format-align-center' },
  { value: 'right', className: 'icon icon-format-align-right' },
];

export const TEXT_ALIGN_OPTIONS = [
  {
    value: 'left',
    name: 'Left',
    className: 'icon icon-format-align-left',
  },
  {
    value: 'center',
    name: 'Center',
    className: 'icon icon-format-align-center',
  },
  {
    value: 'right',
    name: 'Right',
    className: 'icon icon-format-align-right',
  },
  {
    value: 'justify',
    name: 'Justify',
    className: 'icon icon-format-align-justify',
  },
];
