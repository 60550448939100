import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import TextField from 'components/Forms/TextField';
import Select from 'components/Forms/Select';

const messages = defineMessages({
  description: { id: 'page-settings-modal.seo.description' },
  titleLabel: { id: 'page-settings-modal.seo.title-label' },
  titlePlaceholder: { id: 'page-settings-modal.seo.title-placeholder' },
  titleDescription: { id: 'page-settings-modal.seo.title-description' },
  categoryLabel: { id: 'page-settings-modal.seo.category-label' },
  categoryPlaceholder: { id: 'page-settings-modal.seo.category-placeholder' },
  categoryOptions: { id: 'page-settings-modal.seo.category-options' },
  descriptionLabel: { id: 'page-settings-modal.seo.description-label' },
  descriptionPlaceholder: {
    id: 'page-settings-modal.seo.description-placeholder',
  },
  descriptionDescription: {
    id: 'page-settings-modal.seo.description-description',
  },
});

const categoryOptions = [
  'startup',
  'personal',
  'business',
  'portfolio',
  'presentation',
  'organization',
  'event',
  'other',
];

function SEO() {
  const { register } = useFormContext();

  const intl = useIntl();

  return (
    <div className="page-settings-modal__seo">
      <div className="page-settings-modal__row">
        <div className="page-settings-modal__sub-title">
          {intl.formatMessage(messages.description)}
        </div>
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('settings.meta.title')}
          label={intl.formatMessage(messages.titleLabel)}
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
          helperText={intl.formatMessage(messages.titleDescription)}
          fullWidth
        />
        <Select
          {...register('settings.meta.addsearchCategory')}
          className="page-settings-modal__seo-select"
          label={intl.formatMessage(messages.categoryLabel)}>
          <option value="">
            {intl.formatMessage(messages.categoryPlaceholder)}
          </option>
          {categoryOptions.map((option) => (
            <option key={option} value={option}>
              {intl.formatMessage(messages.categoryOptions, { option })}
            </option>
          ))}
        </Select>
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('settings.meta.description')}
          label={intl.formatMessage(messages.descriptionLabel)}
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          helperText={intl.formatMessage(messages.descriptionDescription)}
          multiline
          fullWidth
        />
      </div>
    </div>
  );
}

export default SEO;
