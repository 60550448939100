import { useCallback, useState } from 'react';

export function useModal(initialOpen = false) {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [meta, setMeta] = useState(null);

  const close = useCallback(() => {
    setMeta(null);
    setIsOpen(false);
  }, []);

  const open = useCallback((_meta) => {
    setIsOpen(true);

    if (_meta) {
      setMeta(_meta);
    }
  }, []);

  return { isOpen, close, open, meta };
}
