import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Label.sass';

const Label = React.forwardRef(function Label(
  { className, children, component: Component = 'label', ...rest },
  ref,
) {
  return (
    <Component className={cx('dnd-label', className)} {...rest} ref={ref}>
      {children}
    </Component>
  );
});

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  component: PropTypes.string,
};

export default Label;
