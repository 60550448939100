import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './List.sass';

const List = React.forwardRef(function List({ className, ...rest }, ref) {
  return <ul className={cx('dnd-list', className)} {...rest} ref={ref} />;
});

List.propTypes = {
  className: PropTypes.string,
};

export default List;
