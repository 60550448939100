import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Toggler.sass';

const Toggler = React.forwardRef(function Toggler(
  { checked, disabled, onChange },
  ref,
) {
  const [value, setValue] = useState(checked);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const handleAfterChange = () => {
    if (disabled && value !== checked) {
      setValue(checked);
      return;
    }

    onChange(value);
  };

  const handleChange = (e) => {
    setValue(e.target.checked);
  };

  return (
    <label className="dnd-toggler">
      <div className={cx('dnd-toggler__background', { checked: value })} />
      <div
        className={cx('dnd-toggler__handle', { checked: value })}
        onTransitionEnd={handleAfterChange}
      />
      <input
        checked={value}
        onChange={handleChange}
        className="dnd-toggler__input"
        type="checkbox"
        ref={ref}
      />
    </label>
  );
});

Toggler.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Toggler;
