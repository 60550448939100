import { checkComponentType, getSelectorByType, setRuleStyle } from 'helpers';

export default (
  editor,
  {
    sliderType,
    sliderFrameType,
    sliderSlidesType,
    sliderSlideType,
    sliderPrevType,
    sliderNextType,
    imageType,
    tabsType,
    tabsListType,
    tabsListItemType,
    tabsContentType,
    tabsContentItemType,
  },
) => {
  const domc = editor.DomComponents;

  domc.addType(sliderType, {
    extend: 'default',
    model: {
      defaults: {
        openTraitsOnSelect: true,
        name: 'Slider',
        // Slides scrolled at once
        'slides-to-scroll': 1,
        // Enabled mouse events
        'enable-mouse-events': false,
        // Time in milliseconds for the animation of a valid slide attempt
        'slide-speed': 300,
        // Time in milliseconds for the animation of the rewind after the last slide
        'rewind-speed': 600,
        // Time for the snapBack of the slider if the slide attempt was not valid
        'snap-back-speed': 200,
        // Like carousel, works with multiple slides. (do not combine with rewind)
        infinite: 0,
        // If slider reached the last slide, with next click the slider goes
        // back to the startindex. (do not combine with infinite)
        rewind: false,
        // Cubic bezier easing functions: http://easings.net/de
        ease: 'ease',
        droppable: `${getSelectorByType(sliderPrevType)}, ${getSelectorByType(
          sliderNextType,
        )}`,
        style: {
          position: 'relative',
          width: '100%',
          margin: '0 auto',
        },
        traits: [
          {
            id: 'add-slider-item-btn',
            type: 'button',
            text: 'Add New slide',
            full: true,
            command: (editor) => {
              const selected = editor?.getSelected();
              const slides_wrapper = selected.findType('lory-slider-slides');
              const slides = selected.findType('lory-slider-slide');
              const slidesLen = slides?.length || 0;

              slides_wrapper[0].components().add(
                {
                  type: 'lory-slider-slide',
                  attributes: {
                    src: `https://placehold.co/400x400?text=Slide+${
                      slidesLen + 1
                    }`,
                    alt: 'Slider Image',
                  },
                },
                { at: slidesLen },
              );
            },
          },
          {
            type: 'number',
            label: 'Infinite',
            name: 'infinite',
            changeProp: 1,
          },
          {
            type: 'checkbox',
            label: 'Rewind',
            name: 'rewind',
            changeProp: 1,
          },
          {
            type: 'number',
            label: 'Slide speed',
            name: 'slide-speed',
            changeProp: 1,
          },
          {
            type: 'number',
            label: 'Rewind speed',
            name: 'rewind-speed',
            changeProp: 1,
          },
          {
            type: 'number',
            label: 'Slides to scroll',
            name: 'slides-to-scroll',
            changeProp: 1,
          },
          {
            type: 'select',
            label: 'Timing',
            name: 'ease',
            changeProp: 1,
            options: ['ease', 'linear', 'ease-in', 'ease-out', 'ease-in-out'],
          },
        ],
        'script-deps':
          'https://cdnjs.cloudflare.com/ajax/libs/lory.js/2.5.3/lory.min.js',
        'class-frame': sliderFrameType,
        'class-slides': sliderSlidesType,
        'class-prev': sliderPrevType,
        'class-next': sliderNextType,
        // eslint-disable-next-line object-shorthand
        script: function () {
          var el = this;
          var deps = '{[ script-deps ]}';
          var rewind = '{[ rewind ]}';
          var options = {
            slidesToScroll: parseInt('{[ slides-to-scroll ]}', 10),
            enableMouseEvents: '{[ enable-mouse-events ]}' === 'true',
            infinite: parseInt('{[ infinite ]}', 0),
            rewind: rewind === 'true',
            slideSpeed: parseInt('{[ slide-speed ]}', 10),
            rewindSpeed: parseInt('{[ rewind-speed ]}', 10),
            snapBackSpeed: parseInt('{[ snap-back-speed ]}', 10),
            ease: '{[ ease ]}',
            classNameFrame: '{[ class-frame ]}',
            classNameSlideContainer: '{[ class-slides ]}',
            classNamePrevCtrl: '{[ class-prev ]}',
            classNameNextCtrl: '{[ class-next ]}',
          };

          var initSlider = function () {
            window.lory(el, options);
          };

          if (deps && typeof lory === 'undefined') {
            var script = document.createElement('script');
            script.src = deps;
            script.onload = initSlider;
            document.head.appendChild(script);
          } else {
            initSlider();
          }
        },
      },
    },
    view: {
      init() {
        const props = [
          'slides-to-scroll',
          'enable-mouse-events',
          'slide-speed',
          'rewind-speed',
          'snap-back-speed',
          'infinite',
          'rewind',
          'ease',
        ];
        const reactTo = props.map((prop) => `change:${prop}`).join(' ');
        this.listenTo(this.model, reactTo, this.render);
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sliderType)) {
        return { type: sliderType };
      }
    },
  });

  domc.addType(sliderFrameType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Slider frame',
        droppable: false,
        moveable: false,
        draggable: false,
        removable: false,
        copyable: false,
        style: {
          width: '300px',
          margin: '0 auto',
          position: 'relative',
          overflow: 'hidden',
          'white-space': 'nowrap',
        },
      },
      init() {
        if (this.get('classes').pluck('name').indexOf(sliderFrameType) < 0) {
          this.addClass(sliderFrameType);
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sliderFrameType)) {
        return { type: sliderFrameType };
      }
    },
  });

  domc.addType(sliderSlidesType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Slides',
        droppable: false,
        moveable: false,
        draggable: false,
        removable: false,
        copyable: false,
        style: {
          display: 'inline-block',
          'transition-delay': '1ms',
        },
      },
      init() {
        if (this.get('classes').pluck('name').indexOf(sliderSlidesType) < 0) {
          this.addClass(sliderSlidesType);
        }
      },
    },
    view: {
      init() {
        this.listenTo(this.model.components(), 'add remove', this.renderSlider);
      },
      renderSlider() {
        const slider = this.model.parent().parent();
        slider && slider.view.render();
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sliderSlidesType)) {
        return { type: sliderSlidesType };
      }
    },
  });

  domc.addType(sliderSlideType, {
    extend: imageType,
    model: {
      defaults: {
        name: 'Slide',
        draggable: false,
        style: {
          display: 'inline-block',
          position: 'relative',
          color: '#fff',
          width: '300px',
          'margin-right': '10px',
          'vertical-align': 'top',
          'min-height': '130px',
          'white-space': 'normal',
          'background-color': 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    view: {
      onActive(e) {
        if (checkComponentType(e.target, sliderSlideType)) {
          editor.runCommand('open-assets', { isImage: true });
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sliderSlideType)) {
        return { type: sliderSlideType };
      }
    },
  });

  domc.addType(sliderNextType, {
    extend: 'image',
    model: {
      defaults: {
        name: 'Next slider',
        moveable: false,
        draggable: false,
        copyable: false,
        style: {
          position: 'absolute',
          display: 'block',
          cursor: 'pointer',
          top: '50%',
          right: 0,
          'margin-top': '-25px',
        },
      },
      init() {
        if (this.get('classes').pluck('name').indexOf(sliderNextType) < 0) {
          this.addClass(sliderNextType);
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sliderNextType)) {
        return { type: sliderNextType };
      }
    },
  });

  domc.addType(sliderPrevType, {
    extend: 'image',
    model: {
      defaults: {
        name: 'Previous slider',
        moveable: false,
        draggable: false,
        copyable: false,
        style: {
          position: 'absolute',
          display: 'block',
          cursor: 'pointer',
          top: '50%',
          left: 0,
          'margin-top': '-25px',
        },
      },
      init() {
        if (this.get('classes').pluck('name').indexOf(sliderPrevType) < 0) {
          this.addClass(sliderPrevType);
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sliderPrevType)) {
        return { type: sliderPrevType };
      }
    },
  });

  domc.addType(tabsType, {
    model: {
      defaults: {
        droppable: false,
        attributes: { class: 'tabs' },
        activeTabBgColor: '#65a0ba',
        activeTabColor: '#fff',
        activeTabBgHoverColor: '#f9f9f9',
        activeTabHoverColor: '#000',
        traits: [
          {
            id: 'add-tab-item-btn',
            type: 'button',
            text: 'Add item',
            full: true,
            command: (editor) => {
              const selected = editor?.getSelected();
              const tabsList = selected.findType('tabs-list');
              const tabsListItems = selected.findType('tabs-list-item');
              const tabsListLen = tabsListItems?.length || 0;
              const tabsContent = selected.findType('tabs-content');
              const tabsContentItems = selected.findType('tabs-content-item');
              const tabsContentLen = tabsContentItems?.length || 0;
              const newGeneratedText =
                'ddd ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
                  .split(' ')
                  .sort(() => Math.floor(Math.random() * Math.floor(3)) - 1)
                  .join(' ');

              // Add new tab
              tabsList[0].components().add(
                {
                  type: 'tabs-list-item',
                  attributes: {
                    class: 'tab-item',
                  },
                  content: 'New tab',
                },
                { at: tabsListLen },
              );

              // Add new tab content
              const newTabsContentItem = tabsContent[0].components().add(
                {
                  type: 'tabs-content-item',
                  attributes: {
                    class: 'tabs-content-item',
                  },
                  components: {
                    type: 'text',
                    tagName: 'p',
                    content: newGeneratedText,
                  },
                },
                { at: tabsContentLen },
              );

              newTabsContentItem.setStyle({
                padding: '20px',
              });

              selected.view.render();
            },
          },
          {
            type: 'color',
            default: '#65a0ba',
            name: 'activeTabBgColor',
            label: 'Active tab (background color)',
            changeProp: true,
          },
          {
            type: 'color',
            default: '#fff',
            name: 'activeTabColor',
            label: 'Active tab (text color)',
            changeProp: true,
          },
          {
            type: 'color',
            default: '#f9f9f9',
            name: 'activeTabBgHoverColor',
            label: 'Active tab (hover background color)',
            changeProp: true,
          },
          {
            type: 'color',
            default: '#000',
            name: 'activeTabHoverColor',
            label: 'Active tab (hover text color)',
            changeProp: true,
          },
        ],
        components: [
          { type: tabsListType, attributes: { class: 'tab__list' } },
          { type: tabsContentType, attributes: { class: 'tab__content' } },
        ],
        styles: `
          .tabs {
            padding: 20px;
          }
        `,
        // eslint-disable-next-line object-shorthand
        script: function () {
          // Select the tab container
          const tabsContainer = this;

          function initializeTabs(tabsContainer) {
            // Select tab headers and content
            const tabList = tabsContainer.querySelector('.tab__list');
            const tabItems = Array.from(tabList.children);
            const tabContent = tabsContainer.querySelector('.tab__content');
            const tabContentItems = Array.from(tabContent.children);
            let activeIndex = 0;

            // Set the active tab
            function setActiveTab(index) {
              tabItems.forEach((item) => item.classList.remove('is--active'));
              tabContentItems.forEach((content) =>
                content.classList.remove('is--active'),
              );
              tabItems[index].classList.add('is--active');
              tabContentItems[index].classList.add('is--active');
            }

            // Add click event listeners to tab headers
            tabItems.forEach((item, index) => {
              item.addEventListener('click', () => {
                activeIndex = index;
                setActiveTab(index);
              });
            });

            // Set the initial active tab
            setActiveTab(activeIndex);
          }

          // Initialize the tabs
          initializeTabs(tabsContainer);
        },
      },
      init() {
        this.on('change:activeTabBgColor', this.updateActiveTabBgColor);
        this.on('change:activeTabColor', this.updateActiveTabColor);
        this.on(
          'change:activeTabBgHoverColor',
          this.updateActiveTabHoverBgColor,
        );
        this.on('change:activeTabHoverColor', this.updateActiveTabHoverColor);
      },
      updateActiveTabBgColor(model, value) {
        const style = {
          'background-color': value,
        };

        setRuleStyle(
          editor,
          `#${model.getId()} > .tab__list > div.is--active `,
          style,
        );
      },
      updateActiveTabColor(model, value) {
        const style = {
          color: value,
        };

        setRuleStyle(
          editor,
          `#${model.getId()} > .tab__list > div.is--active `,
          style,
        );
      },
      updateActiveTabHoverBgColor(model, value) {
        const style = {
          'background-color': value,
        };

        setRuleStyle(
          editor,
          `#${model.getId()} > .tab__list > div:hover `,
          style,
        );
      },
      updateActiveTabHoverColor(model, value) {
        const style = {
          color: value,
        };

        setRuleStyle(
          editor,
          `#${model.getId()} > .tab__list > div:hover `,
          style,
        );
      },
    },
    isComponent(el) {
      if (checkComponentType(el, tabsType)) {
        return { type: tabsType };
      }
    },
  });

  domc.addType(tabsListType, {
    model: {
      defaults: {
        editable: false,
        collection: false,
        droppable: false,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: false,
        selectable: false,
        hoverable: false,
        resizable: false,
        components: [
          {
            type: tabsListItemType,
            content: 'Tab 1',
            attributes: { class: 'tab-item' },
          },
          {
            type: tabsListItemType,
            content: 'Tab 2',
            attributes: { class: 'tab-item' },
          },
          {
            type: tabsListItemType,
            content: 'Tab 3',
            attributes: { class: 'tab-item' },
          },
        ],
        styles: `
          .tab__list {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        `,
      },
    },
  });

  domc.addType(tabsListItemType, {
    model: {
      defaults: {
        editable: true,
        collection: false,
        droppable: false,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: true,
        selectable: true,
        hoverable: true,
        styles: `
          .tab__list > div {
            padding: 10px 20px;
            border-radius: 4px;
            transition: all 0.3s;
          }
          .tab__list > div.is--active {
            background-color:  #65a0ba;
            color: #fff;
          }
          .tab__list > div:hover {
            background-color: #f9f9f9;
            color: #000;
          }
        `,
      },
    },
    view: {
      events: {
        dblclick: 'onActive',
        focusout: 'onFocusOut',
      },
      onActive() {
        const el = this.el;

        el.contentEditable = true;

        document.onkeydown = function (e) {
          if (e.key === 'Escape') {
            el.contentEditable = false;
          }
        };
      },
      onFocusOut(ev) {
        this.el.contentEditable = false;
        if (this.el.innerHTML === '') {
          this.el.innerHTML = 'Tab';
        } else {
          this.model.components(ev.target.innerHTML);
        }
      },
    },
  });

  domc.addType(tabsContentType, {
    model: {
      defaults: {
        editable: true,
        collection: true,
        droppable: false,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: false,
        selectable: true,
        hoverable: true,
        resizable: false,
        components: [
          {
            type: tabsContentItemType,
            components:
              '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>',
            attributes: { class: 'tab-content-item' },
          },
          {
            type: tabsContentItemType,
            components:
              '<p>Molestie nullam ullamcorper elit mauris at dolor consectetur habitant taciti primis etiam! Dictum sagittis mauris facilisi vitae nascetur.</p>',
            attributes: { class: 'tab-content-item' },
          },
          {
            type: tabsContentItemType,
            components:
              '<p>Placerat convallis potenti eros ut primis nisl. In pellentesque magna at sapien suspendisse. Pretium natoque dolor torquent conubia tortor duis gravida posuere.</p>',
            attributes: { class: 'tab-content-item' },
          },
        ],
        styles: `
          .tab__content > div {
            display: none;
          }
          .tab__content > div.is--active {
            display: block !important;
          }
        `,
      },
    },
  });

  domc.addType(tabsContentItemType, {
    model: {
      defaults: {
        editable: false,
        collection: false,
        droppable: true,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: false,
        selectable: true,
        hoverable: true,
        resizable: false,
        styles: `
          .tab-content-item {
            padding: 20px;
          }
          .tab-content-item:empty {
            height: 50px;
            border: 1px dashed #999;
            border-radius: 4px;
            margin-top: 10px;
            padding: 16px;
          }
        `,
      },
    },
  });
};
