import React from 'react';
import PropTypes from 'prop-types';
import { useIconFinder } from 'hooks';
import { useIntl } from 'react-intl';
import { LOAD_STATUS } from 'appConstants';
import TextField from 'components/Forms/TextField';
import Select from 'components/Forms/Select';
import ImageItem from 'components/Images/ImageItem';
import ImagesContent from 'components/Images/ImagesContent';

function IconImages({ onSetImageUrl }) {
  const intl = useIntl();

  const {
    state: {
      data,
      searchTerm,
      category,
      style,
      status,
      totalCount,
      count,
      offset,
    },
    setSearchTerm,
    loadNextPage,
    iconsCategories,
    iconsStyles,
  } = useIconFinder();

  return (
    <div className="image-modal__content image-modal__icons">
      <div className="image-modal__icons__actions">
        <TextField
          className="image-modal__icons__actions__search"
          label={intl.formatMessage({
            id: 'image-modal.icons.search-input.label',
          })}
          placeholder={intl.formatMessage({
            id: 'image-modal.icons.search-input.placeholder',
          })}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value, category, style)}
          fullWidth
        />
        <Select
          disabled={iconsCategories?.length <= 0}
          value={category}
          onChange={(e) =>
            setSearchTerm(searchTerm, e.target.value || '', style)
          }
          className="image-modal__icons__actions__categories"
          label={intl.formatMessage({
            id: 'image-modal.icons.categories-select.label',
          })}>
          <option value="">
            {intl.formatMessage({
              id: 'image-modal.icons.categories-select.label-none',
            })}
          </option>
          {iconsCategories.map(({ name, identifier }) => (
            <option key={identifier} value={identifier}>
              {name}
            </option>
          ))}
        </Select>
        <Select
          disabled={iconsStyles?.length <= 0}
          value={style}
          onChange={(e) =>
            setSearchTerm(searchTerm, category, e.target.value || '')
          }
          className="image-modal__icons__actions__styles"
          label={intl.formatMessage({
            id: 'image-modal.icons.styles-select.label',
          })}>
          <option value="">
            {intl.formatMessage({
              id: 'image-modal.icons.styles-select.label-none',
            })}
          </option>
          {iconsStyles.map(({ name, identifier }) => (
            <option key={identifier} value={identifier}>
              {name}
            </option>
          ))}
        </Select>
      </div>
      <div className="image-modal__images-wrapper">
        <ImagesContent
          showEmptyContent={status === LOAD_STATUS.IDLE}
          showNotFound={
            !data.length &&
            (status === LOAD_STATUS.RESOLVED || status === LOAD_STATUS.REJECTED)
          }
          showBigSpinner={!data.length && status === LOAD_STATUS.LOADING}
          showLoadMore={
            totalCount - count > offset && status !== LOAD_STATUS.LOADING
          }
          showLoadMoreSpinner={Boolean(
            data.length && status === LOAD_STATUS.LOADING,
          )}
          onLoadNextPage={loadNextPage}>
          {data.map((item) => (
            <ImageItem
              key={item.id}
              thumbUrl={item.url}
              description={item.description}
              type="icon"
              onItemClick={() => {
                onSetImageUrl(item.url);
              }}
            />
          ))}
        </ImagesContent>
      </div>
    </div>
  );
}

IconImages.propTypes = {
  onSetImageUrl: PropTypes.func.isRequired,
};

export default IconImages;
