import axios from 'axios';

const httpGetVersions = (id) => {
  return axios.get(`/api/v2/usertemplate/${id}/versions`);
};

const httpGetRestoedVersion = (id, date) => {
  return axios.get(`/api/v2/usertemplate/${id}/version/${date}`);
};

export { httpGetVersions, httpGetRestoedVersion };
