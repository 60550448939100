import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { useQuery } from 'hooks';
import { getDirtyValues, isEmptyObject } from 'helpers';
import { useEditorContext } from 'routes/Editor/EditorContext';
import Button from '../../Button';
import {
  Appearance,
  Domain,
  EmailSettings,
  FontManager,
  Forms,
  Page,
  ScheduleSettings,
  SEO,
  Social,
  Tracking,
} from './components';
import { getDefaultTemplateSettingsValues } from './utils';
import './PageSettingsModal.sass';

const messages = defineMessages({
  applyButton: { id: 'button.apply' },
  cancelButton: { id: 'button.cancel' },
  errorMessage: { id: 'toast.error-message' },
});

function PageSettings({
  editor,
  onSaveAppearanceSettings,
  onClose,
  subscriptionPlan,
}) {
  const { system } = useQuery();

  const [currentTab, setCurrentTab] = useState(0);

  const {
    template,
    appearanceSettings,
    isSaveSettingsLoading,
    onSaveSettings,
  } = useEditorContext();

  const intl = useIntl();

  const pageSettingsForm = useForm({
    defaultValues: getDefaultTemplateSettingsValues(
      template,
      appearanceSettings,
    ),
    mode: 'onBlur',
  });

  const { dirtyFields } = useFormState({
    control: pageSettingsForm.control,
  });

  const { reset } = pageSettingsForm;

  // resetting default values when template updates
  useEffect(() => {
    reset(getDefaultTemplateSettingsValues(template, appearanceSettings), {
      keepValues: true,
    });
  }, [template, appearanceSettings, reset]);

  // This form contains page settings and appearance settings. The difference
  // between them is that appearance settings should not be send to backend.
  // Because of this appearance settings should be separated from page settings
  // on submit
  const onSubmit = ({ appearanceSettings, ...payload }) => {
    onSaveAppearanceSettings(appearanceSettings);

    const {
      appearanceSettings: dirtyAppearanceSettings,
      ...dirtyPageSettingsFields
    } = dirtyFields;

    const changedSettings = getDirtyValues(payload, dirtyPageSettingsFields);

    if (isEmptyObject(changedSettings)) {
      onClose();
    } else {
      onSaveSettings(changedSettings);
    }
  };

  return (
    <FormProvider {...pageSettingsForm}>
      <form
        className="page-settings-modal__form"
        onSubmit={pageSettingsForm.handleSubmit(onSubmit)}>
        <Tabs
          onChange={(index) => setCurrentTab(index)}
          className="page-settings-modal__content-wrapper">
          <TabList>
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.page.tab" />
            </Tab>
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.appearance.tab" />
            </Tab>
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.fonts.tab" />
            </Tab>
            {!system && (
              <Tab as="div">
                <FormattedMessage id="page-settings-modal.domain.tab" />
              </Tab>
            )}
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.seo.tab" />
            </Tab>
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.social.tab" />
            </Tab>
            {!system && (
              <Tab as="div">
                <FormattedMessage id="page-settings-modal.tracking.tab" />
              </Tab>
            )}
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.forms.tab" />
            </Tab>
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.email.tab" />
            </Tab>
            <Tab as="div">
              <FormattedMessage id="page-settings-modal.schedule.tab" />
            </Tab>
          </TabList>
          <TabPanels className="page-settings-modal__tab-panels">
            <TabPanel>{currentTab === 0 && <Page />}</TabPanel>
            <TabPanel>
              {currentTab === 1 && (
                <Appearance
                  subscriptionPlan={subscriptionPlan}
                  userSelectedFonts={template?.fonts}
                />
              )}
            </TabPanel>
            <TabPanel>
              {currentTab === 2 && (
                <FontManager
                  editor={editor}
                  userSelectedFonts={template?.fonts}
                />
              )}
            </TabPanel>
            {!system && (
              <TabPanel>
                {currentTab === 3 && (
                  <Domain subscriptionPlan={subscriptionPlan} />
                )}
              </TabPanel>
            )}
            <TabPanel>{currentTab === (system ? 3 : 4) && <SEO />}</TabPanel>
            <TabPanel>{currentTab === (system ? 4 : 5) && <Social />}</TabPanel>
            {!system && <TabPanel>{currentTab === 6 && <Tracking />}</TabPanel>}
            <TabPanel>
              {currentTab === (system ? 5 : 7) && (
                <Forms subscriptionPlan={subscriptionPlan} />
              )}
            </TabPanel>
            <TabPanel>
              {currentTab === (system ? 6 : 8) && <EmailSettings />}
            </TabPanel>
            <TabPanel>
              {currentTab === (system ? 7 : 9) && <ScheduleSettings />}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <div className="page-settings-modal__buttons">
          <Button type="button" onClick={onClose}>
            {intl.formatMessage(messages.cancelButton)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            loading={isSaveSettingsLoading}
            disabled={isSaveSettingsLoading}>
            {intl.formatMessage(messages.applyButton)}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

PageSettings.propTypes = {
  editor: PropTypes.object,
  onSaveAppearanceSettings: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subscriptionPlan: PropTypes.object.isRequired,
};

export default PageSettings;
