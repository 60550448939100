import axios from 'axios';

const httpGetSubscriptionPlans = (payload) => {
  return axios.get('/api/v2/subscription_plan', payload);
};

const httpCreateSubscriptionPlan = (payload) => {
  return axios.post('/api/v2/subscription_plan', payload);
};

const httpUpdateSubscriptionPlans = (payload) => {
  return axios.put(`/api/v2/subscription_plan/${payload.id}`, payload);
};

export {
  httpGetSubscriptionPlans,
  httpCreateSubscriptionPlan,
  httpUpdateSubscriptionPlans,
};
