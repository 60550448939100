import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useQuery } from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { isStringWithSpecialSigns } from 'helpers';
import { useEditorContext } from 'routes/Editor/EditorContext';
import Cropper from 'components/Cropper';
import TextField from 'components/Forms/TextField';
import CategoriesCombobox from 'components/CategoriesCombobox';

const messages = defineMessages({
  nameValidation: { id: 'general-error.name-validation' },
  descriptionValidation: { id: 'general-error.description-validation' },
  maxLenExceededValidation: {
    id: 'general-error.max-len-exceeded-validation',
  },
  nameEmptyError: { id: 'general-error.name-empty' },
  tempalteTitleLabel: { id: 'page-settings-modal.page.tempalte-title-label' },
  namePlaceholder: { id: 'page-settings-modal.page.name-placeholder' },
  tagsLabel: { id: 'page-settings-modal.page.tags-label' },
  tagsPlaceholder: { id: 'page-settings-modal.page.tags-placeholder' },
  descriptionLabel: { id: 'page-settings-modal.page.description-label' },
  descriptionPlaceholder: {
    id: 'page-settings-modal.page.description-placeholder',
  },
  categoriesLabel: { id: 'page-settings-modal.page.categories-label' },
  categoriesPlaceholder: {
    id: 'page-settings-modal.page.categories-placeholder',
  },
  categoriesDescription: {
    id: 'page-settings-modal.page.categories-description',
  },
  faviconLabel: { id: 'page-settings-modal.page.favicon-label' },
  faviconDescription: { id: 'page-settings-modal.page.favicon-description' },
  appiconLabel: { id: 'page-settings-modal.page.appicon-label' },
  appiconDescription: { id: 'page-settings-modal.page.appicon-description' },
});

function Page() {
  const intl = useIntl();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { system } = useQuery();
  const isSystem = Boolean(system);

  const {
    template: {
      settings: { favicon, appicon },
    },
    onUploadImage,
  } = useEditorContext();

  const [isFaviconLoading, setIsFaviconLoading] = useState(false);
  const [isAppiconLoading, setIsAppiconLoading] = useState(false);

  const handleUploadFavicon = async (file) => {
    setIsFaviconLoading(true);
    await onUploadImage('favicon', file);
    setIsFaviconLoading(false);
  };

  const handleUploadAppicon = async (file) => {
    setIsAppiconLoading(true);
    await onUploadImage('appicon', file);
    setIsAppiconLoading(false);
  };

  return (
    <div className="page-settings-modal__page">
      <div className="page-settings-modal__row">
        <TextField
          {...register('name', {
            required: intl.formatMessage(messages.nameEmptyError),
            maxLength: {
              value: 255,
              message: intl.formatMessage(messages.maxLenExceededValidation),
            },
            validate: (value) => {
              if (isStringWithSpecialSigns(value)) {
                return intl.formatMessage(messages.nameValidation);
              }
            },
          })}
          maxLength={255}
          label={intl.formatMessage(messages.tempalteTitleLabel)}
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          helperText={errors.name?.message}
          error={Boolean(errors.name)}
          fullWidth
        />
        {!isSystem && (
          <TextField
            {...register('tags')}
            label={intl.formatMessage(messages.tagsLabel)}
            placeholder={intl.formatMessage(messages.tagsPlaceholder)}
            fullWidth
          />
        )}
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('description', {
            maxLength: {
              value: 70,
              message: intl.formatMessage(messages.maxLenExceededValidation),
            },
            validate: (value) => {
              if (value.length > 0 && isStringWithSpecialSigns(value)) {
                return intl.formatMessage(messages.descriptionValidation);
              }
            },
          })}
          maxLength={70}
          label={intl.formatMessage(messages.descriptionLabel)}
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          error={Boolean(errors.description)}
          helperText={errors.description?.message}
          fullWidth
        />
        {isSystem && (
          <Controller
            control={control}
            name="categories"
            render={({ field: { onChange, value } }) => (
              <CategoriesCombobox
                showNewCategoryItem
                value={value}
                onChange={onChange}
              />
            )}
          />
        )}
      </div>
      <div className="page-settings-modal__row">
        <Cropper
          label={intl.formatMessage(messages.faviconLabel)}
          helperText={intl.formatMessage(messages.faviconDescription)}
          canvasSize={{ width: 150, height: 150 }}
          cropSize={{ width: 32, height: 32 }}
          imageUrl={favicon}
          border={5}
          loading={isFaviconLoading}
          onSave={handleUploadFavicon}
        />
        <Cropper
          label={intl.formatMessage(messages.appiconLabel)}
          helperText={intl.formatMessage(messages.appiconDescription)}
          canvasSize={{ width: 150, height: 150 }}
          cropSize={{ width: 256, height: 256 }}
          imageUrl={appicon}
          border={5}
          loading={isAppiconLoading}
          onSave={handleUploadAppicon}
        />
      </div>
    </div>
  );
}

export default Page;
