import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import { FONTS } from 'appConstants';
import { List, ListItem } from 'components/List';
import cx from 'classnames';
import FormControl from '../FormControl';
import Label from '../Label';
import FormHelperText from '../FormHelperText';
import './FontsSelect.sass';

const itemToString = (item) => (item ? item.name : '');

function FontsSelect({
  userSelectedFonts,
  label,
  className,
  helperText,
  error,
  value,
  fullWidth,
  onChange,
}) {
  const allFonts = [...FONTS, ...userSelectedFonts];
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: allFonts,
    selectedItem: value,
    itemToString,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
  });

  const listItems = useMemo(
    () =>
      allFonts.map((item, index) => {
        return (
          <ListItem
            style={{ fontFamily: item.name }}
            className={cx('dnd-fonts-select__list-item', {
              selected: selectedItem === allFonts[index],
              highlighted: highlightedIndex === index,
            })}
            key={item.name}
            {...getItemProps({ item, index })}>
            {item.name}
          </ListItem>
        );
      }),
    [highlightedIndex, selectedItem, getItemProps, allFonts],
  );

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && <Label {...getLabelProps()}>{label}</Label>}
      <button
        style={{ fontFamily: itemToString(selectedItem) }}
        type="button"
        className={cx('dnd-fonts-select__button', { fullWidth })}
        {...getToggleButtonProps()}>
        {selectedItem ? itemToString(selectedItem) : 'Select font'}
        <span className="icon icon-expand-more" />
      </button>
      <List
        className={cx('dnd-fonts-select__list', { isOpen })}
        {...getMenuProps()}>
        {listItems}
      </List>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

FontsSelect.propTypes = {
  userSelectedFonts: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.node,
  helperText: PropTypes.node,
  error: PropTypes.bool,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    url: PropTypes.string,
  }),
  children: PropTypes.node,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default FontsSelect;
