export default (editor) => {
  const cmd = editor.Commands;
  cmd.add('open-sm-custom', {
    run(em, sender) {
      this.sender = sender;
      const config = em.getConfig();
      const panels = em.Panels;

      let cn = document.getElementById('containerStyle');
      const componetSettings = document.getElementById('component-settings');

      componetSettings.style.display = 'block';

      if (!cn) {
        cn = document.createElement('div');
        cn.id = 'containerStyle';

        const tagesHeader = document.createElement('div');
        tagesHeader.className = 'gjs-clm-tags__header';
        tagesHeader.innerText = 'ID Classes';

        // Add button to collapse
        const collapse = document.createElement('div');
        collapse.className = 'collapse';

        const button = document.createElement('div');
        button.className = 'button-collapse';
        button.innerHTML = 'Collapse all tabs';
        button.onclick = () => {
          const setIsComponentSettingsCollapsed = editor
            .getModel()
            .get('setIsComponentSettingsCollapsed');

          setIsComponentSettingsCollapsed(false);
          em.StyleManager.getSectors().each((ctg) => ctg.set('open', false));
        };
        const closeIcon = document.createElement('span');
        closeIcon.className = 'icon icon-close';

        collapse.appendChild(button);
        button.appendChild(closeIcon);

        const cn2 = document.createElement('div');
        cn2.className = 'div-style';
        cn.append(cn2);
        componetSettings.before(collapse);

        setTimeout(function () {
          cn.querySelector('.gjs-clm-tags').prepend(tagesHeader);
        }, 50);

        const dvm = em.DeviceManager;
        if (dvm && config.showDevices) {
          const devicePanel = panels.addPanel({ id: 'devices-c' });
          devicePanel
            .set('appendContent', dvm.render())
            .trigger('change:appendContent');
        }
        // Class Manager container
        const clm = em.SelectorManager;
        if (clm) cn2.append(clm.render([]));

        cn2.append(em.StyleManager.render());
        // Create header
        const header = document.createElement('div');
        header.className = 'header-style';
        header.innerHTML = em.t('styleManager.empty');

        cn.append(header);

        // Create panel if not exists
        if (!panels.getPanel('views-container')) {
          this.panel = panels.addPanel({ id: 'views-container' });
        } else {
          this.panel = panels.getPanel('views-container');
        }
        // Add all containers to the panel
        this.panel.set('appendContent', cn).trigger('change:appendContent');

        this.target = em.editor;
        this.listenTo(this.target, 'component:toggled', this.toggleSm);
      }
      this.toggleSm();
    },
    toggleSm() {
      const { target, sender } = this;

      if (sender && sender.get && !sender.get('active')) return;

      if (target?.getSelectedAll().length === 1) {
        const divStyle = document.getElementsByClassName('div-style')[0];
        divStyle.classList.remove('hide');
        const collapse = document.getElementsByClassName('collapse')[0];
        collapse.classList.remove('hide');

        const headerStyle = document.getElementsByClassName('header-style')[0];
        headerStyle.classList.add('hide');
      } else {
        const divStyle = document.getElementsByClassName('div-style')[0];
        if (divStyle) divStyle.classList.add('hide');
        const collapse = document.getElementsByClassName('collapse')[0];
        if (collapse) collapse.classList.add('hide');

        const headerStyle = document.getElementsByClassName('header-style')[0];
        if (headerStyle) headerStyle.classList.remove('hide');
      }
    },
    stop() {
      const divStyle = document.getElementsByClassName('div-style')[0];
      if (divStyle) divStyle.classList.add('hide');

      const headerStyle = document.getElementsByClassName('header-style')[0];
      if (headerStyle) headerStyle.classList.add('hide');

      const collapse = document.getElementsByClassName('collapse')[0];
      if (collapse) collapse.classList.add('hide');

      document.getElementById('component-settings').style.display = 'none';
    },
  });
};
