import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import './FileInput.sass';

function FileInput({ children, onChange, ...rest }) {
  const inputRef = useRef(null);

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  return (
    <>
      <input
        className="dnd-file-input"
        ref={inputRef}
        type="file"
        onChange={onChange}
        {...rest}
      />
      {React.cloneElement(children, { onClick: handleClick })}
    </>
  );
}

FileInput.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FileInput;
