import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import { FormattedMessage } from 'react-intl';

import { usePopover } from 'hooks';
import IconButton from 'components/IconButton';
import CategoryModal from 'components/Modals/CategoryModal';
import BasePopover from 'components/Modals/BasePopover';
import './CategoryMenu.sass';

function CategoryMenu({
  category,
  categoriesNames,
  onHideCategoryClick,
  onDeleteCategoryClick,
}) {
  const ref = useRef(null);
  const {
    data: {
      targetRef,
      isOpen: isPopoverOpen,
      open: openPopover,
      close: closePopover,
    },
    props: popoverProps,
  } = usePopover({ triggerRef: ref });

  return (
    <>
      <Menu>
        <MenuButton
          aria-label={`open ${category.name} category menu`}
          color="secondary"
          as={IconButton}
          ref={ref}>
          <span className="icon icon-more-horiz" />
        </MenuButton>
        <MenuList className="category-menu__menu-list">
          <MenuItem onSelect={openPopover}>
            <span className="icon icon-edit" />
            <FormattedMessage id="category-menu.rename-category" />
          </MenuItem>
          <MenuItem onSelect={onHideCategoryClick}>
            {category.hidden ? (
              <>
                <span className="icon icon-visibility-off" />
                <FormattedMessage id="category-menu.show-category" />
              </>
            ) : (
              <>
                <span className="icon icon-visibility" />
                <FormattedMessage id="category-menu.hide-category" />
              </>
            )}
          </MenuItem>
          <MenuItem
            className="category-menu__menu-list--delete"
            onSelect={onDeleteCategoryClick}>
            <span className="icon icon-delete" />
            <FormattedMessage id="category-menu.delete-category" />
          </MenuItem>
        </MenuList>
      </Menu>
      <BasePopover
        targetRef={targetRef}
        className="category-menu__popover"
        isOpen={isPopoverOpen}
        title={<FormattedMessage id="category-modal.title" />}
        onClose={closePopover}
        {...popoverProps}>
        <CategoryModal
          category={category}
          categoriesNames={categoriesNames}
          onClose={closePopover}
        />
      </BasePopover>
    </>
  );
}

CategoryMenu.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    hidden: PropTypes.bool,
  }).isRequired,
  categoriesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHideCategoryClick: PropTypes.func.isRequired,
  onDeleteCategoryClick: PropTypes.func.isRequired,
};

export default CategoryMenu;
