import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './NewTemplateItem.sass';

function NewTemplateItem({ onItemClick }) {
  return (
    <div className="new-template-item">
      <div
        className="new-template-item__wrapper"
        role="button"
        tabIndex="0"
        onClick={onItemClick}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onItemClick();
          }
        }}>
        <span className="icon icon-add" />
        <FormattedMessage id="template.button-create" />
      </div>
    </div>
  );
}

NewTemplateItem.propTypes = {
  onItemClick: PropTypes.func.isRequired,
};

export default NewTemplateItem;
