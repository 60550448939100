import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import { showSuccessMessage, showErrorMessage, getErrorText } from 'helpers';
import {
  httpSearchPrincipal,
  httpGetPrincipalRoles,
  httpUpdatePrincipalRoles,
} from 'api/principal.api';
import './UserPermissions.sass';
import LoadingSpinner from 'components/LoadingSpinner';

export const UserPermissions = ({ onClose }) => {
  const intl = useIntl();

  const messages = defineMessages({
    applySuccessMessage: {
      id: 'user-menu.user-permissions-success-message',
    },
    adminRole: { id: 'user-menu.user-permissions-admin-role' },
    userRole: { id: 'user-menu.user-permissions-user-role' },
    cancelButton: { id: 'button.cancel' },
    saveButton: { id: 'button.apply' },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    const getRoles = async () => {
      try {
        const { data } = await httpGetPrincipalRoles();

        setRoles(data);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      }
    };

    const getUsers = async () => {
      try {
        const body = {
          limit: 20,
          offset: 0,
          orderBy: 'firstName',
          criteria: [
            {
              key: 'email',
              value: '@amdtelecom.net',
              operation: 'CONTAINS',
              operator: 'OR',
            },
            {
              key: 'email',
              value: '@waymore.io',
              operation: 'CONTAINS',
              operator: 'OR',
            },
          ],
        };

        const { data } = await httpSearchPrincipal(body);

        setUsers(data);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    getUsers();
    getRoles();
  }, [setUsers, setRoles]);

  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const handleSave = async (values) => {
    const resultRoles = [];

    if (!values || !roles) {
      showErrorMessage(getErrorText());
      onClose();

      return;
    }

    setSaveLoading(true);

    for (const property in values) {
      const filteredRoles = roles.filter(
        (item) => item.name === values[property],
      );
      const roleId = filteredRoles[0].id;

      resultRoles.push({ principalId: property, roleId });
    }

    try {
      const res = await httpUpdatePrincipalRoles(resultRoles);

      showSuccessMessage(intl.formatMessage(messages.applySuccessMessage));

      return res;
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      setSaveLoading(false);
      onClose();
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    users && (
      <form
        className="user-permissions-modal__form"
        onSubmit={handleSubmit(handleSave)}>
        <div
          data-testid="user-list"
          className="user-permissions-modal__content">
          {users.principals
            .filter(
              (item) =>
                (item?.permissions?.length &&
                  item?.permissions.findIndex(
                    (item) => item.name === 'ADMIN_CAN_SET_USER_ROLES',
                  ) === -1) ||
                !item?.permissions?.length,
            )
            .map(({ id, email, userRole }) => {
              const principalId = id.replace(/"/g, '');
              return (
                <div
                  key={principalId}
                  data-testid="user-list-item"
                  className="user-permissions-modal__content__item">
                  <span>{email}</span>
                  <label htmlFor={`admin-${principalId}`}>
                    <input
                      {...register(`${principalId}`)}
                      type="radio"
                      name={`${principalId}`}
                      id={`admin-${principalId}`}
                      value="ROLE_ADMIN"
                      defaultChecked={userRole?.name === 'ROLE_ADMIN'}
                    />
                    {intl.formatMessage(messages.adminRole)}
                  </label>
                  <label htmlFor={`user-${principalId}`}>
                    <input
                      {...register(`${principalId}`)}
                      type="radio"
                      name={`${principalId}`}
                      id={`user-${principalId}`}
                      value="ROLE_USER"
                      defaultChecked={
                        userRole?.name === 'ROLE_USER' || !userRole?.name
                      }
                    />
                    {intl.formatMessage(messages.userRole)}
                  </label>
                </div>
              );
            })}
        </div>
        <div className="user-permissions-modal__buttons">
          <Button type="button" color="secondary" onClick={onClose}>
            {intl.formatMessage(messages.cancelButton)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            loading={isSaveLoading}
            disabled={isSaveLoading}>
            {intl.formatMessage(messages.saveButton)}
          </Button>
        </div>
      </form>
    )
  );
};

UserPermissions.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UserPermissions;
