import axios from 'axios';
import { getSessionId } from '../helpers';

const httpGetCredentials = async ({ data }) => {
  const res = await axios.post('/api/v2/auth/credentials', data);

  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${res.data.token}`;
    config.headers.sessionId = getSessionId();

    if (config.url === '/unsplash' || config.url === '/icons') {
      delete config.headers.sessionId;
    }

    return config;
  });

  return res;
};

export { httpGetCredentials };
