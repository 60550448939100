const productType = 'waymore-product';
const calendarType = 'waymore-calendar';
const columnType = 'column';
const linkButtonType = 'link-button';
const buttonTitle = 'Buy Now';

const getProductItemsStyle = (oldProduct, itemClass) => {
  let productItemStylesString = '';

  const productItem = oldProduct.find(itemClass);
  const productItemStyle =
    productItem.length > 0 ? productItem[0].getStyle() : '';

  Object.keys(productItemStyle).map(
    (objectKey) =>
      (productItemStylesString += `${objectKey}: ${productItemStyle[
        objectKey
      ].replaceAll('"', '&quot;')};`),
  );

  return productItemStylesString;
};

export default (editor) => {
  const cmd = editor.Commands;

  // command to set image which is running by custom image modal
  cmd.add('set-waymore-form-src', {
    run(editor, sender, { src }) {
      const target = editor?.getSelected();

      target.set('src', src);
    },
  });

  cmd.add('set-waymore-product', {
    run(
      editor,
      sender,
      {
        layout,
        showCategory,
        showDescription,
        product: {
          imageLink,
          productName,
          productCategories,
          price,
          productLink,
          productDescription,
        },
        oldProduct,
      },
    ) {
      const selected = editor?.getSelected();
      const selectedHtml = selected?.view?.el.innerHTML;

      const productImageDefaultStyle = 'width: 100%; height: auto;';
      const productTitleDefaultStyle =
        'margin: 0; padding: 0 0 15px; font-size: 24px; font-weight: 700; font-family: Arial, Helvetica, sans-serif;';
      const productDescriptionDefaultStyle = 'padding: 0 0 15px; margin: 0';
      const productCategoryDefaultStyle = 'padding: 0 0 15px; margin: 0';
      const productPriceDefaultStyle =
        'padding: 0 0 15px; margin: 0; color: rgb(126, 140, 224); font-size: 28px; font-weight: 700; font-family: Arial, Helvetica, sans-serif;';
      const productButtonDefaultStyle =
        'display: block; padding: 10px 20px; font-weight: 700; text-transform: uppercase; text-decoration: none; font-size: 16px; color: rgba(255, 255, 255); background-color: rgb(126, 140, 224); text-align: center; font-family: Arial, Helvetica, sans-serif;';

      const productSectionStyle =
        layout === 'vertical' ? 'text-align: center' : 'display: flex';
      const productImageStyle = !selectedHtml
        ? productImageDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__image');
      const productTitleStyle = !selectedHtml
        ? productTitleDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__title');
      const productDescriptionStyle = !selectedHtml
        ? productDescriptionDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__description');
      const productPriceStyle = !selectedHtml
        ? productPriceDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__price');
      const productButtonStyle = !selectedHtml
        ? productButtonDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__button');

      const categories =
        showCategory && productCategories
          ? `<div class="product-item__categories" style="${productCategoryDefaultStyle}">${productCategories.join(
              ' / ',
            )}</div>`
          : '';

      const description =
        showDescription && productDescription
          ? `<div className="product-item__description" style="${productDescriptionStyle}">${productDescription}</div>`
          : '';

      let content;
      if (layout === 'vertical') {
        content = `
          <img src="${imageLink}" alt="${productName}" class="product-item__image" style="${productImageStyle}" />
          <h3 class="product-item__title" style="${productTitleStyle}">${productName}</h3>
          ${categories}
          ${description}
          <div class="product-item__price" style="${productPriceStyle}">${price}</div>
          <a href="${productLink}" data-type="${linkButtonType}" data-gjs-type="${linkButtonType}" class="product-item__button" style="${productButtonStyle}">${buttonTitle}</a>
        `;
      } else {
        content = `
          <div data-type="${columnType}" style="flex-basis: 50%" class="col" width="50" overflow="visible">
            <img src="${imageLink}" alt="${productName}" class="product-item__image" style="${productImageStyle}" />
          </div>
          <div data-type="${columnType}" style="flex-basis: 50%" class="col" width="50" overflow="visible">
            <h3 class="product-item__title" style="${productTitleStyle}">${productName}</h3>
            ${categories}
            ${description}
            <div class="product-item__price" style="${productPriceStyle}">${price}</div>
            <a href="${productLink}" data-type="${linkButtonType}" data-gjs-type="${linkButtonType}" class="product-item__button" style="${productButtonStyle}">${buttonTitle}</a>
          </div>
        `;
      }

      selected.replaceWith(`
          <div data-gjs-type="${productType}" data-type="${productType}" data-product-name="${productName}" class="product-section" style="${productSectionStyle}" verticalalign="default" overflow="visible">
            ${content}
          </div>
        `);
    },
  });

  cmd.add('set-waymore-calendar', {
    run(editor, sender, { calendarId, serviceId, noHeader }) {
      const selected = editor?.getSelected();

      selected.set(
        'content',
        `
        <style>
        div[data-type="${calendarType}"] {
          margin: 1rem;
        }
        .ea-widget .ea-widget-content h2.ea-widget-heading {
          margin-bottom: 2rem;
        }
        </style>
        <div data-gjs-type="${calendarType}" data-type="${calendarType}" data-params="${
          serviceId === 'all' ? 'all_services' : 'service'
        }=${serviceId}&${
          noHeader ? 'no_header' : 'without_no_header'
        }=${noHeader}" data-url="https://cal.waymore.io/index.php/booking/${calendarId}" tag="h1" class="ea-widget ${calendarType}" id="${calendarId}"><div style="display: flex; align-items: center; justify-content: center; height: 200px; width: 100%; background: #f5f4f4;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style="width: 50px">
        <path fill="#8f8aa6" d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5Z"/></svg><div></div>
        <script src="https://cal.waymore.io/booking.js"></script>
      `,
      );
    },
  });
};
