import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash.uniqueid';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import './SelectBox.sass';

function SelectBox({
  error = false,
  onChange,
  loading,
  options,
  defaultValue,
  tooltip,
  label = '',
  placeholder = '',
  description = '',
  sendObject = false,
  isDisabled = false,
  isMulti = false,
  maxLimit,
}) {
  const selectInputRef = useRef(null);

  const [selectedOptions, setSelectedOptions] = useState(defaultValue);

  const selectUniqueId = uniqueId('dnd-select-box__select-');
  const tooltipUniqueId = uniqueId('dnd-select-box__tooltip_id-');

  useEffect(() => {
    if (isMulti && selectInputRef?.current?.inputRef) {
      selectInputRef.current.inputRef.placeholder = placeholder;
    }
  }, [placeholder, isMulti]);

  return (
    <div
      className={cx('dnd-form-control fullWidth dnd-select-box', {
        'dnd-select-box--error': error,
      })}>
      {label && (
        <label
          className="dnd-label dnd-select-box__label"
          htmlFor={selectUniqueId}>
          {label}
          {tooltip && (
            <div className="dnd-select-box__tooltip">
              <span
                className="icon icon-info"
                data-tip
                data-for={tooltipUniqueId}
              />
              <ReactTooltip
                id={tooltipUniqueId}
                type="dark"
                aria-haspopup="true"
                place="right"
                delayHide={300}
                backgroundColor="#000000"
                effect="solid">
                <p>{tooltip}</p>
              </ReactTooltip>
            </div>
          )}
        </label>
      )}
      <Select
        ref={selectInputRef}
        isDisabled={isDisabled}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: 35,
          }),
        }}
        id={selectUniqueId}
        className="dnd-select-box__select"
        classNamePrefix="dnd-select-box__select"
        isLoading={loading}
        isMulti={isMulti}
        isOptionDisabled={() => maxLimit && selectedOptions.length >= 1}
        placeholder={isMulti ? '' : placeholder}
        defaultValue={defaultValue}
        onChange={(selectedItems) => {
          let newSelectedItems = [];
          let newSelectedItem = {};

          if (isMulti) {
            if (!sendObject) {
              selectedItems.forEach(({ value }) =>
                newSelectedItems.push(value),
              );
            } else {
              selectedItems.forEach(({ value, label, ...rest }) =>
                newSelectedItems.push({
                  id: value,
                  name: label,
                  ...rest,
                }),
              );
            }
          } else {
            if (selectedItems) {
              const { value, label, ...rest } = selectedItems;
              newSelectedItem = {
                ...{
                  id: value,
                  name: label,
                  ...rest,
                },
              };
            }
          }

          setSelectedOptions(selectedItems);

          onChange(isMulti ? newSelectedItems : newSelectedItem);
        }}
        options={options}
        isClearable={!isMulti}
        components={{
          IndicatorSeparator: () => null,
          ...(isMulti && { ClearIndicator: () => null }),
        }}
      />
      {description && <div className="dnd-form-helper-text">{description}</div>}
    </div>
  );
}

SelectBox.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  sendObject: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maxLimit: PropTypes.number,
  isMulti: PropTypes.bool,
};

export default SelectBox;
