export const sectionStyles = `
  .section {
    display: flex;
    padding: 4% 0;
  }

  .container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .row {
    display: flex;
    margin: 0 -15px;
    width: 100%;
  }

  .col {
    padding: 0 15px;
  }

  @media screen and (max-width: 568px) {
    .row {
      flex-wrap: wrap;
    }

    .col {
      flex-basis: 100%;
      flex-grow: 1;
      min-width: 100%;
    }
  }
`;
