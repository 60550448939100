import axios from 'axios';

const httpGetCalendarServices = (payload) => {
  return axios.get('/api/v2/calendar/services', payload);
};

const httpGetCalendar = (id) => {
  return axios.get(`/api/v2/calendar/email/${id}`);
};

export { httpGetCalendarServices, httpGetCalendar };
