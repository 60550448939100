import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'hooks';
import IconButton from 'components/IconButton';
import SubscriptionPlanEditModal from 'components/Modals/SubscriptionPlanEditModal';
import SubscriptionPlanCreateModal from 'components/Modals/SubscriptionPlanCreateModal';
import BaseModal from 'components/Modals/BaseModal';
import { getsubscriptionPlanName } from 'helpers';
import SubscriptionPlanItem from './SubscriptionPlanItem';
import './SubscriptionPlans.sass';

export const SubscriptionPlans = ({ plans }) => {
  const [currentPlanId, setCurrentPlanId] = useState('');

  const {
    isOpen: isOpenSubscriptionPlanEdit,
    open: openSubscriptionPlanEdit,
    close: closeSubscriptionPlanEdit,
  } = useModal();

  const {
    isOpen: isOpenSubscriptionPlanCreate,
    open: openSubscriptionPlanCreate,
    close: closeSubscriptionPlanCreate,
  } = useModal();

  return (
    <div className="subscription-plans-modal">
      <IconButton
        className="subscription-plans-modal__plans-list__create-btn"
        variant="contained"
        color="secondary"
        onClick={() => openSubscriptionPlanCreate()}>
        <span className="icon icon-add" />
      </IconButton>
      <ul className="subscription-plans-modal__plans-list">
        {plans &&
          plans.length > 0 &&
          plans
            .filter(({ type }) => type === 'page')
            .sort((a, b) => {
              if (a.subscriptionPlanNumber > b.subscriptionPlanNumber) return 1;
              if (a.subscriptionPlanNumber < b.subscriptionPlanNumber)
                return -1;
              return 0;
            })
            .map((item) => (
              <SubscriptionPlanItem
                key={item.id}
                plan={item}
                getsubscriptionPlanName={getsubscriptionPlanName}
                openSubscriptionPlanEdit={openSubscriptionPlanEdit}
                setCurrentPlanId={setCurrentPlanId}
              />
            ))}
        <li />
        {plans &&
          plans.length > 0 &&
          plans
            .filter(({ type }) => type === 'email')
            .sort((a, b) => {
              if (a.subscriptionPlanNumber > b.subscriptionPlanNumber) return 1;
              if (a.subscriptionPlanNumber < b.subscriptionPlanNumber)
                return -1;
              return 0;
            })
            .map((item) => (
              <SubscriptionPlanItem
                key={item.id}
                plan={item}
                getsubscriptionPlanName={getsubscriptionPlanName}
                openSubscriptionPlanEdit={openSubscriptionPlanEdit}
                setCurrentPlanId={setCurrentPlanId}
              />
            ))}
      </ul>
      <BaseModal
        isOpen={isOpenSubscriptionPlanEdit}
        maxWidth="600px"
        headerTitle="modal-subscription-plan-edit.modal-header"
        onClose={closeSubscriptionPlanEdit}>
        <SubscriptionPlanEditModal
          onClose={closeSubscriptionPlanEdit}
          currentPlanId={currentPlanId}
          getsubscriptionPlanName={getsubscriptionPlanName}
        />
      </BaseModal>
      <BaseModal
        isOpen={isOpenSubscriptionPlanCreate}
        maxWidth="600px"
        headerTitle="modal-subscription-plan-create.modal-header"
        onClose={closeSubscriptionPlanCreate}>
        <SubscriptionPlanCreateModal onClose={closeSubscriptionPlanCreate} />
      </BaseModal>
    </div>
  );
};

SubscriptionPlans.propTypes = {
  plans: PropTypes.array.isRequired,
};

export default SubscriptionPlans;
