import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Loading.sass';

const Loading = ({ hiddenBg = false }) => {
  return (
    <div
      aria-label="loading"
      className={cx('dnd-loading', { 'no-bg': hiddenBg })}>
      <div className="dnd-loading__squares-wrapper">
        <div className="dnd-loading__square" />
        <div className="dnd-loading__square" />
        <div className="dnd-loading__square" />
        <div className="dnd-loading__square" />
        <div className="dnd-loading__square" />
        <div className="dnd-loading__square" />
        <div className="dnd-loading__square" />
      </div>
    </div>
  );
};

Loading.propTypes = {
  hiddenBg: PropTypes.bool,
};

export default Loading;
