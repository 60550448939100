import React from 'react';
import { FormattedMessage } from 'react-intl';

const ErrorMessage = () => {
  return (
    <div id="error-modal">
      <div className="error-wrapper">
        <h1>
          <FormattedMessage id="error-modal.ops" />
        </h1>
        <span>
          <FormattedMessage id="error-modal.message2" />
        </span>
      </div>
    </div>
  );
};

export default ErrorMessage;
