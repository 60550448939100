import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const SideBarGroupLinks = ({ groupTitle, buttons, children }) => {
  return (
    <div className="sidebar__group">
      <div className="sidebar__group__header">
        <div className="sidebar__group__title">
          <FormattedMessage id={groupTitle} />
        </div>
        {buttons && <div className="sidebar__group__buttons">{buttons}</div>}
      </div>
      <ul className="sidebar__group__links">{children}</ul>
    </div>
  );
};

SideBarGroupLinks.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  buttons: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default SideBarGroupLinks;
