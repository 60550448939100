import { FORM_DEFAULT_ATTRIBUTES } from './constants';

export default (
  editor,
  {
    category,
    emailInputType,
    formButtonType,
    formType,
    nameInputType,
    numberCustomFieldType,
    phoneInputType,
    textareaType,
    textCustomFieldType,
  },
) => {
  const bm = editor.BlockManager;

  const {
    buttonbgcolor,
    formbgcolor,
    labelcolor,
    formstyle,
    inputbgcolor,
    inputbordercolor,
    labelmargin,
    maxwidth,
    placeholdercolor,
    rowsgap,
    showlabels,
  } = FORM_DEFAULT_ATTRIBUTES;

  bm.add(formType, {
    attributes: { class: 'bpreview-form' },
    label: 'Form',
    category,
    content: `
      <form
        action=""
        redirect=""
        method="POST"
        data-type="${formType}"
        formstyle="${formstyle}"
        showlabels="${showlabels}"
        maxwidth="${maxwidth}"
        labelmargin="${labelmargin}"
        rowsgap="${rowsgap}"
        formbgcolor="${formbgcolor}"
        labelcolor="${labelcolor}"
        inputbgcolor="${inputbgcolor}"
        inputbordercolor="${inputbordercolor}"
        buttonbgcolor="${buttonbgcolor}"
        state="normal"
        placeholdercolor="${placeholdercolor}"
      >
        <div class="form-content" data-gjs-name="Form content" style="margin: 25px;">
          <h3 class="form-header">Contact form</h3>
          <h4 class="form-subheader">Fill in the form and press the submit button to get in touch</h4>
          <div
            data-gjs-copyable="false"
            data-gjs-droppable="false"
            data-type="name-input"
            style="margin-bottom: 10px"
          >
            <label
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              class="label">First name</label>
            <input
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              data-type="contact-input"
              data-gjs-type="contact-input"
              name="firstName"
              placeholder="Your first name"
              class="input"
              style="margin-bottom: 10px"
            />
            <label
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              class="label">Last name</label>
            <input
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              data-type="contact-input"
              data-gjs-type="contact-input"
              name="lastName"
              placeholder="Your last name"
              class="input"
            />
          </div>
          <div
            data-gjs-copyable="false"
            data-gjs-droppable="false"
            data-type="email-input"
            style="margin-bottom: 10px"
          >
            <label
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              class="label">Email</label>
            <input
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              data-type="contact-input"
              data-gjs-type="contact-input"
              name="email"
              type="email"
              placeholder="email@example.com"
              required="true"
              class="input"
            />
          </div>
          <div
            class="phone-input"
            data-gjs-copyable="false"
            data-gjs-droppable="false"
            data-type="phone-input"
            style="margin-bottom: 10px"
          >
            <label
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              class="label">Phone</label>
            <input
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              data-type="contact-input"
              data-gjs-type="contact-input"
              placeholder="(021) 555-0123"
              name="phone"
              type="tel"
              required="true"
              class="input"
            />
          </div>
          <div data-type="form-group" class="form-group">
            <label
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              class="label">Comment</label>
            <textarea
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-draggable="false"
              name="comment"
              class="input textarea"
              placeholder="Type your Message Here..."></textarea>
          </div>
          <div data-type="form-group" class="form-group">
            <button data-type="${formButtonType}" class="button" type="submit">Submit</button>
          </div>
        </div>
        <div
          class="state-success"
          style="text-align: center; margin: 25px"
          data-type="div"
          data-form-state="success"
          data-gjs-name="Thank you"
          data-gjs-collection="false"
          data-gjs-draggable="false"
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-resizable="false"
        >
          <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#00b881" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm113-303L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
          </svg>
          <h2>Thank you!</h2>
          <p style=" font-size: 14px; line-height: 20px;">We received your request. You can check your inbox to be sure you've sent the message successfully</p>
        </div>
        <div
          class="state-error"
          style="padding: 10px; text-align: center; color: #721c24; background-color: #f8d7da; margin: 0 25px 25px"
          data-type="div"
          data-form-state="error"
          data-gjs-name="Error"
          data-gjs-collection="false"
          data-gjs-draggable="false"
          data-gjs-droppable="false"
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-resizable="false"
        >An error occurred on processing your request, try again!</div>
        <div
          class="email-error"
          style="padding: 10px; text-align: center; color: #721c24; background-color: #f8d7da; margin: 0 25px 25px"
          data-type="div"
          data-form-state="error"
          data-gjs-name="Email error"
          data-gjs-collection="false"
          data-gjs-draggable="false"
          data-gjs-droppable="false"
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-resizable="false"
        >The form should have at least one email type input</div>
      </form>
      <style>
      .state-success,
      .state-error,
      .email-error {
        display: none;
      }
      .iti__country-list {
        overflow: hidden;
      }
      div.iti {
        display: block;
      }
      </style>
    `,
  });

  bm.add(nameInputType, {
    attributes: { class: 'bpreview-name-input' },
    label: 'Name Input',
    category,
    content: `
      <div
        data-gjs-copyable="false"
        data-gjs-droppable="false"
        data-type="name-input"
        style="margin-bottom: 10px"
      >
        <label
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          class="label">First name</label>
        <input
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          data-type="contact-input"
          data-gjs-type="contact-input"
          name="firstName"
          placeholder="Your first name"
          class="input"
          style="margin-bottom: 10px"
        />
        <label
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          class="label">Last name</label>
        <input
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          data-type="contact-input"
          data-gjs-type="contact-input"
          name="lastName"
          placeholder="Your last name"
          class="input"
        />
      </div>
    `,
  });

  bm.add(emailInputType, {
    attributes: { class: 'bpreview-email-input' },
    label: 'Email Input',
    category,
    content: `
      <div
        data-gjs-copyable="false"
        data-gjs-droppable="false"
        data-type="email-input"
        style="margin-bottom: 10px"
      >
        <label
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          class="label">Email</label>
        <input
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          data-type="contact-input"
          data-gjs-type="contact-input"
          name="email"
          type="email"
          placeholder="email@example.com"
          required="true"
          class="input"
        />
      </div>
    `,
  });

  bm.add(phoneInputType, {
    attributes: { class: 'bpreview-phone-input' },
    label: 'Phone Input',
    category,
    content: `
      <div
        class="phone-input"
        data-gjs-copyable="false"
        data-gjs-droppable="false"
        data-type="phone-input"
        style="margin-bottom: 10px"
      >
        <label
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          class="label">Phone</label>
        <input
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          data-type="contact-input"
          data-gjs-type="contact-input"
          name="phone"
          placeholder="(021) 555-0123"
          type="tel"
          required="true"
          class="input"
        />
      </div>
    `,
  });

  bm.add(numberCustomFieldType, {
    attributes: { class: 'bpreview-number-input' },
    label: 'Number',
    category,
    content: `
      <div
        data-gjs-copyable="false"
        data-gjs-droppable="false"
        data-type="number-custom-field"
        style="margin-bottom: 10px"
      >
        <label
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          class="label">Label</label>
        <input
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          data-field-type="custom-field"
          name=""
          type="number"
          data-type="number-custom-field-input"
          placeholder="Type your number here"
          class="input"
        />
      </div>
    `,
    media: '<span>Custom Field</span>',
  });

  bm.add(textCustomFieldType, {
    attributes: { class: 'bpreview-text-input' },
    label: 'Text',
    category,
    content: `
      <div
        data-gjs-copyable="false"
        data-gjs-droppable="false"
        data-type="text-custom-field"
        style="margin-bottom: 10px"
      >
        <label
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          class="label">Label</label>
        <input
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-draggable="false"
          data-field-type="custom-field"
          name=""
          data-type="text-custom-field-input"
          placeholder="Type your text here"
          class="input"
        />
      </div>
    `,
    media: '<span>Custom Field</span>',
  });

  bm.add(textareaType, {
    attributes: { class: 'bpreview-textarea' },
    label: 'Textarea',
    category,
    content: `
    <div data-type="form-group" class="form-group">
      <label
        data-gjs-copyable="false"
        data-gjs-removable="false"
        data-gjs-draggable="false"
        class="label">Comment</label>
      <textarea
        data-gjs-copyable="false"
        data-gjs-removable="false"
        data-gjs-draggable="false"
        name="comment"
        class="input textarea"
        placeholder="Type your Message Here..."></textarea>
    </div>
    `,
  });
};
