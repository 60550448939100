import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { useFont, useModal } from 'hooks';
import BaseModal from 'components/Modals/BaseModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import Pagination from './Pagination';

const messages = defineMessages({
  totalLabel: { id: 'page-settings-modal-fonts.selected-items.total-label' },
  totalsLabel: { id: 'page-settings-modal-fonts.selected-items.totals-label' },
  noItemsText: { id: 'page-settings-modal-fonts.selected-items.no-items-text' },
  nameText: { id: 'page-settings-modal-fonts.selected-items.name-text' },
  variantsText: {
    id: 'page-settings-modal-fonts.selected-items.variants-text',
  },
  subsetsText: { id: 'page-settings-modal-fonts.selected-items.subsets-text' },
  confirmModalDescription: {
    id: 'page-settings-modal-fonts.selected-items.confirm-modal-description',
  },
});

function SelectedFonts({ selectedItems, setSelectedItems }) {
  const intl = useIntl();

  const [itemForDelete, setItemForDelete] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = selectedItems.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );
  const nPages = Math.ceil(selectedItems.length / recordsPerPage);

  const { isLoading, deleteFontSetting } = useFont();

  const {
    isOpen: isConfirmDeleteModalOpen,
    open: openConfirmDeleteModal,
    close: closeConfirmDeleteModal,
  } = useModal();

  const handleDeleteItem = () => {
    const filteredFonts = selectedItems.filter(
      ({ family }) => family !== itemForDelete,
    );
    setSelectedItems(filteredFonts);

    closeConfirmDeleteModal();
    setItemForDelete('');

    deleteFontSetting({
      fonts: filteredFonts,
    });
  };

  return (
    <div className="page-settings-modal__fonts__selected">
      <div
        className={cx('page-settings-modal__fonts__selected__table-wrapper', {
          'table-loading': isLoading,
        })}>
        {isLoading && <span className="icon icon-spinner" />}
        <table>
          <thead>
            <tr>
              <th>{intl.formatMessage(messages.nameText)}</th>
              <th>{intl.formatMessage(messages.variantsText)}</th>
              <th>{intl.formatMessage(messages.subsetsText)}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {currentRecords?.length > 0 ? (
              currentRecords.map(({ family, variants, subsets }, index) => (
                <tr key={`seleted-item-${index}`}>
                  <td>{family}</td>
                  <td>{variants.join(', ')}</td>
                  <td>{subsets.join(', ')}</td>
                  <td align="center">
                    <button
                      data-tip
                      disabled={isLoading}
                      data-for={`remove-font-${index}`}
                      onClick={(e) => {
                        e.preventDefault();

                        setItemForDelete(family);

                        openConfirmDeleteModal();
                      }}>
                      <span className="icon icon-delete" />
                    </button>
                    <ReactTooltip
                      id={`remove-font-${index}`}
                      class="title-tooltip"
                      type="dark"
                      aria-haspopup="true"
                      place="top"
                      clickable={false}
                      border={false}
                      effect="solid">
                      {family}
                    </ReactTooltip>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="no-items" colSpan="4">
                  {intl.formatMessage(messages.noItemsText)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {selectedItems?.length > 0 && (
        <div className="page-settings-modal__fonts__selected__table-footer">
          <p className="page-settings-modal__fonts__selected__table-footer__total">
            {intl.formatMessage(
              selectedItems.length === 1
                ? messages.totalLabel
                : messages.totalsLabel,
              {
                total: selectedItems.length,
                startItem: `${
                  currentPage === 1
                    ? '1'
                    : Math.abs(indexOfLastRecord - recordsPerPage)
                }`,
                endItem: `${indexOfLastRecord}`,
              },
            )}
          </p>
          {selectedItems?.length > recordsPerPage && (
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      )}
      <BaseModal
        isOpen={isConfirmDeleteModalOpen}
        headerTitle="page-settings-modal-fonts.selected-items.confirm-modal-header"
        maxWidth="600px"
        onClose={closeConfirmDeleteModal}>
        <ConfirmModal
          loading={false}
          buttonsColor="secondary"
          onClose={closeConfirmDeleteModal}
          onConfirm={handleDeleteItem}>
          {intl.formatMessage(messages.confirmModalDescription)}
        </ConfirmModal>
      </BaseModal>
    </div>
  );
}

SelectedFonts.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
};

export default SelectedFonts;
