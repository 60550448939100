import axios from 'axios';

const httpGetCategories = (params) => {
  return axios.get('/api/v2/categories', { params });
};

const httpCreateCategory = (payload) => {
  return axios.post('/api/v2/categories', payload);
};

const httpUpdateCategory = (payload) => {
  return axios.patch(`/api/v2/categories/${payload.id}`, payload);
};

const httpDeleteCategory = (id) => {
  return axios.delete(`/api/v2/categories/${id}`);
};

export {
  httpGetCategories,
  httpCreateCategory,
  httpUpdateCategory,
  httpDeleteCategory,
};
