export default (editor) => {
  const cm = editor.Commands;

  cm.add('zoom-reset', {
    run(editor) {
      editor.Canvas.setZoom(100);

      document.querySelector(
        '.editor-content__panel-zoom-actions__view > span',
      ).innerHTML = 100;
    },
  });
};
