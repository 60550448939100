import { LOAD_STATUS, TEMPLATE_STATUS } from 'appConstants';
import { isValidStatus } from 'helpers';

export const emailTemplatesSelector = (state, searchTerm = '') => {
  return state.template.emailTemplates.published?.template?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );
};

export const templatesNumbersSelector = (state) => {
  return {
    published: state.template.templates.published.pagination.totalElements,
    draft: state.template.templates.draft.pagination.totalElements,
    deleted: state.template.templates.deleted.pagination.totalElements,
    shared: state.template.templates.shared.pagination.totalElements,
    favorited: state.template.templates.favorited.pagination.totalElements,
    scheduled: state.template.templates.scheduled.pagination.totalElements,
    system: state.template.systemTemplates.items.length,
    deletedSystem:
      state.template.systemTemplates.deleted.pagination.totalElements,
  };
};

export const templatesSelector = (
  state,
  status,
  categoryId,
  searchTerm = '',
  system_templates,
) => {
  if (status === 'favorited') {
    return state.template.templates['favorited'].template;
  }

  if (status === 'scheduled') {
    return state.template.templates['scheduled'].template;
  }

  if (categoryId && searchTerm) {
    if (categoryId === 'all') {
      return state.template.systemTemplates.searchItems;
    }

    return state.template.systemTemplates.searchItems.filter((template) =>
      template.categories.some((category) => category.id === categoryId),
    );
  }

  if (categoryId && categoryId === 'all') {
    return state.template.systemTemplates.items;
  }

  if (system_templates === 'true' && status === TEMPLATE_STATUS.DELETED) {
    return state.template.systemTemplates.deleted.template;
  }

  if (categoryId) {
    return state.template.systemTemplates.items.filter((template) =>
      template.categories.some((category) => category.id === categoryId),
    );
  }

  if (isValidStatus(status)) {
    return state.template.templates[status].template;
  }

  return state.template.templates.published.template;
};

export const isTemplatesLoadingSelector = (state) => {
  return (
    state.template.templates.status === LOAD_STATUS.IDLE ||
    state.template.templates.status === LOAD_STATUS.LOADING
  );
};

export const isSystemTemplatesLoadingSelector = (state) => {
  return (
    state.template.templates.status === LOAD_STATUS.IDLE ||
    state.template.templates.status === LOAD_STATUS.LOADING
  );
};

export const isTemplatesErrorSelector = (state) => {
  return state.template.templates.status === LOAD_STATUS.REJECTED;
};

export const isAllTemplatesLoadedSelector = (state) => {
  return (
    state.template.templates.isLoaded && state.template.systemTemplates.isLoaded
  );
};

export const paginationSelector = (
  state,
  status,
  isSystem,
  system_templates,
) => {
  if (isSystem && status === TEMPLATE_STATUS.PUBLISHED) {
    return state.template.systemTemplates.pagination;
  }

  if (status === TEMPLATE_STATUS.DELETED && system_templates === 'true') {
    return state.template.systemTemplates.deleted.pagination;
  }

  if (isValidStatus(status)) {
    return state.template.templates[status].pagination;
  }

  return state.template.templates.published.pagination;
};
