import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import find from 'lodash.find';
import { useModal, useQuery } from 'hooks';
import { useSelector } from 'react-redux';
import { principalIdSelector } from 'store/selectors';
import { TEMPLATE_STATUS, VERIFY_DOMAIN_STATUSES } from 'appConstants';
import Loading from 'components/Loading/Loading';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  buildPublishedUrl,
  buildScreenshotUrl,
  showErrorMessage,
} from 'helpers';
import BaseModal from 'components/Modals/BaseModal';
import UpgradeModal from 'components/Modals/UpgradeModal';
import cx from 'classnames';
import TemplateItem from './TemplateItem';
import NewTemplateItem from './NewTemplateItem';
import EmptyContent from './EmptyContent';
import MouseScroll from './MouseScroll';
import './Templates.sass';

const Templates = ({
  status,
  isSystem,
  showBigLoader,
  isError,
  items,
  lastPage,
  isLoadingMore,
  onSetNextTemplatesPage,
  onSetNextDeletedSystemTemplatesPage,
  onDeleteClick,
  onDuplicateClick,
  onEditTemplateClick,
  onSelectSystemTemplateClick,
  onRemoveSystemTemplateClick,
  onRemoveSystemForeverTemplateClick,
  onPreviewSystemTemplateClick,
  onRestoreClick,
  onRemoveClick,
  onOpenTemplateSettingsModal,
  onOpenTemplateSharingModal,
  onOpenNewTemplateModal,
  bulkActionTemplatesList,
  setBulkActionTemplatesList,
  bulkAction,
  templatesView,
  onUpdateSharedTemplateLockStatus,
  onUnpublishTempalte,
  onUpdateFavoriteStatus,
  onSendUnlockRequestSharedTemplateClick,
}) => {
  const { system_templates, status: queryStatus } = useQuery();

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const contentRef = useRef(null);
  const [hideMouseScroll, setHideMouseScroll] = useState(false);

  const currentUser = useSelector(principalIdSelector);

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollTop, offsetHeight, scrollHeight } = contentRef.current;
      if (scrollTop > 800 || scrollTop + offsetHeight >= scrollHeight) {
        setHideMouseScroll(true);
      } else {
        setHideMouseScroll(false);
      }
    }
  };

  const {
    isOpen: isOpenUpgradeModal,
    open: openUpgradeModal,
    close: closeUpgradeModal,
  } = useModal();

  useEffect(() => {
    if (inView && system_templates !== 'true') {
      onSetNextTemplatesPage();
    }

    if (inView && system_templates === 'true') {
      onSetNextDeletedSystemTemplatesPage();
    }
  }, [
    inView,
    onSetNextTemplatesPage,
    onSetNextDeletedSystemTemplatesPage,
    system_templates,
  ]);

  useEffect(() => {
    contentRef.current.scrollTo(0, 0);
  }, [status]);

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current) {
        const { scrollHeight, clientHeight, offsetHeight, scrollTop } =
          contentRef.current;
        const hasVerScrollbar = scrollHeight > clientHeight;

        if (
          scrollHeight === offsetHeight ||
          !hasVerScrollbar ||
          scrollTop + offsetHeight >= scrollHeight
        ) {
          setHideMouseScroll(true);
        } else {
          setHideMouseScroll(false);
        }
      }
    }, 1000);
  }, [setHideMouseScroll, items]);

  let content;

  if (showBigLoader) {
    content = <Loading hiddenBg />;
  } else if (isError) {
    content = <ErrorMessage />;
  } else if (!items.length) {
    content = <EmptyContent status={queryStatus} />;
  } else {
    let sortedItems = [...items];

    sortedItems.sort((a, b) => {
      if (
        find(a?.categories, { name: 'Featured Layouts' }) &&
        !find(b?.categories, { name: 'Featured Layouts' })
      ) {
        return -1;
      } else if (
        find(b?.categories, { name: 'Featured Layouts' }) &&
        !find(a?.categories, { name: 'Featured Layouts' })
      ) {
        return 1;
      }

      return 0;
    });

    const templateItems = sortedItems.map((template) => {
      const {
        id,
        tags,
        lastModified,
        name,
        status: templateStatus,
        settings: { domain },
        categories,
        size: templateSize,
        isShared,
        isLocked,
        isScheduled,
        scheduleSettings,
        userId,
        collaboratorId,
        collaboratorsList,
      } = template;
      const templateImage = buildScreenshotUrl(id, lastModified);

      const checkSharedTemplateIsEditable = () => {
        if (isShared && isLocked && collaboratorId !== currentUser) {
          showErrorMessage(
            "The template is locked because it's currently being edited by another user.",
          );

          return false;
        }

        onEditTemplateClick(id);
      };

      return (
        <TemplateItem
          key={id}
          templateId={id}
          tags={tags}
          isShared={isShared}
          isLocked={isLocked}
          isScheduled={isScheduled}
          scheduleSettings={scheduleSettings}
          userId={userId}
          collaboratorId={collaboratorId}
          collaboratorsList={collaboratorsList}
          templateSize={templateSize}
          status={templateStatus}
          isSystem={isSystem}
          templateImage={templateImage}
          name={name}
          lastModified={lastModified}
          categories={categories}
          disableDeleteBecauseOfActiveDomain={[
            VERIFY_DOMAIN_STATUSES.ACTIVE,
            VERIFY_DOMAIN_STATUSES.ACTIVATION_IN_PROGRESS,
            VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS,
            VERIFY_DOMAIN_STATUSES.DEACTIVATION_ERROR,
          ].includes(domain.status)}
          publishedUrl={buildPublishedUrl(id, templateStatus, domain)}
          onItemClick={() => {
            if (isSystem) {
              onSelectSystemTemplateClick(template);
            } else {
              checkSharedTemplateIsEditable();
            }
          }}
          onSettingsClick={() =>
            onOpenTemplateSettingsModal({ template, isSystem: false })
          }
          onShareClick={() => onOpenTemplateSharingModal({ template })}
          onEditClick={() => checkSharedTemplateIsEditable()}
          onDeleteClick={() => onDeleteClick(id)}
          onRemoveClick={() => onRemoveClick(id)}
          onDuplicateClick={onDuplicateClick}
          onSystemEditClick={() => onEditTemplateClick(id, isSystem)}
          onSystemSelectClick={() => onSelectSystemTemplateClick(template)}
          onSystemSettingsClick={() =>
            onOpenTemplateSettingsModal({ template, isSystem: true })
          }
          onPreviewSystemClick={() => onPreviewSystemTemplateClick(id, status)}
          onSystemRemoveClick={() => onRemoveSystemTemplateClick(id)}
          onSystemRemoveForeverClick={() =>
            onRemoveSystemForeverTemplateClick(id)
          }
          onRestoreClick={() => onRestoreClick(id)}
          openUpgradeModal={openUpgradeModal}
          bulkActionTemplatesList={bulkActionTemplatesList}
          setBulkActionTemplatesList={setBulkActionTemplatesList}
          bulkAction={bulkAction}
          onUpdateSharedTemplateLockStatus={onUpdateSharedTemplateLockStatus}
          onUnpublishClick={() => onUnpublishTempalte(id)}
          onUpdateFavoriteStatus={onUpdateFavoriteStatus}
          onSendUnlockRequestSharedTemplateClick={
            onSendUnlockRequestSharedTemplateClick
          }
        />
      );
    });

    if (
      status !== TEMPLATE_STATUS.DELETED &&
      !isSystem &&
      queryStatus !== 'shared' &&
      queryStatus !== 'favorited' &&
      queryStatus !== 'scheduled'
    ) {
      templateItems.push(
        <NewTemplateItem key="new" onItemClick={onOpenNewTemplateModal} />,
      );
    }

    content = (
      <>
        <div className={`templates ${templatesView}`}>
          <div className="view-templates-preview">{templateItems}</div>
          <div
            className={cx('templates__scroll-more', {
              'templates__scroll-more--deactive': hideMouseScroll,
            })}>
            <MouseScroll />
          </div>
        </div>
        {!lastPage && !isSystem && (
          <div
            ref={!isLoadingMore ? ref : null}
            className="templates__load-more">
            {isLoadingMore ? <LoadingSpinner /> : 'Load More'}
          </div>
        )}
        <BaseModal
          isOpen={isOpenUpgradeModal}
          onClose={closeUpgradeModal}
          maxWidth="350px"
          headerTitle="upgrade-modal.title">
          <UpgradeModal onClose={closeUpgradeModal} />
        </BaseModal>
      </>
    );
  }

  return (
    <main
      className={cx('templates-wrapper', {
        'shared-templates': queryStatus === 'shared',
        'favorited-templates': queryStatus === 'favorited',
        'scheduled-templates': queryStatus === 'scheduled',
      })}
      onScroll={() => onScroll()}
      ref={contentRef}>
      {content}
    </main>
  );
};

Templates.propTypes = {
  isSystem: PropTypes.bool,
  status: PropTypes.string.isRequired,
  showBigLoader: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  lastPage: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  onSetNextTemplatesPage: PropTypes.func.isRequired,
  onSetNextDeletedSystemTemplatesPage: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDuplicateClick: PropTypes.func.isRequired,
  onEditTemplateClick: PropTypes.func.isRequired,
  onSelectSystemTemplateClick: PropTypes.func.isRequired,
  onRemoveSystemTemplateClick: PropTypes.func.isRequired,
  onRemoveSystemForeverTemplateClick: PropTypes.func.isRequired,
  onPreviewSystemTemplateClick: PropTypes.func.isRequired,
  onRestoreClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onOpenTemplateSettingsModal: PropTypes.func.isRequired,
  onOpenTemplateSharingModal: PropTypes.func.isRequired,
  onOpenNewTemplateModal: PropTypes.func.isRequired,
  bulkActionTemplatesList: PropTypes.array.isRequired,
  setBulkActionTemplatesList: PropTypes.func.isRequired,
  bulkAction: PropTypes.bool.isRequired,
  templatesView: PropTypes.string.isRequired,
  onUpdateSharedTemplateLockStatus: PropTypes.func.isRequired,
  onUnpublishTempalte: PropTypes.func.isRequired,
  onUpdateFavoriteStatus: PropTypes.func.isRequired,
  onSendUnlockRequestSharedTemplateClick: PropTypes.func.isRequired,
};

export default Templates;
