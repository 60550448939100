import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  httpActivateDomain,
  httpDeactivateDomain,
  httpDeleteDomain,
  httpGetDomain,
  httpVerifyDomain,
} from 'api';
import { getErrorText, showErrorMessage } from 'helpers';

const verifyDomain = createAsyncThunk(
  'domain/verifyDomain',
  async (payload) => {
    try {
      const { data } = await httpVerifyDomain(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText(error));
      throw error;
    }
  },
);

const getDomain = createAsyncThunk('domain/getDomain', async (id) => {
  const { data } = await httpGetDomain(id);

  return data;
});

const activateDomain = createAsyncThunk('domain/activateDomain', async (id) => {
  try {
    await httpActivateDomain(id);
  } catch (error) {
    showErrorMessage(getErrorText(error));
    throw error;
  }
});

const deactivateDomain = createAsyncThunk(
  'domain/deactivateDomain',
  async (id) => {
    try {
      await httpDeactivateDomain(id);
    } catch (error) {
      showErrorMessage(getErrorText(error));
      throw error;
    }
  },
);

const deleteDomain = createAsyncThunk('domain/deleteDomain', async (id) => {
  try {
    await httpDeleteDomain(id);
  } catch (error) {
    showErrorMessage(getErrorText(error));
    throw error;
  }
});

export {
  verifyDomain,
  getDomain,
  activateDomain,
  deactivateDomain,
  deleteDomain,
};
