const templateImageSelector = (state) => {
  return state.assets.templateImages;
};

const globalImageSelector = (state) => {
  return state.assets.globalImages;
};

const unsplashSelector = (state) => {
  return state.assets.unsplash;
};

const iconFinderSelector = (state) => {
  return state.assets.iconFinder;
};

export {
  globalImageSelector,
  templateImageSelector,
  unsplashSelector,
  iconFinderSelector,
};
