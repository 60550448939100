import { useState } from 'react';
import { LOAD_STATUS } from 'appConstants';
import { showErrorMessage, getErrorText } from 'helpers';
import { httpAiImages } from 'api';

export const useAiImages = () => {
  const [loadStatus, setLoadStatus] = useState(LOAD_STATUS.IDLE);
  const [searchResult, setSearchResult] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [style, setStyle] = useState('');
  const [aspectRatio, setAspectRatio] = useState('');

  const handleGenerateImages = async () => {
    setLoadStatus(LOAD_STATUS.LOADING);

    try {
      const { data } = await httpAiImages({
        prompt,
        negativePrompt,
        aspectRatio,
        style,
      });

      setSearchResult(data);
      setLoadStatus(LOAD_STATUS.RESOLVED);
    } catch (error) {
      showErrorMessage(getErrorText());

      setLoadStatus(LOAD_STATUS.REJECTED);

      throw error;
    }
  };

  return {
    aspectRatio,
    handleGenerateImages,
    loadStatus,
    prompt,
    negativePrompt,
    searchResult,
    setAspectRatio,
    setPrompt,
    setNegativePrompt,
    setStyle,
    style,
  };
};
