import { useToggleTypes } from 'hooks';
import { HEADER_SELECTOR, PARAGRAPH_SELECTOR } from 'appConstants';

export default async (editor) => {
  const cmd = editor.Commands;
  const wrapper = editor?.getWrapper();

  cmd.add('open-page-settings', {
    run(editor) {
      const allFonts = Array.from(JSON.parse(wrapper.get('fontsList')));

      const cssComposer = editor.CssComposer;
      const target = editor.getModel();
      const onChangeAppearanceSettings = editor
        .getModel()
        .get('onChangeAppearanceSettings');

      // default settings
      const settings = {
        headerFont: allFonts[0],
        paragraphFont: allFonts[0],
        lineSpacing: 'inherit',
        containerWidth: '960',
      };

      const headerRule = cssComposer.getRule(HEADER_SELECTOR);
      const paragraphRule = cssComposer.getRule(PARAGRAPH_SELECTOR);
      const containerRule = cssComposer.getRule('.container');

      if (headerRule) {
        const style = headerRule.get('style');

        if (style['font-family']) {
          const fontValue = style['font-family'];

          settings.headerFont = allFonts?.find(
            ({ value }) => value === fontValue,
          );
        }

        if (style['line-height']) {
          settings.lineSpacing = style['line-height'];
        }
      }

      if (paragraphRule) {
        const style = paragraphRule.get('style');

        if (style['font-family']) {
          const fontValue = style['font-family'];

          settings.paragraphFont = allFonts?.find(
            ({ value }) => value === fontValue,
          );
        }
      }

      if (containerRule) {
        const style = containerRule.get('style');

        if (style['max-width']) {
          settings.containerWidth = parseInt(style['max-width']);
        }
      }

      // this is generalSettings state here src\routes\Editor\Grapes\Grapes.js
      onChangeAppearanceSettings(settings);

      target.get('onShow')(useToggleTypes.showPageSettings);
    },
  });

  cmd.add('set-appearance-settings', {
    run(
      editor,
      sender,
      { headerFont, paragraphFont, lineSpacing, containerWidth },
    ) {
      const cssComposer = editor.CssComposer;

      const onChangeAppearanceSettings = editor
        .getModel()
        .get('onChangeAppearanceSettings');

      if (headerFont.url) {
        editor.runCommand('add-font', {
          url: headerFont.url.replaceAll('"', ''),
        });
      }

      if (paragraphFont.url) {
        editor.runCommand('add-font', {
          url: paragraphFont.url.replaceAll('"', ''),
        });
      }

      cssComposer.setRule(HEADER_SELECTOR, {
        'font-family': headerFont.value,
        'line-height': lineSpacing,
      });

      cssComposer.setRule(PARAGRAPH_SELECTOR, {
        'font-family': paragraphFont.value,
        'line-height': lineSpacing,
      });

      cssComposer.setRule('.container', {
        display: 'flex',
        width: '100%',
        margin: '0 auto',
        padding: '0 15px',
        'max-width': `${containerWidth}px`,
        'justify-content': 'center',
        'flex-wrap': 'wrap',
      });

      onChangeAppearanceSettings({
        headerFont,
        paragraphFont,
        lineSpacing,
        containerWidth,
      });
    },
  });
};
