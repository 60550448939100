import { createSlice } from '@reduxjs/toolkit';
import { LOAD_STATUS } from 'appConstants';
import {
  normalizeIconFinderResponse,
  normalizeUnsplashResponse,
} from 'helpers';
import { combineReducers } from 'redux';
import {
  clearIconFinder,
  clearTemplateImages,
  clearGlobalImages,
  clearUnsplash,
  deleteGlobalAsset,
  deleteAsset,
  getGlobalAssets,
  getAssets,
  getUnsplash,
  searchIcons,
  setIconFinderNextPage,
  setIconFinderSearchTerm,
  setUnsplashNextPage,
  setUnsplashSearchTerm,
  uploadAssets,
} from 'store/actions';

const initialGlobalImageState = {
  data: [],
  loading: false,
};

const initialTemplateImageState = {
  data: [],
  loading: false,
};

const { reducer: templateImages } = createSlice({
  name: 'templateImages',
  initialState: initialTemplateImageState,
  extraReducers: (builder) => {
    builder
      .addCase(getAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAssets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        const { name } = action.payload;

        state.data = state.data.filter(({ savedName }) => name !== savedName);
      })
      .addCase(clearTemplateImages, () => {
        return initialTemplateImageState;
      });
  },
});

const { reducer: globalImages } = createSlice({
  name: 'GlobalImages',
  initialState: initialGlobalImageState,
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGlobalAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getGlobalAssets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploadAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadAssets.fulfilled, (state, action) => {
        const newData = action.payload.reverse();

        state.loading = false;
        state.data = [...newData, ...state.data];
      })
      .addCase(uploadAssets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteGlobalAsset.fulfilled, (state, action) => {
        const { assetName } = action.payload;

        state.data = state.data.filter(
          ({ savedName }) => assetName !== savedName,
        );
      })
      .addCase(clearGlobalImages, () => {
        return initialGlobalImageState;
      });
  },
});

const initialUnsplashState = {
  searchTerm: '',
  page: 1,
  perPage: 20,
  totalPages: 1,
  status: LOAD_STATUS.IDLE,
  data: [],
};

const { reducer: unsplash } = createSlice({
  name: 'unsplash',
  initialState: initialUnsplashState,
  extraReducers: (builder) => {
    builder
      .addCase(getUnsplash.pending, (state) => {
        state.status = LOAD_STATUS.LOADING;
      })
      .addCase(getUnsplash.fulfilled, (state, action) => {
        if (!Array.isArray(action.payload?.results)) {
          return;
        }

        const results = normalizeUnsplashResponse(action.payload.results);

        state.status = LOAD_STATUS.RESOLVED;
        state.data = state.data.concat(results);
        state.totalPages = action.payload.total_pages;
      })
      .addCase(getUnsplash.rejected, (state) => {
        state.status = LOAD_STATUS.REJECTED;
      })
      .addCase(setUnsplashSearchTerm, (state, action) => {
        state.status = action.payload.status;
        state.searchTerm = action.payload.searchTerm;
        // setting to initial state because user searches for something else
        state.page = 1;
        state.totalPages = 1;
        state.data = [];
        state.error = null;
      })
      .addCase(setUnsplashNextPage, (state) => {
        state.page++;
        // setting status because of debounced value
        state.status = LOAD_STATUS.LOADING;
      })
      .addCase(clearUnsplash, () => {
        return initialUnsplashState;
      });
  },
});

const initialIconFinderState = {
  searchTerm: '',
  category: '',
  style: '',
  offset: 0,
  count: 36,
  totalCount: 1,
  status: LOAD_STATUS.IDLE,
  data: [],
};

const { reducer: iconFinder } = createSlice({
  name: 'iconFinder',
  initialState: initialIconFinderState,
  extraReducers: (builder) => {
    builder
      .addCase(searchIcons.pending, (state) => {
        state.status = LOAD_STATUS.LOADING;
      })
      .addCase(searchIcons.fulfilled, (state, action) => {
        const results = normalizeIconFinderResponse(action.payload.icons);

        state.status = LOAD_STATUS.RESOLVED;
        state.data = state.data.concat(results);
        state.totalCount = action.payload.total_count;
      })
      .addCase(searchIcons.rejected, (state) => {
        state.status = LOAD_STATUS.REJECTED;
      })
      .addCase(setIconFinderSearchTerm, (state, action) => {
        state.status = action.payload.status;
        state.searchTerm = action.payload.searchTerm;
        state.category = action.payload.category;
        state.style = action.payload.style;
        // setting to initial state because user searches for something else
        state.offset = 0;
        state.totalCount = 1;
        state.data = [];
      })
      .addCase(setIconFinderNextPage, (state) => {
        state.offset += state.count;
        // setting status because of debounced value
        state.status = LOAD_STATUS.LOADING;
      })
      .addCase(clearIconFinder, () => {
        return initialIconFinderState;
      });
  },
});

const assetsReducer = combineReducers({
  globalImages,
  templateImages,
  unsplash,
  iconFinder,
});

export { assetsReducer };
