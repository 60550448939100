import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_USERS } from 'appConstants';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import './SwitchUsers.sass';

export const SwitchUsers = ({ setNewSessionId, closeSwitchUsers }) => {
  const { email: defaultUserEmail } = useSelector((state) => state.user);

  const handleUserItemClick = (sessionId) => {
    setNewSessionId(sessionId);
    localStorage.setItem('fakeSessionId', sessionId);
    closeSwitchUsers();
  };

  return (
    <div className="switch-users-modal">
      {DEFAULT_USERS.map(
        ({ email, id, image, name, role, sessionId }, index) => {
          const isCurrentUser = email === defaultUserEmail;

          return (
            <div
              key={id}
              data-testid="switch-user-item"
              tabIndex={index + 1}
              role="button"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  isCurrentUser
                    ? e.preventDefault()
                    : handleUserItemClick(sessionId);
                }
              }}
              onClick={(e) =>
                isCurrentUser
                  ? e.preventDefault()
                  : handleUserItemClick(sessionId)
              }
              className={cx('switch-users-modal__user-item', {
                'current-user': isCurrentUser,
              })}>
              <div className="switch-users-modal__user-item__avatar">
                <img src={image} alt={name} />
              </div>
              <div className="switch-users-modal__user-item__details">
                <h3>{name}</h3>
                <div className="switch-users-modal__user-item__details__role">
                  {role === 'admin' ? 'Admin' : 'User'} ({id})
                </div>
                <div className="switch-users-modal__user-item__details__email">
                  {email}
                </div>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};

SwitchUsers.propTypes = {
  setNewSessionId: PropTypes.func.isRequired,
  closeSwitchUsers: PropTypes.func.isRequired,
};

export default SwitchUsers;
