export const categoriesSelector = (state) => {
  return Object.values(state.categories.items);
};

export const categoriesWithCountSelector = (state) => {
  return Object.values(state.categories.items).reduce((acc, category) => {
    let count = 0;

    // TODO: think about refactoring categories - convert categories
    // from array to object
    state.template.systemTemplates.items.forEach(({ categories }) => {
      const isCategoryPresent = categories.some(({ id }) => id === category.id);

      if (isCategoryPresent) {
        count++;
      }
    });

    acc.push({ ...category, count });

    return acc;
  }, []);
};
