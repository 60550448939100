import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useCodemirror } from 'hooks';
import CodeMirrorHeader from 'components/CodeMirrorHeader';
import FormControl from 'components/Forms/FormControl';
import Button from 'components/Button';
import './CustomCodeModal.sass';

function CustomCodeModal({ editor, onClose }) {
  const intl = useIntl();
  const htmlEditorRef = useRef();
  const selected = editor?.getSelected();

  const htmlEditor = useCodemirror(htmlEditorRef, {
    value: selected.get('custom-code-plugin__code') || '',
    mode: 'htmlmixed',
  });

  const handleSave = () => {
    selected.set('custom-code-plugin__code', htmlEditor.current.getValue());
    onClose();
  };

  return (
    <div className="custom-code-modal">
      <div className="custom-code-modal__row">
        <FormControl className="custom-code-modal__editor-wrapper">
          <CodeMirrorHeader title="Code" htmlEditor={htmlEditor} activeCode />
          <div className="custom-code-modal__editor" ref={htmlEditorRef} />
        </FormControl>
      </div>
      <div className="custom-code-modal__buttons">
        <Button
          onClick={handleSave}
          className="custom-code-modal__button"
          variant="contained">
          {intl.formatMessage({ id: 'button.save-codes' })}
        </Button>
      </div>
    </div>
  );
}

CustomCodeModal.propTypes = {
  editor: PropTypes.object,
  onClose: PropTypes.func,
};

export default CustomCodeModal;
