import { REDIRECT_URL } from 'appConstants';

export default (
  editor,
  {
    wayMoreFormType,
    wayMoreProductType,
    wayMoreCalendarType,
    waymoreIntegration,
  },
) => {
  const bm = editor.BlockManager;

  bm.add(wayMoreFormType, {
    label: 'Add a WayMore Form',
    activate: true,
    category: 'WayMore Forms',
    content: {
      type: wayMoreFormType,
      style: {
        height: '335px',
        width: '100%',
        border: 0,
      },
      attributes: { scrolling: 'auto' },
    },
    attributes: {
      class: `bpreview-forms-integration gjs-thumb-label--big ${
        !waymoreIntegration ? 'gjs-block--lock' : ''
      }`,
    },
    disable: !waymoreIntegration,
    media: !waymoreIntegration
      ? `<a target="_blank" title="Not enabled in your plan" href="${REDIRECT_URL}/settings/subscriptions" draggable="false"><span class="icon icon-lock"></span></a>`
      : '',
  });

  bm.add(wayMoreProductType, {
    label: 'Add products directly from your website',
    activate: true,
    category: 'WayMore Products',
    content: { type: wayMoreProductType },
    attributes: {
      class: `bpreview-products-integration gjs-thumb-label--big ${
        !waymoreIntegration ? 'gjs-block--lock' : ''
      }`,
    },
    disable: !waymoreIntegration,
    media: !waymoreIntegration
      ? `<a target="_blank" title="Not enabled in your plan" href="${REDIRECT_URL}/settings/subscriptions" draggable="false"><span class="icon icon-lock"></span></a>`
      : '',
  });

  bm.add(wayMoreCalendarType, {
    label: 'Add calendar booking widget',
    activate: true,
    category: 'WayMore Calendar',
    content: { type: wayMoreCalendarType },
    attributes: {
      class: `bpreview-calendar-integration gjs-thumb-label--big ${
        !waymoreIntegration ? 'gjs-block--lock' : ''
      }`,
    },
    disable: !waymoreIntegration,
    media: !waymoreIntegration
      ? `<a target="_blank" title="Not enabled in your plan" href="${REDIRECT_URL}/settings/subscriptions" draggable="false"><span class="icon icon-lock"></span></a>`
      : '',
  });
};
