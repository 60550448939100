import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import App from './App';
import { store } from './store';
import messages from './locales';
import './styles/main.sass';

const language = 'en';

const app = (
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages[language]}>
      <Router>
        <App />
      </Router>
    </IntlProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
