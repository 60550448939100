import React from 'react';
import PropTypes from 'prop-types';
import { LOAD_STATUS } from 'appConstants';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAiImages } from 'hooks';
import TextField from 'components/Forms/TextField';
import Select from 'components/Forms/Select';
import Button from 'components/Button';
import ImageItem from 'components/Images/ImageItem';
import ImagesContent from 'components/Images/ImagesContent';

function AiImages({ onSetImageUrl }) {
  const intl = useIntl();

  const {
    aspectRatio,
    handleGenerateImages,
    loadStatus,
    prompt,
    negativePrompt,
    searchResult,
    setAspectRatio,
    setPrompt,
    setNegativePrompt,
    setStyle,
    style,
  } = useAiImages();

  return (
    <div className="image-modal__content image-modal__ai-images">
      <div className="image-modal__ai-images__actions">
        <TextField
          data-testid="ai-images-prompt"
          disabled={loadStatus === LOAD_STATUS.LOADING}
          className="image-modal__ai-images__actions__search"
          label={intl.formatMessage({
            id: 'image-modal.ai-images.search-input.label',
          })}
          placeholder={intl.formatMessage({
            id: 'image-modal.ai-images.search-input.placeholder',
          })}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <TextField
          data-testid="ai-images-negative-prompt"
          disabled={loadStatus === LOAD_STATUS.LOADING}
          className="image-modal__ai-images__actions__negative_prompt"
          label={intl.formatMessage({
            id: 'image-modal.ai-images.negative_prompt-input.label',
          })}
          placeholder={intl.formatMessage({
            id: 'image-modal.ai-images.negative_prompt-input.placeholder',
          })}
          value={negativePrompt}
          onChange={(e) => setNegativePrompt(e.target.value)}
        />
        <Select
          data-testid="ai-images-style"
          disabled={loadStatus === LOAD_STATUS.LOADING}
          value={style || 'photograph'}
          onChange={(e) => setStyle(e.target.value)}
          className="image-modal__ai-images__actions__style"
          label={intl.formatMessage({
            id: 'image-modal.ai-images.style-select.label',
          })}>
          <option value="photograph">Photograph</option>
          <option value="digital_art">Digital art</option>
          <option value="landscape">Landscape</option>
          <option value="sketch">Sketch</option>
          <option value="watercolor">Watercolor</option>
          <option value="cyberpunk">Cyberpunk</option>
          <option value="pop_art">Pop art</option>
        </Select>
        <Select
          data-testid="ai-images-aspect-ratio"
          disabled={loadStatus === LOAD_STATUS.LOADING}
          value={aspectRatio || '1:1'}
          onChange={(e) => setAspectRatio(e.target.value)}
          className="image-modal__ai-images__actions__aspect-ratio"
          label={intl.formatMessage({
            id: 'image-modal.ai-images.aspect-ratio-select.label',
          })}>
          <option value="1:1">1:1</option>
          <option value="3:4">3:4</option>
          <option value="4:3">4:3</option>
          <option value="16:9">16:9</option>
          <option value="9:16">9:16</option>
        </Select>
        <Button
          data-testid="ai-images-generate-button"
          className="image-modal__ai-images__actions__generate-button"
          disabled={loadStatus === LOAD_STATUS.LOADING || !prompt}
          variant="contained"
          loading={loadStatus === LOAD_STATUS.LOADING}
          onClick={handleGenerateImages}>
          <FormattedMessage id="button.generate" />
        </Button>
      </div>
      <div className="image-modal__images-wrapper">
        <ImagesContent
          showEmptyContent={loadStatus === LOAD_STATUS.IDLE}
          showNotFound={
            !searchResult.length &&
            (loadStatus === LOAD_STATUS.RESOLVED ||
              loadStatus === LOAD_STATUS.REJECTED)
          }
          showBigSpinner={loadStatus === LOAD_STATUS.LOADING}>
          {searchResult.map((thumbUrl, index) => (
            <ImageItem
              key={index}
              thumbUrl={`${thumbUrl}/preview`}
              description=""
              onItemClick={() => onSetImageUrl(`${thumbUrl}/preview`)}
            />
          ))}
        </ImagesContent>
      </div>
    </div>
  );
}

AiImages.propTypes = {
  onSetImageUrl: PropTypes.func.isRequired,
};

export default AiImages;
