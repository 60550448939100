import blocks from './blocks';
import components from './components';

export default (editor, config) => {
  // Add blocks
  blocks(editor, config);

  // Add blocks
  components(editor, config);
};
