import React, { useState } from 'react';
import { useEditorContext } from 'routes/Editor/EditorContext';
import { isEmail } from 'helpers';
import { useFormContext, Controller } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
import TextFieldTags from 'components/Forms/TextFieldTags';

function EmailsSendMultiCombobox() {
  const intl = useIntl();
  const { control } = useFormContext();

  const {
    template: { emailSettings },
  } = useEditorContext();

  const [emails, setEmails] = useState(emailSettings?.emailSendTo || []);
  const [error] = useState(null);

  const maximumEmailsNumber = 3;

  const handleValidate = (value) => {
    if (!isEmail(value)) {
      return intl.formatMessage(messages.invalidEmailError);
    }
  };

  const messages = defineMessages({
    invalidEmailError: {
      id: 'error-invalid-email',
    },
    emailSendToLabel: {
      id: 'page-settings-modal.forms.email-send-to-label',
    },
    emailSendToDescription: {
      id: 'page-settings-modal.forms.email-send-to-description',
    },
    emailSendToPlaceholder: {
      id: 'page-settings-modal.forms.email-send-to-placeholder',
    },
  });

  return (
    <Controller
      control={control}
      name="emailSettings.emailSendTo"
      render={({ field: { onChange } }) => (
        <TextFieldTags
          error={!!error}
          disabled={emails.length >= maximumEmailsNumber}
          placeholder={intl.formatMessage(messages.emailSendToPlaceholder)}
          label={intl.formatMessage(messages.emailSendToLabel)}
          name="email"
          helperText={
            error ||
            intl.formatMessage(messages.emailSendToDescription, {
              number: maximumEmailsNumber,
            })
          }
          value={emails}
          onChange={(selectedItem) => {
            onChange(selectedItem);
            setEmails(selectedItem);
          }}
          onValidate={handleValidate}
          fullWidth
        />
      )}
    />
  );
}

export default EmailsSendMultiCombobox;
