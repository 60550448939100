import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../Button';
import './CleanModal.sass';

export const CleanModal = ({ handleClean, onClose }) => {
  const handleChooseClear = () => {
    handleClean();
    onClose();
  };
  return (
    <div className="clean-modal__buttons">
      <Button type="button" onClick={onClose}>
        <FormattedMessage id="clean-modal.close" />
      </Button>
      <Button type="button" variant="contained" onClick={handleChooseClear}>
        <FormattedMessage id="clean-modal.clear" />
      </Button>
    </div>
  );
};

CleanModal.propTypes = {
  handleClean: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CleanModal;
