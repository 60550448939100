import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { showSuccessMessage } from 'helpers';
import {
  updateTemplate,
  activateDomain,
  deactivateDomain,
  deleteDomain,
  getDomain,
  verifyDomain,
} from 'store/actions';
import { useInterval } from 'hooks/useInterval';
import { VERIFY_DOMAIN_STATUSES } from 'appConstants';

const messages = defineMessages({
  errorMessage: {
    id: 'toast.error-message',
  },
  activateSuccessMessage: {
    id: 'toast.domain-activate-success',
  },
});

const pollingDelay = 10000;

export function useDomain(id, status) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isUnmounted = useRef(false);

  const [enableCustomDomain, setEnableCustomDomain] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isUpdateDomainLoading, setIsUpdateDomainLoading] = useState(false);
  const [isDeleteDomainLoading, setIsDeleteDomainLoading] = useState(false);
  const [delay, setDelay] = useState(null);

  // long polling to update domain
  useInterval(async () => {
    const { status } = await dispatch(getDomain(id));

    if (status === VERIFY_DOMAIN_STATUSES.ACTIVE) {
      showSuccessMessage(intl.formatMessage(messages.activateSuccessMessage));
    }
  }, delay);

  useEffect(() => {
    if (id) {
      setEnableCustomDomain(true);
    }
  }, [id]);

  useEffect(() => {
    // doing long polling only for particular domain statuses
    if (
      [
        VERIFY_DOMAIN_STATUSES.ACTIVATION_IN_PROGRESS,
        VERIFY_DOMAIN_STATUSES.DEACTIVATION_IN_PROGRESS,
      ].includes(status)
    ) {
      setDelay(pollingDelay);
    } else {
      setDelay(null);
    }
  }, [intl, status]);

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const handleGetDomain = useCallback(() => {
    if (id) {
      dispatch(getDomain(id));
    }
  }, [id, dispatch]);

  const handleChangeCustomDomain = (checked) => {
    setEnableCustomDomain(checked);
  };

  const handleVerifyDomain = async ({
    domainName,
    id,
    shouldSaveAfterVerify,
  }) => {
    setIsUpdateDomainLoading(true);
    try {
      const { id: domainId, name } = await dispatch(
        verifyDomain({ name: domainName, templateId: id }),
      ).unwrap();

      if (shouldSaveAfterVerify) {
        await dispatch(
          updateTemplate({
            id,
            payload: { settings: { domain: { name } } },
          }),
        ).unwrap();
      }

      await dispatch(activateDomain(domainId)).unwrap();
    } catch {
      // catching error and doing nothing
    } finally {
      if (!isUnmounted.current) {
        setIsUpdateDomainLoading(false);
      }
    }
  };

  const handleActivateDomain = async () => {
    setIsUpdateDomainLoading(true);

    await dispatch(activateDomain(id));

    if (!isUnmounted.current) {
      setIsUpdateDomainLoading(false);
    }
  };

  const handleDeactivateDomain = async () => {
    setIsUpdateDomainLoading(true);

    await dispatch(deactivateDomain(id));

    if (!isUnmounted.current) {
      setIsUpdateDomainLoading(false);
    }
  };

  const handleDeleteDomain = async () => {
    try {
      setIsDeleteDomainLoading(true);
      await dispatch(deleteDomain(id)).unwrap();

      if (!isUnmounted.current) {
        setEnableCustomDomain(false);
        setIsConfirmModalOpen(false);
      }
    } catch {
      // catching error and doing nothing
    } finally {
      if (!isUnmounted.current) {
        setIsDeleteDomainLoading(false);
      }
    }
  };

  const handleToggleConfirmModal = () => {
    setIsConfirmModalOpen((prevIsConfirmModalOpen) => !prevIsConfirmModalOpen);
  };

  return {
    enableCustomDomain,
    isConfirmModalOpen,
    isUpdateDomainLoading,
    isDeleteDomainLoading,
    handleGetDomain,
    handleVerifyDomain,
    handleChangeCustomDomain,
    handleToggleConfirmModal,
    handleActivateDomain,
    handleDeactivateDomain,
    handleDeleteDomain,
  };
}
