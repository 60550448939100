import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/InfoTooltip';

const messages = defineMessages({
  infoLabel: { id: 'page-settings-modal.domain-info.label' },
  typeTooltip: { id: 'page-settings-modal.domain-info.type-tooltip' },
  hostTooltip: { id: 'page-settings-modal.domain-info.host-tooltip' },
  valueTooltip: { id: 'page-settings-modal.domain-info.value-tooltip' },
  ttlTooltip: { id: 'page-settings-modal.domain-info.ttl-tooltip' },
  hostValueTooltip: {
    id: 'page-settings-modal.domain-info.host-value-tooltip',
  },
  ttlValueTooltip: { id: 'page-settings-modal.domain-info.ttl-value-tooltip' },
});

function DomainInfo({ host }) {
  const intl = useIntl();

  return (
    <div className="page-settings-modal__domain-info">
      <div className="page-settings-modal__domain-info__label">
        {intl.formatMessage(messages.infoLabel)}
      </div>
      <table className="page-settings-modal__domain-info__table">
        <thead className="page-settings-modal__domain-info__table-head">
          <tr>
            <th className="page-settings-modal__domain-info__table-item">
              Type
              <InfoTooltip label={intl.formatMessage(messages.typeTooltip)} />
            </th>
            <th className="page-settings-modal__domain-info__table-item">
              Host
              <InfoTooltip label={intl.formatMessage(messages.hostTooltip)} />
            </th>
            <th className="page-settings-modal__domain-info__table-item">
              Value
              <InfoTooltip label={intl.formatMessage(messages.valueTooltip)} />
            </th>
            <th className="page-settings-modal__domain-info__table-item">
              TTL
              <InfoTooltip label={intl.formatMessage(messages.ttlTooltip)} />
            </th>
          </tr>
        </thead>
        <thead className="page-settings-modal__domain-info__table-body">
          <tr>
            <th className="page-settings-modal__domain-info__table-item">
              CNAME
            </th>
            <th className="page-settings-modal__domain-info__table-item">
              {host}
              <InfoTooltip
                label={intl.formatMessage(messages.hostValueTooltip)}
              />
            </th>
            <th className="page-settings-modal__domain-info__table-item">
              mypages.waymore.io
            </th>
            <th className="page-settings-modal__domain-info__table-item">
              Automatic
              <InfoTooltip
                label={intl.formatMessage(messages.ttlValueTooltip)}
              />
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
}

DomainInfo.propTypes = {
  host: PropTypes.string.isRequired,
};

export default DomainInfo;
