import axios from 'axios';

const httpGetContactGroups = (payload) => {
  return axios.get('/api/v2/contacts/groups', payload);
};

const httpGetContactTags = (payload) => {
  return axios.get('/api/v2/contacts/tags', payload);
};

export { httpGetContactGroups, httpGetContactTags };
