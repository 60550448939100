import { createAsyncThunk } from '@reduxjs/toolkit';

import { httpGetCredentials, httpSearchPrincipal } from 'api';
import { redirectToAuth, showErrorMessage, getErrorText } from 'helpers';

const getCredentials = createAsyncThunk(
  'user/getCredentials',
  async (sessionId) => {
    try {
      const { data } = await httpGetCredentials({
        data: { sessionId, type: 'page' },
      });

      return data;
    } catch (error) {
      redirectToAuth();
      throw error;
    }
  },
);

const getPrincipal = createAsyncThunk('template/getPrincipal', async () => {
  try {
    const body = {
      criteria: [
        {
          key: 'email',
          value: '@amdtelecom.net',
          operation: 'CONTAINS',
          operator: 'OR',
        },
        {
          key: 'email',
          value: '@waymore.io',
          operation: 'CONTAINS',
          operator: 'OR',
        },
      ],
    };

    const { data } = await httpSearchPrincipal(body);

    return { data };
  } catch (error) {
    showErrorMessage(getErrorText());
    throw error;
  }
});

export { getCredentials, getPrincipal };
