export const FORM_STYLES = ['default', 'minimal', 'dark'];

export const FORM_DEFAULT_ATTRIBUTES = {
  formstyle: FORM_STYLES[0],
  showlabels: true,
  maxwidth: 500,
  labelmargin: 5,
  rowsgap: 10,
  formbgcolor: '#f9f9f9',
  labelcolor: '#000',
  placeholdercolor: '#ccc',
  inputbgcolor: '#fff',
  inputbordercolor: '#ccc',
  buttonbgcolor: '#0cf',
};

export const FORM_MINIMAL_ATTRIBUTES = {
  formstyle: FORM_STYLES[1],
  showlabels: true,
  maxwidth: 500,
  labelmargin: 5,
  rowsgap: 10,
  formbgcolor: '#fff',
  labelcolor: '#000',
  placeholdercolor: '#ccc',
  inputbgcolor: '#fff',
  inputbordercolor: '#707070',
  buttonbgcolor: '#000',
};

export const FORM_DARK_ATTRIBUTES = {
  formstyle: FORM_STYLES[2],
  showlabels: true,
  maxwidth: 500,
  labelmargin: 5,
  rowsgap: 10,
  formbgcolor: '#262833',
  labelcolor: '#7E8CE0',
  placeholdercolor: '#707070',
  inputbgcolor: 'unset',
  inputbordercolor: '#7E8CE0',
  buttonbgcolor: '#7e8ce0',
};
