import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import './CopyBox.sass';

function CopyBox({ value, children, className, fullWidth }) {
  const timeoutId = useRef();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  const handleShowTooltip = () => {
    setIsTooltipVisible(true);

    clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      setIsTooltipVisible(false);
    }, 3000);
  };

  const handleCopy = async () => {
    if (!value) {
      return;
    }

    try {
      await navigator.clipboard.writeText(value);
      handleShowTooltip();
    } catch (error) {
      // catching error in case browser doesn't support clipboard API
    }
  };

  return (
    <div
      role="button"
      tabIndex="0"
      aria-label={value ? `copy ${value} to clipboard` : ''}
      className={cx('dnd-copy-box', className, { disabled: !value, fullWidth })}
      onClick={handleCopy}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleCopy();
        }
      }}>
      {children}
      {isTooltipVisible && (
        <div role="tooltip" className="dnd-copy-box__tooltip">
          <FormattedMessage id="toast.copy.success-message" />
        </div>
      )}
    </div>
  );
}

CopyBox.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default CopyBox;
