import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { generateId } from 'helpers';
import FormControl from '../FormControl';
import Label from '../Label';
import FormHelperText from '../FormHelperText';
import './Select.sass';

const Select = React.forwardRef(function Select(
  {
    label,
    className,
    helperText,
    error,
    value,
    fullWidth,
    onChange,
    children,
    ...rest
  },
  ref,
) {
  const inputId = useRef(generateId('dnd-select'));

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && <Label htmlFor={inputId.current}>{label}</Label>}
      <div className="dnd-select-wrapper">
        <select
          id={inputId.current}
          value={value}
          onChange={onChange}
          className="dnd-select__select"
          aria-describedby={`${inputId.current}-helper-text`}
          {...rest}
          ref={ref}>
          {children}
        </select>
      </div>
      {helperText && (
        <FormHelperText id={`${inputId.current}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  helperText: PropTypes.node,
  error: PropTypes.bool,
  value: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default Select;
