import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FormControl from '../FormControl';
import Label from '../Label';
import FormHelperText from '../FormHelperText';
import Toggler from '../Toggler';
import './Switch.sass';

const CheckBox = React.forwardRef(function CheckBox(
  { label, checked, helperText, fullWidth, disabled, error, ...rest },
  ref,
) {
  return (
    <FormControl fullWidth={fullWidth}>
      <Label className={cx('dnd-switch__label', { disabled })}>
        <Toggler checked={checked} disabled={disabled} {...rest} ref={ref} />
        {label}
      </Label>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
});

CheckBox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default CheckBox;
