import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useEditorContext } from 'routes/Editor/EditorContext';
import { useIntl, defineMessages } from 'react-intl';
import { showErrorMessage, getErrorText } from 'helpers';
import uniqueId from 'lodash.uniqueid';
import { httpGetPrincipalEmail } from 'api/principal.api';
import Combobox from 'components/Combobox';

function EmailFromCombobox({ onChange, error }) {
  const intl = useIntl();

  const { template } = useEditorContext();

  const messages = defineMessages({
    emailFromLabel: {
      id: 'page-settings-modal.forms.email-from-label',
    },
    emailFromDescription: {
      id: 'page-settings-modal.forms.email-from-description',
    },
    emailFromPlaceholder: {
      id: 'page-settings-modal.forms.email-from-placeholder',
    },
  });

  const itemToString = (item) => (item ? item.email : '');
  const normalizeItem = (item) => ({
    key: uniqueId(`${item.email}_`),
    label: item.email,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const getEmails = async () => {
      try {
        const { data } = await httpGetPrincipalEmail();

        setEmails(data);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    getEmails();

    return () => {
      setIsLoading(false);
    };
  }, [setEmails]);

  const finalEmails = emails?.filter((item) =>
    item?.email?.includes(searchTerm.toLowerCase()),
  );

  return (
    <Combobox
      error={error}
      initialSelectedItem={
        template?.emailSettings?.emailFrom &&
        template?.emailSettings?.emailFrom?.email
          ? template?.emailSettings?.emailFrom
          : ''
      }
      label={intl.formatMessage(messages.emailFromLabel)}
      placeholder={intl.formatMessage(messages.emailFromPlaceholder)}
      helperText={intl.formatMessage(messages.emailFromDescription)}
      loading={loading}
      items={finalEmails || emails || []}
      itemToString={itemToString}
      normalizeItem={normalizeItem}
      onInputValueChange={({ inputValue }) => setSearchTerm(inputValue)}
      onSelectedItemChange={({ selectedItem }) => {
        onChange(selectedItem || {});
      }}
      fullWidth
    />
  );
}

EmailFromCombobox.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default EmailFromCombobox;
