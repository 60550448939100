import { BLOCKS_CATEGORIES } from 'appConstants';

export default (editor) => {
  editor.BlockManager.getCategories().each((cat) => {
    if (
      BLOCKS_CATEGORIES.modules &&
      BLOCKS_CATEGORIES?.modules?.includes(cat.id) &&
      cat.id !== BLOCKS_CATEGORIES.modules[0]
    ) {
      cat.set('open', false);
    }
  });
};
