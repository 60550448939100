import { useEffect, useRef } from 'react';
import codemirror from 'codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror-formatting';
import 'codemirror/addon/display/panel';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/dialog/dialog.css';

const codemirrorConfig = {
  theme: 'hopscotch',
  lineWrapping: false,
  lineNumbers: true,
  dragDrop: false,
};

export function useCodemirror(elementRef, options) {
  const editor = useRef(null);

  useEffect(() => {
    editor.current = codemirror(elementRef.current, {
      ...codemirrorConfig,
      ...options,
    });

    if (editor.current?.autoFormatRange) {
      codemirror.commands.selectAll(editor.current);
      editor.current.autoFormatRange(
        editor.current.getCursor(true),
        editor.current.getCursor(false),
      );
      codemirror.commands.goDocStart(editor.current);
    }
    // setup editor only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  return editor;
}
