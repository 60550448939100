import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ButtonBase from 'components/ButtonBase';
import './Button.sass';

const Button = React.forwardRef(function Button(
  { className, fullWidth, children, ...rest },
  ref,
) {
  return (
    <ButtonBase
      className={cx('dnd-button', className, { fullWidth })}
      {...rest}
      ref={ref}>
      {children}
    </ButtonBase>
  );
});

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
};

export default Button;
