import axios from 'axios';

const httpVerifyDomain = (payload) => {
  return axios.post('/api/v2/domains/validate', payload);
};

const httpGetDomain = (id) => {
  return axios.get(`/api/v2/domains/${id}`);
};

const httpActivateDomain = (id) => {
  return axios.post(`/api/v2/domains/${id}/activate`);
};

const httpDeactivateDomain = (id) => {
  return axios.post(`/api/v2/domains/${id}/deactivate`);
};

const httpDeleteDomain = (id) => {
  return axios.delete(`/api/v2/domains/${id}`);
};

export {
  httpVerifyDomain,
  httpGetDomain,
  httpActivateDomain,
  httpDeactivateDomain,
  httpDeleteDomain,
};
