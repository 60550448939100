import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FONTAWESOME_URL } from 'appConstants';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { getLinks } from 'helpers';
import { useSelector, useDispatch } from 'react-redux';
import { isAdminSelector, getCustomBlocksGroupSelector } from 'store/selectors';
import { getCustomBlocksGroup } from 'store/actions';
import { useCodemirror } from 'hooks';
import CodeMirrorHeader from 'components/CodeMirrorHeader';
import CodeMirrorAllButtons from 'components/CodeMirrorAllButtons';
import Button from 'components/Button';
import TextField from 'components/Forms/TextField';
import Select from 'components/Forms/Select';
import FormControl from 'components/Forms/FormControl';
import Label from 'components/Forms/Label';
import './BlockModal.sass';
import cx from 'classnames';

function BlockModal({
  block,
  isBlocksLoading,
  templateId,
  editor,
  onSaveBlock,
  onClose,
  selectedFontsList,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: block?.name,
      description: block?.description,
    },
  });

  const intl = useIntl();
  const dispatch = useDispatch();
  const htmlEditorRef = useRef();
  const cssEditorRef = useRef();

  // Add extra container for single column module
  const [html, setHtml] = useState(
    block?.html?.startsWith('<div data-type="column"')
      ? '<div style="display: flex">' + block?.html + '</div>'
      : block?.html,
  );
  const [css, setCss] = useState(block?.css);
  const [fontScripts, setFontScripts] = useState('');
  const [links, setLinks] = useState([FONTAWESOME_URL]);

  const isAdmin = useSelector(isAdminSelector);
  const categoriesNames = useSelector(getCustomBlocksGroupSelector);

  const htmlEditor = useCodemirror(htmlEditorRef, {
    value: html,
    mode: 'htmlmixed',
  });

  const cssEditor = useCodemirror(cssEditorRef, {
    value: `${css.indexOf('<style') >= 0 ? css : `<style>${css}</style>`}`,
    mode: 'css',
  });

  useEffect(() => {
    dispatch(getCustomBlocksGroup());
  }, [dispatch]);

  useEffect(() => {
    const fontScriptsData = [
      `<link href="${FONTAWESOME_URL}" rel="stylesheet">`,
    ];
    const socialExist = editor?.getSelected()?.findType('social-icon-wrapper');
    const socialFollowExist = editor?.getSelected()?.findType('social-follow');
    const socialShareExist = editor?.getSelected()?.findType('social-share');
    const selectedFontsListScripts = selectedFontsList.map(
      ({ url }) => `<link href="${url}" rel="stylesheet">`,
    );
    const fontScriptsResult =
      socialExist?.length > 0 ||
      socialFollowExist?.length > 0 ||
      socialShareExist?.length > 0
        ? [...fontScriptsData, ...selectedFontsListScripts].join('')
        : [...selectedFontsListScripts].join('');

    if (selectedFontsList && selectedFontsList.length > 0) {
      selectedFontsList.forEach(({ url }) => {
        setLinks((links) => [...links, url]);
        setFontScripts(fontScriptsResult);
      });
    } else {
      if (
        socialExist &&
        socialFollowExist &&
        socialShareExist &&
        (socialExist?.length > 0 ||
          socialFollowExist?.length > 0 ||
          socialShareExist?.length > 0)
      ) {
        setLinks([FONTAWESOME_URL]);
        setFontScripts(fontScriptsData[0]);
      }
    }

    if (block?.links) {
      block.links.forEach(({ href, rel }) => {
        const link = `<link rel="${rel}" href="${href}">`;

        if (!fontScriptsData[0].replace(/&amp;/g, '&').includes(link)) {
          setFontScripts((fontScriptsData[0] += link));
        }
      });
    }
  }, [editor, block, selectedFontsList]);

  useEffect(() => {
    const htmlEditorInstance = htmlEditor.current;
    const cssEditorInstance = cssEditor.current;

    const onHtmlChange = (cm) => {
      setHtml(cm.getValue());
    };

    const onCssChange = (cm) => {
      setCss(cm.getValue());
    };

    htmlEditorInstance.on('change', onHtmlChange);
    cssEditorInstance.on('change', onCssChange);

    return () => {
      htmlEditorInstance.off('change', onHtmlChange);
      cssEditorInstance.off('change', onCssChange);
    };
  }, [htmlEditor, cssEditor]);

  const handleSave = (values) => {
    const payload = {
      id: block?.id,
      type: 'page',
      ...values,
      html: htmlEditor.current.getValue(),
      css: cssEditor.current.getValue(),
      links: getLinks(block?.links || [], links),
      category: null,
      templateId,
    };

    if (values.category && values.category !== 'My modules') {
      const selectedCategory = categoriesNames?.filter(
        (item) => item.id === values.category,
      );

      const { id, name, group, type, hidden } = selectedCategory[0];

      payload.category = {
        id,
        name,
        group,
        type,
        hidden,
      };
    }

    onSaveBlock(payload);
  };

  return (
    <form className="block-modal__form" onSubmit={handleSubmit(handleSave)}>
      <div className="block-modal__buttons">
        <Button type="button" className="block-modal__button" onClick={onClose}>
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button
          type="submit"
          className="block-modal__button"
          variant="contained"
          disabled={isBlocksLoading}
          loading={isBlocksLoading}>
          {intl.formatMessage({ id: 'button.save' })}
        </Button>
      </div>
      <div className="block-modal__content">
        <div className="block-modal__row">
          <div
            className={cx('block-modal__row__inner', {
              'block-modal__row__inner-full': !isAdmin,
            })}>
            <TextField
              {...register('name', {
                required: true,
              })}
              label={intl.formatMessage({ id: 'block-modal.name.label' })}
              helperText={
                errors.name
                  ? intl.formatMessage({ id: 'block-modal.name.error' })
                  : intl.formatMessage({
                      id: 'block-modal.name.helper-text',
                    })
              }
              placeholder={intl.formatMessage({
                id: 'block-modal.name.placeholder',
              })}
              error={Boolean(errors.name)}
              fullWidth
            />
            <TextField
              {...register('description')}
              label={intl.formatMessage({
                id: 'block-modal.description.label',
              })}
              placeholder={intl.formatMessage({
                id: 'block-modal.description.placeholder',
              })}
              helperText={intl.formatMessage({
                id: 'block-modal.description.helper-text',
              })}
              fullWidth
            />
          </div>
          {isAdmin && (
            <div className="block-modal__row__inner block-modal__row__category">
              <Select
                {...register('category', {
                  required: true,
                })}
                error={Boolean(errors.category)}
                label={intl.formatMessage({
                  id: 'block-modal.category.label',
                })}
                fullWidth
                defaultValue={block?.category?.id}
                helperText={
                  errors.category?.message ||
                  intl.formatMessage({
                    id: 'block-modal.category.helper-text',
                  })
                }>
                <option value="My modules">
                  {intl.formatMessage({
                    id: 'block-modal.category.placeholder',
                  })}
                </option>
                {categoriesNames?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
          )}
        </div>
        <div className="block-modal__row">
          <FormControl className="block-modal__editor-wrapper">
            <CodeMirrorHeader
              title="HTML"
              htmlEditor={htmlEditor}
              cssEditor={cssEditor}
              activeHtml
            />
            <div className="block-modal__editor" ref={htmlEditorRef} />
          </FormControl>
          <FormControl className="block-modal__editor-wrapper">
            <CodeMirrorHeader title="CSS" cssEditor={cssEditor} activeCss />
            <div className="block-modal__editor" ref={cssEditorRef} />
          </FormControl>
        </div>
        <div className="block-modal__row">
          <CodeMirrorAllButtons
            editor={editor}
            htmlEditor={htmlEditor}
            cssEditor={cssEditor}
            disableUpload
          />
        </div>
        <div className="block-modal__row">
          <FormControl fullWidth className="block-modal__preview-wrapper">
            <Label>Preview</Label>
            <iframe
              className="block-modal__preview"
              title="custom block preview"
              srcDoc={`
              <html>
                <head>${fontScripts}</head>
              </html>
              ${html}
              ${css.indexOf('<style') >= 0 ? css : `<style>${css}</style>`}
            `}
            />
          </FormControl>
        </div>
      </div>
    </form>
  );
}

BlockModal.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.object,
    description: PropTypes.string,
    html: PropTypes.string.isRequired,
    css: PropTypes.string.isRequired,
    links: PropTypes.array,
  }),
  isBlocksLoading: PropTypes.bool.isRequired,
  templateId: PropTypes.string.isRequired,
  editor: PropTypes.shape({
    Canvas: PropTypes.object.isRequired,
    getSelected: PropTypes.func.isRequired,
  }),
  onSaveBlock: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedFontsList: PropTypes.array,
};

export default BlockModal;
