import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'timeago.js';
import {
  defineMessages,
  FormattedDateParts,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { useEditorContext } from 'routes/Editor/EditorContext';
import {
  buildVersionPreviewUrl,
  getErrorText,
  getListUsersFullName,
  showErrorMessage,
  showSuccessMessage,
} from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { httpGetVersions, httpGetRestoedVersion } from 'api';
import { getPrincipal } from 'store/actions';
import { principalListSelector } from 'store/selectors';
import ReactTooltip from 'react-tooltip';
import LoadingSpinner from 'components/LoadingSpinner';
import cx from 'classnames';
import './VersionHistory.sass';

const VersionHistory = ({ editor, onClose, onSaveTemplate }) => {
  const {
    template: { id: templateId },
  } = useEditorContext();

  const intl = useIntl();

  const dispatch = useDispatch();

  const messages = defineMessages({
    noVersions: { id: 'version-history.no-versions' },
  });

  const principalList = useSelector(principalListSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [versions, setVersions] = useState(null);
  const [activeVersion, setActiveVersion] = useState(null);

  useEffect(() => {
    const getAllPrincipals = async () => {
      await dispatch(getPrincipal());
    };

    getAllPrincipals();
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);

    const getVersions = async () => {
      try {
        const { data } = await httpGetVersions(templateId);

        setVersions(data);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    getVersions();
  }, [setVersions, templateId]);

  const handleVersionPreviewUrlBtnClick = (templateId, versionDate, index) => {
    const width = 1376;
    const height = 768;
    const top = window.screen.height / 2 - height / 2;
    const left = window.screen.width / 2 - width / 2;
    const features = `
      status=no,
      location=no,
      toolbar=no,
      menubar=no,
      width=${width.toString()},
      height=${height.toString()},
      left=${left.toString()},
      top=${top.toString()},
      resizable=yes,
      scrollbars=yes
    `;

    window.open(
      buildVersionPreviewUrl(templateId, versionDate),
      'Preview',
      features,
    );

    setActiveVersion(index);
  };

  const handleRestoreVersionBtnClick = async (templateId, versionDate) => {
    try {
      const { data } = await httpGetRestoedVersion(templateId, versionDate);
      const restoreDate = new Date(versionDate);
      const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      const restoreDateFormatted = restoreDate.toLocaleString(
        'default',
        dateOptions,
      );

      // Load components & styles from the loaded version
      editor.setComponents(data?.template?.components);
      editor.setStyle(data?.template?.style);

      showSuccessMessage(
        `The template has been restored to the version from ${restoreDateFormatted}.`,
      );

      // Call save action ...
      setTimeout(() => {
        onSaveTemplate(editor);
      }, 1000);
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    } finally {
      onClose();
    }
  };

  const checkOneDayPassed = (dateString) => {
    const givenDate = new Date(dateString);
    const now = new Date();
    const differenceInMilliseconds = now - givenDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays >= 1;
  };

  return (
    <div className="version-history-modal">
      {isLoading ? (
        <div className="version-history-modal__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="version-history-modal__versions">
          {versions && versions.length > 0 ? (
            versions
              ?.sort((a, b) => {
                const dateA = new Date(a.versionDate);
                const dateB = new Date(b.versionDate);

                return dateB - dateA;
              })
              .map(({ userId, versionDate }, index) => (
                <div
                  key={index}
                  className={cx('version-history-modal__version', {
                    active: activeVersion === index,
                  })}>
                  <div className="version-history-modal__version__title">
                    <time>
                      {checkOneDayPassed(versionDate) ? (
                        <FormattedDateParts
                          value={versionDate}
                          weekday="short"
                          day="2-digit"
                          month="short"
                          hour="numeric"
                          minute="numeric">
                          {(parts) =>
                            `${parts[0].value} ${parts[1].value} ${parts[2].value} ${parts[4].value} ${parts[5].value} ${parts[6].value}${parts[7].value}${parts[8].value} ${parts[10].value}`
                          }
                        </FormattedDateParts>
                      ) : (
                        format(versionDate)
                      )}
                    </time>
                    <span className="version-history-modal__version__title__user-name">
                      {getListUsersFullName(principalList, [
                        userId,
                      ]).toLocaleLowerCase() || userId}
                    </span>
                  </div>
                  <div className="version-history-modal__version__actions">
                    <button
                      data-tip
                      data-for="version-history-restore"
                      onClick={() =>
                        handleRestoreVersionBtnClick(templateId, versionDate)
                      }>
                      <span className="icon icon-history" />
                    </button>
                    <button
                      data-tip
                      data-for="version-history-preview"
                      onClick={() =>
                        handleVersionPreviewUrlBtnClick(
                          templateId,
                          versionDate,
                          index,
                        )
                      }>
                      <span className="icon icon-visibility" />
                    </button>
                  </div>
                </div>
              ))
          ) : (
            <div className="version-history-modal__no-versions">
              <span className="icon icon-history" />
              {intl.formatMessage(messages.noVersions)}
            </div>
          )}
          <ReactTooltip
            id="version-history-preview"
            class="title-tooltip"
            type="dark"
            aria-haspopup="true"
            place="top"
            clickable={false}
            delayShow={500}
            border={false}
            effect="solid">
            <FormattedMessage id="button.preview" />
          </ReactTooltip>
          <ReactTooltip
            id="version-history-restore"
            class="title-tooltip"
            type="dark"
            aria-haspopup="true"
            place="top"
            clickable={false}
            delayShow={500}
            border={false}
            effect="solid">
            <FormattedMessage id="button.restore" />
          </ReactTooltip>
        </div>
      )}
    </div>
  );
};

VersionHistory.propTypes = {
  editor: PropTypes.shape({
    setStyle: PropTypes.func.isRequired,
    setComponents: PropTypes.func.isRequired,
    Pages: PropTypes.shape({
      select: PropTypes.func.isRequired,
      getMain: PropTypes.func.isRequired,
    }),
  }),
  onClose: PropTypes.func.isRequired,
  onSaveTemplate: PropTypes.func.isRequired,
};

export default VersionHistory;
