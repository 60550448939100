import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ButtonBase from '../ButtonBase';
import './IconButton.sass';

const IconButton = React.forwardRef(({ className, ...rest }, ref) => {
  return (
    <ButtonBase
      className={cx('dnd-icon-button', className)}
      {...rest}
      ref={ref}
    />
  );
});

IconButton.propTypes = {
  className: PropTypes.string,
};

export default IconButton;
