import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

const SideBarLink = ({
  className,
  iconClass,
  active,
  hidden,
  children,
  after,
  ...rest
}) => {
  return (
    <li
      className={cx('sidebar__link-item', className, {
        active,
        hidden,
      })}>
      <Link className="sidebar__link" {...rest}>
        {iconClass && <i className={iconClass} />}
        {children}
      </Link>
      {after && <div className="sidebar__link__after">{after}</div>}
    </li>
  );
};

SideBarLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  iconClass: PropTypes.string,
  hidden: PropTypes.bool,
  rest: PropTypes.array,
  active: PropTypes.bool.isRequired,
  after: PropTypes.node,
};

export default SideBarLink;
