import { useToggleTypes } from 'hooks';

export default (editor) => {
  const cmd = editor.Commands;

  // replace built-in command to open custom image modal
  cmd.add('open-assets', {
    run(editor, sender, { isIcon, isImage, isLinkedImage, select }) {
      const model = editor.getModel();

      model.set('assetTarget', isIcon || isImage ? 'image' : 'background');
      model.set('linkedImageAssetTarget', isLinkedImage);

      if (select) {
        model.set('onSetBackgroundImage', select);
      }

      if (isIcon) {
        model.get('onShow')(useToggleTypes.showIconModal);
        return;
      }

      model.get('onShow')(useToggleTypes.showImageModal);
    },
  });

  cmd.add('open-svg-modal', {
    run(editor) {
      const model = editor.getModel();

      model.get('onShow')(useToggleTypes.showSvgModal);
    },
  });

  // command to set image which is running by custom image modal
  cmd.add('set-image-url', {
    run(editor, sender, { url }) {
      const target = editor.getSelected();
      const editorModel = editor.getModel();
      const assetTarget = editorModel.get('assetTarget');
      const onSetBackgroundImage = editorModel.get('onSetBackgroundImage');
      const linkedImageAssetTarget = editorModel.get('linkedImageAssetTarget');

      if (
        onSetBackgroundImage instanceof Function &&
        assetTarget === 'background'
      ) {
        const bgImgStyle = target.getStyle('background-image');

        if (bgImgStyle === 'none') {
          target.addStyle({ 'background-image': `url("${url}")` });
        } else {
          target.addStyle({
            'background-image': `${bgImgStyle.replace(
              'none,',
              '',
            )}, url("${url}")`,
          });
        }
      }

      if (assetTarget === 'image') {
        target.set('src', url);
      }

      if (linkedImageAssetTarget) {
        if (target.findType('linked-image-image')) {
          const cmpStyle = target.getStyle();
          const imgCmp = target.findType('linked-image-image')[0];

          imgCmp.set('src', url);
          imgCmp.setStyle({
            width: `${cmpStyle?.width || '100%'}`,
            height: `${cmpStyle?.height || 'auto'}`,
          });
        }
      }
    },
  });

  cmd.add('set-svg-icon', {
    run(editor, sender, { svgIcon }) {
      const selected = editor.getSelected();

      selected.replaceWith(svgIcon);
    },
  });
};
