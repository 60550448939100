import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import TextField from 'components/Forms/TextField';
import Button from 'components/Button';
import './CustomModulCategoryModal.sass';

function CustomModulCategoryModal({
  category = {},
  isLoading,
  categoriesNames,
  onSaveCustomModuleCategory,
}) {
  const intl = useIntl();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: category?.name,
    },
    mode: 'onBlur',
  });

  const handleSave = async (values) => {
    onSaveCustomModuleCategory(values);
  };

  return (
    <>
      <form
        className="custom-modul-category-modal__form"
        onSubmit={handleSubmit(handleSave)}>
        <TextField
          {...register('name', {
            validate: (value) => {
              const trimmedValue = value.trim();

              if (!trimmedValue) {
                return intl.formatMessage({
                  id: 'custom-modules-category.required-error',
                });
              }

              if (
                categoriesNames.find((item) => item?.name === value?.trim())
              ) {
                return intl.formatMessage({
                  id: 'custom-modules-category.category-exists-error',
                });
              }
            },
          })}
          fullWidth
          placeholder={intl.formatMessage({
            id: 'custom-modules-category.placeholder',
          })}
          helperText={
            errors.name?.message ||
            intl.formatMessage({ id: 'custom-modules-category.description' })
          }
          error={Boolean(errors.name)}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isLoading}
          loading={isLoading}>
          <FormattedMessage id="button.save" />
        </Button>
      </form>
    </>
  );
}

CustomModulCategoryModal.propTypes = {
  category: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  categoriesNames: PropTypes.array.isRequired,
  onSaveCustomModuleCategory: PropTypes.func.isRequired,
};

export default CustomModulCategoryModal;
