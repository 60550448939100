import { checkComponentType, setRuleStyle } from 'helpers';

export default (
  editor,
  {
    boxType,
    countdownType,
    customCodeType,
    discountCodeType,
    editCustomCode,
    mapType,
    tooltipType,
  },
) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('default');
  const customCodeKey = 'custom-code-plugin__code';
  let timedInterval;

  domc.addType(boxType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Box',
        type: boxType,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, boxType)) {
        return { type: boxType };
      }
    },
  });

  domc.addType(customCodeType, {
    model: {
      defaults: {
        name: 'Custom Code',
        editable: true,
      },

      /**
       * Initilize the component
       */
      init() {
        this.listenTo(this, `change:${customCodeKey}`, this.onCustomCodeChange);

        const initialCode =
          this.get(customCodeKey) ||
          `<span>Insert here your custom code</span>`;
        const toolbar = this.get('toolbar');
        const id = 'custom-code';

        if (!this.components().length) {
          this.components(initialCode);
        }

        // Add the custom code toolbar button if requested and it's not already in
        if (!toolbar.filter((tlb) => tlb.id === id).length) {
          toolbar.unshift({
            id,
            attributes: {
              title: '',
            },
            command: editCustomCode ? 'custom-code-modal' : 'custom-code-lock',
            label: `<span class="icon ${
              editCustomCode ? 'icon-code' : 'icon-code lock-status'
            }"></span>`,
          });
        }
      },

      /**
       * Callback to launch on customCodeKey change
       */
      onCustomCodeChange() {
        this.components(this.get(customCodeKey));
      },
      /**
       * The component can be used only if requested explicitly via `type` property
       */
      isComponent() {
        return false;
      },
    },
    view: defaultType.view.extend({
      events: {
        dblclick: 'onActive',
      },
      init() {
        this.listenTo(
          this.model.components(),
          'add remove reset',
          this.onComponentsChange,
        );
        this.onComponentsChange();
      },
      /**
       * Things to do once inner components of custom code are changed
       */
      onComponentsChange() {
        if (timedInterval) {
          clearInterval(timedInterval);
        }

        timedInterval = setTimeout(() => {
          const { model } = this;
          const content = model.get(customCodeKey) || '';
          let droppable = true;

          // Avoid rendering codes with scripts
          if (content.indexOf('<script') >= 0) {
            this.el.innerHTML = this.el.innerHTML.replace(
              /<script[\s\S]*?>[\s\S]*?<\/script[\s\S]*?>/gm,
              `<div style="pointer-events: none; padding: 10px;">
            <svg viewBox="0 0 24 24" style="height: 30px; vertical-align: middle;">
              <path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"></path>
              </svg>
            Custom code with <i>&lt;script&gt;</i> can't be rendered on the canvas
          </div>`,
            );
            droppable = false;
          }

          model.set({ droppable });
        }, 0);
      },
      onActive() {
        const target = this.model;
        editCustomCode &&
          this.em.get('Commands').run('custom-code-modal', { target });
      },
    }),
  });

  domc.addType(mapType, {
    model: {
      defaults: {
        openTraitsOnSelect: true,
      },
    },
  });

  domc.addType(countdownType, {
    extend: 'default',
    model: {
      defaults: {
        startfrom: '',
        endText: 'EXPIRED',
        droppable: false,
        traits: [
          {
            label: 'Start',
            name: 'startfrom',
            changeProp: 1,
            type: 'date',
          },
          {
            label: 'End text',
            name: 'endText',
            changeProp: 1,
          },
        ],
        openTraitsOnSelect: true,
        // eslint-disable-next-line object-shorthand
        script: function () {
          var startfrom = '{[ startfrom ]}';
          var endTxt = '{[ endText ]}';
          var countDownDate = new Date(startfrom).getTime();
          var countdownEl = this.querySelectorAll('.countdown-block');
          var endTextEl = this.querySelector('[data-countdown=endtext]');
          var dayEl = this.querySelector('[data-countdown=day]');
          var hourEl = this.querySelector('[data-countdown=hour]');
          var minuteEl = this.querySelector('[data-countdown=minute]');
          var secondEl = this.querySelector('[data-countdown=second]');
          var oldInterval = this.gjsCountdownInterval;
          if (oldInterval) {
            oldInterval && clearInterval(oldInterval);
          }

          var setTimer = function (days, hours, minutes, seconds) {
            if (dayEl) {
              dayEl.innerHTML = days < 10 ? '0' + days : days;
            }
            if (hourEl) {
              hourEl.innerHTML = hours < 10 ? '0' + hours : hours;
            }
            if (minuteEl) {
              minuteEl.innerHTML = minutes < 10 ? '0' + minutes : minutes;
            }
            if (secondEl) {
              secondEl.innerHTML = seconds < 10 ? '0' + seconds : seconds;
            }
          };

          var moveTimer = function () {
            var secondInMilliseconds = 1000;
            var minuteInMilliseconds = 60 * secondInMilliseconds;
            var hourInMilliseconds = 60 * minuteInMilliseconds;
            var dayInMilliseconds = 24 * hourInMilliseconds;

            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / dayInMilliseconds);
            var hours = Math.floor(
              (distance % dayInMilliseconds) / hourInMilliseconds,
            );
            var minutes = Math.floor(
              (distance % hourInMilliseconds) / minuteInMilliseconds,
            );
            var seconds = Math.floor(
              (distance % minuteInMilliseconds) / secondInMilliseconds,
            );

            setTimer(days, hours, minutes, seconds);

            /* If the count down is finished, write some text */
            if (distance < 0) {
              clearInterval(interval);
              endTextEl.innerHTML = endTxt;
              endTextEl.style.display = '';
              Array.from(countdownEl).forEach((item) =>
                item.classList.add('expired'),
              );
            }
          };

          if (countDownDate) {
            var interval = setInterval(moveTimer, 1000);
            this.gjsCountdownInterval = interval;
            endTextEl.style.display = 'none';
            Array.from(countdownEl).forEach((item) =>
              item.classList.remove('expired'),
            );
            moveTimer();
          } else {
            setTimer(0, 0, 0, 0);
          }
        },
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          'change:startfrom change:endText',
          this.updateScript,
        );
      },
    },
    isComponent(el) {
      if (checkComponentType(el, countdownType)) {
        return { type: countdownType };
      }
    },
  });

  domc.addType(tooltipType, {
    model: {
      defaults: {
        openTraitsOnSelect: true,
      },
    },
  });

  domc.addType(discountCodeType, {
    model: {
      defaults: {
        name: 'Discount code',
        droppable: false,
        draggable: true,
        resizable: false,
        openTraitsOnSelect: true,
        discountCode: 'SAMPLECODE',
        width: '',
        codeFontSize: 24,
        codeColor: '#000',
        codeColorCopied: '#000',
        codeBgColor: '#edf2ff',
        codeBgColorCopied: '#e6faf6',
        codeBorderButtonColor: '#3258b3',
        codeBorderButtonColorCopied: '#20b18c',
        codeButtonTextColor: '#fff',
        codeButtonTextColorCopied: '#fff',
        traits: [
          {
            id: 'toggleCopiedModeBtn',
            type: 'button',
            text: 'Show copied mode',
            full: true,
            changeProp: true,
            command: (editor) => {
              const selected = editor?.getSelected();

              selected.get('traits').forEach((trait) => {
                if (trait.id === 'toggleCopiedModeBtn') {
                  if (selected.getClasses().includes('copied')) {
                    selected.removeClass('copied');
                    trait.set('text', 'Show copied mode');
                    trait.view.elInput.innerText = 'Show copied mode';
                  } else {
                    editor
                      .getSelected()
                      .setClass([...selected.getClasses(), 'copied']);

                    trait.set('text', 'Hide copied mode');
                    trait.view.elInput.innerText = 'Hide copied mode';
                  }
                }
              });
            },
          },
          {
            type: 'text',
            name: 'discountCode',
            label: 'Discount code',
            placeholder: 'Enter your code',
            changeProp: true,
          },
          {
            type: 'number',
            name: 'width',
            label: 'Width',
            changeProp: true,
          },
          {
            type: 'number',
            name: 'codeFontSize',
            label: 'Discount font size',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeColor',
            label: 'Code color',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeColorCopied',
            label: 'Code color (copied status)',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeBgColor',
            label: 'Background color',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeBgColorCopied',
            label: 'Background color (copied status)',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeBorderButtonColor',
            label: 'Border/button color',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeBorderButtonColorCopied',
            label: 'Border/button color (copied status)',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeButtonTextColor',
            label: 'Button text color',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeButtonTextColorCopied',
            label: 'Button text color (copied status)',
            changeProp: true,
          },
        ],
        attributes: {
          class: 'discount-code',
          'data-type': 'discount-code',
        },
        components: `
          <input type="text" class="discount-code__code" readonly value="SAMPLECODE">
          <button type="button" class="discount-code__btn">Copy</button>
        `,
        styles: `
          .discount-code {
            font-size: 24px;
            width: fit-content;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            padding: 6px;
            color: #000;
            background-color: #edf2ff;
            border: 1px dashed #3258b3;
            border-radius: 4px;
          }
          .discount-code.copied {
            background-color: #e6faf6;
            border-color: #20b18c;
            color: #000;
          }
          .discount-code.copied > button {
            background-color: #20b18c;
          }
          .discount-code__code {
            margin: 0 4px;
            text-align: center;
            padding: 0;
            border: 0;
            background: transparent;
            border-radius: 0;
            overflow: hidden;
            letter-spacing: 6px;
            font-weight: bold;
            font-size: 1em;
            width: auto
            text-transform: uppercase;
            color: inherit;
          }
          .discount-code__code:focus {
            outline: none !important;
            border: none !important;
          }
          .discount-code__btn {
            text-transform: uppercase;
            color: #fff;
            background-color: #3258b3;
            border: none;
            border-radius: 4px;
            align-self: stretch;
            padding: 4px 16px;
            margin: 0 0 0 8px;
            font-size: 0.6em;
            font-weight: bold;
            line-height: 17px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            transition: all 0.5s;
          }
          .discount-code__btn:hover {
            background-color: #487eff;
          }
          @media (max-width: 480px) {
            .discount-code,
            .discount-code__code {
              width: 100%;
              max-width: 100%;
            }
          }
        `,
      },
      init() {
        this.on('change:discountCode', this.updateDiscountCode);
        this.on('change:width', this.updateWidth);
        this.on('change:codeFontSize', this.updateCodeSize);
        this.on('change:codeColor', this.updateCodeColor);
        this.on('change:codeColorCopied', this.updateCodeColorCopied);
        this.on('change:codeBgColor', this.updateCodeBgColor);
        this.on('change:codeBgColorCopied', this.updateCodeBgColorCopied);
        this.on('change:codeButtonTextColor', this.updateCodeButtonTextColor);
        this.on(
          'change:codeButtonTextColorCopied',
          this.updateCodeButtonTextColorCopied,
        );
        this.on(
          'change:codeBorderButtonColor',
          this.updateCodeBorderButtonColor,
        );
        this.on(
          'change:codeBorderButtonColorCopied',
          this.updateCodeBorderButtonColorCopied,
        );
      },
      updateDiscountCode(cmp, value) {
        const discountCodeInput = this.find('input');

        if (discountCodeInput && discountCodeInput?.length > 0) {
          discountCodeInput[0].addAttributes({ value });
        }
      },
      updateWidth(cmp, value) {
        const discountCodeInput = this.find('input');
        const style = {
          width: `${value}px`,
        };

        if (discountCodeInput && discountCodeInput?.length > 0) {
          setRuleStyle(editor, `#${discountCodeInput[0].getId()}`, style);
        }
      },
      updateCodeSize(cmp, value) {
        const style = {
          'font-size': `${value}px`,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
      },
      updateCodeColor(cmp, value) {
        const style = {
          color: value,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
      },
      updateCodeColorCopied(cmp, value) {
        const style = {
          color: value,
        };

        setRuleStyle(editor, `#${cmp.getId()}.copied`, style);
      },
      updateCodeBgColor(cmp, value) {
        const style = {
          'background-color': value,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
      },
      updateCodeBgColorCopied(cmp, value) {
        const style = {
          'background-color': value,
        };

        setRuleStyle(editor, `#${cmp.getId()}.copied`, style);
      },
      updateCodeBorderButtonColor(cmp, value) {
        const style = {
          border: `1px dashed ${value}`,
        };
        const buttonStyle = {
          'background-color': value,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
        setRuleStyle(editor, `#${cmp.getId()} > button`, buttonStyle);
      },
      updateCodeBorderButtonColorCopied(cmp, value) {
        const style = {
          border: `1px dashed ${value}`,
        };
        const buttonStyle = {
          'background-color': value,
        };

        setRuleStyle(editor, `#${cmp.getId()}.copied`, style);
        setRuleStyle(editor, `#${cmp.getId()}.copied > button`, buttonStyle);
      },
      updateCodeButtonTextColor(cmp, value) {
        const style = {
          color: value,
        };

        setRuleStyle(editor, `#${cmp.getId()} > button`, style);
      },
      updateCodeButtonTextColorCopied(cmp, value) {
        const style = {
          color: value,
        };

        setRuleStyle(editor, `#${cmp.getId()}.copied > button`, style);
      },
    },
    isComponent(el) {
      if (checkComponentType(el, discountCodeType)) {
        return { type: discountCodeType };
      }
    },
  });
};
