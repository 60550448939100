import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import TextField from 'components/Forms/TextField';

const messages = defineMessages({
  idPlaceholder: { id: 'page-settings-modal.tracking.id-placeholder' },
  googleLabel: { id: 'page-settings-modal.tracking.google-label' },
  googleIdValidationMessage: {
    id: 'page-settings-modal.tracking.google-id-validation',
  },
  googleTagManagerValidationMessage: {
    id: 'page-settings-modal.tracking.google-tagmanager-validation',
  },
  googleDescription: { id: 'page-settings-modal.tracking.google-description' },
  googleTagManagerLabel: {
    id: 'page-settings-modal.tracking.google-tagmanager-label',
  },
  googleTagManagerDescription: {
    id: 'page-settings-modal.tracking.google-tagmanager-description',
  },
  googleTagManagerPlaceholder: {
    id: 'page-settings-modal.tracking.google-tagmanager-placeholder',
  },
  facebookLabel: { id: 'page-settings-modal.tracking.facebook-label' },
  facebookDescription: {
    id: 'page-settings-modal.tracking.facebook-description',
  },
  hotjarLabel: { id: 'page-settings-modal.tracking.hotjar-label' },
  hotjarDescription: { id: 'page-settings-modal.tracking.hotjar-description' },
});

function Tracking() {
  const intl = useIntl();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="page-settings-modal__tracking">
      <div className="page-settings-modal__row">
        <TextField
          {...register('tracking.googleId', {
            validate: (value) => {
              if (value && !value?.match(/^(UA|YT|MO)-\d+-\d+$/m)) {
                return intl.formatMessage(messages.googleIdValidationMessage);
              }
            },
          })}
          error={Boolean(errors?.tracking?.googleId)}
          label={intl.formatMessage(messages.googleLabel)}
          maxLength={20}
          helperText={
            errors?.tracking?.googleId ? (
              errors?.tracking?.googleId?.message
            ) : (
              <>
                {intl.formatMessage(messages.googleDescription)} (
                <a
                  href="https://support.google.com/analytics/answer/11583528"
                  target="_blank"
                  rel="noopener noreferrer">
                  Read more
                </a>
                )
              </>
            )
          }
          placeholder={intl.formatMessage(messages.idPlaceholder)}
          fullWidth
        />
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('tracking.googleTagManagerId', {
            validate: (value) => {
              if (value && !value?.match(/GTM-(\w+)/m)) {
                return intl.formatMessage(
                  messages.googleTagManagerValidationMessage,
                );
              }
            },
          })}
          maxLength={20}
          error={Boolean(errors?.tracking?.googleTagManagerId)}
          label={intl.formatMessage(messages.googleTagManagerLabel)}
          helperText={
            errors?.tracking?.googleTagManagerId
              ? errors?.tracking?.googleTagManagerId?.message
              : intl.formatMessage(messages.googleTagManagerDescription)
          }
          placeholder={intl.formatMessage(messages.googleTagManagerPlaceholder)}
          fullWidth
        />
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('tracking.facebookId')}
          label={intl.formatMessage(messages.facebookLabel)}
          helperText={intl.formatMessage(messages.facebookDescription)}
          placeholder={intl.formatMessage(messages.idPlaceholder)}
          fullWidth
          maxLength={20}
        />
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('tracking.hotjarId')}
          label={intl.formatMessage(messages.hotjarLabel)}
          helperText={intl.formatMessage(messages.hotjarDescription)}
          placeholder={intl.formatMessage(messages.idPlaceholder)}
          fullWidth
          maxLength={20}
        />
      </div>
    </div>
  );
}

export default Tracking;
