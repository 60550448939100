import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import TextField from 'components/Forms/TextField';
import IconButton from 'components/IconButton';
import { createCategory } from 'store/actions';
import './NewCategoryItem.sass';

function NewCategoryListItem({ categoriesNames }) {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  const handleSave = async (values) => {
    setIsLoading(true);
    try {
      await dispatch(createCategory({ name: values.name.trim() })).unwrap();

      reset();
    } catch {
      // catching error end doing nothing
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(handleSave)(event);
    }
  };

  return (
    <div className="new-category-item">
      <TextField
        {...register('name', {
          validate: (value) => {
            const trimmedValue = value.trim();

            if (!trimmedValue) {
              return intl.formatMessage({
                id: 'category-modal.required-error',
              });
            }

            if (categoriesNames.includes(value.trim())) {
              return intl.formatMessage({
                id: 'category-modal.category-exists-error',
              });
            }
          },
        })}
        onKeyPress={handleKeyPress}
        fullWidth
        placeholder={intl.formatMessage({ id: 'category-modal.placeholder' })}
        helperText={
          errors.name?.message ||
          intl.formatMessage({ id: 'category-modal.description' })
        }
        error={Boolean(errors.name)}
        after={
          <IconButton
            variant="contained"
            color="secondary"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleSubmit(handleSave)}>
            <span className="icon icon-add" />
          </IconButton>
        }
      />
    </div>
  );
}

NewCategoryListItem.propTypes = {
  categoriesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NewCategoryListItem;
