import React from 'react';
import PropTypes from 'prop-types';
import { showSuccessMessage } from 'helpers';
import { defineMessages, useIntl } from 'react-intl';
import QRCode from 'react-qr-code';
import Button from 'components/Button';
import './TemplateQRModal.sass';

const messages = defineMessages({
  cancelButton: { id: 'button.cancel' },
  downloadButton: { id: 'button.download' },
});

export const TemplateQRModal = ({ publishedUrl, onClose }) => {
  const intl = useIntl();

  const onImageCownload = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
    showSuccessMessage(
      intl.formatMessage({ id: 'toast.download-qr.success-message' }),
    );
  };

  return (
    <div className="template-qr-modal">
      <div className="template-qr-modal__qr-wrapper">
        <QRCode id="QRCode" value={publishedUrl} />
      </div>
      <div className="template-info-modal__buttons">
        <Button type="button" onClick={onClose}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
        <Button type="button" variant="contained" onClick={onImageCownload}>
          {intl.formatMessage(messages.downloadButton)}
        </Button>
      </div>
    </div>
  );
};

TemplateQRModal.propTypes = {
  publishedUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default TemplateQRModal;
