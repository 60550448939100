import { FONTS, RTE_BUTTON_STATE } from 'appConstants';
import { isValidAnchor } from 'helpers';

const richTextEditorConfig = {
  actions: ['bold'],
};

const richTextEditor = (editor) => {
  const rte = editor.RichTextEditor;

  rte.remove('bold');

  rte.add('fontName', {
    icon: `<select id="rte-select-font-family" class="gjs-rte-select">
      <option value="">Font family</option>
      ${FONTS.reduce((acc, font) => {
        acc += `<option value='${font.value}'>${font.name}</option>`;

        return acc;
      }, '')}
    </select>`,
    attributes: { title: 'Font family' },
    event: 'change',
    result: (rte, action) => {
      const wrapper = editor.getWrapper();
      const { value: fontValue } = action.btn.firstChild;

      rte.exec('fontName', fontValue);

      const font = Array.from(JSON.parse(wrapper.get('fontsList'))).find(
        ({ value }) => value === fontValue,
      );

      if (font?.url) {
        const onAddFont = editor.getModel().get('onAddFont');

        onAddFont(font);
        editor.runCommand('add-font', { url: font.url });
      }
    },
    update: (rte, action) => {
      const wrapper = editor.getWrapper();
      const fontsList = wrapper.get('fontsList');
      const fontFamilySelectBox = document.getElementById(
        'rte-select-font-family',
      );
      const optionNames = [...fontFamilySelectBox.options].map((o) => o.text);

      Array.from(JSON.parse(fontsList)).forEach(({ value, name }) => {
        const option = document.createElement('option');
        option.text = name;
        option.value = value;

        if (!optionNames.includes(name)) {
          fontFamilySelectBox.appendChild(option);
        }
      });

      const value = rte.doc.queryCommandValue(action.name);

      const font = FONTS.find(
        (font) =>
          font.value === value || font.value === value.replaceAll('"', ''),
      );

      if (font) {
        action.btn.firstChild.value = font.value;
        return;
      }

      action.btn.firstChild.value = value ? value : '';
    },
  });

  rte.add('fontSize', {
    icon: `<select class="gjs-rte-select">
      <option value="1">X-Small</option>
      <option value="2">Small</option>
      <option value="3">Medium</option>
      <option value="4">Large</option>
      <option value="5">X-Large</option>
      <option value="6">XX-Large</option>
      <option value="7">XXX-Large</option>
    </select>`,
    event: 'change',
    attributes: { title: 'Font size' },
    result: (rte, action) => {
      const { value } = action.btn.firstChild;

      rte.exec('fontSize', value);
    },
    update: (rte, action) => {
      const value = rte.doc.queryCommandValue(action.name);

      action.btn.firstChild.value = value;
    },
  });

  rte.add('bold', {
    icon: '<span class="gjs-rte-button icon icon-bold"></span>',
    attributes: { title: 'Bold' },
    result: (rte) => rte.exec('bold'),
  });

  rte.add('italic', {
    icon: '<span class="gjs-rte-button icon icon-italic"></span>',
    attributes: { title: 'Italic' },
    result: (rte) => rte.exec('italic'),
  });

  rte.add('strikethrough', {
    icon: '<span class="gjs-rte-button icon icon-strikethrough"></span>',
    attributes: { title: 'Strikethrough' },
    result: (rte) => rte.exec('strikethrough'),
  });

  rte.add('underline', {
    icon: '<span class="gjs-rte-button icon icon-format-underlined"></span>',
    attributes: { title: 'Underline' },
    result: (rte) => rte.exec('underline'),
  });

  rte.add('foreColor', {
    icon: `<label class="gjs-rte-button gjs-rte-button--font-color icon icon-text-fields"><input class="gjs-rte-color" type="color" /></label>`,
    attributes: { title: 'Font color' },
    event: 'input',
    result: (rte, action) => {
      const { value } = action.btn.firstChild.firstChild;

      rte.exec('foreColor', value);
    },
  });

  rte.add('backColor', {
    icon: `<label class="gjs-rte-button gjs-rte-button--background-color icon icon-text-fields"><input class="gjs-rte-color" type="color" /></label>`,
    attributes: { title: 'Background color' },
    event: 'input',
    result: (rte, action) => {
      const { value } = action.btn.firstChild.firstChild;

      rte.exec('backColor', value);
    },
  });

  rte.add('link', {
    icon: '<span class="gjs-rte-button icon icon-link"></span>',
    attributes: { title: 'Link' },
    state: (rte) => {
      if (rte && rte.selection()) {
        return isValidAnchor(rte)
          ? RTE_BUTTON_STATE.ACTIVE
          : RTE_BUTTON_STATE.INACTIVE;
      } else {
        return RTE_BUTTON_STATE.INACTIVE;
      }
    },
    result: (rte) => {
      const blockType = editor.getSelected().get('type');

      if (blockType === 'link-button') {
        const attributes = editor.getSelected().get('attributes');

        const editURL = document.createElement('div');
        editURL.className = 'edit-url-modal__form';

        const editURLTitle = document.createElement('p');
        editURLTitle.className = 'edit-url-modal__form-title';
        editURLTitle.innerHTML = 'Add, edit or remove link';

        const editButtons = document.createElement('div');
        editButtons.className = 'edit-url-modal__buttons';

        const saveButton = document.createElement('button');
        saveButton.type = 'button';
        saveButton.className = 'dnd-button-base dnd-button contained default';
        saveButton.innerHTML = '<div class="dnd-button__label">Save</div>';
        saveButton.onclick = () => {
          const inputUrlValue = document.querySelector(
            '.edit-url-modal__url-input',
          ).value;
          const inputUrlTarget = document.querySelector(
            '.edit-url-modal__url-target',
          ).value;

          editor
            .getSelected()
            .addAttributes({ href: inputUrlValue, target: inputUrlTarget });
          editor.getSelected().view.render();
          editor.Modal.close();
        };

        const cancelButton = document.createElement('button');
        cancelButton.type = 'button';
        cancelButton.className = 'dnd-button-base dnd-button text default';
        cancelButton.innerHTML = '<div class="dnd-button__label">Cancel</div>';
        cancelButton.onclick = () => editor.Modal.close();

        const urlInput = document.createElement('input');
        const urlInputLable = document.createElement('label');
        urlInput.type = 'url';
        urlInput.placeholder = 'eg. https://google.com';
        urlInput.value = attributes.href || '';
        urlInput.className = 'edit-url-modal__url-input dnd-input__input';
        urlInput.id = 'edit-url-modal__url-input';
        urlInputLable.className = 'dnd-label';
        urlInputLable.setAttribute('for', 'edit-url-modal__url-input');
        urlInputLable.innerHTML = 'Link';

        const selectListWrapper = document.createElement('div');
        selectListWrapper.className = 'edit-url-modal__select-wrapper';
        const selectList = document.createElement('select');
        const thisWinOption = document.createElement('option');
        const newWinOption = document.createElement('option');
        const selectListLable = document.createElement('label');
        selectList.className = 'edit-url-modal__url-target';
        selectList.id = 'edit-url-modal__url-target';
        selectListLable.className = 'edit-url-modal__url-target';
        selectListLable.id = 'edit-url-modal__url-target';
        selectListLable.className = 'dnd-label';
        selectListLable.setAttribute('for', 'edit-url-modal__url-target');
        selectListLable.innerHTML = 'Target';
        thisWinOption.value = '_self';
        thisWinOption.text = 'This window';
        newWinOption.value = '_blank';
        newWinOption.text = 'New window';
        selectList.appendChild(thisWinOption);
        selectList.appendChild(newWinOption);

        editURL.appendChild(editURLTitle);
        editURL.appendChild(urlInputLable);
        editURL.appendChild(urlInput);
        editURL.appendChild(selectListLable);
        selectListWrapper.appendChild(selectList);
        editURL.appendChild(selectListWrapper);
        editButtons.appendChild(cancelButton);
        editButtons.appendChild(saveButton);
        editURL.appendChild(editButtons);

        if (attributes.target === '_blank') {
          newWinOption.setAttribute('selected', true);
        } else {
          thisWinOption.setAttribute('selected', true);
        }

        editor.Modal.setTitle('Link Settings')
          .setContent(editURL)
          .open({
            attributes: { class: 'edit-url-modal' },
          });
      } else {
        if (isValidAnchor(rte)) {
          rte.exec('unlink');
        } else {
          rte.insertHTML(`<a class="link" href="">${rte.selection()}</a>`);
        }
      }
    },
  });
};

export { richTextEditorConfig, richTextEditor };
