import { sectionStyles } from '../../common';

export default (
  editor,
  {
    baseUrl,
    bigHeadingType,
    category,
    column3Type,
    columnType,
    containerType,
    divideSectionType,
    iconBlockType,
    imageBlockType,
    linkButtonType,
    linkType,
    modalType,
    newSectionType,
    paragraphType,
    rowType,
    sectionType,
    singleFloatingImageType,
    singleImageType,
    linkedImageType,
    spaceType,
    videoType,
    socialIconWrapperType,
    socialIconType,
    circleShapeType,
    squareShapeType,
  },
) => {
  const bm = editor.BlockManager;
  const socialIconBlock = (icon, title) => `
    <a
      href=""
      data-gjs-type="${socialIconType}"
      data-type="${socialIconType}"
      class="fa-brands fa-${icon}"
      target="_blank"
      title="${title}"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        width: 1em;
        height: 1em;
        margin: 0 4px;
        text-decoration: none;
        color: #333;
      "
    ></a>
  `;

  bm.add(newSectionType, {
    attributes: { class: 'gjs-f-b1' },
    label: 'New Section',
    category,
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 100%" class="col" width="100" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add(divideSectionType, {
    label: 'Divide Section',
    type: 'section',
    attributes: { class: 'gjs-f-b2' },
    category,
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 50%;" class="col" width="50" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 50%;" class="col" width="50" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add(column3Type, {
    label: '3 Columns',
    category,
    attributes: { class: 'gjs-fonts gjs-f-b3' },
    content: `
      <div data-type="${sectionType}" class="section" verticalalign="default" overflow="visible">
        <div data-type="${containerType}" class="container" boxed="true">
          <div data-type="${rowType}" class="row" verticalalign="default" overflow="visible">
            <div data-type="${columnType}" style="flex-basis: 33.33%;" class="col" width="33.33" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 33.33%;" class="col" width="33.33" overflow="visible"></div>
            <div data-type="${columnType}" style="flex-basis: 33.33%;" class="col" width="33.33" overflow="visible"></div>
          </div>
        </div>
      </div>
      <style>
        ${sectionStyles}
      </style>
    `,
  });

  bm.add(imageBlockType, {
    attributes: { class: 'bpreview-background-image' },
    label: 'Background Image',
    category,
    select: true,
    activate: true,
    content: {
      attributes: { 'data-type': imageBlockType },
      type: imageBlockType,
      style: {
        width: '100%',
        height: '200px',
        'background-position': 'center center',
        'background-attachment': 'scroll',
        'background-size': 'cover',
        'background-image': `url('${baseUrl}/images/image-placeholder.png')`,
        '__background-type': 'image',
        __p: true,
      },
    },
  });

  bm.add(singleImageType, {
    attributes: { class: 'bpreview-single-image' },
    category,
    label: 'Single Image',
    activate: true,
    select: true,
    content: `
      <img data-gjs-type="image" data-type="image" src="data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNzYgNzYiPgogIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xNSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJtMjMuNDE3IDQ0LjI1IDEwLjQxNiAxMi41TDQ4LjQxNyAzOGwxOC43NSAyNUg4LjgzM003NS41IDY3LjE2N1Y4LjgzM0E4LjMzMyA4LjMzMyAwIDAgMCA2Ny4xNjcuNUg4LjgzM0M0LjI1LjUuNSA0LjI1LjUgOC44MzN2NTguMzM0Qy41IDcxLjc1IDQuMjUgNzUuNSA4LjgzMyA3NS41aDU4LjMzNGM0LjU4MyAwIDguMzMzLTMuNzUgOC4zMzMtOC4zMzNaIi8+CiAgICA8dGV4dCBmaWxsPSIjRkZGIiBmb250LWZhbWlseT0iUm9ib3RvLVJlZ3VsYXIsIFJvYm90byIgZm9udC1zaXplPSI2LjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uNSAuNSkiPgogICAgICAgICAgICAgICAgPHRzcGFuIHg9IjUuODM3IiB5PSIxNy40MjMiPkRvdWJsZSBjbGljayB0byBjaGFuZ2U8L3RzcGFuPgogICAgICAgICAgICA8L3RleHQ+CiAgPC9nPgo8L3N2Zz4=" alt="image" style="width: 100%; height: auto;" />`,
  });

  bm.add(linkedImageType, {
    attributes: { class: 'bpreview-linked-image' },
    category,
    label: 'Linked Image',
    activate: true,
    select: true,
    content: `
      <a style="display: block; overflow: hidden" data-type="linked-image">
        <img
          data-gjs-editable="true"
          data-gjs-collection="false"
          data-gjs-droppable="false"
          data-gjs-draggable="false"
          data-gjs-badgable="false"
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-selectable="false"
          data-gjs-hoverable="false"
          data-gjs-resizable="false"
          src="https://cdn11.waymore.io/dnd/images/image_placeholder.jpg" data-type="linked-image-image"
          alt="image"
          style="width: 100%; height: auto;"
        />
      </a>
    `,
  });

  bm.add(singleFloatingImageType, {
    attributes: { class: 'bpreview-single-floating-image' },
    category,
    label: 'Floating Image',
    activate: true,
    select: true,
    content: `
      <img data-drag-status="absolute" data-gjs-name="Floating image" data-gjs-type="image" data-type="image" src="data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNzYgNzYiPgogIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xNSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJtMjMuNDE3IDQ0LjI1IDEwLjQxNiAxMi41TDQ4LjQxNyAzOGwxOC43NSAyNUg4LjgzM003NS41IDY3LjE2N1Y4LjgzM0E4LjMzMyA4LjMzMyAwIDAgMCA2Ny4xNjcuNUg4LjgzM0M0LjI1LjUuNSA0LjI1LjUgOC44MzN2NTguMzM0Qy41IDcxLjc1IDQuMjUgNzUuNSA4LjgzMyA3NS41aDU4LjMzNGM0LjU4MyAwIDguMzMzLTMuNzUgOC4zMzMtOC4zMzNaIi8+CiAgICA8dGV4dCBmaWxsPSIjRkZGIiBmb250LWZhbWlseT0iUm9ib3RvLVJlZ3VsYXIsIFJvYm90byIgZm9udC1zaXplPSI2LjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uNSAuNSkiPgogICAgICAgICAgICAgICAgPHRzcGFuIHg9IjUuODM3IiB5PSIxNy40MjMiPkRvdWJsZSBjbGljayB0byBjaGFuZ2U8L3RzcGFuPgogICAgICAgICAgICA8L3RleHQ+CiAgPC9nPgo8L3N2Zz4=" alt="star" style="width: 200px; height: 200px;" />
  `,
  });

  bm.add(iconBlockType, {
    label: 'Icon',
    category,
    attributes: { class: 'bpreview-icon' },
    activate: true,
    select: true,
    content: `
      <img data-gjs-type="icon-wrapper" data-type="icon-wrapper" src="data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNzYgNzYiPgogIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xNSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJtMjMuNDE3IDQ0LjI1IDEwLjQxNiAxMi41TDQ4LjQxNyAzOGwxOC43NSAyNUg4LjgzM003NS41IDY3LjE2N1Y4LjgzM0E4LjMzMyA4LjMzMyAwIDAgMCA2Ny4xNjcuNUg4LjgzM0M0LjI1LjUuNSA0LjI1LjUgOC44MzN2NTguMzM0Qy41IDcxLjc1IDQuMjUgNzUuNSA4LjgzMyA3NS41aDU4LjMzNGM0LjU4MyAwIDguMzMzLTMuNzUgOC4zMzMtOC4zMzNaIi8+CiAgICA8dGV4dCBmaWxsPSIjRkZGIiBmb250LWZhbWlseT0iUm9ib3RvLVJlZ3VsYXIsIFJvYm90byIgZm9udC1zaXplPSI2LjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uNSAuNSkiPgogICAgICAgICAgICAgICAgPHRzcGFuIHg9IjUuODM3IiB5PSIxNy40MjMiPkRvdWJsZSBjbGljayB0byBjaGFuZ2U8L3RzcGFuPgogICAgICAgICAgICA8L3RleHQ+CiAgPC9nPgo8L3N2Zz4=" alt="image" style="width: 128px; height: 128px;" />`,
  });

  bm.add(videoType, {
    label: 'Video',
    category,
    content: {
      type: 'video',
      src: 'img/video2.webm',
      style: {
        height: '350px',
        width: '615px',
      },
    },
  });

  bm.add(paragraphType, {
    category: 'Basic',
    label: 'Paragraph',
    content: `
      <p style="margin: 0px; padding: 20px; text-align: left;">
        This sentence has five words. Here are five more words. Five-word sentences are fine. But several together become monotonous. Listen to what is happening. The writing is getting boring. The sound of it drones. It’s like a stuck record. The ear demands some variety. Now listen. I vary the sentence length, and I create music. Music. The writing sings.
      </p>
    `,
    attributes: {
      class: 'bpreview-paragraph',
    },
  });

  bm.add(bigHeadingType, {
    attributes: { class: 'bpreview-big-heading' },
    label: 'Big Heading',
    category,
    content: `
      <h1 style="padding: 20px; margin: 0; font-size: 24px; font-weight: 700; line-height: 1.2">Insert your heading here</h1>
    `,
  });

  bm.add(linkButtonType, {
    attributes: { class: 'bpreview-button' },
    label: 'Button',
    category,
    content: `
      <a class="clearfix" data-type="${linkButtonType}" href="" style="display: inline-block; background-color: #83a2d9; color: #fff; padding: 20px 25px; font-size: 16px; text-transform: uppercase; text-decoration: none; font-family: Arial, Helvetica, sans-serif; font-weight: 600;">Click me</a>
      <style>
        .clearfix::after {
          content: "";
          clear: both;
          display: block;
        }
      </style>
      `,
  });

  bm.add(linkType, {
    category,
    label: 'Link',
    attributes: { class: 'bpreview-link' },
    content:
      '<a href="" style="color: #d983a6; display: inline-block;">This is a link</a>',
  });

  bm.add(spaceType, {
    label: 'Space',
    category,
    attributes: { class: 'bpreview-space' },
    content: `<div data-gjs-type="${spaceType}" style="width: 100%; height: 50px" isspace="true"></div>`,
  });

  bm.add(modalType, {
    label: 'Modal',
    attributes: { class: 'bpreview-modal' },
    category: 'Basic',
    content: `
      <div
        data-gjs-type="modal"
        data-type="modal"
        style="display: inline-block"
      >
        <button
          type="button"
          class="modal-open-button"
          data-gjs-type="modal-button"
          data-type="modal-button"
          style="
            font-family: Arial, Helvetica, sans-serif;
            display: inline-block;
            background-color: #83a2d9;
            color: #fff;
            padding: 12px 20px;
            font-size: 14px;
            text-decoration: none;
            border-radius: 4px;
            border: 0;
          "
        >Open modal</button>
        <div
          class="modal-wrapper"
          data-gjs-type="modal-wrapper"
          data-type="modal-wrapper"
          style="
            display: none;
            position: fixed;
            z-index: 100;
            padding-top: 100px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0,0,0,0.4);
          "
        >
          <div
            class="modal-content"
            data-gjs-type="modal-content"
            data-type="modal-content"
            style="
              background-color: #ffffff;
              margin: auto;
              padding: 24px;
              width: 80%;
            "
          >
            <div
              class="modal-content__header"
              style="
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 32px;
              "
            >
              <h3
                class="modal-content__title"
                style="
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  font-size: 22px;
                  font-weight: 400;
                  color: #4D4F5C;
                "
              >Modal title</h3>
              <button
                type="button"
                class="modal-content__close-button"
                style="
                  padding: 0;
                  border: none;
                  background-color: transparent;
                  cursor: pointer;
                  width: 20px;
                  height: 20px;
                  font-size: 30px;
                  color: #77787A;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >&times;</button>
            </div>
            <div
              class="modal-content__text"
              style="
                margin-bottom: 24px;
              "
            >
              <p>Modal body text goes here</p>
            </div>
            <div
              class="modal-content__footer"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 32px;
              "
            >
              <button
                class="modal-content__footer__close-button"
                type="button"
                style="
                  padding: 8px 16px;
                  border-radius: 4px;
                  border: 0;
                  background: transparent;
                  color: #1c1d1f;
                  margin-left: 4px;
                  cursor: pointer;
                "
              >Close</button>
              <button
                class="modal-content__footer__save-button"
                type="button"
                style="
                  padding: 8px 16px;
                  border-radius: 4px;
                  border: 0;
                  background: #43425D;
                  color: #fff;
                  margin-left: 4px;
                  cursor: pointer;
                "
              >Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    `,
  });

  bm.add(socialIconWrapperType, {
    label: 'Social (Beta)',
    category,
    attributes: { class: 'bpreview-social-share-block' },
    content: `
    <div
      data-gjs-editable="false"
      data-type="social-icon-wrapper"
      data-gjs-type="social-icon-wrapper"
      data-social-icon-style="preview-32-logo-black"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 24px
      "
    >
      ${[
        { icon: 'facebook-f', title: 'Facebook' },
        { icon: 'linkedin-in', title: 'LinkedIn' },
        { icon: 'x-twitter', title: 'X Twitter' },
      ]
        .map(({ icon, title }) => socialIconBlock(icon, title))
        .join('')}
    </div>
    `,
  });

  bm.add(circleShapeType, {
    attributes: { class: 'bpreview-circle-shape' },
    category,
    label: 'Circle Shape',
    activate: true,
    select: true,
    content: {
      type: circleShapeType,
      attributes: {
        'data-drag-status': 'absolute',
      },
    },
  });

  bm.add(squareShapeType, {
    attributes: { class: 'bpreview-square-shape' },
    category,
    label: 'Square Shape',
    activate: true,
    select: true,
    content: {
      type: squareShapeType,
      attributes: {
        'data-drag-status': 'absolute',
      },
    },
  });
};
