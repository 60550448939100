import pluginNavbar from 'grapesjs-navbar';
import pluginBlocks from 'grapesjs-blocks-basic';
import pluginTooltip from 'grapesjs-tooltip';

import { BLOCKS_CATEGORIES, CDN_HOST } from 'appConstants';
import basic from './Basic';
import form from './Forms';
import navigation from './Navigation';
import social from './Social';
import extra from './Extra';
import sections from './Sections';

export default (editor, createCustomCode, editCustomCode, isAdmin) => {
  const baseUrl = CDN_HOST || window.origin;

  const commonTypes = {
    sectionType: 'section',
    containerType: 'container',
    rowType: 'section-row',
    columnType: 'column',
    buttonType: 'button',
    imageType: 'image',
    imageBlockType: 'image-block',
    iconBlockType: 'icon-block',
    spaceType: 'space',
  };

  // basic category
  basic(editor, {
    baseUrl,
    category: 'Basic',
    newSectionType: 'new-section',
    divideSectionType: 'divide-section',
    column3Type: 'column3',
    singleImageType: 'single-image',
    linkedImageType: 'linked-image',
    linkedImageImageType: 'linked-image-image',
    singleFloatingImageType: 'single-floating-image',
    videoType: 'video',
    paragraphType: 'paragraph',
    bigHeadingType: 'big-heading',
    linkButtonType: 'link-button',
    linkType: 'link',
    spaceType: 'space',
    svgType: 'svg',
    iconWrapperType: 'icon-wrapper',
    modalType: 'modal',
    modalWrapperType: 'modal-wrapper',
    modalContentType: 'modal-content',
    modalButtonType: 'modal-button',
    socialIconWrapperType: 'social-icon-wrapper',
    socialIconType: 'social-icon',
    circleShapeType: 'circle-shape',
    squareShapeType: 'square-shape',
    ...commonTypes,
  });

  navigation(editor, {
    baseUrl,
    category: 'Navigation',
    tabsType: 'tabs',
    tabsListType: 'tabs-list',
    tabsListItemType: 'tabs-list-item',
    tabsContentType: 'tabs-content',
    tabsContentItemType: 'tabs-content-item',
    sliderType: 'lory-slider',
    sliderFrameType: 'lory-slider-frame',
    sliderSlidesType: 'lory-slider-slides',
    sliderSlideType: 'lory-slider-slide',
    sliderPrevType: 'lory-slider-prev',
    sliderNextType: 'lory-slider-next',
    ...commonTypes,
  });

  form(editor, {
    baseUrl,
    category: 'Forms',
    contactInputType: 'contact-input',
    emailInputType: 'email-input',
    formButtonType: 'form-button',
    formGroupType: 'form-group',
    formType: 'form',
    labelType: 'label',
    nameInputType: 'name-input',
    numberCustomFieldInputType: 'number-custom-field-input',
    numberCustomFieldType: 'number-custom-field',
    phoneInputType: 'phone-input',
    textareaType: 'textarea',
    textCustomFieldInputType: 'text-custom-field-input',
    textCustomFieldType: 'text-custom-field',
    ...commonTypes,
  });

  sections(editor, {
    baseUrl,
    category: 'Sections',
    ...commonTypes,
  });

  pluginBlocks(editor, {
    baseUrl,
    blocks: ['map'],
    category: 'Extra',
  });

  pluginNavbar(editor, {
    baseUrl,
    blocks: ['grapesjs-navbar'],
    block: {
      media: '',
      category: 'Navigation',
      attributes: { class: 'bpreview-navbar' },
    },
  });

  pluginTooltip(editor);

  extra(editor, {
    baseUrl,
    category: 'Extra',
    quoteType: 'quote',
    textBasicType: 'text-basic',
    listItemsType: 'list-items',
    mapType: 'map',
    countdownType: 'countdown',
    tooltipType: 'tooltip',
    customCodeType: 'custom',
    boxType: 'box',
    discountCodeType: 'discount-code',
    createCustomCode,
    editCustomCode,
    isAdmin,
  });

  social(editor, {
    baseUrl,
    category: 'Social',
    shape: 'round',
    color: 'color',
    size: '50',
    socialItemType: 'social-item',
    socialBlockType: 'social-block',
    socialFollowBlockType: 'social-follow-block',
    socialFollowItemType: 'social-follow',
    socialShareBlockType: 'social-share-block',
    socialShareItemType: 'social-share',
    socialFollowLinkTraitType: 'social-follow-link-trait',
    socialShareLinkTraitType: 'social-share-link-trait',
  });

  editor.BlockManager.getCategories().each((ctg) => {
    if (ctg.id !== BLOCKS_CATEGORIES.blocks[0]) {
      ctg.set('open', false);
    }
  });
};
