import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { useQuery } from 'hooks';
import { isEmptyObject, isStringWithSpecialSigns } from 'helpers';
import { useEditorContext } from 'routes/Editor/EditorContext';
import { useForm, Controller } from 'react-hook-form';
import CategoriesCombobox from 'components/CategoriesCombobox';
import Button from 'components/Button';
import TextField from '../../../components/Forms/TextField';
import './TemplateInfoModal.sass';

const messages = defineMessages({
  nameValidation: { id: 'general-error.name-validation' },
  descriptionValidation: { id: 'general-error.description-validation' },
  maxLenExceededValidation: {
    id: 'general-error.max-len-exceeded-validation',
  },
  nameEmptyError: { id: 'general-error.name-empty' },
  templateName: { id: 'template-info-modal.name' },
  templateTags: { id: 'template-info-modal.tags' },
  templateDescription: { id: 'template-info-modal.description' },
  cancelButton: { id: 'button.cancel' },
  saveButton: { id: 'button.save' },
});

export const TemplateInfoModal = ({
  name,
  description,
  tags,
  categories,
  onClose,
  onChange,
}) => {
  const intl = useIntl();
  const { system } = useQuery();
  const isSystem = Boolean(system);
  const {
    register,
    control,
    formState: { isDirty, isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name,
      description,
      tags,
      categories,
    },
  });

  const { isChangeInfoLoading } = useEditorContext();

  const handleSave = (payload) => {
    if (isEmptyObject(payload)) {
      onClose();
    } else {
      onChange(payload);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <TextField
        {...register('name', {
          required: intl.formatMessage(messages.nameEmptyError),
          maxLength: {
            value: 255,
            message: intl.formatMessage(messages.maxLenExceededValidation),
          },
          validate: (value) => {
            if (isStringWithSpecialSigns(value)) {
              return intl.formatMessage(messages.nameValidation);
            }
          },
        })}
        helperText={errors.name?.message}
        maxLength={255}
        error={Boolean(errors.name)}
        name="name"
        label={intl.formatMessage(messages.templateName)}
        placeholder={intl.formatMessage(messages.templateName)}
        fullWidth
      />
      {isSystem ? (
        <Controller
          control={control}
          name="categories"
          render={({ field: { onChange, value } }) => (
            <CategoriesCombobox
              showNewCategoryItem
              value={value}
              onChange={onChange}
            />
          )}
        />
      ) : (
        <TextField
          {...register('tags')}
          name="tags"
          label={intl.formatMessage(messages.templateTags)}
          placeholder={intl.formatMessage(messages.templateTags)}
          fullWidth
        />
      )}
      <TextField
        {...register('description', {
          maxLength: {
            value: 70,
            message: intl.formatMessage(messages.maxLenExceededValidation),
          },
          validate: (value) => {
            if (value.length > 0 && isStringWithSpecialSigns(value)) {
              return intl.formatMessage(messages.descriptionValidation);
            }
          },
        })}
        name="description"
        maxLength={70}
        label={intl.formatMessage(messages.templateDescription)}
        placeholder={intl.formatMessage(messages.templateDescription)}
        error={Boolean(errors.description)}
        helperText={errors.description?.message}
        fullWidth
      />
      <div className="template-info-modal__buttons">
        <Button type="button" onClick={onClose}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={!isDirty || !isValid || isChangeInfoLoading}
          loading={isChangeInfoLoading}>
          {intl.formatMessage(messages.saveButton)}
        </Button>
      </div>
    </form>
  );
};

TemplateInfoModal.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TemplateInfoModal;
