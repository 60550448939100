import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { showErrorMessage, getErrorText } from 'helpers';
import { httpGetContactTags } from 'api/contacts.api';
import SelectBox from 'components/SelectBox';

function TagsMultiCombobox({ value, onChange }) {
  let newValue = [];

  const intl = useIntl();
  const [loading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);

  if (value) {
    value.forEach((item) => newValue.push({ value: item, label: item }));
  }

  useEffect(() => {
    setIsLoading(true);

    const getTags = async () => {
      try {
        let newData = [];
        const { data } = await httpGetContactTags();

        data.forEach((item) => newData.push({ value: item, label: item }));

        setTags(newData);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    getTags();

    return () => {
      setIsLoading(false);
    };
  }, [setTags]);

  return (
    <SelectBox
      isMulti
      isDisabled={loading}
      onChange={onChange}
      loading={loading}
      options={tags}
      defaultValue={newValue}
      tooltip={intl.formatMessage({
        id: 'page-settings-modal.forms.tags-label-tooltip',
      })}
      label={intl.formatMessage({
        id: 'page-settings-modal.forms.tags-label',
      })}
      placeholder={intl.formatMessage({
        id: 'page-settings-modal.forms.tags-placeholder',
      })}
      description={intl.formatMessage({
        id: 'page-settings-modal.forms.tags-description',
      })}
    />
  );
}

TagsMultiCombobox.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default TagsMultiCombobox;
