import { showWarningMessage } from 'helpers';
import { customFieldsModal } from './utils';

export default (editor, { buttonType }) => {
  editor.on('component:remove', (model) => {
    if (model.get('type') === buttonType) {
      const form = model.closest('form');
      if (form) {
        form.remove();
      }
    }
  });

  editor.on('canvas:drop', (_dataTransfer, model) => {
    if (!Array.isArray(model)) {
      if (model.get('type') === 'form') {
        const view = model.getView();
        const formStyle = model.get('formstyle');
        view.updateFormStyle(model, formStyle);
      }

      if (
        model.get('type') === 'text-custom-field' ||
        model.get('type') === 'number-custom-field'
      ) {
        const customFieldType =
          model.get('type') === 'text-custom-field' ? 'string' : 'number';
        customFieldsModal(editor, customFieldType);
      }
    }
  });

  editor.on('sorter:drag:end', (targetCollection) => {
    const wrapper = editor.getWrapper();
    const nodeName = targetCollection?.validResult?.src?.nodeName;
    const nodeType = targetCollection?.validResult?.src?.dataset?.type;
    const targetType = targetCollection?.validResult?.srcModel?.get('type');

    let nameInputCounter = 0;
    let emailInputCounter = 0;
    let phoneInputCounter = 0;
    let formCounter = 0;

    // Stop dragging more than one form
    for (const model of wrapper.findType('form')) {
      formCounter++;

      if (formCounter > 1) {
        showWarningMessage('Each template can only contain one "Form" block');
        model.remove();

        break;
      }
    }

    if (targetCollection.validResult.valid) {
      for (const model of targetCollection.targetCollection.models) {
        if (model.get('type') === 'name-input') {
          nameInputCounter++;
          if (nameInputCounter > 1) {
            showWarningMessage(
              'Each form can only contain one "Name Input" block',
            );
            model.remove();

            break;
          }
        }

        if (model.get('type') === 'email-input') {
          emailInputCounter++;
          if (emailInputCounter > 1) {
            showWarningMessage(
              'Each form can only contain one "Email Input" block',
            );
            model.remove();

            break;
          }
        }

        if (model.get('type') === 'phone-input') {
          phoneInputCounter++;

          if (phoneInputCounter > 1) {
            showWarningMessage(
              'Each form can only contain one "Phone Input" block',
            );

            model.remove();
          }
        }
      }
    }

    if (targetType === 'column' && targetCollection.warns.length > 1) {
      showWarningMessage('This block can only be dropped in a section');
    }
    if (
      targetCollection.warns.length > 1 &&
      (nodeName === 'INPUT' ||
        nodeName === 'SELECT' ||
        nodeName === 'TEXTAREA' ||
        nodeName === 'LABEL' ||
        nodeName === 'BUTTON' ||
        (nodeName === 'DIV' && nodeType === 'form-group') ||
        (nodeName === 'DIV' && nodeType === 'email-input') ||
        (nodeName === 'DIV' && nodeType === 'phone-input'))
    ) {
      showWarningMessage('This block can only be dropped in a form');
    }
  });
};
