import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { LOAD_STATUS } from 'appConstants';
import {
  getWayMoreStores,
  getWayMoreCategories,
  getWayMoreProducts,
  selectWayMoreStore,
  selectWayMoreProduct,
  selectWayMoreCategory,
  resetWayMoreProducts,
} from 'store/actions';

export const storeItemToString = (item) => (item ? item.storeUrl : '');
export const categoryItemToString = (item) => (item ? item.join(' / ') : '');
export const productItemToString = (item) =>
  item && item.productName ? item.productName : '';

export function useProducts() {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [storeSearchTerm, setStoreSearchTerm] = useState('');

  const [productSearchTerm, setProductSearchTerm] = useState('');

  const [categorySearchTerm, setCategorySearchTerm] = useState('');

  const [showDescription, setShowDescription] = useState(false);

  const categoriesAbort = useRef();
  const productsAbort = useRef();

  const [layout, setLayout] = useState('horizontal');

  const [showCategory, setShowCategory] = useState(false);

  const storeItems = useSelector((state) => state.waymore.products.stores);

  const categoryItems = useSelector(
    (state) => state.waymore.products.categories,
  );

  const productItems = useSelector((state) => state.waymore.products.products);

  const storesStatus = useSelector(
    (state) => state.waymore.products.storesStatus,
  );

  const categoriesStatus = useSelector(
    (state) => state.waymore.products.categoriesStatus,
  );

  const productsStatus = useSelector(
    (state) => state.waymore.products.productsStatus,
  );

  const storeSelectedItem = useSelector(
    (state) => state.waymore.products.storeSelectedItem,
  );

  const categorySelectedItem = useSelector(
    (state) => state.waymore.products.categorySelectedItem,
  );

  const productSelectedItem = useSelector(
    (state) => state.waymore.products.productSelectedItem,
  );

  useEffect(() => {
    if (storesStatus !== LOAD_STATUS.IDLE) {
      return;
    }

    dispatch(getWayMoreStores());
  }, [dispatch, intl, storesStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetWayMoreProducts());
    };
  }, [dispatch]);

  const fetchCategories = (selectedStore) => {
    if (categoriesAbort.current) {
      categoriesAbort.current();
    }

    const promise = dispatch(
      getWayMoreCategories({
        storeId: selectedStore.storeUuid,
      }),
    );

    categoriesAbort.current = promise.abort;
  };

  const fetchProducts = (selectedStore, selectedCategory) => {
    if (productsAbort.current) {
      productsAbort.current();
    }

    const promise = dispatch(
      getWayMoreProducts({
        storeId: selectedStore.storeUuid,
        category: selectedCategory
          ? selectedCategory[selectedCategory.length - 1]
          : null,
      }),
    );

    productsAbort.current = promise.abort;
  };

  const handleStoreSelectedItemChange = (selectedItem) => {
    dispatch(selectWayMoreStore(selectedItem));

    if (selectedItem) {
      fetchCategories(selectedItem);
      fetchProducts(selectedItem);
    }
  };

  const handleCategorySelectedItemChange = (selectedItem) => {
    dispatch(selectWayMoreCategory(selectedItem));

    fetchProducts(storeSelectedItem, selectedItem);
  };

  const handleProductSelectedItemChange = (selectedItem) => {
    dispatch(selectWayMoreProduct(selectedItem));
  };

  const handleChangeLayout = ({ target: { value } }) => {
    setLayout(value);
  };

  const handleChangeShowCategory = (value) => {
    setShowCategory(value);
  };

  const handleChangeShowDescription = (value) => {
    setShowDescription(value);
  };

  const storesLoading = storesStatus === LOAD_STATUS.LOADING;
  const categoriesLoading = categoriesStatus === LOAD_STATUS.LOADING;
  const productsLoading = productsStatus === LOAD_STATUS.LOADING;

  const showEmptyContent =
    (storesStatus === LOAD_STATUS.RESOLVED ||
      storesStatus === LOAD_STATUS.REJECTED) &&
    !storeItems.length;

  return {
    showEmptyContent,
    storesLoading,
    categoriesLoading,
    productsLoading,
    storeSelectedItem,
    productSelectedItem,
    categorySelectedItem,
    storeItems: storeItems.filter((item) =>
      storeItemToString(item)
        .toLowerCase()
        .includes(storeSearchTerm.toLowerCase()),
    ),
    categoryItems: categoryItems.filter((item) =>
      categoryItemToString(item)
        .toLowerCase()
        .includes(categorySearchTerm.toLowerCase()),
    ),
    productItems: productItems.filter((item) =>
      productItemToString(item)
        .toLowerCase()
        .includes(productSearchTerm.toLowerCase()),
    ),
    layout,
    showCategory,
    showDescription,
    setStoreSearchTerm,
    setProductSearchTerm,
    setCategorySearchTerm,
    handleStoreSelectedItemChange,
    handleCategorySelectedItemChange,
    handleProductSelectedItemChange,
    handleChangeLayout,
    handleChangeShowCategory,
    handleChangeShowDescription,
  };
}
