import { PUBLIC_URL } from 'appConstants/env';

const BASE_PATH = `${PUBLIC_URL}/images`;

export const dashboardTourStep1 = () =>
  `${BASE_PATH}/user-tour/dashboard-tour_step1.png`;
export const dashboardTourStep2 = () =>
  `${BASE_PATH}/user-tour/dashboard-tour_step2.gif`;
export const dashboardTourStep3NewTemplate = () =>
  `${BASE_PATH}/user-tour/dashboard-tour_step3_new-template.png`;
export const dashboardTourStep3Templates = () =>
  `${BASE_PATH}/user-tour/dashboard-tour_step3_templates.png`;

export const newTemplatesSprite = (templateIcon) =>
  `${BASE_PATH}/new-template/templates-sprite.svg#${templateIcon}`;

export const userIcon = () => `${BASE_PATH}/icons/user-icon.svg`;
