import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useCodemirror } from 'hooks';
import CodeMirrorHeader from 'components/CodeMirrorHeader';
import CodeMirrorAllButtons from 'components/CodeMirrorAllButtons';
import FormControl from 'components/Forms/FormControl';
import './ViewCodeModal.sass';

function ViewCodeModal({ editor }) {
  const htmlEditorRef = useRef();
  const cssEditorRef = useRef();

  const htmlEditor = useCodemirror(htmlEditorRef, {
    value: editor.getHtml(),
    mode: 'htmlmixed',
    readOnly: true,
  });

  const cssEditor = useCodemirror(cssEditorRef, {
    value: `<style>${editor.getCss()}</style>`,
    mode: 'css',
    readOnly: true,
  });

  return (
    <div className="view-code-modal">
      <div className="view-code-modal__row">
        <FormControl className="view-code-modal__editor-wrapper">
          <CodeMirrorHeader
            title="HTML"
            htmlEditor={htmlEditor}
            cssEditor={cssEditor}
            activeHtml
            disablePaste
          />
          <div className="view-code-modal__editor" ref={htmlEditorRef} />
        </FormControl>
        <FormControl className="view-code-modal__editor-wrapper">
          <CodeMirrorHeader
            title="CSS"
            cssEditor={cssEditor}
            activeCss
            disablePaste
          />
          <div className="view-code-modal__editor" ref={cssEditorRef} />
        </FormControl>
      </div>
      <div className="code-import-modal__buttons">
        <CodeMirrorAllButtons
          editor={editor}
          htmlEditor={htmlEditor}
          cssEditor={cssEditor}
          disablePaste
          disableUpload
        />
      </div>
    </div>
  );
}

ViewCodeModal.propTypes = {
  editor: PropTypes.object,
};

export default ViewCodeModal;
