import { COMPONENT_CHANGE_STATUSES } from 'appConstants';
import { checkComponentType } from 'helpers';
import {
  socialFollowTypes,
  socialShapes,
  socialColors,
  socialHexColor,
  socialShareTypes,
} from './constants';

const addSocialItem = (editor, { socialItemType }) => {
  editor.DomComponents.addType(socialItemType, {
    extend: 'link',
    model: {
      defaults: {
        name: 'Social Item',
        type: socialItemType,
        tagName: 'a',
        network: null,
        droppable: false,
        editable: false,
        draggable: false,
        openTraitsOnSelect: true,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:status', this.initializeAttributes);
        this.listenTo(this.model, 'change:network', this.updateNetwork);
      },
      initializeAttributes(model, status) {
        if (status !== COMPONENT_CHANGE_STATUSES.SELECTED) {
          return;
        }

        const { network } = model.getAttributes();

        model.attributes.network = network;
      },
      updateNetwork(model, network) {
        model.addAttributes({
          network,
          class: `fa-brands fa-${network === 'x' ? 'x-twitter' : network}`,
        });

        const parent = model.parent();

        const { color } = parent.getAttributes();

        if (color === 'color') {
          model.addStyle({
            'background-color': socialHexColor[network],
          });
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, socialItemType)) {
        return { type: socialItemType };
      }
    },
  });
};

const addSocialBlock = (editor, { socialBlockType, size, shape, color }) => {
  let traits = [
    {
      type: 'number',
      label: 'Size (px)',
      name: 'size',
      changeProp: true,
      min: 0,
      max: 96,
    },
    {
      type: 'select',
      label: 'Shape',
      name: 'shape',
      changeProp: true,
      options: socialShapes.map((shape) => {
        return { value: shape, name: shape };
      }),
    },
    {
      type: 'select',
      label: 'Color',
      name: 'color',
      changeProp: true,
      options: socialColors.map((color) => {
        return { value: color, name: color };
      }),
    },
  ];

  editor.DomComponents.addType(socialBlockType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Social Block',
        type: socialBlockType,
        traits,
        droppable: false,
        size,
        shape,
        color,
        openTraitsOnSelect: true,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:status', this.initializeAttributes);
        this.listenTo(this.model, 'change:size', this.updateSize);
        this.listenTo(this.model, 'change:shape', this.updateShape);
        this.listenTo(this.model, 'change:color', this.updateColor);
      },
      initializeAttributes(model, status) {
        if (status !== COMPONENT_CHANGE_STATUSES.SELECTED) {
          return;
        }

        const { size, shape, color } = model.getAttributes();

        model.attributes.size = size;
        model.attributes.shape = shape;
        model.attributes.color = color;
      },
      updateSize(model, size) {
        model.addAttributes({ size });

        const components = model.components();

        components.models.forEach((itemModel) => {
          itemModel.addStyle({
            width: `${size}px`,
            height: `${size}px`,
            'font-size': `${size * 0.5}px`,
          });
        });
      },
      updateShape(model, shape) {
        model.addAttributes({ shape });

        const components = model.components();

        let borderRadius = '50%';

        if (shape === 'square') {
          borderRadius = '5px';
        }

        components.models.forEach((itemModel) => {
          itemModel.addStyle({
            'border-radius': borderRadius,
          });
        });
      },
      updateColor(model, color) {
        model.addAttributes({ color });

        const components = model.components();

        components.models.forEach((itemModel) => {
          const { network } = itemModel.getAttributes();

          const style = {};

          switch (color) {
            case 'black': {
              style.color = '#fff';
              style['background-color'] = '#000';
              style.border = 'none';
              break;
            }
            case 'grey': {
              style.color = '#000';
              style['background-color'] = '#BDBDBD';
              style.border = 'none';
              break;
            }

            case 'white': {
              style.color = '#000';
              style['background-color'] = '#fff';
              style.border = 'none';
              break;
            }

            case 'transparent': {
              style.color = '#000';
              style['background-color'] = 'transparent';
              style.border = '2px solid #000';
              break;
            }
            default: {
              style.color = '#fff';
              style['background-color'] = socialHexColor[network];
              style.border = 'none';
            }
          }

          itemModel.addStyle(style);
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, socialBlockType)) {
        return { type: socialBlockType };
      }
    },
  });
};

const addSocialFollowItem = (
  editor,
  { socialFollowItemType, socialItemType, socialFollowLinkTraitType },
) => {
  const traits = [
    {
      type: 'select',
      label: 'Network',
      name: 'network',
      changeProp: 1,
      options: socialFollowTypes.map((type) => {
        return { value: type, name: type };
      }),
    },
    {
      type: socialFollowLinkTraitType,
      label: 'Social URL or ID',
      name: 'url',
      changeProp: true,
    },
  ];

  editor.DomComponents.addType(socialFollowItemType, {
    extend: socialItemType,
    model: {
      defaults: {
        name: 'Social Follow Item',
        type: socialFollowItemType,
        traits,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, socialFollowItemType)) {
        return { type: socialFollowItemType };
      }
    },
  });
};

const addSocialShareItem = (
  editor,
  { socialShareItemType, socialItemType },
) => {
  const traits = [
    {
      type: 'select',
      label: 'Network',
      name: 'network',
      changeProp: 1,
      options: socialShareTypes.map((type) => {
        return { value: type, name: type };
      }),
    },
  ];

  editor.DomComponents.addType(socialShareItemType, {
    extend: socialItemType,
    model: {
      defaults: {
        name: 'Social Share Item',
        type: socialShareItemType,
        traits,
        // eslint-disable-next-line object-shorthand
        script: function () {
          const { href } = window.location;
          const src = window.frameElement
            ? window.frameElement.getAttribute('src')
            : href;

          if (src != null) {
            const links = document.querySelectorAll(
              `[data-type="social-share"]`,
            );

            links.forEach((link) => {
              const network = link.getAttribute('network');

              if (network) {
                let url;

                if (network === 'facebook') {
                  url = `https://www.facebook.com/sharer/sharer.php?display=page&u=${src}`;
                }

                if (network === 'x') {
                  url = `http://x.com/share?url=${src}`;
                }

                if (network === 'linkedin') {
                  url = `https://www.linkedin.com/shareArticle?url=${src}`;
                }

                if (network === 'pinterest') {
                  url = `http://pinterest.com/pin/create/link/?url=${src}`;
                }

                if (network === 'vk') {
                  url = `https://vk.com/share.php?url=${src}`;
                }

                link.href = url;
                link.target = '_blank';
              }
            });
          }
        },
      },
    },
    isComponent(el) {
      if (checkComponentType(el, socialShareItemType)) {
        return { type: socialShareItemType };
      }
    },
  });
};

export default (editor, config) => {
  addSocialItem(editor, config);
  addSocialBlock(editor, config);
  addSocialFollowItem(editor, config);
  addSocialShareItem(editor, config);
};
