import { socialHexColor } from './constants';

const addSocialFollowBlock = (
  editor,
  {
    category,
    socialFollowItemType,
    socialFollowBlockType,
    socialBlockType,
    size,
    shape,
    color,
  },
) => {
  editor.BlockManager.add(socialFollowBlockType, {
    label: 'Social Follow',
    category,
    content: `
      <div
        data-type="${socialBlockType}"
        class="social-follow-block"
        size="${size}"
        shape="${shape}"
        color="${color}"
      >
        <a
          href=""
          class="fa-brands fa-facebook-f"
          data-type="${socialFollowItemType}"
          network="facebook"
          style="color: #fff; background-color: ${socialHexColor.facebook}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-instagram"
          data-type="${socialFollowItemType}"
          network="instagram"
          style="color: #fff; background-color: ${socialHexColor.instagram}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-linkedin-in"
          data-type="${socialFollowItemType}"
          network="linkedin"
          style="color: #fff; background-color: ${socialHexColor.linkedin}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-x-twitter"
          data-type="${socialFollowItemType}"
          network="x"
          style="color: #fff; background-color: ${socialHexColor.x}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-vk"
          data-type="${socialFollowItemType}"
          network="vk"
          style="color: #fff; background-color: ${socialHexColor.vk}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-youtube"
          data-type="${socialFollowItemType}"
          network="youtube"
          style="color: #fff; background-color: ${socialHexColor.youtube}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-pinterest-p"
          data-type="${socialFollowItemType}"
          network="pinterest"
          style="color: #fff; background-color: ${socialHexColor.pinterest}"
        >
        </a>
      </div>
      <style>
        .social-follow-block {
          display: flex;
          justify-content: center;
        }

        .social-follow-block a[data-type="social-follow"] {
          width: ${size}px;
          height: ${size}px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: ${size * 0.5}px;
          text-decoration: none;
          margin: 0 2px;
          border-radius: ${shape === 'round' ? '50%' : '5px'};
          color: #fff;
          background-color: #000;
        }

        .social-follow-block a[data-type="social-follow"]:hover {
          opacity: 0.7;
        }
      </style>
    `,
    attributes: { class: 'bpreview-social-follow-block' },
  });
};

const addSocialShareBlock = (
  editor,
  {
    category,
    socialShareItemType,
    socialShareBlockType,
    socialBlockType,
    size,
    shape,
    color,
  },
) => {
  const bm = editor.BlockManager;
  bm.add(socialShareBlockType, {
    label: 'Social Share',
    category,
    content: `
      <div
        class="social-share-block"
        size="${size}"
        shape="${shape}"
        color="${color}"
        data-type="${socialBlockType}"
      >
        <a
          href=""
          class="fa-brands fa-facebook-f"
          data-type="${socialShareItemType}"
          network="facebook"
          style="color: #fff; background-color: ${socialHexColor.facebook}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-linkedin-in"
          data-type="${socialShareItemType}"
          network="linkedin"
          style="color: #fff; background-color: ${socialHexColor.linkedin}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-x-twitter"
          data-type="${socialShareItemType}"
          network="x"
          style="color: #fff; background-color: ${socialHexColor.x}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-vk"
          data-type="${socialShareItemType}"
          network="vk"
          style="color: #fff; background-color: ${socialHexColor.vk}"
        >
        </a>
        <a
          href=""
          class="fa-brands fa-pinterest-p"
          data-type="${socialShareItemType}"
          network="pinterest"
          style="color: #fff; background-color: ${socialHexColor.pinterest}"
        >
        </a>
      </div>
      <style>
        .social-share-block {
          display: flex;
          justify-content: center;
        }
        .social-share-block a[data-type="social-share"] {
          width: ${size}px;
          height: ${size}px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: ${size * 0.5}px;
          text-decoration: none;
          margin: 0 2px;
          border-radius: ${shape === 'round' ? '50%' : '5px'};
          color: #fff;
          background-color: #000;
        }
        .social-share-block a[data-type="social-share"]:hover {
          opacity: 0.7;
        }
      </style>
    `,
    attributes: { class: 'bpreview-social-share-block' },
  });
};

export default (editor, config) => {
  addSocialFollowBlock(editor, config);
  addSocialShareBlock(editor, config);
};
