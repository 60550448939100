import { useToggleTypes } from 'hooks';

export default (editor) => {
  const cm = editor.Commands;

  cm.add('view-code-modal', {
    run(editor) {
      const target = editor.getModel();

      target.get('onShow')(useToggleTypes.showViewCodeModal);
    },
  });
};
