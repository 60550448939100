/**
 * Function to get only dirty fields values
 * @param {object} values All form values
 * @param {object} dirtyFields Object with the same shape as values object
 * @returns {object} Returns object with dirty values
 * @example
 * // returns { nested: { touched: 'touched' } }
 * getDirtyValues(
 *  { notTouched: 'not touched', nested: { touched: 'touched' } },
 *  { nested: { touched: true } }
 * )
 */
export function getDirtyValues(values, dirtyFields) {
  const res = {};

  for (let key in dirtyFields) {
    if (typeof dirtyFields[key] === 'object') {
      res[key] = getDirtyValues(values[key], dirtyFields[key]);
    } else {
      res[key] = values[key];
    }
  }

  return res;
}
