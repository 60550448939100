import React, { useState, useEffect } from 'react';
import uniqueId from 'lodash.uniqueid';
import { isEmptyObject } from 'helpers';
import { defineMessages, useIntl } from 'react-intl';
import { URL_REGEX_2, TIME_ZONE } from 'appConstants';
import TextField from 'components/Forms/TextField';
import Combobox from 'components/Combobox';
import Switch from 'components/Forms/Switch';
import { useFormContext, Controller } from 'react-hook-form';
import { useEditorContext } from 'routes/Editor/EditorContext';

const messages = defineMessages({
  maxLenExceededValidation: {
    id: 'general-error.max-len-exceeded-validation',
  },
  description: { id: 'page-settings-modal.schedule.description' },
  startDateLabel: { id: 'page-settings-modal.schedule.start-date-label' },
  startDateError: { id: 'page-settings-modal.schedule.start-date-error' },
  endDateError: { id: 'page-settings-modal.schedule.end-date-error' },
  laterStartDateError: {
    id: 'page-settings-modal.schedule.later-start-date-error',
  },
  earlierEndDateError: {
    id: 'page-settings-modal.schedule.earlier-end-date-error',
  },
  endDateLabel: { id: 'page-settings-modal.schedule.end-date-label' },
  redirectUrlLabel: { id: 'page-settings-modal.schedule.redirect-url-label' },
  redirectUrlDescription: {
    id: 'page-settings-modal.schedule.redirect-url-description',
  },
  redirectUrlErrorMessage: {
    id: 'page-settings-modal.schedule.redirect-url-error',
  },
  timezoneLabel: { id: 'page-settings-modal.schedule.timezone-label' },
  timezoneError: { id: 'page-settings-modal.schedule.timezone-error' },
  isScheduledLabel: { id: 'page-settings-modal.schedule.is-scheduled-label' },
  isScheduledDescription: {
    id: 'page-settings-modal.schedule.is-scheduled-description',
  },
});

function ScheduleSettings() {
  const intl = useIntl();

  const [searchTerm, setSearchTerm] = useState('');

  const itemToString = (item) => (item ? item.name : '');
  const normalizeItem = (item) => ({
    key: uniqueId(`${item.name}_`),
    label: item.name,
  });

  const { template } = useEditorContext();

  const scheduleSettingsTimezone = template?.scheduleSettings?.timezone;

  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
  const findClientTimezone = TIME_ZONE.filter((item) =>
    item?.name.toLocaleLowerCase().includes(clientTimezone.toLocaleLowerCase()),
  );

  const filteredTimezone = TIME_ZONE.filter((item) =>
    item?.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
  );

  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext({
    defaultValues: {
      'scheduleSettings.timezone':
        scheduleSettingsTimezone || findClientTimezone?.[0] || '',
    },
  });

  useEffect(() => {
    if (!scheduleSettingsTimezone || scheduleSettingsTimezone === '') {
      setValue('scheduleSettings.timezone', findClientTimezone?.[0], {
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-settings-modal__schedule">
      <div className="page-settings-modal__sub-title">
        {intl.formatMessage(messages.description)}
      </div>
      <div className="page-settings-modal__schedule__date-range">
        <TextField
          data-testid="schedule-start-date"
          {...register('scheduleSettings.startDate', {
            validate: (value) => {
              if (!value) {
                return intl.formatMessage(messages.startDateError);
              }

              if (
                value &&
                Date.parse(getValues('scheduleSettings.endDate')) <
                  Date.parse(value)
              ) {
                return intl.formatMessage(messages.laterStartDateError);
              }
            },
          })}
          type="datetime-local"
          error={Boolean(errors?.scheduleSettings?.startDate)}
          helperText={
            errors?.scheduleSettings?.startDate
              ? errors?.scheduleSettings?.startDate?.message
              : ''
          }
          label={intl.formatMessage(messages.startDateLabel)}
          fullWidth
        />
        <TextField
          data-testid="schedule-end-date"
          {...register('scheduleSettings.endDate', {
            validate: (value) => {
              if (!value) {
                return intl.formatMessage(messages.endDateError);
              }

              if (
                value &&
                Date.parse(value) <
                  Date.parse(getValues('scheduleSettings.startDate'))
              ) {
                return intl.formatMessage(messages.earlierEndDateError);
              }
            },
          })}
          type="datetime-local"
          error={Boolean(errors?.scheduleSettings?.endDate)}
          helperText={
            errors?.scheduleSettings?.endDate
              ? errors?.scheduleSettings?.endDate?.message
              : ''
          }
          label={intl.formatMessage(messages.endDateLabel)}
          fullWidth
        />
        <Controller
          control={control}
          name="scheduleSettings.timezone"
          rules={{
            validate: (value) => {
              if (isEmptyObject(value) || !value) {
                return intl.formatMessage(messages.timezoneError);
              }
            },
          }}
          render={({ field: { onChange } }) => (
            <Combobox
              error={Boolean(errors?.scheduleSettings?.timezone)}
              initialSelectedItem={
                scheduleSettingsTimezone || findClientTimezone?.[0] || ''
              }
              label={intl.formatMessage(messages.timezoneLabel)}
              items={filteredTimezone || TIME_ZONE || []}
              itemToString={itemToString}
              normalizeItem={normalizeItem}
              onInputValueChange={({ inputValue }) => {
                if (!inputValue) {
                  setValue('scheduleSettings.timezone', '');
                }

                setSearchTerm(inputValue);
              }}
              onSelectedItemChange={({ selectedItem }) =>
                onChange(selectedItem || {})
              }
              fullWidth
            />
          )}
        />
      </div>
      <TextField
        data-testid="schedule-redirect-url"
        {...register('scheduleSettings.redirectUrl', {
          pattern: URL_REGEX_2,
          maxLength: {
            value: 255,
            message: intl.formatMessage(messages.maxLenExceededValidation),
          },
        })}
        maxLength={255}
        label={intl.formatMessage(messages.redirectUrlLabel)}
        placeholder="https://"
        helperText={
          errors?.scheduleSettings?.redirectUrl
            ? intl.formatMessage(messages.redirectUrlErrorMessage)
            : intl.formatMessage(messages.redirectUrlDescription)
        }
        error={Boolean(errors?.scheduleSettings?.redirectUrl)}
        fullWidth
      />
      <Controller
        control={control}
        name="isScheduled"
        render={({ field: { onChange, value } }) => (
          <Switch
            disabled={false}
            label={intl.formatMessage(messages.isScheduledLabel)}
            fullWidth
            checked={value || false}
            onChange={(value) => onChange(value)}
            helperText={intl.formatMessage(messages.isScheduledDescription)}
          />
        )}
      />
    </div>
  );
}

export default ScheduleSettings;
