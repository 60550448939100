import axios from 'axios';

const httpGetIconsCategories = () => {
  return axios.get('/api/v2/icons/category');
};

const httpGetIconsStyles = () => {
  return axios.get('/api/v2/icons/style');
};

export { httpGetIconsCategories, httpGetIconsStyles };
