import React from 'react';
import { FormattedMessage } from 'react-intl';
import './MouseScroll.sass';

function MouseScroll() {
  return (
    <div className="mouse-scroll">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30px"
        height="40px"
        viewBox="0 0 247 390"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="1.5">
        <path
          id="mouse-scroll__wheel"
          d="M123.359,79.775l0,72.843"
          fill="none"
          stroke="#000"
          strokeWidth="20px"
        />
        <path
          d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
          fill="none"
          stroke="#000"
          strokeWidth="20px"
        />
      </svg>
      <div>
        <p>
          <FormattedMessage id="mouse-scroll.title-1" />
        </p>
        <p>
          <FormattedMessage id="mouse-scroll.title-2" />
        </p>
      </div>
    </div>
  );
}

export default MouseScroll;
