export const SORTBYLIST = ['lastModified', 'created', 'name'];

export const TEMPLATE_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  DELETED: 'deleted',
  SHARED: 'shared',
  FAVORITED: 'favorited',
  SCHEDULED: 'scheduled',
};
