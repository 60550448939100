import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { REDIRECT_URL } from 'appConstants';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { useFont } from 'hooks';
import { RadioGroup, RadioButton } from 'components/Forms/Radio';
import TextField from 'components/Forms/TextField';
import Switch from 'components/Forms/Switch';
import { Controller, useFormContext } from 'react-hook-form';
import FontsSelect from 'components/Forms/FontsSelect';
import ReactTooltip from 'react-tooltip';

const lineSpacingOptions = [
  { label: 'Inherit', value: 'inherit' },
  { label: 'Tight', value: '1' },
  { label: 'Average', value: '1.5' },
  { label: 'Narrow', value: '2' },
];

const messages = defineMessages({
  headerFontLabel: {
    id: 'page-settings-modal.appearance.header-font-label',
  },
  headerFontDescription: {
    id: 'page-settings-modal.appearance.header-font-description',
  },
  paragraphFontLabel: {
    id: 'page-settings-modal.appearance.paragraph-font-label',
  },
  paragraphFontDescription: {
    id: 'page-settings-modal.appearance.paragraph-font-description',
  },
  lineSpacingLabel: {
    id: 'page-settings-modal.appearance.line-spacing-label',
  },
  lineSpacingDescription: {
    id: 'page-settings-modal.appearance.line-spacing-description',
  },
  containerWidthLabel: {
    id: 'page-settings-modal.appearance.container-width-label',
  },
  containerWidthDescription: {
    id: 'page-settings-modal.appearance.container-width-description',
  },
  plansTooltip: { id: 'plans-tooltip' },
  buttonPlans: { id: 'button.plans' },
  watermarkLabel: { id: 'page-settings-modal.appearance.watermark-label' },
  watermarkDescription: {
    id: 'page-settings-modal.appearance.watermark-description',
  },
});

function Appearance({ subscriptionPlan, userSelectedFonts }) {
  const intl = useIntl();

  const { register, control } = useFormContext();

  const { addStylesheetURL } = useFont();

  const { removeBranding } = subscriptionPlan;

  const modifiedSelectedFontsList =
    userSelectedFonts?.map(({ family, category }) => {
      const newValue = /\s/g.test(family.trim()) ? `"${family}"` : family;

      return { name: family, value: `${newValue}, ${category}` };
    }) || [];

  useEffect(() => {
    addStylesheetURL(userSelectedFonts, '');
  }, [addStylesheetURL, userSelectedFonts]);

  return (
    <div className="page-settings-modal__font">
      <div className="page-settings-modal__row">
        <Controller
          control={control}
          name="appearanceSettings.headerFont"
          render={({ field: { value, onChange } }) => (
            <FontsSelect
              userSelectedFonts={modifiedSelectedFontsList}
              value={value}
              onChange={onChange}
              label={intl.formatMessage(messages.headerFontLabel)}
              helperText={intl.formatMessage(messages.headerFontDescription)}
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="appearanceSettings.paragraphFont"
          render={({ field: { value, onChange } }) => (
            <FontsSelect
              userSelectedFonts={modifiedSelectedFontsList}
              value={value}
              onChange={onChange}
              label={intl.formatMessage(messages.paragraphFontLabel)}
              helperText={intl.formatMessage(messages.paragraphFontDescription)}
              fullWidth
            />
          )}
        />
      </div>
      <div className="page-settings-modal__row">
        <Controller
          control={control}
          name="appearanceSettings.lineSpacing"
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              label={intl.formatMessage(messages.lineSpacingLabel)}
              helperText={intl.formatMessage(messages.lineSpacingDescription)}
              name="lineSpacing"
              fullWidth
              onChange={onChange}>
              {lineSpacingOptions.map((item) => (
                <RadioButton
                  key={item.value}
                  label={
                    <div className="page-settings-modal__radio-item-label">
                      {item.label} <div className={item.label} />
                    </div>
                  }
                  value={item.value}
                />
              ))}
            </RadioGroup>
          )}
        />
      </div>
      <div className="page-settings-modal__row">
        <TextField
          {...register('appearanceSettings.containerWidth')}
          label={intl.formatMessage(messages.containerWidthLabel)}
          placeholder="960"
          helperText={intl.formatMessage(messages.containerWidthDescription)}
          type="number"
          fullWidth
        />
      </div>
      <div
        className={cx('page-settings-modal__row', {
          'page-settings-modal__lock-status': !removeBranding,
        })}>
        <Controller
          control={control}
          name="settings.hideWatermark"
          render={({ field: { onChange, value } }) => (
            <Switch
              label={intl.formatMessage(messages.watermarkLabel)}
              fullWidth
              disabled={!removeBranding}
              checked={value || false}
              onChange={(value) => onChange(value)}
              helperText={intl.formatMessage(messages.watermarkDescription)}
            />
          )}
        />
        {!removeBranding && (
          <div className="page-settings-modal__lock-status__icon">
            <span className="icon icon-lock" data-tip data-for="global" />
            <ReactTooltip
              id="global"
              type="light"
              aria-haspopup="true"
              place="right"
              clickable={true}
              delayHide={300}
              border={true}
              borderColor="#bab6c8"
              effect="solid">
              <span className="icon icon-lock" data-tip data-for="global" />
              <p>
                <FormattedMessage
                  {...messages.plansTooltip}
                  values={{
                    p: (...chunks) => <p>{chunks}</p>,
                    br: <br />,
                  }}
                />
              </p>
              <button
                onClick={() =>
                  window.open(`${REDIRECT_URL}/settings/subscriptions`)
                }>
                {intl.formatMessage(messages.buttonPlans)}
              </button>
            </ReactTooltip>
          </div>
        )}
      </div>
    </div>
  );
}

Appearance.propTypes = {
  subscriptionPlan: PropTypes.object.isRequired,
  userSelectedFonts: PropTypes.array,
};

export default Appearance;
