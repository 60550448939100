import React from 'react';
import PropTypes from 'prop-types';
import { List, CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import cx from 'classnames';
import FontItem from './FontItem';

function FontsList({
  editor,
  isLoading,
  fonts,
  previewText,
  categoryValue,
  searchValueDebounce,
  selectedItems,
  setSelectedItems,
  addStylesheetURL,
}) {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 94,
    minHeight: 94,
  });

  const list =
    fonts &&
    fonts
      .filter(({ category }) =>
        categoryValue === 'all' ? true : category === categoryValue,
      )
      .filter(({ family }) =>
        family.toLowerCase().includes(searchValueDebounce.toLowerCase()),
      )
      .map((font, index) => (
        <FontItem
          editor={editor}
          key={`font-item-${index}`}
          font={font}
          previewText={previewText}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
        />
      ));

  function rowRenderer({ key, index, isVisible, style, parent }) {
    if (isVisible) {
      const { family } = list[index].props.font;

      addStylesheetURL(fonts, family);
    }

    return (
      <CellMeasurer key={key} cache={cache} rowIndex={index} parent={parent}>
        <div style={style}>{list[index]}</div>
      </CellMeasurer>
    );
  }

  return (
    <div data-testid="fonts-list" className="page-settings-modal__fonts__list">
      <div
        className={cx('page-settings-modal__fonts__list__inner-list', {
          loading: isLoading,
        })}>
        {isLoading ? (
          <span className="icon icon-spinner" />
        ) : (
          <List
            width={934}
            height={300}
            rowCount={list?.length}
            rowHeight={94}
            rowRenderer={rowRenderer}
            deferredMeasurementCache={cache}
          />
        )}
      </div>
    </div>
  );
}

FontsList.propTypes = {
  editor: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  fonts: PropTypes.array.isRequired,
  previewText: PropTypes.string.isRequired,
  categoryValue: PropTypes.string.isRequired,
  searchValueDebounce: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  addStylesheetURL: PropTypes.func.isRequired,
};

export default FontsList;
