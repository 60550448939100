import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { TEMPLATE_STATUS } from 'appConstants';
import Button from 'components/Button/Button';
import CopyBox from 'components/CopyBox';
import './PublishedModal.sass';

function PublishedModal({ publishedUrl, onClose }) {
  const publishedSearch = new URLSearchParams({
    status: TEMPLATE_STATUS.PUBLISHED,
  });

  return (
    <div className="published-modal">
      <div className="published-modal__content-wrapper">
        <div className="published-modal__description">
          <FormattedMessage id="published-modal.description" />
        </div>
        <CopyBox value={publishedUrl}>
          <div className="published-modal__link-wrapper">
            <span className="icon icon-link" />
            <div className="published-modal__link">{publishedUrl}</div>
            <span className="icon icon-content-copy" />
          </div>
        </CopyBox>
        <div className="published-modal__description">
          <FormattedMessage id="published-modal.description-2" />
          <Link to={{ pathname: '/', search: String(publishedSearch) }}>
            <FormattedMessage id="published-modal.published-page-link" />
          </Link>
        </div>
      </div>
      <div className="published-modal__buttons">
        <Button type="button" onClick={onClose}>
          <FormattedMessage id="button.back-to-editing" />
        </Button>
        <Button
          as="a"
          href={publishedUrl}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained">
          <FormattedMessage id="button.view-page" />
          <span className="icon icon-open_in_new" />
        </Button>
      </div>
    </div>
  );
}

PublishedModal.propTypes = {
  publishedUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default PublishedModal;
